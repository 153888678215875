import React, { useEffect, useState } from "react"
import { CircularProgress, Dialog, DialogContent, DialogTitle, Slide, Tooltip, Typography } from "@mui/material"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import data from '../../lib/backend/data'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import ListadoViajes from "../../components/Viajes/listado";
import CloseIcon from '@mui/icons-material/Close';
import socket from '../../lib/websockets';


const ContadorViajes = (props) => {
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const titulo = props.titulo ? props.titulo : 'Registros'
    const informacion = props.info ? props.info : ''
    const condicion = props.condicion_default ? props.condicion_default : { }
    const [total, setTotal] = useState(0)
    const [loadingCounter, setLoadingCounter] = useState(true)
    const [modalShow, setMotalShow] = useState(false)
    const showCounter = props.showCounter === true ? true : false

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

    const consultarRegistros = async () => {
        return fetch(`${data.urlapi}/viajes/counter`,{
            method:'POST',
            body: JSON.stringify({
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCounter(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCounter(false)
            } else if(res.total){
                const numero = parseInt(res.total)
                if(isNaN(numero) !== true){
                    setTotal(res.total)
                }
            }
            return setLoadingCounter(false)
        })
        .catch(error => {
            return setLoadingCounter(false)
        })
    }
    
    useEffect(() => {
        consultarRegistros()
        if(condicion.status){
            if(typeof condicion.status === 'string'){
                socket.on(`nuevo_estado`, estados => {
                    if(!estados) return false
                    if(Array.isArray(estados) !== true) return false
                    if(estados.includes(condicion.status) !== false) return consultarRegistros()
                    return false
                })
            }
        }
    },[])

    const closeModal = () => {
        return setMotalShow(false)
    }
    
    const openModal = () => {
        return setMotalShow(true)
    }

    const onSelectedTrip = (id) => {
        if(props.onSelectedTrip) props.onSelectedTrip(id)
        return closeModal()
    }

    const modalDatos = () => {
        if(!modalShow) return false
        return <Dialog
        open={modalShow}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="xl"
      >
        <DialogTitle><CloseIcon style={{ marginBottom: -4 }} onClick={() => closeModal()} /> {titulo}</DialogTitle>
        <DialogContent>        
        <Typography sx={{ mb:1 }}>{informacion}</Typography>
            <ListadoViajes tipo="link" open_new_tab={true} showCounter={showCounter} condicion_default={condicion} onSelectedTrip={(id) => onSelectedTrip(id)} />
        </DialogContent>
        </Dialog>
    }

    return <div>
        <Tooltip title={informacion} placement='left'>
            <HelpOutlineIcon fontSize="small" />
        </Tooltip>
        <h6 style={{ marginBottom: 0, marginTop: -5 }}>{titulo}</h6>
        { loadingCounter ? <CircularProgress /> : <Typography className="hover" variant="h4" onClick={() => openModal()} >{total}</Typography> }
        {modalDatos()}
    </div>

}

export default ContadorViajes