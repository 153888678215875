import { Card, Divider, Grid, Typography } from "@mui/material"

const MostrarProductos = (props) => {
    const productos = props.productos ? props.productos : []
    if(!productos) return false
    if(Array.isArray(productos) !== true) return false
    return <div>
        <Typography variant="h6" sx={{ mb:1 }}>{productos.length} Bultos</Typography>
        {
            productos.map((producto,ip) => {
                return <Card sx={{ p:2, mb:2 }} variant="outlined" >
                    <Grid container spacing={2}>
                    <Grid item md={3} >
                        <Typography variant="p">Ancho</Typography>
                        <Typography variant="h6">{producto.ancho}</Typography>
                    </Grid>
                    
                    <Grid item md={3} >
                        <Typography variant="p">Alto</Typography>
                        <Typography variant="h6">{producto.alto}</Typography>
                    </Grid>
                    <Grid item md={3} >
                        <Typography variant="p">Largo</Typography>
                        <Typography variant="h6">{producto.largo}</Typography>
                    </Grid>
                    <Grid item md={3} >
                        <Typography variant="p">Peso</Typography>
                        <Typography variant="h6">{producto.peso}</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="p" sx={{ fontWeight: "bold" }}>Descripcion</Typography>
                        <Divider  sx={{ mt:1, mb:1 }} />
                        <Typography variant="p" >{producto.descripcion}</Typography>
                    </Grid>
                </Grid>
                </Card>
            })
        }
    </div>
}

export default MostrarProductos