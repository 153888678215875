import React, { useEffect, useState } from "react"
import { CircularProgress, Dialog, DialogContent, DialogTitle, Slide, Tooltip, Typography } from "@mui/material"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import data from '../../lib/backend/data'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import CloseIcon from '@mui/icons-material/Close';
import socket from '../../lib/websockets';
import ListadoConductores from "../../components/Drivers/listado";

const ContadorConductores = (props) => {
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const status = props.estado ? props.estado : 'pending'
    const titulo = props.titulo ? props.titulo : 'Registros'
    const informacion = props.info ? props.info : ''
    const condicion = props.condicion_default ? props.condicion_default : { }
    const [total, setTotal] = useState(0)
    const [loadingCounter, setLoadingCounter] = useState(true)
    const [modalShow, setMotalShow] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

    const consultarRegistros = async () => {
        return fetch(`${data.urlapi}/conductores/counter`,{
            method:'POST',
            body: JSON.stringify({
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCounter(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCounter(false)
            } else if(typeof res.total !== "undefined"){
                const numero = parseInt(res.total)
                if(isNaN(numero) !== true){
                    setTotal(res.total)
                }
            }
            return setLoadingCounter(false)
        })
        .catch(error => {
            return setLoadingCounter(false)
        })
    }
    
    useEffect(() => {
        consultarRegistros()
        const identificador = `conexion_conductor`
        socket.on(identificador, conductor => {
            return consultarRegistros()
        })
        return () => {
            socket?.off(identificador)
        }
    },[])

    const closeModal = () => {
        return setMotalShow(false)
    }
    
    const openModal = () => {
        return setMotalShow(true)
    }

    const onSelectedTrip = (id) => {
        if(props.onSelectedTrip) props.onSelectedTrip(id)
        return closeModal()
    }

    const modalDatos = () => {
        if(!modalShow) return false
        return <Dialog
        open={modalShow}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="xl"
      >
        <DialogTitle><CloseIcon style={{ marginBottom: -4 }} onClick={() => closeModal()} /> {titulo}</DialogTitle>
        <DialogContent>        
        <Typography sx={{ mb:1 }}>{informacion}</Typography>
            <ListadoConductores condicion_default={condicion} onSelectedTrip={(id) => onSelectedTrip(id)} />
        </DialogContent>
        </Dialog>
    }

    return <div>
        <Tooltip title={informacion} placement='left'>
            <HelpOutlineIcon fontSize="small" />
        </Tooltip>
        <h6 style={{ marginBottom: 0, marginTop: -5 }}>{titulo}</h6>
        { loadingCounter ? <CircularProgress /> : <Typography className="hover" variant="h4" onClick={() => openModal()} >{total}</Typography> }
        {modalDatos()}
    </div>

}

export default ContadorConductores