import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, List, ListItem, ListItemButton, ListItemText, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState} from 'react'
import Structure from '../Structure'
import ListadoConductores from './listado'
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link } from 'react-router-dom';
import { urlapi } from '../../lib/backend/data';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import toast from 'react-hot-toast';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { obtenerIdentificadorConductor } from '../../lib/helpers/conductor';
import { LoadingButton } from '@mui/lab';
import ExcelButtonDownload from '../../subcomponents/reports.js/button_download';

const Drivers = () => {
    const [ value, setValue ] = useState(0)
    const dispatch = useDispatch()
    const [ loading, setLoading ] = useState(true)
    const [ conductores, setConductores ] = useState([])
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ pt: 3 }}>{children}</Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
      
    const handleChange = (e, value) => {
        return setValue(value)
    }

    const obtenerConductoresPendientes = async (tipo) => {
      return fetch(`${urlapi}/conductores/requisitos-pendientes`,{
        method:'POST',
        body: JSON.stringify({
            tipo
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
            return setLoading(false)
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
            return setLoading(false)
        } else if(Array.isArray(res) !== false){
            setConductores(res)
        }
        return setLoading(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setLoading(false)
    })
    }

    useEffect(() => {
      obtenerConductoresPendientes("conductor")
    }, [])

    const conductoresPendientes = () => {
      if(conductores.length < 1) return false
      return <div style={{ marginTop: 20 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6"><ErrorIcon color="error" /> {conductores.length} Conductores con requisitos pendientes de aprobación</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              { conductores.map((driver,id) => {
                return <ListItem key={`dri-${id}`} disablePadding>
                  <Link to={`driver-${driver._id}`} style={{ width: "100%", textDecoration: "none", color:"black" }}>
                  <ListItemButton>
                  <ListItemText primary={ obtenerIdentificadorConductor(driver)} />
                  </ListItemButton>
                  </Link>
                </ListItem>
              }) }
            </List>
          </AccordionDetails>
        </Accordion>
        
      </div>
    }
    
    const mostrarLista = () => {
      return <div>
        <Box sx={{ width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="primary tabs example"
                >
                    <Tab iconPosition="start" value={0} label="Activos" {...a11yProps(0)} />
                    <Tab iconPosition="start" value={1} label="Archivados" {...a11yProps(1)} />
                    <Tab iconPosition="start" value={2} label="Sin empresa asignada" {...a11yProps(1)} />
                    <Tab iconPosition="start" value={3} label="Sin vehículo asignado" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <ListadoConductores title="activos" condicion_default={{ status: { $nin: ['trash'] } }} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ListadoConductores title="en papelera" condicion_default={{ status: "trash" }} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ListadoConductores title="sin empresa asignada" condicion_default={{ propietario: { $in: ['',null,false] } }} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ListadoConductores title="sin vehiculo asignado" exclude_without_vehicle={true} condicion_default={{ }} />
            </TabPanel>
      </div>
    }

    const render = () => {
        return <div>
            <h1 style={{ margin: 0 }}>Conductores</h1>
            <Link to={`/drivers-new`}><Button variant="outlined">CREAR</Button></Link>
            <Link to={`/drivers/import`}><Button variant="outlined">IMPORTAR</Button></Link>
            {conductoresPendientes()}
            {mostrarLista()}
        </div>
    }

    return <Structure component={render()} />
}

export default Drivers