import { Box, Breadcrumbs, Button, CircularProgress, Grid, Tab, Tabs, TextField, Typography } from '@mui/material'
import React, { useState} from 'react'
import Structure from '../Structure'
import { Link, useParams } from 'react-router-dom'
import { Link as Enlace } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data'
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import { validateEmail } from '../../lib/helpers/helpers';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main'
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda'

const UsersCreate = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [usuario, setUsuario] = useState({})
    const sesion = useSelector(state => state.miusuario)
    const requeridos = [
        { value:'nombres', label: 'Nombres' },
        { value:'apellidos', label: 'Apellidos' },
        { value:'phone', label: 'Móvil' },
        { value:'password', label: 'Contraseña' },
        { value:'repeat_password', label: 'Repetir contraseña' },
        { value:'email', label: 'Email' }
    ]
    
    const handleChange = (e) => {
        const { name, value } = e.target
        usuario[name] = value
        console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const crearUsuario = async () => {
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        if(usuario.password !== usuario.repeat_password) return toast.error("Contraseñas no coinciden")

        const email_valido = validateEmail(usuario.email)
        if(!email_valido) return toast.error("Email inválido")
        
        setLoading(true)
        return fetch(`${data.urlapi}/usuarios`,{
            method:'POST',
            body: JSON.stringify(usuario),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                toast.success("Creado exitosamente")
                setTimeout(() => {
                    return window.location = `/user-${res._id}`
                }, 500);
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const render = () => {
        return <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link to="/users">Usuarios</Link>
                <Enlace underline="hover" color="text.primary" >Crear conductor</Enlace>
            </Breadcrumbs>
            <h1 style={{ margin: 0 }}>Crear usuario</h1>
            <Grid container spacing={2} sx={{ mt:1 }} >
                <Grid item md={12} xs={12}>
                    <Typography variant='h6'>Datos personales</Typography>
                    <CamposObligatoriosLeyenda />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField
                        label="Nombres"
                        variant="filled"
                        name="nombres"
                        required={siEsRequeridoEnEsquema("nombres",requeridos)}
                        fullWidth
                        onChange={handleChange}
                        />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField
                        label="Apellidos"
                        variant="filled"
                        name="apellidos"
                        required={siEsRequeridoEnEsquema("apellidos",requeridos)}
                        fullWidth
                        onChange={handleChange}
                        />
                </Grid>
                <Grid item md={2} xs={12}>
                    <TextField
                        label="Email"
                        variant="filled"
                        name="email"
                        required={siEsRequeridoEnEsquema("email",requeridos)}
                        fullWidth
                        onChange={handleChange}
                        />
                </Grid>
                <Grid item md={2} xs={12}>
                    <TextField
                        label="Rut"
                        variant="filled"
                        name="rut"
                        required={siEsRequeridoEnEsquema("rut",requeridos)}
                        fullWidth
                        onChange={handleChange}
                        />
                </Grid>
                <Grid item md={2} xs={12}>
                    <TextField
                        label="Teléfono"
                        variant="filled"
                        helperText="Ejemplo: 56912345678"
                        name="phone"
                        required={siEsRequeridoEnEsquema("phone",requeridos)}
                        fullWidth
                        onChange={handleChange}
                        />
                </Grid>
                <Grid item md={12} xs={12}><Typography variant='h6'>Seguridad</Typography></Grid>
                <Grid item md={2} xs={12} >
                    <TextField
                        label="Contraseña"
                        variant="filled"
                        name="password"
                        required={siEsRequeridoEnEsquema("password",requeridos)}
                        type="password"
                        fullWidth
                        onChange={handleChange}
                        />
                </Grid>
                <Grid item md={2} xs={12} >
                    <TextField
                        label="Repetir contraseña"
                        variant="filled"
                        name="repeat_password"
                        required={siEsRequeridoEnEsquema("repeat_password",requeridos)}
                        type="password"
                        fullWidth
                        onChange={handleChange}
                        />
                </Grid>
                <Grid item md={12} xs={12}>
                    {
                        loading === true ? <CircularProgress /> : <Button variant="contained" xs={{ m:0}} onClick={() => crearUsuario()} >CREAR</Button>
                    }
                </Grid>
            </Grid>            
        </div>
    }

    return <Structure component={render()} />
}

export default UsersCreate