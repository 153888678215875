import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import { PrivateRoute } from './components/PrivateRoute';
import Registrarse from './components/Registrarse';
import { Toaster } from 'react-hot-toast';
import Drivers from './components/Drivers';
import { PersistGate } from 'redux-persist/integration/react';
import reduxStore from '../src/redux/store';
import DriverDetails from './components/Drivers/details';
import Vehicles from './components/Vehicles';
import Operation from './components/Operacion';
import Users from './components/Usuarios';
import UsersCreate from './components/Usuarios/crear';
import UsersDetails from './components/Usuarios/detalles';
import Configuracion from './components/Configuracion';
import VehiclesTypes from './components/VehiclesTypes';
import VehicleTypesDetails from './components/VehiclesTypes/detalles';
import VehicleTypeCreate from './components/VehiclesTypes/crear';
import Soporte from './components/Soporte';
import SoporteCreate from './components/Soporte/crear';
import SoporteDetails from './components/Soporte/detalles';
import SubSoporte from './components/SubSoporte';
import SubSoporteCreate from './components/SubSoporte/crear';
import SubSoporteDetails from './components/SubSoporte/detalles';
import SeguroCarga from './components/SeguroCarga/index';
import SeguroCreate from './components/SeguroCarga/crear';
import SeguroDetails from './components/SeguroCarga/detalles';
import RequisitosConfig from './components/RequisitosConfig';
import RequisitosCreate from './components/RequisitosConfig/crear';
import RequisitoDetails from './components/RequisitosConfig/detalles';
import Opciones from './components/Calificaciones_Opciones.js/index';
import OpcionCreate from './components/Calificaciones_Opciones.js/crear';
import OpcionDetails from './components/Calificaciones_Opciones.js/detalles';
import Valores from './components/Calificaciones_Valores.js';
import ValoresDetails from './components/Calificaciones_Valores.js/detalles';
import ValoresCreate from './components/Calificaciones_Valores.js/crear';
import Calificaciones from './components/Calificaciones';
import CalificacionesDetails from './components/Calificaciones/index';
import Clientes from './components/Clientes';
import ClientesCreate from './components/Clientes/crear';
import ClientsDetails from './components/Clientes/detalles';
import Ratings from './components/Calificaciones';
import MarcaVehiculo from './components/MarcaVehiculos';
import MarcaCreate from './components/MarcaVehiculos/crear';
import MarcaDetails from './components/MarcaVehiculos/detalles';
import ModeloCreate from './components/ModeloVehiculos/crear';
import Modelo from './components/ModeloVehiculos';
import ModeloDetails from './components/ModeloVehiculos/detalles';
import VehiclesCreate from './components/Vehicles/crear';
import TipoMotorCreate from './components/TipoMotor/crear';
import TipoMotor from './components/TipoMotor';
import TipoDetails from './components/TipoMotor/detalles';
import VehiclesDetails from './components/Vehicles/detalles';
import Banco from './components/Banco';
import BancoCreate from './components/Banco/crear';
import BancoDetails from './components/Banco/detalles';
import Publicacion from './components/Publicacion';
import PublicacionCreate from './components/Publicacion/crear';
import PublicacionDetails from './components/Publicacion/detalles';
import ColoresVehiculos from './components/ColoresVehiculos/';
import ColorCreate from './components/ColoresVehiculos/crear';
import ColorDetails from './components/ColoresVehiculos/detalles';
import DriversCreate from './components/Drivers/crear';
import AtencionCliente from './components/AtencionCliente';
import Geocercas from './components/Geocercas';
import GeocercaDetails from './components/Geocercas/detalles';
import GeocercaCrear from './components/Geocercas/crear';
import DetallesViaje from './components/Viajes/detalles';
import Empresas from './components/Empresas';
import EmpresaDetalles from './components/Empresas/detalles';
import EmpresasCrear from './components/Empresas/crear';
import Notificaciones from './components/Notificaciones';
import Viajes from './components/Viajes';
import Ganancias from './components/Ganancias';
import VehiclesImport from './components/Vehicles/importar';
import DriversImport from './components/Drivers/importar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import DetallesUsuarioProveedor from './components/Proveedores/Usuarios/detalles';
import 'mapbox-gl/dist/mapbox-gl.css'; 
import FormularioTerminosCondiciones from './components/Configuracion/formulario_terminos_condiciones';
import Equipments from './components/Equipments';
import { rutas } from './lib/data/rutas';
import EquipmentDetails from './components/Equipments/detalles';
import EquipmentCreate from './components/Equipments/crear';
import EquipmentImport from './components/Equipments/importar';
import Contactos from './components/Contactos';
import ContactoDetails from './components/Contactos/detalles';
import ContactosImport from './components/Contactos/importar';
import ContactoCreate from './components/Contactos/crear';
import Destinatarios from './components/Destinatarios';
import DestinatariosImport from './components/Destinatarios/importar';
import DestinatarioCreate from './components/Destinatarios/crear';
import DestinatarioDetails from './components/Destinatarios/detalles';
import Bodegas from './components/Bodegas';
import BodegasImport from './components/Bodegas/importar';
import BodegaCreate from './components/Bodegas/crear';
import BodegaDetails from './components/Bodegas/detalles';
import CamposPersonalizados from './components/CamposPersonalizados/index.js';
import CamposPersonalizadosImport from './components/CamposPersonalizados/importar.js';
import CamposPersonalizadosCreate from './components/CamposPersonalizados/crear.js';
import CamposPersonalizadosDetails from './components/CamposPersonalizados/detalles.js';
import Categorias from './components/Categorias/index.js';
import CategoriaCrear from './components/Categorias/crear.js';
import CategoriaDetails from './components/Categorias/detalles.js';
import CategoriaImport from './components/Categorias/importar.js';
import Productos from './components/Productos/index.js';
import ProductoCreate from './components/Productos/crear.js';
import ProductoDetails from './components/Productos/detalles.js';
import ProductosImport from './components/Productos/importar.js';
import Costos from './components/Costos/index.js';
import CostosImport from './components/Costos/importar.js';
import CostosCreate from './components/Costos/crear.js';
import CostosDetails from './components/Costos/detalles.js';
import EstadosCarga from './components/EstadosCarga/index.js';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Chart, LineController, LineElement, PointElement, BarElement, LinearScale, Tooltip, Title,CategoryScale, ArcElement, Legend } from 'chart.js';
import Ordenes from './components/Ordenes/index.js';
import ViajesReporte from './components/Viajes/reporte.js';
Chart.register(LineController, LineElement, PointElement, BarElement, Legend, LinearScale, ArcElement, Title, Tooltip, CategoryScale);

const { store, persistor } = reduxStore();

const App = (
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Toaster position='bottom-center' reverseOrder={false} />
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path='/' component={Home} />
          <PrivateRoute exact path='/configuration' component={Configuracion} />

          <PrivateRoute exact path='/terms-conditions' component={FormularioTerminosCondiciones} />

          <PrivateRoute exact path='/notifications' component={Notificaciones} />
          
          <PrivateRoute exact path='/users' component={Users} />
          <PrivateRoute exact path='/users-new' component={UsersCreate} />
          <PrivateRoute exact path='/providers/user-:id' component={DetallesUsuarioProveedor} />
          <PrivateRoute exact path='/user-:id' component={UsersDetails} />

          <PrivateRoute exact path='/viajes' component={Viajes} />
          <PrivateRoute exact path='/viajes-reporte' component={ViajesReporte} />
          <PrivateRoute exact path='/viajes/status/:status' component={Viajes} />
          <PrivateRoute exact path='/viaje-:id' component={DetallesViaje} />

          <PrivateRoute exact path='/operation' component={Operation} />

          <PrivateRoute exact path='/earnings' component={Ganancias} />

          <PrivateRoute exact path='/geocercas' component={Geocercas} />
          <PrivateRoute exact path='/geocerca-new' component={GeocercaCrear} />
          <PrivateRoute exact path='/geocerca-:id' component={GeocercaDetails} />

          <PrivateRoute exact path={`${rutas.equipments.slug}-new`} component={EquipmentCreate} />
          <PrivateRoute exact path={rutas.equipments.slug} component={Equipments} />
          <PrivateRoute exact path={`${rutas.equipments.slug}-:id`} component={EquipmentDetails} />
          <PrivateRoute exact path={`${rutas.equipments.slug}/import`} component={EquipmentImport} />

          <PrivateRoute exact path={rutas.contacts.slug} component={Contactos} />
          <PrivateRoute exact path={`${rutas.contacts.slug}/import`} component={ContactosImport} />
          <PrivateRoute exact path={`${rutas.contacts.slug}-new`} component={ContactoCreate} />
          <PrivateRoute exact path={`${rutas.contacts.slug}-:id`} component={ContactoDetails} />
{/*           
          <PrivateRoute exact path={rutas.service_types.slug} component={TipoServicio} />
          <PrivateRoute exact path={`${rutas.service_types.slug}/import`} component={TipoServicioImport} />
          <PrivateRoute exact path={`${rutas.service_types.slug}-new`} component={TipoServicioCreate} />
          <PrivateRoute exact path={`${rutas.service_types.slug}-:id`} component={TipoServicioDetails} /> */}

          <PrivateRoute exact path={rutas.categories.slug} component={Categorias} />
          <PrivateRoute exact path={`${rutas.categories.slug}/import`} component={CategoriaImport} />
          <PrivateRoute exact path={`${rutas.categories.slug}-new`} component={CategoriaCrear} />
          <PrivateRoute exact path={`${rutas.categories.slug}-:id`} component={CategoriaDetails} />

          <PrivateRoute exact path={rutas.custom_fields.slug} component={CamposPersonalizados} />
          <PrivateRoute exact path={`${rutas.custom_fields.slug}/import`} component={CamposPersonalizadosImport} />
          <PrivateRoute exact path={`${rutas.custom_fields.slug}-new`} component={CamposPersonalizadosCreate} />
          <PrivateRoute exact path={`${rutas.custom_fields.slug}-:id`} component={CamposPersonalizadosDetails} />

          <PrivateRoute exact path={rutas.bodegas.slug} component={Bodegas} />
          <PrivateRoute exact path={`${rutas.bodegas.slug}/import`} component={BodegasImport} />
          <PrivateRoute exact path={`${rutas.bodegas.slug}-new`} component={BodegaCreate} />
          <PrivateRoute exact path={`${rutas.bodegas.slug}-:id`} component={BodegaDetails} />
          
          <PrivateRoute exact path={rutas.estados_carga.slug} component={EstadosCarga} />

          <PrivateRoute exact path={rutas.ordenes.slug} component={Ordenes} />
          <PrivateRoute exact path={`${rutas.ordenes.slug}/import`} component={DestinatariosImport} />
          <PrivateRoute exact path={`${rutas.ordenes.slug}-new`} component={DestinatarioCreate} />
          <PrivateRoute exact path={`${rutas.ordenes.slug}-:id`} component={DestinatarioDetails} />

          <PrivateRoute exact path={rutas.destinatarios.slug} component={Destinatarios} />
          <PrivateRoute exact path={`${rutas.destinatarios.slug}/import`} component={DestinatariosImport} />
          <PrivateRoute exact path={`${rutas.destinatarios.slug}-new`} component={DestinatarioCreate} />
          <PrivateRoute exact path={`${rutas.destinatarios.slug}-:id`} component={DestinatarioDetails} />
          
          <PrivateRoute exact path={rutas.productos.slug} component={Productos} />
          <PrivateRoute exact path={`${rutas.productos.slug}/import`} component={ProductosImport} />
          <PrivateRoute exact path={`${rutas.productos.slug}-new`} component={ProductoCreate} />
          <PrivateRoute exact path={`${rutas.productos.slug}-:id`} component={ProductoDetails} />
          
          <PrivateRoute exact path={rutas.costos.slug} component={Costos} />
          <PrivateRoute exact path={`${rutas.costos.slug}/import`} component={CostosImport} />
          <PrivateRoute exact path={`${rutas.costos.slug}-new`} component={CostosCreate} />
          <PrivateRoute exact path={`${rutas.costos.slug}-:id`} component={CostosDetails} />

          <PrivateRoute exact path='/vehicles' component={Vehicles} />
          <PrivateRoute exact path='/vehicles-new' component={VehiclesCreate} />
          <PrivateRoute exact path='/vehicles-:id' component={VehiclesDetails} />
          <PrivateRoute exact path='/color-vehicles' component={ColoresVehiculos} />
          <PrivateRoute exact path='/color-vehicles-new' component={ColorCreate} />
          <PrivateRoute exact path='/color-vehicles-:id' component={ColorDetails} />
          <PrivateRoute exact path={rutas.vehicles_brands_new.slug} component={MarcaCreate} />
          <PrivateRoute exact path={`${rutas.vehicles_brands.slug}-:id`} component={MarcaDetails} />
          <PrivateRoute exact path='/vehicles/import' component={VehiclesImport} />
          <PrivateRoute exact path='/model/vehicles' component={Modelo} />
          <PrivateRoute exact path='/model/vehicles-new' component={ModeloCreate} />
          <PrivateRoute exact path='/model/vehicles-:id' component={ModeloDetails} />
          <PrivateRoute exact path='/configuration-vehicles-type' component={VehiclesTypes} />
          <PrivateRoute exact path='/configuration-vehicles-type-new' component={VehicleTypeCreate} />
          <PrivateRoute exact path='/configuration-vehicles-type-:id' component={VehicleTypesDetails} />
          <PrivateRoute exact path={`${rutas.vehicles_brands.slug}`} component={MarcaVehiculo} />

          <PrivateRoute exact path='/drivers' component={Drivers} />
          <PrivateRoute exact path='/drivers/import' component={DriversImport} />
          <PrivateRoute exact path='/driver-:id' component={DriverDetails} />
          <PrivateRoute exact path='/drivers-new' component={DriversCreate} />

          <PrivateRoute exact path='/soporte' component={Soporte} />
          <PrivateRoute exact path='/soporte-new' component={SoporteCreate} />
          <PrivateRoute exact path='/soporte-:id' component={SoporteDetails} />

          <PrivateRoute exact path='/empresas-:id' component={EmpresaDetalles} />
          <PrivateRoute exact path='/empresas' component={Empresas} />
          <PrivateRoute exact path='/empresa-new' component={EmpresasCrear} />

          <PrivateRoute exact path='/clients' component={Clientes} />
          <PrivateRoute exact path='/clients-new' component={ClientesCreate} />
          <PrivateRoute exact path='/client-:id' component={ClientsDetails} />

          <PrivateRoute exact path='/banco' component={Banco} />
          <PrivateRoute exact path='/banco-new' component={BancoCreate} />
          <PrivateRoute exact path='/banco-:id' component={BancoDetails} />

          <PrivateRoute exact path='/publicacion' component={Publicacion} />
          <PrivateRoute exact path='/publicacion-new' component={PublicacionCreate} />
          <PrivateRoute exact path='/publicacion-:id' component={PublicacionDetails} />

          <PrivateRoute exact path='/customer-service' component={AtencionCliente} />

          <PrivateRoute exact path='/ratings' component={Ratings} />

          <PrivateRoute exact path='/subsoporte' component={SubSoporte} />
          <PrivateRoute exact path='/subsoporte-new' component={SubSoporteCreate} />
          <PrivateRoute exact path='/subsoporte-:id' component={SubSoporteDetails} />

          <PrivateRoute exact path='/seguro-carga-new' component={SeguroCreate} />
          <PrivateRoute exact path='/seguro-carga-:id' component={SeguroDetails} />
          <PrivateRoute exact path='/seguro-carga' component={SeguroCarga} />

          <PrivateRoute exact path='/requisitos-new' component={RequisitosCreate} />
          <PrivateRoute exact path='/requisitos-:id' component={RequisitoDetails} />
          <PrivateRoute exact path='/requisitos' component={RequisitosConfig} />

          <PrivateRoute exact path='/calificaciones' component={Calificaciones} />
          <PrivateRoute exact path='/calificaciones-:id' component={CalificacionesDetails} />
          <PrivateRoute exact path='/calificaciones/opciones' component={Opciones} />
          <PrivateRoute exact path='/calificaciones/opciones-new' component={OpcionCreate} />
          <PrivateRoute exact path='/calificaciones/opciones-:id' component={OpcionDetails} />
          <PrivateRoute exact path='/calificaciones/valores' component={Valores} />
          <PrivateRoute exact path='/calificaciones/valores-new' component={ValoresCreate} />
          <PrivateRoute exact path='/calificaciones/valores-:id' component={ValoresDetails} />
          
          <PrivateRoute exact path='/motor-type' component={TipoMotor} />
          <PrivateRoute exact path='/motor-type-new' component={TipoMotorCreate} />
          <PrivateRoute exact path='/motor-type-:id' component={TipoDetails} />
          
          <Route path='/login' component={Login} />
          <Route path='/registrarse' component={Registrarse} />
        </Switch>
      </BrowserRouter>
    </PersistGate>
    </LocalizationProvider>
  </Provider>
);

ReactDOM.render(App, document.getElementById('root'));