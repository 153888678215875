import { useEffect, useState } from "react";
import { urlapi } from "../../lib/backend/data";
import { cerrarSesion } from "../../redux/actions/session";
import CargandoTabla from "./cargando";
import { Box, Card, CardContent, Grid, Skeleton, Tooltip, Typography } from "@mui/material";
import { toast } from "react-hot-toast";

const { useSelector, useDispatch } = require("react-redux");

const ContadoresEmpresa = (props) => {
    const {
        selected
    } = props
    const sesion = useSelector((state) => state.miusuario)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const dispatch = useDispatch();
    const [ loading, setLoading ] = useState(false)
    const [ filtros, setFiltros] = useState([])

    const obtenerDatos = async () => {
        setLoading(true);
        return fetch(`${urlapi}/empresas/contadores`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
        },
        })
        .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
        })
        .then((res) => {
            if (!res) {
            toast.error('Sin datos');
            } else if (res.errorMessage) {
            toast.error(res.errorMessage);
            } else if (Array.isArray(res) !== false) {
                setFiltros(res)
            }
            return setLoading(false);
        })
        .catch((error) => {
            toast.error('Error al consultar la información, intente nuevamente');
            return setLoading(false);
        })
    }

    const onClick = (condicion, llave) => {
        if(condicion){
            if(typeof condicion === "object"){
                if(props.onClick) props.onClick(condicion, llave)
            }
        }
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const mostrarRegistros = () => {
        if(loading) return <div>
            <Grid container spacing={3}>
                <Grid item md={2}> <Skeleton height={100} /></Grid>
                <Grid item md={2}> <Skeleton height={100} /></Grid>
                <Grid item md={2}> <Skeleton height={100} /></Grid>
                <Grid item md={2}> <Skeleton height={100} /></Grid>
                <Grid item md={2}> <Skeleton height={100} /></Grid>
                <Grid item md={2}> <Skeleton height={100} /></Grid>
            </Grid>
        </div>


return <Box mb={3}>
            <Grid container spacing={3}>
                {
                    filtros.map(filtro => {
                        const estilo = selected === filtro.label ? { border: "2px solid #07539e" } : {  }
                        return <Grid item md={2} >
                            <Tooltip title="Toca para filtrar" placement="top">
                            <Card className="hover" style={estilo} p={10} onClick={() => onClick(filtro.condicion, filtro.label)}>
                                <CardContent>
                                    <Typography mb={0} style={{ fontSize: 10}} mt={0}>{filtro.label}</Typography>
                                    <Typography variant="h5" mb={0} mt={0}>{filtro.cantidad}</Typography>
                                </CardContent>
                            </Card>
                            </Tooltip>
                        </Grid>
                    })
                }
            </Grid>
        </Box>
    }

    return <div>
        {mostrarRegistros()}
    </div>
}

export default ContadoresEmpresa