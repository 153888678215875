import React, { Component} from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-hot-toast';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { userService } from '../../services/user.service'
import { CircularProgress } from '@mui/material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        WMS Softnet
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

class Registrarse extends Component {
    constructor(){
        super()
        this.state = {
            user: JSON.parse(localStorage.getItem('wms_softnet_user')),
            nombres: '',
            apellidos:'',
            password: '',
            email: '',
            loading: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeStatus = this.handleChangeStatus.bind(this)
    }

    handleChangeStatus(e){
      const { name, value } = e.target
      return this.setState({ [name]: value })
  }

    handleSubmit(e){
        e.preventDefault()
        const { nombres, apellidos, email, password } = this.state

        if(!nombres) return toast.error('Falta el nombre')
        if(!apellidos) return toast.error('Falta el apellido')
        if(!email) return toast.error('Falta el email')
        if(!password) return toast.error('Falta la contraseña')
        if(password.length < 5) return toast.error('La contraseña debe tener al menos 5 carácteres')

        this.setState({ loading: true });
        userService.register( nombres, apellidos, email, password )
            .then(
                user => {
                  console.log(user)
                    if(user.tokenSession){
                        const { from } = this.props.location.state || { from: { pathname: "/" } };
                        this.props.history.push(from);
                    } else if( user.errorMessage ){
                        toast.error(user.errorMessage)
                        this.setState({ loading: false })
                    }
                    return this.setState({ loading: false })
                },
                error => this.setState({ error, loading: false })
            )
    }

    render(){
      const { loading } = this.state
      return (
            <ThemeProvider theme={theme}>
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Regístrate
                  </Typography>
                  <Box component="form" noValidate onSubmit={this.handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="given-name"
                          name="nombres"
                          required
                          fullWidth
                          id="firstName"
                          label="Nombres"
                          onChange={this.handleChangeStatus}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="lastName"
                          label="Apellidos"
                          name="apellidos"
                          autoComplete="family-name"
                          onChange={this.handleChangeStatus}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          autoComplete="email"
                          onChange={this.handleChangeStatus}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label="Contraseña"
                          type="password"
                          id="password"
                          autoComplete="new-password"
                          onChange={this.handleChangeStatus}
                        />
                      </Grid>
                    </Grid>
                    {
                      loading ? <div>
                          <CircularProgress />
                      </div> : <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >REGISTRARME</Button>
                    }
                    <Grid container justifyContent="flex-middle">
                      <Grid item>
                        <Link to="/login" variant="body2">
                          ¿Ya tienes una cuenta? Inicia sesión
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
              </Container>
            </ThemeProvider>
          )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Registrarse);