import { useDispatch, useSelector } from "react-redux"
import ReactFlagsSelect from "react-flags-select";
import { useState } from "react";
import { cambiarPais } from "../../redux/actions/pais";

const SelectorPais = (props) => {
    const dispatch = useDispatch()
    const usuario = useSelector(state => state.miusuario)
    const idioma = useSelector(state => state.idioma)
    const pais = useSelector(state => state.pais)
    const [selected, setSelected] = useState(pais);

    return <div>
    <ReactFlagsSelect
        className="selector_pais"
        selected={selected}
        onSelect={(code) => {
            if(pais === code) return false
            setSelected(code)
            dispatch(cambiarPais(code))
            window.location = "/"
        }}
        countries={["BR", "CL", "CO", "MX"]}
        // customLabels={{ US: "EN-US", GB: "EN-GB", FR: "FR", DE: "DE", IT: "IT" }}
        placeholder="Selecciona país"

    />
    </div>
}

export default SelectorPais