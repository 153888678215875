import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data, { configs3 } from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import PropTypes from 'prop-types';
import SinData from '../General/sin_data';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import S3FileUpload from 'react-s3/lib/ReactS3';
import ConfirmationAlert from '../../subcomponents/main/confirmation';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';

const PublicacionDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [loading, setLoading] = useState(false);
  const [publicacion, setPublicacion] = useState(false);
  const [loadingFoto, setLoadingFoto] = useState(false);
  const [permisosOtorgados, setPermisosOtorgados] = useState([])
  const [loadingEliminado, setLoadingEliminado] = useState(false)
  const sesion = useSelector((state) => state.miusuario);
  const { id } = useParams();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const eliminarPublicacion = async () => {
    setLoadingEliminado(true)
    return fetch(`${data.urlapi}/publicacion?id=${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingEliminado(false)
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingEliminado(false)
        } else if (res._id) {
          toast.success("Eliminado exitosamente")
          setTimeout(() => {
              return window.location.href = "/publicacion"
          }, 500);
        }
        return setLoadingEliminado(false)
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingEliminado(false)
      });
  }

  const obtenerPublicacion = async () => {
    return fetch(`${data.urlapi}/publicacion?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setPublicacion(res);
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      });
  }

  useEffect(() => {
    obtenerPublicacion();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    publicacion[name] = value;
    console.log(publicacion);
    return setPublicacion({ ...{}, ...publicacion });
  };

  const actualizarPublicacion = async () => {
    const requeridos = [
      { value: 'titulo', label: 'Título' },
      { value: 'tipo', label: 'Tipo' },
      { value: 'tipo_destino', label: 'Tipo de destino' },
      { value: 'descripcion', label: 'Descripcion' },
    ];
    let faltantes = [];
    requeridos.map((campo) => {
      if (!publicacion[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    publicacion.permisos = permisosOtorgados;

    setLoading(true);
    return fetch(`${data.urlapi}/publicacion`, {
      method: 'PUT',
      body: JSON.stringify(publicacion),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const handleImage = (e) => {
    if(e.target.files.length < 1) return toast.error('Sin archivos encontrados')
        const archivo = e.target.files[0]
        setLoadingFoto(true)
        return S3FileUpload.uploadFile(archivo, configs3)
        .then(data => {
        if(!data.location) {
            toast.error('No pudimos cargar tu imagen')
            return setLoadingFoto(false)
        }
        publicacion.imagen = data.location
        setPublicacion({ ...{}, ...publicacion })
        return setLoadingFoto(false)
  })
  .catch(err => {
      setLoadingFoto(false)
      return toast.error('No pudimos cargar tu imagen')
      })
  }

  const render = () => {
    if (loadingMaster) return <CircularProgress />;
    if (!publicacion) return <SinData />;
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Href to='/publicacion'>Publicación</Href>
          <Enlace underline='hover' color='text.primary'>
            Editar Publicación
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Editar Publicación</h1>
        <CamposObligatoriosLeyenda />
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography variant='h6'>
                  <SettingsIcon sx={{ mb: -0.7 }} /> Detalles
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={{ textAlign: "right" }}>
                <ConfirmationAlert 
                    loading={loadingEliminado}
                    onConfirm={() => eliminarPublicacion()}
                  />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  label='Título'
                  variant='filled'
                  name='titulo'
                  required
                  fullWidth
                  defaultValue={publicacion.titulo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  label='Descripción'
                  variant='filled'
                  name='descripcion'
                  required
                  multiline
                  rows={4}
                  fullWidth
                  defaultValue={publicacion.descripcion}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel required>Tipo</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Tipo'
                    name='tipo'
                    onChange={handleChange}
                    defaultValue={publicacion.tipo}
                  >
                    <MenuItem value='pregunta-frecuente'>Preguntas frecuentes</MenuItem>
                    <MenuItem value='destacado'>Destacado</MenuItem>
                    <MenuItem value='pantalla-conexion'>Pantalla de conexión</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel required>Destino</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Destino'
                    name='tipo_destino'
                    onChange={handleChange}
                    defaultValue={publicacion.tipo_destino}
                  >
                    <MenuItem value=''>Seleccione</MenuItem>
                    <MenuItem value='cliente'>Cliente</MenuItem>
                    <MenuItem value='conductor'>Conductor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel required>Status</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Status'
                    name='status'
                    onChange={handleChange}
                    defaultValue={publicacion.status}
                  >
                    <MenuItem value=''>Seleccione</MenuItem>
                    <MenuItem value='active'>Activo</MenuItem>
                    <MenuItem value='trash'>Inactivo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={2} xs={12}>
                {
                  loadingFoto ? 
                  <CircularProgress /> : 
                  <Button variant='contained' component='label'>
                    <ImageIcon /> Carga imagen
                    <input type='file' hidden onChange={handleImage} />
                  </Button>
                }
                
              </Grid>
              <Grid item md={12} xs={12}>
                {
                publicacion.imagen ? <div style={{ marginTop: 20 }}>
                    <img width={300} alt={publicacion.titulo} src={publicacion.imagen} />
                </div> : false
                }
                {loading === true ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant='contained'
                    color='success'
                    xs={{ m: 0 }}
                    onClick={() => actualizarPublicacion()}
                  >
                    GUARDAR CAMBIOS
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default PublicacionDetails;
