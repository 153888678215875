import CargandoTabla from "./cargando"
import { Box, Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, LinearProgress, MenuItem, Pagination, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from "react"
import data from '../../lib/backend/data'
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { fechaATexto } from "../../lib/helpers/helpers"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ListadoEventos = (props) => {
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ vehiculos, setVehiculos] = useState([])
    const [ showModal, setShowModal] = useState(false)
    const [ currentRequisito, setCurrentRequisito] = useState(false)
    const [ loadingVehiculos, setLoadingVehiculos] = useState([])
    const [ loadingCambios, setLoadingCambios] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ idLoading, setIdLoading ] = useState(false)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const trash = props.trash ? props.trash : false
    const show_filter = props.show_filter ? props.show_filter : false
    const titulo = props.title ? props.title : 'registros'

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerRequisitos(page)
    }
    
    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerRequisitos(page, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination sx={{ mt:2 }} color="primary" count={cantidad} page={pagina} onChange={handleChangePagina} />
        </div>
    }

    const obtenerRequisitos = async (page, query)=>{
        setLoadingVehiculos(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/eventos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingVehiculos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingVehiculos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setVehiculos(res.datos)
                setTotal(res.total)
            }
            return setLoadingVehiculos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingVehiculos(false)
        })
    }

    useEffect(() => {
        obtenerRequisitos(1, false)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!campoBusqueda) return toast.error('Selecciona una patente')
        const condicion = { $text: { $search: campoBusqueda.toLowerCase() }, activo: true }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerRequisitos(1, condicion)
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target
        return setCampoBusqueda(value)
    }

    const filtro = () => {
        if(!show_filter) return false
        return <div>
            <Grid container >
                <Grid item xs={12}>
                    <Box component="form" noValidate onSubmit={handleSubmit}  sx={{ mt: 1, mb: 1 }}>
                        <TextField id="outlined-basic" label="Buscar por nombre, apellido, email o móvil" variant="outlined" onChange={handleChangeCampo} fullWidth />
                    </Box>
                </Grid>
            </Grid>
        </div>
    }

    const obtenerRegistro = (id) => {
        if(idLoading !== false) return
        setIdLoading(id)
        return fetch(`${data.urlapi}/requisitos?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setIdLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setIdLoading(false)
            } else if(res._id){
                setCurrentRequisito(prev => ({...{}, ...res}))
                setShowModal(true)
            }
            return setIdLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setIdLoading(false)
        })
    }

    const handleModalRF = () => {
        return setShowModal(false)
    }

    const guardarCambios = () => {
        setLoadingCambios(true)
        return fetch(`${data.urlapi}/requisitos`,{
            method:'PUT',
            body: JSON.stringify(currentRequisito),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingCambios(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCambios(false)
            } else if(res._id){
                toast.success('Actualizado exitosamente')
                const instance = vehiculos
                const i = instance.findIndex(v => v._id === res._id)
                if(i > -1){
                    instance[i] = res
                    setVehiculos(prev => [...[], ...instance])
                }
                setShowModal(false)
            }
            return setLoadingCambios(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCambios(false)
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        currentRequisito[name] = value
        return setCurrentRequisito(prev => ({...{}, ...currentRequisito}))
    }

    const ModalRegistrosFotograficos = () => {
        let titulo = 'No disponible'
        if(!showModal) return false

        if(typeof currentRequisito.permiso_modelo === 'object'){
            titulo = currentRequisito.permiso_modelo.titulo
        }

        return <Dialog
            open={showModal}
            fullWidth={true}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            onClose={()=>handleModalRF()}
            aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle id="alert-dialog-slide-description">{titulo}</DialogTitle>
        <DialogContent>
            <Grid container spacing={3}>
                <Grid item md={8}>
                    {
                        currentRequisito.valor ? <img src={currentRequisito.valor} style={{ width: '100%' }} /> : <div>
                            <ErrorOutlineIcon fontSize="large" />
                            <Typography variant="h6" xs={{ mb:0 }}>Sin datos cargados</Typography>
                        </div>
                    }
                </Grid>
                <Grid item md={4}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Detalles</Typography>
                    <TextField 
                        sx={{ mb: 2 }} 
                        id="outlined-basic" 
                        label="Observaciones" 
                        name="comentario" 
                        onChange={handleChange} 
                        value={currentRequisito.comentario} 
                        variant="outlined" fullWidth 
                    />
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Estado"
                        sx={{ mb: 2 }}
                        fullWidth
                        name="status"
                        value={currentRequisito.status}
                        onChange={handleChange}
                        >
                        <MenuItem value="pendiente">Pendiente</MenuItem>
                        <MenuItem value="rechazado">Rechazado</MenuItem>
                        <MenuItem value="aprobado">Aprobado</MenuItem>
                    </TextField>

                    { loadingCambios === true ? <CircularProgress /> : 
                    <Button variant="contained" color="success" fullWidth onClick={()=>guardarCambios()} >GUARDAR</Button> }

                </Grid>
            </Grid>
        </DialogContent>
        </Dialog>
    }

    const mostrarRegistros = () => {
        if(vehiculos.length < 1) return <h3>No hay registros</h3>
        
        return <div>
            <Grid container >
                <Grid item xs={6}>{paginacion(data.pagina, total)}</Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}><h3 sx={{ m:0 }} >{total} {titulo}</h3></Grid>
            </Grid>
            <TableContainer component={Paper} sx={{ mt:2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
            <TableHead>
            <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Mensaje</TableCell>
                <TableCell>Canal</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            { vehiculos.map((data) => (
                <TableRow
                key={data._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell>{fechaATexto(data.createdAt)}</TableCell>
                <TableCell>{data.mensaje}</TableCell>
                <TableCell>{data.canal}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        </div>
    }

    if(loadingVehiculos) return <CargandoTabla />

    return <div>
        {filtro()}
        {ModalRegistrosFotograficos()}
        {mostrarRegistros()}
    </div>
}

export default ListadoEventos