const control = {};
const { DateTime } = require('luxon');
const { configuracion_horas } = require('./opcionesfecha');
const opcionesfecha = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const configuracion_fechas = {
  weekday: 'long', 
  year: 'numeric', 
  month: 'long', 
  day: 'numeric'
}

control.fechaATexto = (fecha, lang) => {
  const date = new Date(fecha);
  return `${date.toLocaleDateString(
    lang ? lang : 'es-ES',
    opcionesfecha
  )} · ${date.toLocaleTimeString('en-US', configuracion_horas)}`;
};

control.fechaUTCATextoSimple = (fecha, lang) => {
  if(!fecha) return 'Sin datos'
  const date = new Date(fecha)
  const date2 = DateTime.fromISO(fecha).toJSDate()
  return `${date2.toLocaleDateString('es-ES', configuracion_fechas )}`
}


control.fechaATextoSimple = (fecha, lang) => {
  const date = new Date(fecha);
  return `${date.toLocaleDateString('es-ES', opcionesfecha)}`;
};

control.limpiarRut = (rut) => {
  if (!rut) return rut;
  const dato = rut.toString().replace('.', '');
  if (!dato) return rut;
  const dato2 = dato.replace('.', '');
  if (!dato2) return dato;
  const dato3 = dato2.replace('.', '');
  if (!dato3) return dato2;
  const dato4 = dato3.replace('-', '');
  if (!dato4) return dato2.replace('-', '');
  return dato4;
};

control.checkForDuplicatesEmails = (email, lista) => {
  const buscar = lista.filter((user) => user.email === email);
  if (buscar.length > 0) return true;
  return false;
};

control.validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

control.checkForDuplicatesRut = (rut, lista) => {
  const buscar = lista.filter((user) => user.rut === rut);
  if (buscar.length > 0) return true;
  return false;
};

control.reemplazarEmailConDominio = (email, dominio) => {
  if (!email) return '';
  const dividir = email.split('@');
  const correo = dividir[0];
  const nuevo_email = `${correo}@${dominio}`;
  return nuevo_email;
};

control.sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

control.corregirTelefono = (num) => {
  if (!num) {
    return '(en blanco)';
  }

  const digito = num.toString();

  const longitud = digito.length;

  if (longitud === 0) {
    return '(en blanco)';
  }
  const numero = digito.replace('+56', '56');
  if (longitud === 9) {
    return `56${numero}`;
  } else if (longitud === 8) {
    return `569${numero}`;
  }

  return numero;
};

control.limpiarStringLetrasNumeros = (cadena) => {
  const regex = /[^a-zA-Z0-9]/g; // Expresión regular para buscar caracteres no alfanuméricos
  return cadena.replace(regex, '');
};

control.limpiarStringNumeros = (cadena) => {
  const regex = /\D/g
  return cadena.replace(regex, '');
};


module.exports = control;
