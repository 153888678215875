import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { useDispatch, useSelector } from 'react-redux';
import { urlapi } from '../../lib/backend/data';

const SelectorZonaV2 = (props) => {
  const [value, setValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()



const fetchPredictions = (input) => {
  // Replace this fetch with your API call to get place predictions
  return fetch(`${urlapi}/geodata/zonas/list/select`,{
    method: "POST",
    body: JSON.stringify({
      condicion: {
        $text: { $search: input },
      }
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer: ${session.tokenSession}`,
      'country': pais,
      'lang': idioma
    }
  })
    .then((response) => response.json())
    .then((data) => {
      // Assuming the response contains an array of objects with 'titulo' and 'descripcion' fields
      return data.map((place) => place)
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      return [];
    });
};


  const fetchDatos = React.useMemo(
    () =>
      debounce(async (input) => {
        setLoading(true)
        const results = await fetchPredictions(input);
        setOptions(results);
        setLoading(false)
      }, 400),
    []
  );

  React.useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchDatos(inputValue);
  }, [value, inputValue, fetchDatos]);

  return (
    <Autocomplete
      id="custom-autocomplete"
      fullWidth
      size="small"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.titulo
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      loading={loading}
      loadingText="Cargando opciones"
      filterSelectedOptions
      value={value}
      noOptionsText="Sin opciones"
      onChange={(event, newValue) => {
        if(!newValue){
          if(props.onChange) props.onChange(false)
          return false
        }
        const i = options.findIndex(m => m._id === newValue._id)
        
        if(props.onChange) props.onChange(options[i])
        
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} fullWidth />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
              >
                <Box
                    component="span"
                    sx={{ fontWeight: 'bold' }}
                  >
                    {option.titulo}
                  </Box>
                
                <Typography variant="body2" color="text.secondary">
                  {option.local_name}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default SelectorZonaV2