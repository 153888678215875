import { useDispatch, useSelector } from "react-redux";
import { urlapi } from "../../../lib/backend/data";
import { useEffect, useState } from "react";
import Select from "react-select";
import { cerrarSesion } from "../../../redux/actions/session";
import { toast } from "react-hot-toast";
import { Autocomplete, TextField } from "@mui/material";

const SelectorTipoMotor = (props) => {
    const {
        defaultValue,
        error
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ opcionesComplete, setOpcionesComplete ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/tipomotor/list/select`, {
          method: 'POST',
          body: JSON.stringify({}),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setOpcionesComplete(res)
              setOpciones(res.filter(m => m.titulo).map(op => ({ value: op._id, label: op.titulo.toUpperCase() })))
            }
            return setLoading(false);
          })
          .catch((error) => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }

      const onChangeValue = (e, data) => {
        if(!data){
          if(props.onChange) props.onChange(false)
          return false
        }
        const i = opcionesComplete.findIndex(m => m._id === data.value)
        
        if(props.onChange) props.onChange(opcionesComplete[i])
      }

      useEffect(() => {
        obtenerDatos()
      }, [])

      return <div>
        <Autocomplete
        disablePortal
        noOptionsText="Sin opciones"
        loading={loading}
        options={opciones}
        renderInput={(params) => <TextField {...params} />}
        onChange={onChangeValue}
        />
      </div>
}

export default SelectorTipoMotor