import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import PropTypes from 'prop-types';
import SinData from '../General/sin_data';
import SettingsIcon from '@mui/icons-material/Settings';

const SoporteDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [loading, setLoading] = useState(false);
  const [soporte, setSoporte] = useState(false);
  const [permisosOtorgados, setPermisosOtorgados] = useState([]);
  const sesion = useSelector((state) => state.miusuario);
  const { id } = useParams();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const obtenerSoporte = async () => {
    return fetch(`${data.urlapi}/soporte?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setSoporte(res);
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      });
  };

  useEffect(() => {
    obtenerSoporte();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    soporte[name] = value;
    console.log(soporte);
    return setSoporte({ ...{}, ...soporte });
  };

  const actualizarSoporte = async () => {
    const requeridos = [
      { value: 'titulo', label: 'Titulo' },
      { value: 'tipo', label: 'Tipo' },
    ];
    let faltantes = [];
    requeridos.map((campo) => {
      if (!soporte[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    soporte.permisos = permisosOtorgados;

    setLoading(true);
    return fetch(`${data.urlapi}/soporte`, {
      method: 'PUT',
      body: JSON.stringify(soporte),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const render = () => {
    if (loadingMaster) return <CircularProgress />;
    if (!soporte) return <SinData />;
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Href to='/soporte'>Categoria-Soporte</Href>
          <Enlace underline='hover' color='text.primary'>
            Editar Categoria-Soporte
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Editar Categoria-Soporte</h1>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography variant='h6'>
                  <SettingsIcon sx={{ mb: -0.7 }} /> Detalles
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Título'
                  variant='filled'
                  name='titulo'
                  fullWidth
                  defaultValue={soporte.titulo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel id='demo-simple-select-label'>Tipo</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Tipo'
                    name='tipo'
                    onChange={handleChange}
                    defaultValue={soporte.tipo}
                  >
                    <MenuItem value='conductor'>Conductor</MenuItem>
                    <MenuItem value='cliente'>Cliente</MenuItem>
                    <MenuItem value='empresa'>Empresa</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel id='demo-simple-select-label'>Estado</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Estado'
                    name='status'
                    onChange={handleChange}
                    defaultValue={soporte.status}
                  >
                    <MenuItem value='active'>Activo</MenuItem>
                    <MenuItem value='trash'>Desactivado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                {loading === true ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant='contained'
                    color='success'
                    xs={{ m: 0 }}
                    onClick={() => actualizarSoporte()}
                  >
                    GUARDAR CAMBIOS
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default SoporteDetails;
