import CargandoTabla from './cargando';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { fechaATexto } from '../../lib/helpers/helpers';
import { Link } from 'react-router-dom';
import ExcelButtonDownload from '../../subcomponents/reports.js/button_download';
import { LoadingButton } from '@mui/lab';
import ModalEmpresas from '../Empresas/modal_seleccion';
import ModalConductores from '../Drivers/modal_seleccion';

const ListadoVehiculos = (props) => {
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.miusuario);
  const [vehiculos, setVehiculos] = useState([]);
  const [loadingVehiculos, setLoadingVehiculos] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [loadingAcciones, setLoadingAcciones] = useState(false);
  const [campoBusqueda, setCampoBusqueda] = useState('');
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const [total, setTotal] = useState(0);
  const [condicion_busqueda, setCondicionBusqueda] = useState(
    props.condicion_default ? props.condicion_default : {}
  );
  const trash = props.trash ? props.trash : false;
  const titulo = props.title ? props.title : 'registros';
  const tipo = props.tipo ? props.tipo : 'link';
  const show_filter = typeof props.show_filter !== "undefined" ? props.show_filter : false;
  const open_new_tab = props.open_new_tab ? props.open_new_tab : false;

  const paginacion = (ciclo, total) => {
    const cantidad = Math.ceil(total / ciclo);
    if (!cantidad) return false;
    return (
      <div className='mb-3'>
        <Pagination
          sx={{ mt: 2 }}
          color='primary'
          count={cantidad}
          page={pagina}
          onChange={handleChangePagina}
        />
      </div>
    );
  };

  const obtenerVehiculos = async (page, query) => {
    setLoadingVehiculos(true);
    if (query) if (trash === true) query.status = 'trash';
    const condicion = query ? query : condicion_busqueda;
    return fetch(`${data.urlapi}/vehiculos/list`, {
      method: 'POST',
      body: JSON.stringify({
        condicion,
        pagina: page,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingVehiculos(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingVehiculos(false);
        }
        if (Array.isArray(res.datos) !== false) {
          setVehiculos(res.datos);
          setTotal(res.total);
        }
        return setLoadingVehiculos(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingVehiculos(false);
      });
  };

  const handleChangePagina = (e, page) => {
    setPagina(page);
    return obtenerVehiculos(page, condicion_busqueda);
  };

  useEffect(() => {
    obtenerVehiculos(1, false);
  }, []);

  const handleChangeSeleccion = (e) => {
    const { name } = e.target
    const valor = e.target.checked
    const i = vehiculos.findIndex(ve => ve._id === name )
    vehiculos[i].seleccionado = valor
    setVehiculos([...[], ...vehiculos])
  }

  const deseleccionarTodos = (e) => {
    setVehiculos([...[], ...vehiculos.map(veh => { 
      veh.seleccionado = e.target.checked
      return veh 
    })])
  }

  const handleSubmit = (e) => {
    if(props.onCleanFilter) props.onCleanFilter()
    e.preventDefault();
    if (!campoBusqueda) return toast.error('Selecciona una patente');
    const condicion = {
      $text: { $search: campoBusqueda.toLowerCase() },
      activo: true,
    };
    setCondicionBusqueda(condicion);
    setPagina(1);
    return obtenerVehiculos(1, condicion);
  };

  const handleChangeCampo = (e) => {
    const { value } = e.target;
    return setCampoBusqueda(value);
  };

  const asignacionEmpresa = async (campo) => {
    setLoadingAcciones(true);
    const ids = vehiculos.filter(veh => veh.seleccionado === true).map(veh => veh._id)
    return fetch(`${data.urlapi}/vehiculos/asignacion/empresa`, {
      method: 'POST',
      body: JSON.stringify({
        ids,
        propietario: campo,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        console.log(res)
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if(res.modifiedCount){
          toast.success("Realizado exitosamente")
          setTimeout(() => {
              window.location.reload()
          }, 1000);
        }
        return setLoadingAcciones(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingAcciones(false);
      });
  };

  const asignacionConductor = async (campo) => {
    setLoadingAcciones(true);
    const ids = vehiculos.filter(veh => veh.seleccionado === true).map(veh => veh._id)
    return fetch(`${data.urlapi}/vehiculos/asignacion/conductor`, {
      method: 'POST',
      body: JSON.stringify({
        ids,
        id_usuario: campo,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        console.log(res)
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if(res.modifiedCount){
          toast.success("Realizado exitosamente")
          setTimeout(() => {
              window.location.reload()
          }, 1000);
        }
        return setLoadingAcciones(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingAcciones(false);
      });
  };


  const acciones = () => {
    if(loadingAcciones) return <CircularProgress  />
    const seleccionados = vehiculos.filter(veh => veh.seleccionado === true).length
    const enable = seleccionados > 0 ? false : true
    return <Box sx={{ mt:2}}>
      <Card variant="outlined">
      <Grid container spacing={2}>
        <Grid item md={2}>
        <LoadingButton disabled={enable} style={{ fontSize: 11 }} fullWidth color='inherit' onClick={() => asignacionEmpresa('')}>DESVINCULAR EMPRESA</LoadingButton>
        </Grid>
        <Grid item md={2}>
        <ModalEmpresas seleccionarEmpresa={(compania) => asignacionEmpresa(compania.rut)} custom_component={<LoadingButton style={{ fontSize: 11 }} disabled={enable} fullWidth color='inherit'>ASIGNAR EMPRESA</LoadingButton>} titulo="REASIGNAR EMPRESA" />
        </Grid>
        <Grid item md={2}>
        <LoadingButton disabled={enable} style={{ fontSize: 11 }} fullWidth color='inherit' onClick={() => asignacionConductor("")}>DESVINCULAR CONDUCTOR</LoadingButton>
        </Grid>
        <Grid item md={2}>
          <ModalConductores seleccionarConductor={(driver => asignacionConductor(driver._id)) } custom_component={<LoadingButton style={{ fontSize: 11 }} disabled={enable} fullWidth color='inherit'>ASIGNAR CONDUCTOR</LoadingButton>}  />
        </Grid>
      </Grid>
      </Card>
    </Box>
  }
  const filtro = () => {
    if(show_filter !== true) return false
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box
              component='form'
              noValidate
              onSubmit={handleSubmit}
              sx={{ mb: 1 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={8}>
                <TextField
                id='outlined-basic'
                size="small"
                label='Buscar por patente, modelo, marca, año, tipo'
                variant='outlined'
                onChange={handleChangeCampo}
                fullWidth
              />
                            </Grid>
                            <Grid item xs={4}>
                                <Button style={{ width: "100%" }} type="submit" size="large" variant="contained" >BUSCAR</Button>
                            </Grid>
                        </Grid>
            </Box>
          </Grid>
          <Grid item xs={4}>
          <ExcelButtonDownload condicion={condicion_busqueda} slug="vehicles" />
          </Grid>
        </Grid>
      </div>
    );
  };

  const seleccionarVehiculo = (driver) => {
    if (props.seleccionarVehiculo) props.seleccionarVehiculo(driver);
    return false;
  };

  const showByType = (tipo, driver) => {
    switch (tipo) {
      case 'funcion':
        return (
          <b onClick={() => seleccionarVehiculo(driver)}>{driver.tipo}</b>
        );
      case 'link':
        return (
          <b>
            <Link target={open_new_tab === true ? "_blank" : "_self"} to={`/vehicles-${driver._id}`}>
                        {driver.patente ? driver.patente.toUpperCase() : 'VER DETALLES'}
            </Link>
          </b>
        );
      default:
        return;
    }
  };
  
  const mostrarTabla = () => {
    if (vehiculos.length < 1) return <h3>NO HAY REGISTROS</h3>;

    return <div>
      <Grid container>
          <Grid item xs={6}>
            {paginacion(data.pagina, total)}
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <h3 sx={{ m: 0 }}>
              {total} REGISTROS
            </h3>
          </Grid>
        </Grid>
      
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label='sticky table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell><Checkbox size='small' onChange={deseleccionarTodos} /></TableCell>
                <TableCell>Patente</TableCell>
                <TableCell>Modelo</TableCell>
                <TableCell>Marca</TableCell>
                <TableCell>Año</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Registro</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehiculos.map((driver) => (
                <TableRow
                key={driver._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell><Checkbox size='small' id='vehiculo_check' checked={driver.seleccionado ? true : false} name={driver._id} onChange={handleChangeSeleccion} /></TableCell>
                  <TableCell>{showByType(tipo, driver)}</TableCell>
                  <TableCell>{driver.modelo}</TableCell>
                  <TableCell>{driver.marca}</TableCell>
                  <TableCell>{driver.ano}</TableCell>
                  <TableCell>{driver.tipo}</TableCell>
                  <TableCell>{fechaATexto(driver.createdAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </div>
  }

  const mostrarRegistros = () => {

    return (
      <div>
        {filtro()}
        {acciones()}
        {mostrarTabla()}
      </div>
    );
  };

  if (loadingVehiculos) return <CargandoTabla />;

  return <div>{mostrarRegistros()}</div>;
};

export default ListadoVehiculos;
