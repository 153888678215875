import React, { useEffect, useRef, useState} from 'react'
import { mapbox_token, urlapi } from '../../lib/backend/data';
import socket from '../../lib/websockets';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { Button, Card, CardActions, CardContent, CircularProgress, Divider, Grid, Tooltip, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import { cerrarSesion } from '../../redux/actions/session';
import { toast } from 'react-hot-toast';
import { fechaATexto } from '../../lib/helpers/helpers';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import BusquedaOperacion from './busqueda';
import { Map, Marker } from 'react-map-gl';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import * as turf from '@turf/turf';

const OperationMap = () => {
    const pais = useSelector(state => state.pais)
    const centro_mapa_inicial = obtenerCentroMapaPorPais(pais)
    const [marcadores, setMarcadores] = useState([])
    const [loadingRefrescar, setLoadingRefrescar] = useState(false)
    const [intervalo, setIntervalo] = useState(false)
    const [centroMapa, setCentroMapa] = useState(false)
    const zoom_mapa = centro_mapa_inicial ? centro_mapa_inicial.zoom : 13
    const sesion = useSelector(state => state.miusuario)
    const idioma = useSelector(state => state.idioma)
    const [coordenadasRecibidas, setCoordenadasRecibidas] = useState(0)
    const [ condicionBusqueda, setCondicionBusqueda ] = useState({})
    const dispatch = useDispatch()
    const coordRef = useRef();
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()
    coordRef.current = coordenadasRecibidas

    const socketFunctions = () => {
      socket.on(`coordenadas_mapa`, data => {
        toast.success("coordenadas recibidas")
        /* ESTE ES EL PAYLOAD DE DATA
        {
            lat: latitude,
            lng: longitude,
            date: formatDateHoy(new Date()),
            precisiongps:"",
            idusuario: driverauthorization,
            identificador_conductor
        }
        */
        const nuevo = coordRef.current + 1
        setCoordenadasRecibidas(nuevo)
        let nuevos = marcadores
        const i = nuevos.findIndex(m => m.idusuario === data.idusuario)
        if(i > -1){
          nuevos[i] = data
        } else {
          nuevos.unshift(data)
        }
        return setMarcadores(prev => [...[], ...nuevos])
      })
      socket.on('conexion_conductor', datos => {
        refrescarListado()
      })
    }

    const ajustarCentro = (markers) => {
      if(markers.length < 1) return false
      if(markers.length === 1){
        return centrarMapa(markers[0], 2)
      }
      if(markers.length < 1) return false

      const markers_validos = markers.filter(ma => {
        if(isNaN(parseFloat(ma.lat)) || isNaN(parseFloat(ma.lng))) return false
        return true 
      })

      const points = markers_validos.map(marker => turf.point([marker.lng, marker.lat]));
    const collection = turf.featureCollection(points);
    const bounds = turf.bbox(collection);

    const newViewport = {
      ...viewState,
      latitude: (bounds[1] + bounds[3]) / 2,
      longitude: (bounds[0] + bounds[2]) / 2
    };

    const options = {
      padding: 30 // Ajusta el valor de padding según tus necesidades
    };

    // setViewState(newViewport);
    mapRef.current.fitBounds(bounds, options);
  }

    useEffect(() => {
      refrescarListado(null, true)
      const id_intervalo = setInterval(() => {
        refrescarListado()
      }, 4000);
      setIntervalo(id_intervalo)
      const identificador_1 = 'coordenadas_mapa'
      const identificador_2 = 'conexion_conductor'
      socket.on(identificador_1, data => {
        const nuevo = coordRef.current + 1
        setCoordenadasRecibidas(nuevo)
        let nuevos = marcadores
        const i = nuevos.findIndex(m => m.idusuario === data.idusuario)
        if(i > -1){
          nuevos[i] = data
        } else {
          nuevos.unshift(data)
        }
        return setMarcadores(prev => [...[], ...nuevos])
      })
      socket.on(identificador_2, datos => {
        refrescarListado()
      })
      return () => {
        clearInterval(id_intervalo)
        socket?.off(identificador_1)
        socket?.off(identificador_2)
      }
  },[ ])

  const refrescarListado = async (cond, centrar) => {
    
    const query = cond ? cond : condicionBusqueda
    return fetch(`${urlapi}/conductores/vehicles-actives`,{
        method:'POST',
        body: JSON.stringify({
          condicion: query
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            return false
        } else if(res.errorMessage){
            return false
        } else if(Array.isArray(res) !== false){
          let ids_usuarios = []
          for( const marker of marcadores ){
            const { idusuario } = marker
            if(!idusuario) continue
            const i = res.findIndex(m => m._id === marker.idusuario)
            if(i < 0){
              ids_usuarios.push(marker.idusuario)
            }
          }
          // console.log({ids_usuarios})
          for( const id of ids_usuarios ){
            const pos = marcadores.findIndex(m => m.idusuario === id)
            if(pos > -1) marcadores.splice(pos,1)
          }
          console.log({marcadores,res})
          setMarcadores(prev => [...[], ...res])
          if(centrar === true){
            setTimeout(() => {
              ajustarCentro(res)
            }, 300);
          }
        }
        return setLoadingRefrescar(false)
    })
    .catch(error => {
        return setLoadingRefrescar(false)
    })
}

const centrarMapa = (centro, max_zoom) => {
  if(isNaN(parseFloat(centro.lat)) || isNaN(parseFloat(centro.lng))) return false
  console.log(centro)
  const nuevo_zoom = defaultViewState.zoom + (max_zoom ? max_zoom : 3)
  mapRef.current?.flyTo({center: [centro.lng, centro.lat], zoom: nuevo_zoom, duration: 3000});
}

  const visualizarListado = () => {

    if(marcadores.length < 1) return <div style={{ textAlign: "center", padding: 25 }} >
      <img src={`/images/satelite.png`} style={{ width: 80 }} />
      <Typography sx={{ mb: 2 }} variant="h6" >Esperando ubicaciones de los conductores activos</Typography>
    </div>

    return <Card sx={{ p:2 }}>
      { loadingRefrescar ? <CircularProgress /> : false }
      <Typography sx={{ mb: 2 }} variant="h6" >{marcadores.length} Conductores activos</Typography>
      <Typography sx={{ mb: 2, fontSize: 12 }} variant="p" >En esta sección se muestran los conductores que en tiempo real transmiten su ubicación</Typography>
      <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
      {
        marcadores.map((mark,i) => {
          return <div key={`driver-${mark.idusuario}`} style={{ paddingTop: 13 }}>
            <Typography sx={{ mb: 0 }} >{mark.identificador_conductor} <Link to={`/driver-${mark.idusuario}`}><OpenInNewIcon style={{ marginBottom: -4 }} fontSize="small" /></Link></Typography>
            <Typography sx={{ mb: 0, fontSize: 12 }} ><Moment locale="ES" fromNow>{mark.fecha}</Moment></Typography>
            <Button size="small" onClick={() => centrarMapa({ lat: mark.lat, lng: mark.lng })}>VER EN MAPA</Button>
            <Divider sx={{ mt:1, mb:1 }} />
          </div>
        })
      }
      </div>
    </Card>
  }

  const onFilter = (cond) => {
    setLoadingRefrescar(true)
    setCondicionBusqueda(cond)
    refrescarListado(cond, true)
    clearInterval(intervalo)
    const id_intervalo = setInterval(() => {
      refrescarListado(cond)
    }, 4000);
    setIntervalo(id_intervalo)
  }

  return <Grid container spacing={2} sx={{ mt:2, backgroundColor: "white", paddingBottom: 2, paddingRight: 2 }}>
  <Grid item xs={12}>
  <BusquedaOperacion onFilter={data => onFilter(data)}/>
  </Grid>
  <Grid item md={3} xs={12}>
  {visualizarListado()}
  </Grid>
  <Grid item md={9} xs={12}>
        <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: 500}}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
  >
    {
        marcadores.map(marker => {
            const content = (
                <div>
                    <h4>{marker.fecha}</h4>
                </div>
            )
            return <Marker
                    key={marker._id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                  <Tooltip title={<div>
                    {marker.identificador_conductor}<br/>
                    <Moment locale="ES" fromNow>{marker.fecha}</Moment>
                  </div>} placement="top">

            <DirectionsCarIcon />
                  </Tooltip>
            </Marker>
        })
    }
  </Map>
  </Grid>
  </Grid>
}

export default OperationMap