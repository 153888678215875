import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Structure from '../Structure';
import ImageIcon from '@mui/icons-material/Image';
import { Link, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data, { configs3 } from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import S3FileUpload from 'react-s3/lib/ReactS3';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';

const PublicacionCreate = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [publicacion, setPublicacion] = useState({});
  const [loadingFoto, setLoadingFoto] = useState(false);
  const sesion = useSelector((state) => state.miusuario);

  const handleChange = (e) => {
    const { name, value } = e.target;
    publicacion[name] = value;
    return setPublicacion({ ...{}, ...publicacion });
  };
  
  const handleImage = (e) => {
    if(e.target.files.length < 1) return toast.error('Sin archivos encontrados')
        const archivo = e.target.files[0]
        setLoadingFoto(true)
        return S3FileUpload.uploadFile(archivo, configs3)
        .then(data => {
        if(!data.location) {
            toast.error('No pudimos cargar tu imagen')
            return setLoadingFoto(false)
        }
        publicacion.imagen = data.location
        setPublicacion({ ...{}, ...publicacion })
        return setLoadingFoto(false)
  })
  .catch(err => {
      setLoadingFoto(false)
      return toast.error('No pudimos cargar tu imagen')
      })
  }

  const crearPublicacion = async () => {
    console.log(sesion);
    const requeridos = [
      { value: 'titulo', label: 'Título' },
      { value: 'tipo', label: 'Tipo' },
      { value: 'tipo_destino', label: 'Tipo de destino' },
      { value: 'descripcion', label: 'Descripcion' },
    ];
    let faltantes = [];
    requeridos.map((campo) => {
      if (!publicacion[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);

    setLoading(true);
    return fetch(`${data.urlapi}/publicacion`, {
      method: 'POST',
      body: JSON.stringify(publicacion),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Creado exitosamente');
          setTimeout(() => {
            return (window.location = `/publicacion-${res._id}`);
          }, 500);
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const render = () => {
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to='/publicacion'>Publicación</Link>
          <Enlace underline='hover' color='text.primary'>
            Crear Publicación
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Crear Publicación</h1>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item md={12} xs={12}>
            <Typography variant='h6'>Datos</Typography>
            <CamposObligatoriosLeyenda />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              label='Título'
              variant='filled'
              name='titulo'
              required
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              label='Descripción'
              variant='filled'
              name='descripcion'
              multiline
              rows={4}
              required
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel required>Tipo</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Tipo'
                    name='tipo'
                    onChange={handleChange}
                  >
                    <MenuItem value='pregunta-frecuente'>Preguntas frecuentes</MenuItem>
                    <MenuItem value='destacado'>Destacado</MenuItem>
                    <MenuItem value='pantalla-conexion'>Pantalla de conexión</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
          <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel required>Destino</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Destino'
                    name='tipo_destino'
                    onChange={handleChange}
                  >
                    <MenuItem value=''>Seleccione</MenuItem>
                    <MenuItem value='cliente'>Cliente</MenuItem>
                    <MenuItem value='conductor'>Conductor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
          <Grid item md={2} xs={12}>
          {
                  loadingFoto ? 
                  <CircularProgress /> : 
                  <Button variant='contained' component='label'>
                    <ImageIcon /> Carga imagen
                    <input type='file' hidden onChange={handleImage} />
                  </Button>
                }
            
          </Grid>

          <Grid item md={12} xs={12}>
                {
                publicacion.imagen ? <div style={{ marginTop: 20 }}>
                    <img width={300} alt={publicacion.titulo} src={publicacion.imagen} />
                </div> : false
                }

            {loading === true ? (
              <CircularProgress />
            ) : (
              <Button
                variant='contained'
                xs={{ m: 0 }}
                onClick={() => crearPublicacion()}
              >
                CREAR
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default PublicacionCreate;
