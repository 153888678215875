import CargandoTabla from "./cargando"
import { Avatar, Box, Grid, Pagination, TextField, Typography } from '@mui/material'
import { useEffect, useState } from "react"
import data from '../../../lib/backend/data'
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../../redux/actions/session"
import { fechaATexto } from "../../../lib/helpers/helpers"

const ListadoRegistrosFotograficos = (props) => {
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const trash = props.trash ? props.trash : false
    const titulo = props.title ? props.title : 'registros'
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    
    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerConductores(page,false)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination sx={{ mt:2 }} color="primary" count={cantidad} page={pagina} onChange={handleChangePagina} />
        </div>
    }

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/conductores/reporte-fotografico`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!campoBusqueda) return toast.error('Selecciona una patente')
        const condicion = { $text: { $search: campoBusqueda.toLowerCase() }, activo: true }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerConductores(1, condicion)
    }

    const handleChangeFecha = (e) => {
        const { value } = e.target
        const a = new Date(value)
        console.log(a)
    }

    if(loadingConductores) return <CargandoTabla />

    const mostrarRegistros = () => {
        if(conductores.length < 1) return <h3>No hay registros</h3>

        return <div>
            <Grid container >
                <Grid item xs={4}>
                    <Box component="form" noValidate onSubmit={handleSubmit}  sx={{ mt: 1, mb: 1 }}>
                    <TextField
                        label="Fecha"
                        type="date"
                        defaultValue="2017-05-24"
                        sx={{ width: 220 }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        onChange={handleChangeFecha}
                    />
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ mb:3 }}>
                <Grid item xs={6}>{paginacion(data.pagina, total)}</Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}><h3 sx={{ m:0 }} >{total} {titulo}</h3></Grid>
            </Grid>
            {
                conductores.map((dato) => (
                    <Grid container sx={{ mb: 3 }} spacing={3} key={dato._id} alignItems="center" >
                    <Grid item md={2} xs={12}>
                        <Avatar 
                            sx={{ height:60, width:60 }}
                            src={dato.url_imagen}
                        />
                    </Grid>
        
                    <Grid item md={4}>
                        <Typography mb={0} component="div" >Fecha</Typography>
                        <Typography variant="h6" mb={0} component="div" >{fechaATexto(dato.fecha_local)}</Typography>
                    </Grid>
        
                    <Grid item md={3}>
                        <Typography mb={0} component="div" >Puntuación fidelidad</Typography>
                        <Typography variant="h6" mb={0} component="div" >{Math.round(dato.nivel_fidelidad)}/100</Typography>
                    </Grid>
        
                    <Grid item md={3}>
                        <Typography mb={0} component="div" >Conexión permitida</Typography>
                        <Typography variant="h6" mb={0} component="div" >{dato.estado_validacion === true ? "SI" : "NO"}</Typography>
                    </Grid>
             </Grid>
                ))
            }
        </div>
    }

    return <div>
            {mostrarRegistros()}
    </div>
}

export default ListadoRegistrosFotograficos