const data = {
    module_name: "custom-fields",
    title: "Campos Personalizados",
    key: 'custom_fields', // RUTAS FILE
    category_key: "custom-fields",
    excel_key: "custom-fields",
    tipos_registro: [
        { value: "conductor", label: "Conductor" },
        { value:"vehiculo", label: "Vehículo" },
        { value:"cliente", label: "Cliente" },
        { value:"equipo", label: "Equipo" },
        { value:"contacto", label: "Contacto de cliente" },
        { value:"viaje", label: "Viaje" },
        { value:"producto", label: "Producto" },
    ],
    tipos_ingreso: [
        { value: "", label: "Escritura" },
        { value: "seleccion", label: "Seleccion" },
    ]
}

module.exports = data