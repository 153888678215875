import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Select as Selector,
  Card,
  Autocomplete,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, Link, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data, { maps_key, style } from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import SinData from '../General/sin_data';
import { LoadingButton } from '@mui/lab';
import ModalEmpresas from '../Empresas/modal_seleccion';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import BotonEliminar from './boton_eliminar';
import { rutas } from '../../lib/data/rutas';
import { key, module_name, tipos_ingreso, tipos_registro } from './data';
import { validateEmail } from '../../lib/helpers/helpers';
import { AiFillMinusCircle, AiOutlinePlus } from 'react-icons/ai';

const CategoriaDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [empresa, setEmpresa] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vehiculo, setVehiculo] = useState([]);
  const [loadingDefinicion, setLoadingDefinicion] = useState(false);
  const [permisosOtorgados, setPermisosOtorgados] = useState([]);
  const sesion = useSelector((state) => state.miusuario);
  const [loadintAsignacion, setLoadingAsignacion] = useState(false);
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const requeridos = [
    { value: 'title', label: 'Título' },
    { value: 'tipo', label: 'Tipo' }
  ];
  const { id } = useParams();

  const obtenerVehiculo = async () => {
    return fetch(`${data.urlapi}/${module_name}?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setVehiculo(res);
          if(res.empresa) setEmpresa(res.empresa)
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      })
  };


  useEffect(() => {
    obtenerVehiculo()
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    vehiculo[name] = value;
    return setVehiculo({ ...{}, ...vehiculo });
  }

  const opcionesInvalids = () => {
    return toast.error("Opciones inválidas para tipo de selección")
  }
  const actualizarVehiculo = async () => {
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!vehiculo[campo.value]) faltantes.push(campo.label);
      return true;
    });
    console.log({faltantes})
    if (faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)

    if(vehiculo.tipo_ingreso === "seleccion"){
      if(!vehiculo.opciones) return opcionesInvalids()
      if(vehiculo.opciones.length < 1) return opcionesInvalids()
      const filter = vehiculo.opciones.filter(e => !e.value)
      if(filter.length > 0) return opcionesInvalids()
  }

    setLoading(true);
    return fetch(`${data.urlapi}/${module_name}`, {
      method: 'PUT',
      body: JSON.stringify(vehiculo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };


const handleChangeSelector = (e, name) => {
  vehiculo[name] = e.value
  return setVehiculo({ ...{}, ...vehiculo });
};

  const formularioEditar = () => {

    return <Grid container spacing={2} sx={{ mb: 2 }}>
    <Grid item md={12} xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <ModalEmpresas titulo="Seleccionar empresa" seleccionarEmpresa={(data) => {
      vehiculo.propietario = data.rut
      setEmpresa(data)
      toast.success("Guardar los cambios antes de salir de esta pantalla", { icon: false })
      return setVehiculo(vehiculo)
    }} />
    {
            empresa ? <div>
              <Card sx={{ p:2, mt: 2 }}>
              <Typography mb={0}>Empresa asignada</Typography>
              <Typography variant='h6' mb={0}>{empresa.razon_social}</Typography>
              <Button onClick={() => {
                setEmpresa(false)
                vehiculo.propietario = ''
                setVehiculo(vehiculo)
                toast.success("Guardar los cambios antes de salir de esta pantalla", { icon: false })
                }}>REMOVER</Button>
              </Card>
              </div> : false
          }
                                  <Grid container spacing={2}>
        <Grid item md={12} xs={12} >
          <CamposObligatoriosLeyenda />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Título'
            variant='filled'
            name='title'
            required={siEsRequeridoEnEsquema("title",requeridos)}
            defaultValue={vehiculo.title}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
        <Autocomplete
                                                disablePortal
                                                noOptionsText="Sin opciones" 
                                                renderInput={(params) => <TextField label="Tipo de registro" {...params} />}
                                                options={tipos_registro}
                                                defaultValue={vehiculo.tipo}
                                                onChange={(e,d) => handleChangeSelector(d, 'tipo')} 
                                            />
        </Grid>
        <Grid item md={12} xs={12}>
        </Grid>
        <Grid item md={12} xs={12}>
            <LoadingButton
              variant='contained'
              color='success'
              loading={loading}
              xs={{ m: 0 }}
              onClick={() => actualizarVehiculo()}
            >
              GUARDAR CAMBIOS
            </LoadingButton>
        </Grid>
      </Grid>
          </Box>
    </Grid>
  </Grid>
  }

  const handleChangeOpcion = (e,pos) => {
    return setVehiculo(prev => {
        let actual = {...prev}
        actual.opciones[pos].value = e.target.value
        return {...{}, ...actual}
    })
}

const eliminarOpcion = (i) => {
    return setVehiculo(prev => {
        const actual = {...prev}
        actual.opciones.splice(i,1)
        return {...{}, ...actual}
    })
}

const mostrarOpciones = () => {
    if(!vehiculo.opciones) return false
    return <div style={{ marginTop: 15 }}>
        {
            vehiculo.opciones.map((opcio,ip) => {
                return <Box key={`op-${ip}`} sx={{ mb: 2 }} >
                                <TextField style={{ width: "100%" }} value={opcio.value} onChange={(e) => handleChangeOpcion(e,ip)} />
                                <Button sx={{ mt:1}} size="small" variant='outlined' color="error" onClick={() => eliminarOpcion(ip)} startIcon={<AiFillMinusCircle  color={"red"} />} > REMOVER</Button>
                                
                </Box>
            })
        }
    </div>
}

const agregarValor = () => {
    return setVehiculo(prev => {
        let actual = {...prev}
        if(!actual.opciones) actual.opciones = []
        actual.opciones.unshift({
            value: ""
        })
        return {...{}, ...actual}
    })
}

const opcionesBox = () => {
    if(!vehiculo) return false
    if(vehiculo.tipo_ingreso !== "seleccion") return false
    return <div style={{ marginTop: 15 }}>
        <Button size="small" onClick={() => agregarValor()} variant="outlined" ><AiOutlinePlus style={{ verticalAlign: "middle" }} /> AGREGAR NUEVO VALOR</Button>
        {mostrarOpciones()}
    </div>
}

  const render = () => {
    if (loadingMaster) return <CircularProgress />
    if (!vehiculo) return <SinData />

    return (
      <div>
          <Breadcrumbs aria-label='breadcrumb'>
          <Href to={rutas[key].slug}>{rutas[key].title}</Href>
          <Enlace underline='hover' color='text.primary'>Editar { vehiculo ? vehiculo.razon_social : rutas[key].title}</Enlace>
          </Breadcrumbs>
          <h1 style={{ margin: 0 }}>Editar { vehiculo ? vehiculo.razon_social : rutas[key].title}</h1>
          <BotonEliminar id={id} />
          {formularioEditar()}
      </div>
    );
  };
  
  return <Structure component={render()} />;
};

export default CategoriaDetails