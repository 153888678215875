import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Select as Selector,
  Tab,
  Alert,
  Card,
  Divider,
  IconButton,
  Menu,
  Autocomplete,
  CardContent,
  CardActions
} from '@mui/material';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, Link, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import SinData from '../General/sin_data';
import { LoadingButton } from '@mui/lab';
import ModalEmpresas from '../Empresas/modal_seleccion';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import BotonEliminar from './boton_eliminar';
import { rutas } from '../../lib/data/rutas';
import SelectorGeneral from '../../subcomponents/general/selector_general';
import { category_key, key, module_name } from './data';
import { GoDeviceMobile } from 'react-icons/go'
import { GrAdd } from 'react-icons/gr'
import { codigos_area } from '../../lib/data/internacional';
import { AiFillMinusCircle, AiOutlineMail } from 'react-icons/ai';
import { validateEmail } from '../../lib/helpers/helpers';
import ModalDestinatarios from '../Destinatarios/modal_seleccion';

const ContactoDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [marcas, setMarcas] = useState([]);
  const [selectOptionsColors, setSelectOptionsColors] = useState([]);
  const [empresa, setEmpresa] = useState(false);
  const [destinatario, setDestinatario] = useState(false);
  const [selectOptionsTipo, setSelectOptionsTipo] = useState([]);
  const [loadingSelectModelo, setLoadingSelectModelo] = useState(true);
  const [selectOptionsModelo, setSelectOptionsModelo] = useState([]);
  const [selectOptionsTipoMotor, setSelectOptionsTipoMotor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehiculo, setVehiculo] = useState([]);
  const [loadingDefinicion, setLoadingDefinicion] = useState(false);
  const [marca, setMarca] = useState('');
  const [permisosOtorgados, setPermisosOtorgados] = useState([]);
  const [selectedTab, setSelectedTab] = useState("1")
  const sesion = useSelector((state) => state.miusuario);
  const [loadintAsignacion, setLoadingAsignacion] = useState(false);
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const requeridos = [
    { value: 'nombres', label: 'Nombres' },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { id } = useParams();

  const handleSelectModel = (e) => {
    vehiculo.modelo = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const obtenerVehiculo = async () => {
    return fetch(`${data.urlapi}/${module_name}?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setVehiculo(res);
          if(res.empresa) setEmpresa(res.empresa)
          if(res.destinatario) setDestinatario(res.destinatario)
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      })
  };


  useEffect(() => {
    obtenerVehiculo()
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    vehiculo[name] = value;
    return setVehiculo({ ...{}, ...vehiculo });
  };

  const extraerValor = (val) => {
    switch (typeof val) {
        case "string":
            return val
        case "object":
            if(val._id){
                return val._id
            } else if(val.value){
                return val.value
            } else if(val.target){
                const { name, value } = val.target
                console.log({name,value})
                return val.target.value
            } 
            return ''
        default:
            return ''
    }
  }

  const handleChangeSelect = (e, name) => {
    const valor = extraerValor(e)
    console.log(valor)
    vehiculo[name] = valor
    return setVehiculo({ ...{}, ...vehiculo });
  };

  const actualizarVehiculo = async () => {
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!vehiculo[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    vehiculo.permisos = permisosOtorgados;

    const emails = vehiculo.emails_adicionales ? vehiculo.emails_adicionales : []
    const emails_invalidos = emails.filter(e => {
      return !validateEmail(e.email)
    }).map(e => e.email ? e.email : "Email vacío")

    if(emails_invalidos.length > 0) return toast.error(`Emails inválidos, corrige y vuelve a intentar: ${emails_invalidos.join(', ')}`)

    setLoading(true);
    return fetch(`${data.urlapi}/${module_name}`, {
      method: 'PUT',
      body: JSON.stringify(vehiculo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const handleChangeTab = (e,val) => {
    return setSelectedTab(val.toString())
  }

  const selectorModelo = (default_modelo) => {
    if(!vehiculo.marca) return false

    const opciones_filtradas = selectOptionsModelo.filter(m => m.id_marca === vehiculo.marca)

    let valorModelo = default_modelo
    if(!vehiculo.modelo){
      valorModelo = false
    }
    
    return <Grid item md={3} xs={12}>
    <InputLabel id="demo-simple-select-label" required={siEsRequeridoEnEsquema("modelo",requeridos)}>Modelo</InputLabel>
      <Select
        options={opciones_filtradas.map(m => ({ value: m._id, label: m.titulo }))}
        isLoading={loadingSelectModelo}
        loadingMessage={() => 'Cargando'}
        noOptionsMessage={() => 'Sin opciones'}
        placeholder='Selecciona un Modelo'
        value={valorModelo}
        onChange={handleSelectModel}
        height={20}
      />
    </Grid>
  }

  const desvincularConductor = () => {
      vehiculo.id_usuario = ''
      vehiculo.detalles_conductor = false
      vehiculo.selected = false
      setTimeout(() => {
        actualizarVehiculo()
      }, 500);
      scollToBootom()
      return setVehiculo({...{},  ...vehiculo})
  }

  const mostrarConductor = () => {
    if(!vehiculo) return false
    if(typeof vehiculo !== "object") return false
    if(!vehiculo.detalles_conductor) return false
    if(typeof vehiculo.detalles_conductor !== "object") return false
    if(!vehiculo.detalles_conductor._id) return false

    return <div>
      <Grid container >
              <Grid item md={10}>
                <Typography variant='h5' className='mb-0'>{vehiculo.detalles_conductor.nombres}</Typography>
              </Grid>
              <Grid item md={2}>
              <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '30ch',
          },
        }}
      >
        <MenuItem key={0} ><Link target="_blank" style={{ textDecoration: "none" }} to={`/driver-${vehiculo.detalles_conductor._id}`}>VER DETALLES</Link></MenuItem>
        <MenuItem key={0} onClick={() => desvincularConductor()}>DESVINCULAR CONDUCTOR</MenuItem>
      </Menu>
              </Grid>
            </Grid>

    </div>
  }

  const seleccionarConductor = (data) => {
    vehiculo.id_usuario = data._id
    vehiculo.detalles_conductor = data
    setTimeout(() => {
        actualizarVehiculo()
    }, 500);
    scollToBootom()
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  }

  const handleChangeMovil = (e, pos, name) => {
    let value = e
    if(value){
      if(typeof e === "object") if(e.target) value = e.target.value
    }
    vehiculo.moviles_adicionales[pos][name] = value ? value : ""
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const removerMovil = (i) => {
    vehiculo.moviles_adicionales.splice(i,1)
    return setVehiculo(prev => ({...{}, ...vehiculo})) 
}

const anadirMovilAdicional = () => {
    if(!vehiculo.moviles_adicionales) vehiculo.moviles_adicionales = []
    vehiculo.moviles_adicionales.unshift({
        pais_codigo: '',
        movil: ''
    })
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const anadirEmailAdicional = () => {
    if(!vehiculo.emails_adicionales) vehiculo.emails_adicionales = []
    vehiculo.emails_adicionales.unshift({
        email: ''
    })
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const removerEmail = (i) => {
    vehiculo.emails_adicionales.splice(i,1)
    return setVehiculo(prev => ({...{}, ...vehiculo})) 
}

const handleChangeEmail = (e,pos) => {
    const { name, value } = e.target
    console.log({ name, value, pos})
    vehiculo.emails_adicionales[pos][name] = value
    vehiculo.emails_adicionales[pos].valid = validateEmail(value)
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const mostrarEmailsAdicionales = () => {
    if(!vehiculo.emails_adicionales) return false
    return <div>
        {
            vehiculo.emails_adicionales.map((field,i) => {
                return <div key={`email-${i}`} className="mt-3">
                    <Card  >
                            {/* <Input status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} /> */}
                            <CardContent>
                            <TextField
                              label='Email'
                              error={field.valid === false ? true : false }
                              placeholder="Escribe aquí el email"
                              variant='filled'
                              name='email'
                              required={siEsRequeridoEnEsquema("cargo",requeridos)}
                              fullWidth
                              value={field.email}
                              onChange={(e) => handleChangeEmail(e,i)}
                              helperText="Ingresa un email válido."

                            />
                            </CardContent>
                            <CardActions>
                            <Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>
                            </CardActions>
                    </Card>
                </div>
            })
        }
    </div>
}

const mostrarMovilesAdicionales = () => {

    // const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    if(!vehiculo.moviles_adicionales) return false
    return <div>
        {
            vehiculo.moviles_adicionales.map((field,i) => {
                return <div key={`movil-${i}`} className="mt-3">
                <Card>
                    <CardContent>
                    <Grid container spacing={1}>
                        <Grid item md={4}>
                            <Autocomplete
                              style={{ width: "100%" }} 
                              placeholder="Código de area" 
                              name="pais_codigo" 
                              value={field.pais_codigo} 
                              disablePortal
                              noOptionsText="Sin opciones" 
                              options={codigos_area}
                              renderInput={(params) => <TextField label="Código de área" {...params} />}
                              onChange={(event,e) => handleChangeMovil(e,i,"pais_codigo")} 
                            />
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                              label='Número de teléfono'
                              placeholder="Escribe aquí el número telefónico"
                              variant='filled'
                              name='movil'
                              required={true}
                              fullWidth
                              value={field.movil}
                              onChange={(e) => handleChangeMovil(e,i,"movil")}
                            />
                        </Grid>
                    </Grid>
                    </CardContent>
                    <CardActions>
                    <Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>
                    </CardActions>
                </Card>
                </div>
            })
        }
    </div>
}

  const formularioEditar = () => {

    let default_modelo = false
    let default_tipo = false
    
    if(vehiculo.id_tipo){
      const i_tipo_vehiculo = selectOptionsTipo.findIndex(m => m.value === vehiculo.id_tipo)
      if(i_tipo_vehiculo > -1 ){
        default_tipo = { value: selectOptionsTipo[i_tipo_vehiculo].value, label: selectOptionsTipo[i_tipo_vehiculo].label }
      }
    }

    if(vehiculo.modelo){
      const i_model = selectOptionsModelo.findIndex(m => m._id === vehiculo.modelo)
      if(i_model > -1 ){
        default_modelo = { value: selectOptionsModelo[i_model]._id, label: selectOptionsModelo[i_model].titulo }
      }
    }

    let default_marca = false

    if(vehiculo.marca){
      const i_marca = marcas.findIndex(m => m.value === vehiculo.marca)
      if(i_marca > -1 ){
        default_marca = marcas[i_marca]
      }
    }
    
    let default_color = false

    if(vehiculo.color){
      const i_color = selectOptionsColors.findIndex(m => m.value === vehiculo.color)
      if(i_color > -1 ){
        default_color = selectOptionsColors[i_color]
      }
    }
    
    let default_tipo_motor = false

    if(vehiculo.tipo_motor){
      const i_tipo_motor = selectOptionsTipoMotor.findIndex(m => m.value === vehiculo.tipo_motor)
      if(i_tipo_motor > -1 ){
        default_tipo_motor = selectOptionsTipoMotor[i_tipo_motor]
      }
    }

    return <Grid container spacing={2} sx={{ mb: 2, mt:1 }}>
    <Grid item md={12} xs={12}>
    
    <Grid container spacing={2} >
            <Grid item md={6} xs={12}>
            <ModalEmpresas titulo="Seleccionar empresa" seleccionarEmpresa={(data) => {
                vehiculo.propietario = data.rut
                vehiculo.id_cliente = ""
                setEmpresa(data)
                setDestinatario(false)
                toast.success("Guardar los cambios antes de salir de esta pantalla", { icon: false })
                return setVehiculo({...{}, ...vehiculo})
              }} />
              {
                      empresa ? <div>
                        <Card sx={{ p:2, mt: 2 }}>
                        <Typography mb={0}>Empresa asignada</Typography>
                        <Typography variant='h6' mb={0}>{empresa.razon_social}</Typography>
                        <Button onClick={() => {
                          setEmpresa(false)
                          vehiculo.propietario = ''
                          vehiculo.id_cliente = ""
                          setVehiculo(vehiculo)
                          setDestinatario(false)
                          toast.success("Guardar los cambios antes de salir de esta pantalla", { icon: false })
                          }}>REMOVER</Button>
                        </Card>
                        </div> : false
              }
            </Grid>
            <Grid item md={6} xs={12}>
            <ModalDestinatarios titulo="Seleccionar destinatario" condicion_default={{ propietario: vehiculo.propietario }} seleccionarEmpresa={(data) => {
                vehiculo.id_cliente = data._id
                setDestinatario(data)
                toast.success("Guardar los cambios antes de salir de esta pantalla", { icon: false })
                return setVehiculo(vehiculo)
              }} />
              {
                      destinatario ? <div>
                        <Card sx={{ p:2, mt: 2 }}>
                        <Typography mb={0}>Destinatario asignado</Typography>
                        <Typography variant='h6' mb={0}>{destinatario.razon_social}</Typography>
                        <Button onClick={() => {
                          setDestinatario(false)
                          vehiculo.id_cliente = ''
                          setVehiculo({...{}, ...vehiculo})
                          toast.success("Guardar los cambios antes de salir de esta pantalla", { icon: false })
                          }}>REMOVER</Button>
                        </Card>
                        </div> : false
              }
            </Grid>
          </Grid>
      <Grid container spacing={2} sx={{ mt:2 }}>
        <Grid item md={12} xs={12} >
          <CamposObligatoriosLeyenda />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Nombres'
            variant='filled'
            name='nombres'
            required={siEsRequeridoEnEsquema("nombres",requeridos)}
            defaultValue={vehiculo.nombres}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Apellidos'
            variant='filled'
            name='apellidos'
            required={siEsRequeridoEnEsquema("apellidos",requeridos)}
            fullWidth
            defaultValue={vehiculo.apellidos}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Nota'
            variant='filled'
            name='notas'
            required={siEsRequeridoEnEsquema("notas",requeridos)}
            fullWidth
            defaultValue={vehiculo.notas}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Cargo'
            variant='filled'
            name='cargo'
            required={siEsRequeridoEnEsquema("cargo",requeridos)}
            fullWidth
            defaultValue={vehiculo.cargo}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
        <SelectorGeneral otherProps={{ label: "Categoría del cargo" }} value={vehiculo.id_cargo} strict={true} hideLabel={true} module="categorias" condicion_default={{ tipo: "cargo" }} onChange={data => handleChangeSelect(data, 'id_cargo' )} />
        </Grid>
        <Grid item md={3} xs={12}>

          <FormControl fullWidth className='mb-3'>
          <InputLabel id="demo-simple-select-label" required={siEsRequeridoEnEsquema("status",requeridos)}>Estado</InputLabel>
          <Selector
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Estado * "
              name="status"
              onChange={handleChange}
              defaultValue={vehiculo.status}
              >
              <MenuItem value="active">Activo</MenuItem>
              <MenuItem value="trash">Desactivado</MenuItem>
          </Selector>
          </FormControl>

          </Grid>
          <Grid item md={24} xs={24}>
                    <Divider />
                    <Grid container gutter={15}>
                        <Grid item md={12}>
                        <h3 ><GoDeviceMobile /> Teléfonos</h3>
                        <Button  variant="outlined" sx={{ mb:2 }} startIcon={<GrAdd />} onClick={() => anadirMovilAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarMovilesAdicionales()}
                        </Grid>
                        <Grid item md={12}>
                        <h3 ><AiOutlineMail /> Emails</h3>
                        <Button variant="outlined" sx={{ mb:2 }} startIcon={<GrAdd />} onClick={() => anadirEmailAdicional()} > AÑADIR NUEVO</Button>
                        {mostrarEmailsAdicionales()}
                        </Grid>
                    </Grid>
                </Grid>
        
         
        <Grid item md={12} xs={12}>
          {loading === true ? (
            <CircularProgress />
          ) : (
            <Button
              variant='contained'
              color='success'
              xs={{ m: 0 }}
              onClick={() => actualizarVehiculo()}
            >
              GUARDAR CAMBIOS
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  }

  const cambiarVehiculoConductor = async () => {
    setLoadingDefinicion(true)
    return fetch(`${data.urlapi}/vehiculos/definir-seleccionado`, {
        method: 'POST',
        body: JSON.stringify({
          id: vehiculo._id
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then((res) => {
          if (!res) {
            toast.error('Sin datos');
            return setLoadingDefinicion(false);
          } else if (res.errorMessage) {
            toast.error(res.errorMessage);
            return setLoadingDefinicion(false);
          } else if (res._id) {
            vehiculo.selected = true
            toast.success("Realizado exitosamente")
            setVehiculo(prev => ({...{}, ...vehiculo}));
          }
          return setLoadingDefinicion(false);
        })
        .catch((error) => {
          toast.error('Error al consultar la información, intente nuevamente');
          return setLoadingDefinicion(false);
        })
  }

  const asignarRequisito = async (_id) => {
    setLoadingAsignacion(true)
    return fetch(`${data.urlapi}/requisitos/asignar?id=${_id}&target=${id}`, {
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(res._id){
            toast.success("Agregado exitosamente")
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }
        return setLoadingAsignacion(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setLoadingAsignacion(false)
    })
}

  const seleccionarRequisito = (req) => {
    asignarRequisito(req._id)
  }


  const scollToBootom = () => {
    const container = document.getElementById('main'); // Reemplaza 'container' con el ID de tu contenedor
    if(!container) return false
    container.scrollTo({
      top: container.scrollHeight,
      behavior: 'smooth',
      block: 'end'
    });
  }
  const autoSeleccionado = () => {
    if(!vehiculo.id_usuario) return false
    if(vehiculo.selected !== true) return <LoadingButton
        loading={loadingDefinicion}
        variant="outlined"
        sx={{ mb:2 }}
        onClick={() => cambiarVehiculoConductor()}
    >DEFINIR COMO VEHÍCULO ACTUAL DE ESTE CONDUCTOR</LoadingButton>

    return <div>
      <Alert severity="info" sx={{ mb:2}}>Este vehículo es el vehículo actual de este conductor</Alert>
    </div>
  }

  const render = () => {
    if (loadingMaster) return <CircularProgress />
    if (!vehiculo) return <SinData />

    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Href to={rutas[key].slug}>{rutas[key].title}</Href>
          <Enlace underline='hover' color='text.primary'>Editar {rutas[key].title}</Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Editar {rutas[key].title}</h1>
          <BotonEliminar id={id} />
          {autoSeleccionado()}
          {formularioEditar()}
      </div>
    );
  };
  
  return <Structure component={render()} />;
};

export default ContactoDetails