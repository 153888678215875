const stringByTipoPublicacion = (tipo) => {
    switch (tipo) {
        case "destacado-clientes":
            return "Destacados"
        case "pregunta-frecuente":
            return "Preguntas frecuentes"
        case "pantalla-conexion":
            return "Pantalla de conexión"
        default:
            return tipo
    }
}

module.exports = {
    stringByTipoPublicacion
}