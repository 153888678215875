import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select as Selector,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import PropTypes from 'prop-types';
import SinData from '../General/sin_data';
import Select from 'react-select';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';


const SubSoporteDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [loadingSelect, setLoadingSelect] = useState(true);
  const [subSoporte, setSubSoporte] = useState(false);
  const [permisosOtorgados, setPermisosOtorgados] = useState([]);
  const sesion = useSelector((state) => state.miusuario);
  const { id } = useParams();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const obtenerSubSoporte = async () => {
    return fetch(`${data.urlapi}/subsoporte?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setSubSoporte(res);
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      });
  }

  const obtenerSoportes = async () => {
    return fetch(`${data.urlapi}/soporte/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (Array.isArray(res) !== false) {
          const valores = res.map(opcion => ({ value: opcion._id, label: opcion.titulo_front }))
          console.log({valores})
          setSelectOptions(prev => [...[], ...valores])
        }
        setLoadingSelect(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
      });
  }

  useEffect(() => {
    obtenerSoportes()
    obtenerSubSoporte();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    subSoporte[name] = value;
    console.log(subSoporte);
    return setSubSoporte({ ...{}, ...subSoporte });
  };

  const actualizarSubSoporte = async () => {
    const requeridos = [{ value: 'titulo', label: 'Titulo' }];
    let faltantes = [];
    requeridos.map((campo) => {
      if (!subSoporte[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    subSoporte.permisos = permisosOtorgados;

    setLoading(true);
    return fetch(`${data.urlapi}/subsoporte`, {
      method: 'PUT',
      body: JSON.stringify(subSoporte),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const handleSelect = (e) => {
    subSoporte.parent = e.value
    return setSubSoporte({ ...{}, ...subSoporte });
  }

  const render = () => {
    if (loadingMaster) return <CircularProgress />
    if (loadingSelect) return <CircularProgress />
    if (!subSoporte) return <SinData />

    let default_categoria = false
    if(subSoporte.parent){
      const i = selectOptions.findIndex(o => o.value === subSoporte.parent)
      if(i > -1){
        default_categoria = selectOptions[i]
      }
    }

    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Href to='/subsoporte'>Categorías de soporte</Href>
          <Enlace underline='hover' color='text.primary'>Editar categoría de soporte</Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Editar categoría de soporte</h1>
        <CamposObligatoriosLeyenda />
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Título'
                  variant='filled'
                  name='titulo'
                  required
                  fullWidth
                  defaultValue={subSoporte.titulo}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                  <InputLabel required>Categoría</InputLabel>
                  <Select
                    options={selectOptions}
                    defaultValue={default_categoria}
                    isLoading={loadingSelect}
                    loadingMessage={() => 'Cargando'}
                    noOptionsMessage={() => 'Sin opciones'}
                    placeholder="Selecciona"
                    label='Tipo *'
                    name='tipo'
                    onChange={handleSelect}
                    height={20}
                  />
              </Grid>
              
              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel required>Estado</InputLabel>
                  <Selector
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Estado *'
                    name='status'
                    onChange={handleChange}
                    defaultValue={subSoporte.status}
                  >
                    <MenuItem value='active'>Activo</MenuItem>
                    <MenuItem value='trash'>Desactivado</MenuItem>
                  </Selector>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                {loading === true ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant='contained'
                    color='success'
                    xs={{ m: 0 }}
                    onClick={() => actualizarSubSoporte()}
                  >
                    GUARDAR CAMBIOS
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default SubSoporteDetails;
