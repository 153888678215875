// Soporte - Categoria  Soporte - Subcategoria
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import opcionesfecha from '../../lib/helpers/opcionesfecha';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';

const ModeloCreate = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(true);
  const [selectOptions, setSelectOptions] = useState([]);
  const [modelo, setModelo] = useState({});
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const sesion = useSelector((state) => state.miusuario);
  const requeridos = [
    { value: 'titulo', label: 'Titulo' },
    { value: 'marca', label: 'Marca' },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    modelo[name] = value;
    return setModelo({ ...{}, ...modelo });
  };

  const handleSelect = (e) => {
    modelo.id_marca = e.value;
    modelo.marca = e.label;
  };

  const crearModeloVehiculo = async () => {
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!modelo[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);

    setLoading(true);
    return fetch(`${data.urlapi}/vehiculos/modelo`, {
      method: 'POST',
      body: JSON.stringify(modelo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos o no existe la categoria Modelo');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Creado exitosamente');
          return setTimeout(() => {
            return window.location = `/model/vehicles-${res._id}`
          }, 500);
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const opciones = (res) => {
    res.map((opcion) => {
      return selectOptions.push({ value: opcion._id, label: opcion.titulo });
    });
    setLoadingSelect(false);
    return selectOptions;
  };

  const obtenerMarcas = async () => {
    return fetch(`${data.urlapi}/vehiculos/marca/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (res.length > 0) {
          opciones(res);
        } else if (res.length < 1) {
          setLoadingSelect(false);
          toast.error('No existen Marcas.');
        }
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
      });
  };

  useEffect(() => {
    obtenerMarcas();
  }, []);

  const render = () => {
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to='/model/vehicles'>Modelo-Vehículos</Link>
          <Enlace underline='hover' color='text.primary'>
            Crear Modelo
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Crear Modelo</h1>
        <CamposObligatoriosLeyenda />
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item md={12} xs={12}>
            <Typography variant='h6'>Datos</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label='Titulo'
              variant='filled'
              name='titulo'
              required
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <InputLabel required={siEsRequeridoEnEsquema("marca",requeridos)}>Marca</InputLabel>
            <Select
              options={selectOptions}
              isLoading={loadingSelect}
              loadingMessage={() => 'Cargando'}
              noOptionsMessage={() => 'Sin opciones'}
              placeholder='Selecciona un tipo'
              onChange={handleSelect}
              height={20}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            {loading === true ? (
              <CircularProgress />
            ) : (
              <Button
                variant='contained'
                xs={{ m: 0 }}
                onClick={() => crearModeloVehiculo()}
              >
                CREAR
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default ModeloCreate;
