import { TabContext, TabList, TabPanel } from "@mui/lab"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../../redux/actions/session"
import { toast } from "react-hot-toast"
import { Box, Tab } from "@mui/material"
import FiltroZona from "../../../subcomponents/zonas/filtro"
import { urlapi } from "../../../lib/backend/data"

const FiltrosConductores = (props) => {
    const [ value, setValue ] = useState(0)
    const [ loading, setLoading ] = useState(false)
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)

    const handleChange = (e, value) => {
        return setValue(value);
      }

      const buscarPorGeocerca = async (id_geocerca) => {
        setLoading(true)
        return fetch(`${urlapi}/conductores/busqueda/ultima-ubicacion`,{
            method:'POST',
            body: JSON.stringify({
                id_geocerca
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
          if (!res) {
            toast.error('Sin datos');
            return setLoading(false);
          } else if (res.errorMessage) {
            toast.error(res.errorMessage);
            return setLoading(false);
          }
          if(props.onFilter) props.onFilter(res)
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }

    return <div>
        <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                value={value}
                textColor='primary'
                onChange={handleChange}
                indicatorColor='primary'
                aria-label='primary tabs example'
                >
                <Tab
                        key={`tab-0`}
                        value={0}
                        label={"Búsqueda por última ubicación"}
                        />
                </TabList>
                </Box>

                <TabPanel key={`tabpanel-0`} style={{ background: "whitesmoke" }} value={0}>
                <FiltroZona loadingBusqueda={loading} onSelectGeo={(data) => buscarPorGeocerca(data._id)} />
                </TabPanel>
                </TabContext>

    </div>
}

export default FiltrosConductores