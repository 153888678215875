import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { toast } from 'react-hot-toast';
import { urlapi } from '../../lib/backend/data';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
    const {
        id_target
    } = props
  const [ loading, setLoading ] = React.useState(false)  
  const { onClose, selectedValue, open } = props;
  const sesion = useSelector(state => state.miusuario)

  const dispatch = useDispatch()

  const handleClose = () => {
    onClose(selectedValue);
  };

  const eliminar = async () => {
    setLoading(true)
    return fetch(`${urlapi}/empresas?id=${id_target}`,{
        method:'DELETE',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(res._id){
            toast.success("Realizado exitosamente")
            setTimeout(() => {
                return window.location = "/empresas"
            }, 1000);
        }
        return setLoading(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setLoading(false)
    })
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Estás seguro que deseas eliminar este registro, no será posible revertir esta acción</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>CANCELAR</Button>
        <LoadingButton loading={loading} onClick={() => eliminar()} color="error" >SI, ELIMINAR</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function BotonEliminar(props) {
    const { id } = props
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Button size="small" startIcon={<DeleteIcon />} variant="outlined" color="error" onClick={handleClickOpen}>ELIMINAR DEFINITIVAMENTE</Button>
      <SimpleDialog
        id_target={id}
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}