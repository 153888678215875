import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/session"
import { urlapi } from "../../lib/backend/data"
import { Box, Button, Card, CardContent, CircularProgress, FormControlLabel, Grid, Skeleton, Switch, Tab, Tabs, Tooltip, Typography } from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import ModalEmpresas from "../Empresas/modal_seleccion"
import SelectorZona from "../../subcomponents/maps/selector_geocerca"
import PlaceIcon from '@mui/icons-material/Place';
import SelectorZonaV2 from "../../subcomponents/maps/selector_geocerca_v2"

const BusquedaOperacion = (props) => {
    const { 
        condicion_default,
        condicion_default_conductores
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ empresa, setEmpresa ] = useState(false)
    const [ geocercaBusqueda, setGeocercaBusqueda ] = useState(false)
    const [ value, setValue ] = useState(0)
    const [ filtros, setFiltros ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const [ condicion_busqueda_conductores, setCondicionBusquedaConductores ] = useState(condicion_default_conductores ? condicion_default_conductores : false)

    const handleChange = (e, value) => {
        return setValue(value);
      }
      
    const obtenerDatos = async (condi)=>{
        const condicion_enviar = condi ? condi : condicion_busqueda
        setLoading(true)
        return fetch(`${urlapi}/vehiculos/operacion`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_enviar,
                condicion_busqueda_conductores
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
            } else if(Array.isArray(res) !== false){
                setFiltros(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerDatos(condicion_default)
    }, [ condicion_default ])

    const seleccionar = filtro => {
        if(filtro.condicion){
            if(typeof filtro.condicion === "object"){
                if(props.onFilter) props.onFilter(filtro.condicion)
            }
        }
    }

    const resetearBusqueda = () => {
        if(props.onFilter) props.onFilter({})
    }

    const MostrarGrafico = (props) => {
        const { 
            campo
        } = props

        if(campo.values.length < 1) return <div><Typography className="mt-0 mb-3" level={4}>Sin información</Typography></div>
        
                return <div>
                    
                    <Grid container spacing={1}>
                    {
                        campo.values.map((filtro,i) => {
                            return <Grid item md={3} xs={6} key={`filtro-${i}`} onClick={() => seleccionar(filtro)}>
                                <Tooltip title="Toca para filtrar el listado de abajo" placement="top">
                            <Card variant="outlined" className="mb-3 hover">
                                <CardContent>
                                <Grid container>
                                    <Grid xs={12}>
                                        { filtro.image ? <img style={{ width: "100%" }} src={filtro.image} /> : false}
                                        <Typography className="m-0" style={{ fontSize:12 }} >{filtro.label.toUpperCase()}</Typography>
                                        <Typography variant="h5" className="m-0">{filtro.cantidad}</Typography>
                                    </Grid>
                                </Grid>
                                </CardContent>
                            </Card>
                            </Tooltip>
                        </Grid>
                        })
                    }
                    </Grid>
                    
            </div>
    }
    
    const filtrarNuevCondicion = (cond) => {
        setCondicionBusqueda(cond)
        obtenerDatos(cond)
        if(props.onFilter) props.onFilter(cond)
    }

    const mostrarEmpresa = () => {
        return empresa ? <div>
              <Card sx={{ p:2, mt: 2 }} variant="outlined" >
              <Typography mb={0} style={{ fontSize: 11}}>Empresa seleccionada</Typography>
              <Typography variant='h6' mb={0}>{empresa.razon_social}</Typography>
              <Button onClick={() => {
                setEmpresa(false)
                filtrarNuevCondicion({})
              }}>REMOVER</Button>
              </Card>
        </div> : false
          
    }

    const buscarPorGeocerca = async () => {
        setEmpresa(false)
        setLoading(true)
        return fetch(`${urlapi}/vehiculos/contadores/geo`,{
            method:'POST',
            body: JSON.stringify({
                id_geocerca: geocercaBusqueda._id
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
            } else if(Array.isArray(res) !== false){
                setFiltros(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }

    const handleChangeSwitch = (e) => {
        const valor  = e.target.checked
        if(valor === true){
            setCondicionBusquedaConductores({ conectado: valor })
        } else {
            setCondicionBusquedaConductores(false)
        }
    }

    const mostrarFiltros = () => {
        if(loading) return <div>
            <CircularProgress />
            <Typography variant="h6">Cargando gráficos</Typography>
            </div>

        return <div>                
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mb:0, mt:0 }} >Conductores conectados</Typography>
                    { /** <FormControlLabel control={<Switch onChange={handleChangeSwitch} />} label="Mostrar solo vehículos de conductores conectados" />**/}
                    <ModalEmpresas titulo="Filtrar empresa" seleccionarEmpresa={(data) => {
                        const cond = { propietario: data.rut }
                        filtrarNuevCondicion(cond)
                        setEmpresa(data)
                        }} />
                    </Grid>
                    { /* 
                    <Grid item md={5}>
                        <Typography>Búsqueda por zona</Typography>
                        <SelectorZonaV2 defaultValue={geocercaBusqueda ? geocercaBusqueda.titulo.toUpperCase() : ''} onChange={data => setGeocercaBusqueda(data)} />
                    </Grid>
                    <Grid item md={5}>
                        {
                            geocercaBusqueda ? <div>
                                <Typography>Click aquí para buscar</Typography>
                                <Button style={{ width: "100%"}} variant="outlined" size="large" onClick={() => buscarPorGeocerca()} ><PlaceIcon /> BUSCAR EN {geocercaBusqueda.titulo ? geocercaBusqueda.titulo.toUpperCase() : "No determinado"}</Button>
                            </div> : false
                        }
                        
                    </Grid> */ }
                </Grid>

                {mostrarEmpresa()}

                <TabContext value={value}>
                <Box sx={{ mb: 1, mt:2, borderColor: 'divider' }}>
                <TabList
                value={value}
                textColor='primary'
                onChange={handleChange}
                indicatorColor='primary'
                aria-label='primary tabs example'
                >
                {
                    filtros.map((item,index) => {
                        return <Tab
                        key={`tab-${index}`}
                        value={index}
                        label={item.label}
                        
                        />
                    })
                }
                </TabList>
                </Box>
                <Button onClick={() => resetearBusqueda()}>BORRAR FILTROS</Button>
                {
                    filtros.map((item,index) => {
                        return <TabPanel key={`tabpanel-${index}`} style={{ background: "whitesmoke" }} value={index}>
                            <MostrarGrafico campo={item} />
                        </TabPanel>
                    })
                }
                </TabContext>            
        </div>
    }
    return <div>
        {mostrarFiltros()}
    </div>
}

export default BusquedaOperacion