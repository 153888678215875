const debug = require('../data/debug_mode')

const url_images = window.location.protocol + "//" + window.location.host + "/images"

const datos = {
    urlapi: debug === true ? 'http://localhost:5001/api/1.0' : 'https://delpacargo.delpasystem.com/api/1.0',
    key_local_storage_user: 'ubdelpa_bknd_user',
    maps_key: "AIzaSyAv6hXKm28tOq0d8hsrF-RWYto1aBkH71c",
    pagina: 10,
    mapbox_token: "pk.eyJ1IjoiY2FybG9zZGVscGEiLCJhIjoiY2xreTBlZm1xMTByZDNkbzJpOGV0bmU3dCJ9.NL5sGN9Raks1KKQhcrfXIA",
    url_images,
    style: {
        secondary: "#949494",
        primary: "#07539e",
        highlight: "#0070ff",
    },
    polyline_options: {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        paths: [],
        zIndex: 1
    },
    configs3: {
        dirName: "movil/",
        bucketName: "urrapp",
        region: "us-east-1",
        accessKeyId: 'AKIAW3FR56LCTXHZCW5T',
        secretAccessKey: '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',
    },
    timezone: "America/Santiago"
}

module.exports = datos