import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { urlapi } from "../../lib/backend/data";
import { Button, Card, CircularProgress, Divider, Typography } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab";
import { cerrarSesion } from "../../redux/actions/session";
import ModalEmpresas from "../Empresas/modal_seleccion";

const EmpresaVinculada = (props) => {
    const [idconductor,setIdConductor] = useState(props.conductor ? props.conductor : false)
    const [ empresa, setEmpresa ] = useState(false)
    const [ loadingEmpresa, setLoadingEmpresa ] = useState(false)
    const [ autorizado, setAutorizado ] = useState(false)
    const sesion = useSelector((state) => state.miusuario)
    const dispatch = useDispatch()
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)

    const obtenerDatos = async () => {
        if(!idconductor) return false
        return fetch(`${urlapi}/conductores/consultar-empresa`,{
            method:'POST',
            body: JSON.stringify({
                idconductor
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if( res.isBusiness === true ){
                if(res.autorizado === true) setAutorizado(true)
                if( res.business ) setEmpresa(res.business)
            }
        })
        .catch(error => {
            toast.error("Error al consultar la empresa")
        })
    }

    const desvincularEmpresa = async (propietario, propietario_autorizado)=>{
        setLoadingEmpresa(true)
        return fetch(`${urlapi}/conductores/desvincular-empresa`,{
            method:'POST',
            body: JSON.stringify({
                idconductor,
                propietario,
                propietario_autorizado
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingEmpresa(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingEmpresa(false)
            } else if(res.success === true){
                toast.success( propietario_autorizado === true ? "Vinculación exitosa " : "Desvinculada exitosamente")
                if(propietario_autorizado === true){
                    setAutorizado(true)
                } else if(propietario_autorizado === false){
                    setEmpresa(false)
                }
            }
            return setLoadingEmpresa(false)
        })
        .catch(error => {
            toast.error("Error al realizar esta operación, intente nuevamente")
            return setLoadingEmpresa(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const seleccionarEmpresa = async (data) => {
        await desvincularEmpresa(data.rut, true)
        setEmpresa(prev => ({...{}, ...data}))
    }

    const vinculacion = () => { 
        return <div>
            { loadingEmpresa ? <CircularProgress /> : <ModalEmpresas titulo="Vincular empresa" seleccionarEmpresa={data => seleccionarEmpresa(data)} /> }
            
        </div>
    }

    if(!idconductor) return vinculacion()
    if(!empresa) return vinculacion()

    return <div>
        <Card sx={{ p:2, mb:2 }}>
            <Typography>Empresa vinculada</Typography>
            <b><Link to={`/empresas-${empresa._id}`}>{empresa.razon_social} <OpenInNewIcon style={{ marginBottom: -4 }} fontSize="small" /></Link></b>
            <Divider sx={{ mt:1, mb:1 }}/>
            {
                autorizado===true ? <LoadingButton
                loading={loadingEmpresa}
                variant="outlined"
                onClick={() => desvincularEmpresa('',false)}
                >DESVINCULAR EMPRESA</LoadingButton> : <LoadingButton
                    loading={loadingEmpresa}
                    variant="contained"
                    color="success"
                    onClick={() => desvincularEmpresa(empresa.rut,true)}
                >APROBAR VINCULACIÓN</LoadingButton>
            }
            
        </Card>
    </div>
}

export default EmpresaVinculada