import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { urlapi } from "../../lib/backend/data"
import GraficoDona from "../../subcomponents/general/grafico_dona"
import { CircularProgress, Grid, Typography } from "@mui/material"
import { cerrarSesion } from "../../redux/actions/session"

const GraficosCostos = (props) => {
    const {
        condicion_default
    } = props
    const dispatch = useDispatch()
    const [ loading, setLoading ] = useState(true)
    const [ datos, setDatos ] = useState([])
    const [ condicion, setCondicion ] = useState(condicion_default ? condicion_default : {})
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    useEffect(() => {
        const cond = condicion_default ? condicion_default : condicion
        consultarDatos(cond)
    }, [ condicion_default ])

    const consultarDatos = async (forzar_condicion) => {
        const enviar_condicion = forzar_condicion ? forzar_condicion : condicion
        setLoading(true)
        return fetch(`${urlapi}/costos/graph`,{
            method:'POST',
            body: JSON.stringify({
                condicion: enviar_condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setDatos(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    if(loading) return <div><Typography variant="h6" className="mt-0 mb-0"><CircularProgress /> </Typography></div>
    if(datos.length < 1) return <Typography variant="h4">Sin datos</Typography>

    return <div>
                    <Typography variant="h6" className="mt-0 mb-0">Segmentación de costos</Typography>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                        <Typography level={5} className='d-block mb-3' style={{ fontSize: 11 }}>Costos segmentados</Typography>
                        <GraficoDona datos={datos} />
                        </Grid>
                        {
                            datos.map(tipo => {
                                return  <Grid item md={3}>
                                        <Typography level={5} className='d-block mb-3' style={{ fontSize: 11 }}>{tipo.titulo}</Typography>
                                        <GraficoDona datos={tipo.subtipos} />
                                    </Grid>
                            })
                        }
                        </Grid>
    </div>

}

export default GraficosCostos