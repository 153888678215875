import { Avatar, Grid, Skeleton } from "@mui/material"

const datos = [
    {},
    {},
    {},
    {},
    {},
    {}
]

const CargandoTabla = () => {
    return <div>
        {
            datos.map((p,i) => <Skeleton key={`t-${i}`} variant="rectangular" animation="wave" height={70} sx={{ mb: 2 }} />)
        }
    </div>
}

export default CargandoTabla