import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Structure from '../Structure';
import { Link, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';

const EmpresasCrear = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [clientes, setClientes] = useState({});
  const sesion = useSelector((state) => state.miusuario);
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const requeridos = [
    { value: 'razon_social', label: 'Razón social' },
    { value: 'rut', label: 'Rut' }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    clientes[name] = value;
    return setClientes({ ...{}, ...clientes });
  };

  const crearClientes = async () => {
    let faltantes = [];
    requeridos.map((campo) => {
      if (!clientes[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);

    setLoading(true);
    return fetch(`${data.urlapi}/empresas`, {
      method: 'POST',
      body: JSON.stringify(clientes),
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
         'country': pais,
          'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Creado exitosamente');
          setTimeout(() => {
            return (window.location = `/empresas-${res._id}`);
          }, 500);
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const render = () => {
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to='/empresas'>Empresas</Link>
          <Enlace underline='hover' color='text.primary'>
            Crear empresa
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Crear empresa</h1>
        <CamposObligatoriosLeyenda />
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Razón social'
                  variant='filled'
                  name='razon_social'
                  required={siEsRequeridoEnEsquema("razon_social",requeridos)}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Rut'
                  variant='filled'
                  name='rut'
                  required={siEsRequeridoEnEsquema("rut",requeridos)}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Dirección'
                  variant='filled'
                  name='direccion'
                  required={siEsRequeridoEnEsquema("direccion",requeridos)}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                {loading === true ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant='contained'
                    color='success'
                    xs={{ m: 0 }}
                    onClick={() => crearClientes()}
                  >CREAR</Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default EmpresasCrear