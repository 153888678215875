import { Button } from '@mui/material'
import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cerrarSesion } from '../../redux/actions/session'
import Structure from '../Structure'

const Home = () => {
    const dispatch = useDispatch()
    const usuario = useSelector(state => state.miusuario)

    const fecha = "2023-07-16T12:01:06.020+00:00"
    const render = () => {
        return <div>
            <h3>Bienvenido {usuario.data.nombres}</h3>
        </div>
    }

    return <Structure component={render()} />
}

export default Home