import { Button, Grid, Typography } from "@mui/material"
import SelectorZona from "../maps/selector_geocerca"
import PlaceIcon from '@mui/icons-material/Place';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { cerrarSesion } from "../../redux/actions/session";
import { urlapi } from "../../lib/backend/data";
import { LoadingButton } from "@mui/lab";
import SelectorZonaV2 from "../maps/selector_geocerca_v2";


const FiltroZona = (props) => {
    const {
        loadingBusqueda
    } = props
    const sesion = useSelector((state) => state.miusuario)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ geocercaBusqueda, setGeocercaBusqueda ] = useState(false)
    const dispatch = useDispatch();

    const buscarPorGeocerca = async () => {
        if(props.onSelectGeo) props.onSelectGeo(geocercaBusqueda)
    }

    return <div>
        <Grid container spacing={3}>
                    <Grid item md={8}>
                        <Typography>Búsqueda por zona</Typography>
                        <SelectorZonaV2 defaultValue={geocercaBusqueda ? geocercaBusqueda.titulo.toUpperCase() : ''} onChange={data => setGeocercaBusqueda(data)} />
                    </Grid>
                    <Grid item md={4}>
                        {
                            geocercaBusqueda ? <div style={{ width: "100%" }}>
                                <Typography>Click aquí para buscar</Typography>
                                <LoadingButton fullWidth loading={loadingBusqueda} variant="outlined" size="large" onClick={() => buscarPorGeocerca()} ><PlaceIcon /> BUSCAR EN {geocercaBusqueda.titulo ? geocercaBusqueda.titulo.toUpperCase() : "No determinado"}</LoadingButton>
                            </div> : <div style={{ width: "100%" }}>
                                <Typography>Click aquí para buscar</Typography>
                                <LoadingButton fullWidth loading={loadingBusqueda} disabled  variant="outlined" size="large" onClick={() => buscarPorGeocerca()} ><PlaceIcon /> BUSCAR</LoadingButton>
                            </div>
                        }
                        
                    </Grid>
        </Grid>
        </div>
}

export default FiltroZona