import { useDispatch, useSelector } from "react-redux";
import { urlapi } from "../../lib/backend/data";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Autocomplete, CircularProgress, Select, TextField } from "@mui/material";
import { cerrarSesion } from "../../redux/actions/session";

const SelectorGeneral = (props) => {
    const {
        otherProps,
        placeholder,
        hideLabel,
        module,
        titulo,
        condicion_default,
        value
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ opcionesComplete, setOpcionesComplete ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const pais =  useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const label = titulo ? titulo : 'Opción'
    const other_props = otherProps ? otherProps : {}

    const obtenerDatos = async () => {
        setLoading(true)
        return fetch(`${urlapi}/${module}/list/select`, {
          method: 'POST',
          body: JSON.stringify({
            condicion: condicion_default
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setOpcionesComplete(res)
              setOpciones(res.filter(m => m.title).map(op => ({ value: op._id, label: op.title.toUpperCase() })))
            }
            return setLoading(false);
          })
          .catch((error) => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }

      const onChangeValue = (evento, e) => {
        if(!e){
          if(props.onChange) props.onChange('')
          return 
        }
        // if(mode === "multiple"){
        //   let valores = []
        //   for( const id of e ){
        //     const i = opcionesComplete.findIndex(m => m._id === id)
        //     valores.push(opcionesComplete[i])
        //   }
        //   if(props.onChange) props.onChange(valores)
        // } else {
          const i = opcionesComplete.findIndex(m => m._id === e.value)
          if(i > -1) if(props.onChange) props.onChange(opcionesComplete[i])
        // }
      }

      useEffect(() => {
        obtenerDatos()
      }, [ condicion_default ])

      let valor = null
      if(value) {
        const i = opciones.findIndex(e => e.value === value)
        if(i > -1) valor = opciones[i]
      }

      const componente = <Autocomplete
      disablePortal
      noOptionsText="Sin opciones"
      value={valor}
      renderInput={(params) => <TextField {...params} {...other_props} InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }} />}
    placeholder={placeholder ? placeholder : ""}
      options={opciones}
      onChange={onChangeValue}
      />

      return <div>
        {
          hideLabel === true ? componente : <div>
              {componente}
          </div>
        }
      </div>
}

export default SelectorGeneral