import {
  AlertTitle,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import PropTypes from 'prop-types';
import SinData from '../General/sin_data';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import Alert from '@mui/material/Alert';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import BotonEliminar from './boton_eliminar';

const ClientsDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(false);
  const [permisosOtorgados, setPermisosOtorgados] = useState([]);
  const sesion = useSelector((state) => state.miusuario);
  const requeridos = [
    { value: 'nombre', label: 'Nombre' },
    { value: 'apellido', label: 'Apellido' },
    { value: 'movil', label: 'Movil' },
    { value: 'pais_code', label: 'Codigo de País' }
  ];

  const { id } = useParams();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const obtenerCliente = async () => {
    return fetch(`${data.urlapi}/clientes?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setClient(res);
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      });
  };

  useEffect(() => {
    obtenerCliente();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    client[name] = value;
    return setClient({ ...{}, ...client });
  };

  const actualizarCliente = async () => {
    let faltantes = [];
    requeridos.map((campo) => {
      if (!client[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    client.permisos = permisosOtorgados;

    setLoading(true);
    return fetch(`${data.urlapi}/clientes`, {
      method: 'PUT',
      body: JSON.stringify(client),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const mostrarSiEsEmpresa = () => {
    if(!client) return false
    if(client.tipo !== "empresa") return false
    if(!client.detalle_empresa) return false
    if(typeof client.detalle_empresa !== "object") return false

    return <div>
      <Card sx={{ p:2 }}>
        <Typography variant='h6'><BusinessCenterIcon /> EMPRESA VINCULADA</Typography>
        <Divider sx={{ mb:1, mt:1 }} />
        { client.empresa_validada !== true ? <Alert sx={{ mb:1 }} severity="error"><AlertTitle>¡Atención!</AlertTitle>ESTE CLIENTE AÚN NO ESTÁ AUTORIZADO A REPRESENTAR ESTA EMPRESA</Alert> : false }
        <Typography variant='p'>Este es un usuario vinculado a una empresa</Typography>
        <Typography variant='h6'><Href target='_blank' to={`empresas-${client.detalle_empresa._id}`}>{client.detalle_empresa.razon_social}</Href> </Typography>
        <Typography variant='p'>{client.detalle_empresa.rut}</Typography>
      </Card>
    </div>
  }

  const handleChangeAutorizado = (e) => {
    const valor = e.target.checked
    client.empresa_validada = valor
    return setClient({ ...{}, ...client });
  }

  const render = () => {
    if (loadingMaster) return <CircularProgress />;
    if (!client) return <SinData />;
    console.log(client);
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Href to='/clients'>Clientes</Href>
          <Enlace underline='hover' color='text.primary'>
            Editar Cliente
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Editar Cliente</h1>
        <BotonEliminar id={id} />
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography variant='h6'>
                  <SettingsIcon sx={{ mb: -0.7 }} /> Detalles</Typography>
                  <CamposObligatoriosLeyenda />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Nombre'
                  variant='filled'
                  name='nombre'
                  required={siEsRequeridoEnEsquema("nombre",requeridos)}
                  fullWidth
                  defaultValue={client.nombre}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Apellido'
                  variant='filled'
                  name='apellido'
                  required={siEsRequeridoEnEsquema("apellido",requeridos)}
                  fullWidth
                  defaultValue={client.apellido}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Rut'
                  variant='filled'
                  name='rut'
                  required={siEsRequeridoEnEsquema("rut",requeridos)}
                  fullWidth
                  defaultValue={client.rut}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  type='email'
                  label='Email'
                  variant='filled'
                  name='email'
                  required={siEsRequeridoEnEsquema("email",requeridos)}
                  fullWidth
                  defaultValue={client.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Movíl'
                  variant='filled'
                  name='movil'
                  required={siEsRequeridoEnEsquema("movil",requeridos)}
                  fullWidth
                  defaultValue={client.movil}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel id='demo-simple-select-label' required={siEsRequeridoEnEsquema("pais_code",requeridos)}>Codigo de País</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Codigo de Pais *'
                    name='pais_code'
                    defaultValue={client.pais_code}
                    onChange={handleChange}
                  >
                    <MenuItem value='CL'>CL</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel id='demo-simple-select-label' required={siEsRequeridoEnEsquema("tipo",requeridos)}>Tipo</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Tipo *'
                    name='tipo'
                    defaultValue={client.tipo}
                    onChange={handleChange}
                  >
                    <MenuItem value=''>Seleccione</MenuItem>
                    <MenuItem value='usuario'>Usuario</MenuItem>
                    <MenuItem value='empresa'>Empresa</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel id='demo-simple-select-label' required={siEsRequeridoEnEsquema("sexo",requeridos)}>Genero</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Genero *'
                    name='sexo'
                    defaultValue={client.sexo}
                    onChange={handleChange}
                  >
                    <MenuItem value='Masculino'>Masculino</MenuItem>
                    <MenuItem value='Femenino'>Femenino</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel id='demo-simple-select-label' required={siEsRequeridoEnEsquema("status",requeridos)}>Estado</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Estado *'
                    name='status'
                    onChange={handleChange}
                    defaultValue={client.status}
                  >
                    <MenuItem value='active'>Activo</MenuItem>
                    <MenuItem value='trash'>Desactivado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <FormGroup>
                  <FormControlLabel control={<Switch defaultChecked={client.empresa_validada} onChange={handleChangeAutorizado} />} label="Autorizado como empresa" />
                </FormGroup>
              </Grid>
              <Grid item md={12} xs={12}>
                {mostrarSiEsEmpresa()}
              </Grid>
              <Grid item md={12} xs={12}>
                {loading === true ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant='contained'
                    color='success'
                    xs={{ m: 0 }}
                    onClick={() => actualizarCliente()}
                  >
                    GUARDAR CAMBIOS
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default ClientsDetails;
