import CargandoTabla from "./cargando"
import { Box, Button, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { useEffect, useState } from "react"
import data from '../../lib/backend/data'
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { fechaATexto } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"

const ListadoNotificaciones = (props) => {
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const trash = props.trash ? props.trash : false
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'
    const showDiscardButton = typeof props.showDiscardButton ? true : false

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerConductores(page)
    }
    
    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerConductores(page,{})
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination  sx={{ mt:2 }} color="primary" count={cantidad} page={pagina} onChange={handleChangePagina} />
        </div>
    }

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/notificaciones/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!campoBusqueda) return toast.error('Selecciona una patente')
        let nueva_condicion = condicion_busqueda
        nueva_condicion["$text"] = { $search: campoBusqueda.toLowerCase() }
        setCondicionBusqueda(nueva_condicion)
        setPagina(1)
        return obtenerConductores(1, nueva_condicion)
    }

    const descartarNotBD = async (id) => {
        return fetch(`${data.urlapi}/notificaciones/discard?id=${id}`,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${sesion.tokenSession}`
          }
        })
        .then(res => res.json())
        .then(res => res)
        .catch(error => error)
      }

    const seleccionarConductor = (driver) => {
        if(props.seleccionarConductor) props.seleccionarConductor(driver)
        return false
    }

    const showByType = (tipo, driver) => {
        switch (tipo) {
            case "funcion":
                return <b onClick={() => seleccionarConductor(driver)}>{driver.nombres}</b>
            case "link":
                return <b><Link to={`/driver-${driver._id}`}>{driver.nombres}</Link></b>
            default:
                return 
        }
    }

    const descartarNotificacion = (i) => {
        if(conductores[i]) descartarNotBD(conductores[i]._id)
        conductores.splice(i,1)
        return setConductores(prev => [...[], ...conductores])
      }

    const handleChangeCampo = (e) => {
        const { value } = e.target
        return setCampoBusqueda(value)
    }

    if(loadingConductores) return <CargandoTabla />

    return <div>
            <Grid container >
                <Grid item xs={12}>
                    <Box component="form" noValidate onSubmit={handleSubmit}  sx={{ mt: 1, mb: 1 }}>
                        <TextField id="outlined-basic" label="Buscar por nombre, apellido, email o móvil" variant="outlined" onChange={handleChangeCampo} fullWidth />
                    </Box>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={6}>{paginacion(data.pagina, total)}</Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}><h3 sx={{ m:0 }} >{total} {titulo}</h3></Grid>
            </Grid>
        
        <TableContainer component={Paper} sx={{ mt:2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
            <TableHead>
            <TableRow>
                <TableCell>Descripción</TableCell>
                <TableCell>Acción</TableCell>
                <TableCell>Fecha</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            { conductores.map((not,inot) => (
                <TableRow
                key={not.email}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell>{not.descripcion}</TableCell>
                <TableCell>
                    { not.url_slug ? <Link target="_blank" to={not.url_slug}><Button>VER DETALLES</Button></Link> : false }
                    {showDiscardButton ? <Button onClick={() => descartarNotificacion(inot)}>DESCARTAR</Button> : false}
                </TableCell>
                <TableCell>{fechaATexto(not.fecha_zona_horaria_local)}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    </div>
}

export default ListadoNotificaciones