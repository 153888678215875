import { Box, Button, Tab, Tabs, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import ListadoVehiculos from './listado';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import toast from 'react-hot-toast';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { urlapi } from '../../lib/backend/data';
import ContadoresVehiculos from './contadores';

const Vehicles = () => {
  const sesion = useSelector(state => state.miusuario)
  const dispatch = useDispatch()
  const [value, setValue] = useState(0)
  const [ loading, setLoading ] = useState(true)
  const [ filtroSeleccionado, setFiltroSeleccionado ] = useState(false)
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const [ conductores, setConductores ] = useState([])
  const [ condiicon_listado_activos, setCondicionListadoActivos ] = useState({ status: { $nin: ['trash'] } })
  const [ condiicon_listado_inactivos, setCondicionListadoInactivos ] = useState({ status: "trash" })
  const [ condiicon_listado_sin_empresa, setCondicionListadoSinEmpresa ] = useState({ propietario:  { $in: [null,""] } })
  const [ condiicon_listado_sin_conductor, setCondicionListadoSinConductor ] = useState({ id_usuario:  { $in: [null,""] } })

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (e, value) => {
    return setValue(value);
  }

  const obtenerVehiculosPendientes = async (tipo) => {
    return fetch(`${urlapi}/vehiculos/requisitos-pendientes`,{
      method:'POST',
      body: JSON.stringify({
          tipo
      }),
      headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
      }
  })
  .then(res => {
      if(res.status === 401) return dispatch(cerrarSesion())
      return res.json()
  })
  .then(res => {
      if(!res){
          toast.error('Sin datos')
          return setLoading(false)
      } else if(res.errorMessage){
          toast.error(res.errorMessage)
          return setLoading(false)
      } else if(Array.isArray(res) !== false){
          setConductores(res)
      }
      return setLoading(false)
  })
  .catch(error => {
      toast.error("Error al consultar la información, intente nuevamente")
      return setLoading(false)
  })
  }

  useEffect(() => {
    obtenerVehiculosPendientes("vehiculo")
  }, [])

  const VehiculosPendientes = (props) => {
    const drivers = props.conductores ? props.conductores : []
    if(drivers.length < 1) return false
    return <div style={{ marginTop: 20, marginBottom: 20 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6"><ErrorIcon color="error" /> {drivers.length} Vehículos con requisitos pendientes de aprobación</Typography>
        </AccordionSummary>
        <AccordionDetails>
            { drivers.map(driver => {
              let identificador = ''
              if(driver.detalle_marca) identificador = driver.detalle_marca
              if(driver.detalle_modelo) identificador = `${identificador} ${driver.detalle_modelo}`
              if(driver.patente) identificador = `${identificador} ${driver.patente}`
              if(!identificador) identificador = `Vehículo sin modelo, marca o patente`
              return <div><Typography><Link to={`vehicles-${driver._id}`}>{identificador}</Link></Typography></div>
            }) }
        </AccordionDetails>
      </Accordion>
      
    </div>
  }

  const onCleanFilter = () => {
    setFiltroSeleccionado(false)
  }

  const onFilter = (cond, label) => {
    setFiltroSeleccionado(label)
    setCondicionListadoActivos(cond)
    setValue(0)
  }

  const render = () => {
    return (
      <div>
        <h1 style={{ margin: 0 }}>Vehículos</h1>
        <Link to={`/vehicles-new`}>
          <Button variant='outlined'>CREAR</Button>
        </Link>
        <Link to={`/vehicles/import`}>
          <Button variant='outlined'>IMPORTAR</Button>
        </Link>
          <VehiculosPendientes conductores={conductores} />

          <ContadoresVehiculos selected={filtroSeleccionado} cleanSeleccionado={() => setFiltroSeleccionado(false)} onFilter={(data, label) => onFilter(data, label)} />
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor='primary'
            indicatorColor='primary'
            aria-label='primary tabs example'
          >
            <Tab
              value={0}
              label='Activos'
              {...a11yProps(0)}
            />
            <Tab
              value={1}
              label='Archivados'
              {...a11yProps(1)}
            />
            <Tab
              value={2}
              label='Sin empresa asignada'
              {...a11yProps(1)}
            />
            <Tab
              value={3}
              label='Sin conductor asignado'
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ListadoVehiculos onCleanFilter={() => onCleanFilter()} show_filter={true} title='activos' condicion_default={condiicon_listado_activos} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ListadoVehiculos onCleanFilter={() => onCleanFilter()} show_filter={true} title='en papelera' trash={true} condicion_default={condiicon_listado_inactivos} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ListadoVehiculos onCleanFilter={() => onCleanFilter()} show_filter={true} title='sin empresa asignada' condicion_default={condiicon_listado_sin_empresa} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ListadoVehiculos onCleanFilter={() => onCleanFilter()} show_filter={true} title='sin conductor asignado' condicion_default={condiicon_listado_sin_conductor} />
        </TabPanel>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default Vehicles;
