    // import { authHeader } from '../_helpers';
    import data, { key_local_storage_user } from '../lib/backend/data'

    export const userService = {
        login,
        register,
        logout
    };

    function login( email, password ) {
        return fetch(`${data.urlapi}/auth/login`, {
            method: 'POST',
            body: JSON.stringify({
                email: email.toLowerCase(),
                password: password
            }),
            headers: {
            'Content-Type':'application/json'
            }
        })
            .then(user => user.json())
            .then(user => { 
                if(user.tokenSession) localStorage.setItem(key_local_storage_user, JSON.stringify(user))
                return user;
            })
            .catch(error => ({ errorMessage: "error de servidor" }))
    }

    function register( nombres, apellidos, email, password ) {
        return fetch(`${data.urlapi}/auth/register`, {
            method: 'POST',
            body: JSON.stringify({
                nombres,
                apellidos,
                email: email.toLowerCase(),
                password: password
            }),
            headers: {
            'Content-Type':'application/json'
            }
        })
            .then(user => user.json())
            .then(user => { 
                if(user.tokenSession) localStorage.setItem(key_local_storage_user, JSON.stringify(user))
                return user;
            })
            .catch(error => ({ errorMessage: "error de servidor" }))
    }

    function logout() {
        localStorage.removeItem(key_local_storage_user);
    }
