import CargandoTabla from "./cargando"
import { Box, Button, Card, Checkbox, CircularProgress, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { useEffect, useState } from "react"
import data from '../../lib/backend/data'
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { fechaATexto } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import ExcelButtonDownload from "../../subcomponents/reports.js/button_download"
import FiltrosConductores from "./filtros"
import { DateRangePicker, LoadingButton } from "@mui/lab"
import ModalEmpresas from "../Empresas/modal_seleccion"
import { formatearDNIPorPais, formatearMovilPorPais, nombreDNIPorPais } from "../../lib/helpers/data/internationa"

const ListadoConductores = (props) => {
    const {
        hideFilters,
        exclude_without_vehicle,
        hideDownload
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const [loadingAcciones, setLoadingAcciones] = useState(false);
    const idioma = useSelector(state => state.idioma)
    const [conductores, setConductores] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const trash = props.trash ? props.trash : false
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerConductores(page)
    }
    
    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerConductores(page, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination  sx={{ mt:2 }} color="primary" count={cantidad} page={pagina} onChange={handleChangePagina} />
        </div>
    }

    const onFilter = (res) => {
        if(Array.isArray(res.datos) !== false){
            setConductores(res.datos)
            setTotal(res.total)
        }
        if(res.condicion) setCondicionBusqueda(res.condicion)
    }

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/conductores/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page,
                exclude_without_vehicle
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!campoBusqueda){
            setPagina(1)
            setCondicionBusqueda({})
            return obtenerConductores(1, {})
        }
        let nueva_condicion = condicion_busqueda
        nueva_condicion["$text"] = { $search: campoBusqueda.toLowerCase() }
        setCondicionBusqueda(nueva_condicion)
        setPagina(1)
        return obtenerConductores(1, nueva_condicion)
    }

    const seleccionarConductor = (driver) => {
        if(props.seleccionarConductor) props.seleccionarConductor(driver)
        return false
    }

    const showByType = (tipo, driver) => {
        switch (tipo) {
            case "funcion":
                return <b onClick={() => seleccionarConductor(driver)}>{driver.nombres ? driver.nombres : "Sin información"}</b>
            case "link":
                return <b><Link to={`/driver-${driver._id}`}>{driver.nombres ? driver.nombres : "Sin información"}</Link></b>
            default:
                return 
        }
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target
        return setCampoBusqueda(value)
    }

    const deseleccionarTodos = (e) => {
        setConductores([...[], ...conductores.map(driver => { 
          driver.seleccionado = e.target.checked
          return driver
        })])
    }

    const handleChangeSeleccion = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        const i = conductores.findIndex(ve => ve._id === name )
        conductores[i].seleccionado = valor
        setConductores([...[], ...conductores])
      }
      

  const asignacionEmpresa = async (campo) => {
    setLoadingAcciones(true);
    const ids = conductores.filter(veh => veh.seleccionado === true).map(veh => veh._id)
    return fetch(`${data.urlapi}/conductores/asignacion/empresa`, {
      method: 'POST',
      body: JSON.stringify({
        ids,
        propietario: campo,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        console.log(res)
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if(res.modifiedCount){
          toast.success("Realizado exitosamente")
          setTimeout(() => {
              window.location.reload()
          }, 1000);
        }
        return setLoadingAcciones(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingAcciones(false);
      });
  };

      const acciones = () => {
        if(loadingAcciones) return <CircularProgress  />
        const seleccionados = conductores.filter(veh => veh.seleccionado === true).length
        const enable = seleccionados > 0 ? false : true
        return <Box sx={{ mt:2}}>
          <Card variant="outlined">
          <Grid container spacing={2}>
            <Grid item md={2}>
            <LoadingButton disabled={enable} style={{ fontSize: 11 }} fullWidth color='inherit' onClick={() => asignacionEmpresa('')}>DESVINCULAR EMPRESA</LoadingButton>
            </Grid>
            <Grid item md={2}>
            <ModalEmpresas seleccionarEmpresa={(compania) => asignacionEmpresa(compania.rut)} custom_component={<LoadingButton style={{ fontSize: 11 }} disabled={enable} fullWidth color='inherit'>ASIGNAR EMPRESA</LoadingButton>} titulo="REASIGNAR EMPRESA" />
            </Grid>
          </Grid>
          </Card>
        </Box>
      }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />

        return <TableContainer component={Paper} sx={{ mt:2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
            <TableHead>
            <TableRow>
                <TableCell><Checkbox size='small' onChange={deseleccionarTodos} /></TableCell>
                <TableCell>Nombres</TableCell>
                <TableCell>Apellidos</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Registro</TableCell>
                <TableCell>{nombreDNIPorPais(pais)}</TableCell>
                <TableCell>Móvil</TableCell>
                <TableCell>Viajes realizados</TableCell>
                <TableCell>Saldo pendiente</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            { conductores.map((driver) => (
                <TableRow
                key={driver.email}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell>
                <Checkbox size='small' checked={driver.seleccionado ? true : false} name={driver._id} onChange={handleChangeSeleccion} />
                </TableCell>
                <TableCell>{showByType(tipo, driver)}</TableCell>
                <TableCell>{driver.apellido_p} {driver.apellido_m}</TableCell>
                <TableCell>{driver.email}</TableCell>
                <TableCell>{fechaATexto(driver.createdAt)}</TableCell>
                <TableCell>{formatearDNIPorPais(driver.rut, pais)}</TableCell>
                <TableCell>{formatearMovilPorPais(driver.phone, pais)}</TableCell>
                <TableCell>{driver.viajes}</TableCell>
                <TableCell>{driver.saldo}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    }


    return <div>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Box component="form" noValidate onSubmit={handleSubmit}  sx={{ mb: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <TextField id="outlined-basic" fullWidth size="small" defaultValue={campoBusqueda} label="Buscar por nombre, apellido, email o móvil" variant="outlined" onChange={handleChangeCampo} />
                            </Grid>
                            <Grid item xs={3}>
                                <Button fullWidth type="submit" size="large" variant="contained" >BUSCAR</Button>
                            </Grid>
                            <Grid item md={4}>
                                 { hideDownload === true ? false : <ExcelButtonDownload variant="contained" condicion={condicion_busqueda} slug="drivers" />}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} spacing={2}>
                                { hideFilters === true ? false : <FiltrosConductores onFilter={data => onFilter(data)} />}
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={6}>{paginacion(data.pagina, total)}</Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}><h3 sx={{ m:0 }} >{total} {titulo}</h3></Grid>
            </Grid>
        {acciones()}
        {mostrarRegistros()}
    </div>
}

export default ListadoConductores