import { Box, Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import Structure from '../Structure';
import { Link } from 'react-router-dom';
import { rutas } from '../../lib/data/rutas';
import { BsFillFileEarmarkExcelFill } from 'react-icons/bs'
import { AiOutlinePlusSquare } from 'react-icons/ai';
import ListadoContactos from './listado';
import { key, title } from './data';

const Contactos = () => {
  const [ condiicon_listado_sin_empresa, setCondicionListadoSinEmpresa ] = useState({ })

  const render = () => {
    return (
      <div>
        <h1 style={{ margin: 0 }}>{title}</h1>
        <Grid container spacing={2}>
          <Grid item ><Link to={`${rutas[key].slug}-new`}><Button startIcon={<AiOutlinePlusSquare /> } variant='outlined'>CREAR</Button></Link></Grid>
          <Grid item ><Link to={`${rutas[key].slug}/import`}><Button startIcon={<BsFillFileEarmarkExcelFill size={15} /> } variant='outlined'>IMPORTAR</Button></Link></Grid>
        </Grid>
        <Box sx={{ width: '100%', mt: 2 }} >
        <ListadoContactos show_filter={true} title='sin empresa asignada' condicion_default={condiicon_listado_sin_empresa} />
        </Box>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default Contactos