const montoMoneda = (monto) => {
    return Intl.NumberFormat("es-ES").format(monto)
}

const textoTipoViaje = (string) => {
    switch (string) {
        case "ida":
            return "Solo ida"
        case "id_vuelta":
            return "Ida y vuelta"
        default:
            return "Ida y vuelta"
    }
}

const getDirectionMovimiento = (coord1, coord2) => {
    const lat1 = coord1.lat;
    const lon1 = coord1.lng;
    const lat2 = coord2.lat;
    const lon2 = coord2.lng;
  
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
  
    const y = Math.sin(dLon) * Math.cos(lat2 * Math.PI / 180);
    const x = Math.cos(lat1 * Math.PI / 180) * Math.sin(lat2 * Math.PI / 180) - Math.sin(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.cos(dLon);
  
    const bearing = Math.atan2(y, x) * 180 / Math.PI;
    const bearings = ["Norte", "Norest", "Este", "Sureste", "Sur", "Suroeste", "Oeste", "Noroeste", "Norte"];
    const index = Math.round(bearing / 45);
    
    return bearings[index];
  }

const siEsRequeridoEnEsquema = (key, esquema) => {
    if(esquema.findIndex(e => e.value === key) > -1) return true
    return false
}

const stringByStatusActive = (value) => {
    switch (value) {
        case "active":
            return "Activo"
        case "trash":
            return "Inactivo"
        default:
            return value
    }
}

const extraerValor = (val) => {
    switch (typeof val) {
        case "string":
            return val
        case "object":
            if(val._id){
                return val._id
            } else if(val.value){
                return val.value
            } else if(val.target){
                const { name, value } = val.target
                console.log({name,value})
                return val.target.value
            } 
            return val
        default:
            return ''
    }
  }

  const formatoMoneda = value => {
    if(isNaN(value)) return "Sin información"
    return Intl.NumberFormat("en-DE").format(Math.round(value))
}

const calcularVolumenGlobal = (ancho, alto, largo, unidad) => {
    let volumen = ancho * alto * largo; // Calcula el volumen en la unidad de medida original
  
    switch (unidad) {
      case "MTR":
        // El volumen ya está en metros cúbicos, no se necesita conversión
        return { volumen, unidadVolumen: "m³" };
      case "INCH":
        // Convertir pulgadas cúbicas a metros cúbicos: 1 in³ = 0.000016387064 m³
        volumen = volumen * 0.000016387064;
        return { volumen, unidadVolumen: "m³" };
      case "CM":
        // Convertir centímetros cúbicos a metros cúbicos: 1 cm³ = 0.000001 m³
        volumen = volumen * 0.000001;
        return { volumen, unidadVolumen: "m³" };
      case "SQFT":
      case "SQM":
        // SQFT y SQM son unidades de área y no se pueden convertir directamente a volumen sin una dimensión adicional
        return { volumen: 0, unidadVolumen: "no aplicable (SQFT y SQM son unidades de área)" };
      default:
        // En caso de que se proporcione una unidad no soportada
        return { volumen: 0, unidadVolumen: "unidad no soportada" };
    }
}


const calcularVolumen = (pro, u_m) => {
    let um = u_m ? u_m : 'CM'
    let ancho       = 0
    let alto        = 0
    let largo       = 0

    if(!isNaN( parseFloat(pro.ancho) ))     ancho   = parseFloat(pro.ancho)
    if(!isNaN( parseFloat(pro.alto) ))      alto    = parseFloat(pro.alto)
    if(!isNaN( parseFloat(pro.largo) ))     largo   = parseFloat(pro.largo)

    const total = calcularVolumenGlobal(ancho, alto, largo, um)
    return total
}


module.exports = {
    stringByStatusActive,
    siEsRequeridoEnEsquema,
    getDirectionMovimiento,
    montoMoneda,
    formatoMoneda,
    textoTipoViaje,
    extraerValor,
    calcularVolumen
}