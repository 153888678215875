const validarDNIMexico = (curp) => {
    curp = curp.trim().toUpperCase();
    if (curp.length !== 18 || !/^[A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[0-9A-Z]{2}$/.test(curp)) {
      return false;
    }
  
    const digitoVerificador = curp.charAt(17);
    const digitoCalculado = calcularDigitoVerificadorCURP(curp);
    return digitoVerificador === digitoCalculado;
  };
  
  const calcularDigitoVerificadorCURP = (curp) => {
    const diccionario = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
    let suma = 0;
  
    for (let i = 0; i < 17; i++) {
      const caracter = curp.charAt(i);
      const valor = diccionario.indexOf(caracter);
      suma += ((i % 2 === 0) ? (valor * 3) : valor);
    }
  
    const resultado = 10 - (suma % 10);
    return (resultado === 10) ? '0' : resultado.toString();
}

const formatearTelefonoMovilMexico = (telefono) => {
  // Convertir el número a una cadena
  telefono = telefono.toString();

  // Eliminar cualquier caracter que no sea dígito
  telefono = telefono.replace(/\D/g, '');

  // Formatear el número con el código de área (dos primeros dígitos) y espacios en el resto del número
  return `${telefono.substr(0, 2)} ${telefono.substr(2, 3)} ${telefono.substr(5, 3)} ${telefono.substr(8)}`;
}


module.exports = {
    validarDNIMexico,
    formatearTelefonoMovilMexico
}