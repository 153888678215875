const data = {
    module_name: "categorias",
    title: "Categorías",
    key: 'categories', // RUTAS FILE
    category_key: "categories",
    excel_key: "category",
    tipos_registro: [
        { value:"conductor", label: "Conductor" },
        { value:"vehiculo", label: "Vehículo" },
        { value:"cliente", label: "Cliente" },
        { value:"equipo", label: "Equipo" },
        { value:"cargo", label: "Cargo" },
        { value:"contacto", label: "Contacto de cliente" },
        { value:"viaje", label: "Viaje" },
        { value:"costos", label: "Costo" },
        { value:"servicios", label: "Tipo de Servicio" },
        { value:"producto-embalaje", label: "Tipo de embalaje" },
    ],
    tipos_ingreso: []
}

module.exports = data