import React, { useEffect, useState } from "react"
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Slide, Tooltip, Typography } from "@mui/material"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import data from '../../lib/backend/data'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import ListadoViajes from "../../components/Viajes/listado";
import CloseIcon from '@mui/icons-material/Close';
import socket from '../../lib/websockets';
import ListadoConductores from '../Drivers/listado'
import { obtenerIdentificadorConductor } from "../../lib/helpers/conductor";

const ModalConductores = (props) => {
    const {
        custom_component,
        condicion_default,
        showSeleccionado,
        seleccionado
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const status = props.estado ? props.estado : 'pending'
    const titulo = props.titulo ? props.titulo : 'Registros'
    const informacion = props.info ? props.info : ''
    const condicion = condicion_default ? condicion_default : { }
    const [conductores, setConductores] = useState([])
    const [total, setTotal] = useState(0)
    const [loadingCounter, setLoadingCounter] = useState(true)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [modalShow, setMotalShow] = useState(false)

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

    const consultarRegistros = async () => {
        return fetch(`${data.urlapi}/conductores/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCounter(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCounter(false)
            } else if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingCounter(false)
        })
        .catch(error => {
            return setLoadingCounter(false)
        })
    }
    
    useEffect(() => {
        consultarRegistros(condicion_default)
        if(condicion.status){
            if(typeof condicion.status === 'string'){
                socket.on(`nuevo_estado`, estados => {
                    if(!estados) return false
                    if(Array.isArray(estados) !== true) return false
                    if(estados.includes(condicion.status) !== false) return consultarRegistros()
                    return false
                })
            }
        }
    }, [ condicion_default ])

    const closeModal = () => {
        return setMotalShow(false)
    }
    
    const openModal = () => {
        return setMotalShow(true)
    }

    const onSelectedTrip = (id) => {
        if(props.onSelectedTrip) props.onSelectedTrip(id)
        return closeModal()
    }
    
    const seleccionarConductor = (driver) => {
        if(props.seleccionarConductor) props.seleccionarConductor(driver)
        return setMotalShow(false)
    }

    const modalDatos = () => {
        if(!modalShow) return false
        return <Dialog
        open={modalShow}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="xl"
      >
        <DialogTitle><CloseIcon style={{ marginBottom: -4 }} onClick={() => closeModal()} /> {titulo}</DialogTitle>
        <DialogContent>        
        <Typography sx={{ mb:1 }}>{informacion}</Typography>
            <ListadoConductores hideDownload={true} hideFilters={true} tipo="funcion" seleccionarConductor={(id) => seleccionarConductor(id)} condicion_default={condicion} onSelectedTrip={(id) => onSelectedTrip(id)} />
        </DialogContent>
        </Dialog>
    }

    const mostrarAcciones = () => {
        if(loadingCounter === true) return <CircularProgress />
        if(custom_component) return <div onClick={() => openModal()}>{custom_component}</div>

        return <Button variant="outlined" color="inherit" sx={{ mb:0, width: "100%" }}  onClick={() => openModal()} >{seleccionado ? obtenerIdentificadorConductor(seleccionado) : titulo}</Button>
    }

    return <div>
        {mostrarAcciones()}
        {modalDatos()}
    </div>

}

export default ModalConductores