import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Grid,
    Tab,
    Tabs,
    TextField,
    Typography,
    InputAdornment,
    Card,
  } from '@mui/material';
  import React, { useState } from 'react';
  import Structure from '../Structure';
  import { Link, useParams } from 'react-router-dom';
  import { Link as Enlace } from '@mui/material';
  import { useDispatch, useSelector } from 'react-redux';
  import data from '../../lib/backend/data';
  import toast from 'react-hot-toast';
  import { cerrarSesion } from '../../redux/actions/session';
  import S3FileUpload from 'react-s3';
import ModalEmpresas from '../Empresas/modal_seleccion';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import { nombreDNIPorPais } from '../../lib/data/internacional';
import { formatoAyudaDNIPorPais, formatoAyudaMovilPorPais } from '../../lib/helpers/data/internationa';
import { limpiarStringLetrasNumeros, limpiarStringNumeros } from '../../lib/helpers/helpers';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';

  const DriversCreate = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [clientes, setClientes] = useState({});
    const [ empresa, setEmpresa ] = useState(false);
    const sesion = useSelector((state) => state.miusuario);
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)

    const requeridos = [
      { value: 'nombres', label: 'Nombres' },
      { value: 'apellido_p', label: 'Apellido Paterno' },
      { value: 'apellido_m', label: 'Apellido Materno' },
      { value: 'email', label: 'Email' },
      { value: 'rut', label: nombreDNIPorPais(pais) },
      { value: 'phone', label: 'Movil' },
      { value: 'sexo', label: 'Genero' },
      { value: 'imagen_perfil', label: 'Imagen de perfil' },
    ];
    
    const configs3 = {
        dirName: "movil/",
        bucketName: "urrapp",
        region: "us-east-1",
        accessKeyId: 'AKIAW3FR56LCTXHZCW5T',
        secretAccessKey: '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',
    }
    const [loadingFoto, setLoadingFoto] = useState(false)
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      if(name === "rut"){
        clientes[name] = limpiarStringLetrasNumeros(value)
    } else if(name === "phone"){
        clientes[name] = limpiarStringNumeros(value)
    } else {
        clientes[name] = value
    }
      return setClientes({ ...{}, ...clientes });
    };
  
    const crearClientes = async () => {
      
      
      let faltantes = [];
      requeridos.map((campo) => {
        if (!clientes[campo.value]) faltantes.push(campo.label);
        return true;
      });
      if (faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
  
        if(empresa) {
          clientes.propietario = empresa.rut
          clientes.propietario_autorizado = true
        }
      setLoading(true);
      return fetch(`${data.urlapi}/conductores`, {
        method: 'POST',
        body: JSON.stringify(clientes),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then((res) => {
          if (!res) {
            toast.error('Sin datos');
            return setLoading(false);
          } else if (res.errorMessage) {
            toast.error(res.errorMessage);
            return setLoading(false);
          } else if (res._id) {
            toast.success('Creado exitosamente');
            setTimeout(() => {
              return (window.location = `/driver-${res._id}`);
            }, 500);
          }
          return setLoading(false);
        })
        .catch((error) => {
          toast.error('Error al consultar la información, intente nuevamente');
          return setLoading(false);
        });
    };

    const handleChangeImagen = (e) => {
        if(e.target.files.length < 1) return toast.error('Sin archivos encontrados')
        const archivo = e.target.files[0]
        setLoadingFoto(true)
        return S3FileUpload.uploadFile(archivo, configs3)
        .then(data => {
        if(!data.location) {
            toast.error('No pudimos cargar tu imagen')
            return this.setState({ subiendofoto: false })
        }
        clientes.imagen_perfil = data.location
        console.log({ imagen: data.location })
        setClientes({ ...{}, ...clientes })
        return setLoadingFoto(false)
    })
    .catch(err => {
        setLoadingFoto(false)
        return toast.error('No pudimos cargar tu imagen')
        })
    }
  
    const render = () => {
      return (
        <div>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link to='/drivers'>Conductores</Link>
            <Enlace underline='hover' color='text.primary'>
              Crear Conductor
            </Enlace>
          </Breadcrumbs>
          <h1 style={{ margin: 0 }}>Crear Conductor</h1>
          <ModalEmpresas titulo="Seleccionar empresa" seleccionarEmpresa={(data) => setEmpresa(data)} />
          {
            empresa ? <div>
              <Card sx={{ p:2, mt: 2 }}>
              <Typography mb={0}>Empresa seleccionada</Typography>
              <Typography variant='h4' mb={0}>{empresa.razon_social}</Typography>
              <Button onClick={() => setEmpresa(false)}>REMOVER</Button>
              </Card>
              </div> : false
          }
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item md={12} xs={12}>
              <Typography variant='h6'>Datos</Typography>
              <CamposObligatoriosLeyenda />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                label='Nombres'
                variant='filled'
                name='nombres'
                required={siEsRequeridoEnEsquema("nombres",requeridos)}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                label='Apellido Paterno'
                variant='filled'
                name='apellido_p'
                required={siEsRequeridoEnEsquema("apellido_p",requeridos)}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                label='Apellido Materno'
                variant='filled'
                name='apellido_m'
                required={siEsRequeridoEnEsquema("apellido_m",requeridos)}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                type='email'
                label='Email'
                variant='filled'
                name='email'
                required={siEsRequeridoEnEsquema("email",requeridos)}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                label={nombreDNIPorPais(pais)}
                variant='filled'
                name='rut'
                required={siEsRequeridoEnEsquema("rut",requeridos)}
                fullWidth
                value={clientes.rut}
                helperText={formatoAyudaDNIPorPais(pais)}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                label='Movíl'
                variant='filled'
                name='phone'
                required={siEsRequeridoEnEsquema("phone",requeridos)}
                fullWidth
                value={clientes.phone}
                onChange={handleChange}
                helperText={formatoAyudaMovilPorPais(pais)}
                max={8}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                label='Contraseña'
                variant='filled'
                name='password'
                required={siEsRequeridoEnEsquema("password",requeridos)}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth className='mb-3'>
                <InputLabel id='demo-simple-select-label' required={siEsRequeridoEnEsquema("sexo",requeridos)}>Genero</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Genero'
                  name='sexo'
                  onChange={handleChange}
                >
                  <MenuItem value='H'>Masculino</MenuItem>
                  <MenuItem value='M'>Femenino</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
            <Typography variant='h6'>Fotografía de perfil</Typography>
            {
                loadingFoto ? <CircularProgress /> : <Button
                variant="contained"
                component="label"
                >
                SUBIR FOTO PERFIL
                <input
                    type="file"
                    hidden
                    onChange={handleChangeImagen}
                />
                </Button>
            }
            {
                clientes.imagen_perfil ? <div style={{ marginTop: 20 }}>
                    <img width={300} alt={clientes.nombre} src={clientes.imagen_perfil} />
                </div> : false
            }                
            </Grid>
            <Grid item md={12} xs={12}>
              {loading === true ? (
                <CircularProgress />
              ) : (
                <Button
                  variant='contained'
                  xs={{ m: 0 }}
                  onClick={() => crearClientes()}
                >
                  CREAR
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      );
    };
  
    return <Structure component={render()} />;
  };
  
  export default DriversCreate;
  