import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState} from 'react'
import Structure from '../Structure'
import ListadoConductores from './listado'
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Notificaciones = () => {
    const [ value, setValue ] = useState(0)
    const dispatch = useDispatch()
    const [ loading, setLoading ] = useState(true)
    const [ conductores, setConductores ] = useState([])
    const sesion = useSelector(state => state.miusuario)
    
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ pt: 3 }}>{children}</Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
      
    const handleChange = (e, value) => {
        return setValue(value)
    }

    useEffect(() => {
      
    }, [])

    const render = () => {
        return <div>
            <h1 style={{ margin: 0 }}>Notificaciones</h1>
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="primary tabs example"
                >
                    <Tab icon={<CheckCircleOutlineIcon />} iconPosition="start" value={0} label="Vistos" {...a11yProps(0)} />
                    <Tab icon={<DeleteIcon />} iconPosition="start" value={1} label="Descartados" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <ListadoConductores title="vistos" showDiscardButton={true} condicion_default={{ visto: false }} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ListadoConductores title="descartados" condicion_default={{ visto: true }} />
            </TabPanel>
            
        </div>
    }

    return <Structure component={render()} />
}

export default Notificaciones