import { Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, Divider, FormControl, FormControlLabel, Grid, InputLabel, Link, MenuItem, Select, Switch, Tab, Tabs, TextField, Typography } from '@mui/material'
import React, { useEffect, useState} from 'react'
import Structure from '../Structure'
import { Link as Href, useParams } from 'react-router-dom'
import { Link as Enlace } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import data, { urlapi } from '../../lib/backend/data'
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import PropTypes from 'prop-types';
import SinData from '../General/sin_data';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { acciones } from '../../lib/global/data'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SendIcon from '@mui/icons-material/Send';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { LoadingButton } from '@mui/lab'
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main'
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda'

const UsersDetails = () => {
    const dispatch = useDispatch()
    const [loadingMaster, setLoadingMaster] = useState(true)
    const [resetingPass, setResetingPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const [usuario, setUsuario] = useState(false)
    const [permisos, setPermisos] = useState([])
    const [permisosOtorgados, setPermisosOtorgados] = useState([])
    const [loadingSoporte, setLoadingSoporte] = useState(true)
    const [loadingPermisos, setLoadingPermisos] = useState([])
    const [ actualizandoSoporte, setActualizandoSoporte ] = useState(false)
    const [estructuraSoporte, setEstructuraSoporte] = useState([])
    const [credenciales, setCredenciales] = useState({})
    const sesion = useSelector(state => state.miusuario)
    const { id } = useParams()
    const [value, setValue] = useState(0)
    const requeridos = [
        { value:'nombres', label: 'Nombres' },
        { value:'apellidos', label: 'Apellidos' },
        { value:'phone', label: 'Móvil' },
        { value:'role', label: 'Rol de usuario' },
    ]

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ pt: 3 }}>{children}</Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

      const handleChangeTab = (e, value) => {
        return setValue(value)
    }

    const seleccionarCategoria = (i_categoria, i_sub_categoria) => {
        estructuraSoporte[i_categoria].categorias[i_sub_categoria].selected = estructuraSoporte[i_categoria].categorias[i_sub_categoria].selected === true ? false : true
        return setEstructuraSoporte(prev => [...[], ...estructuraSoporte])
    }

    const guardarCambiosSoporte = () => {
        let categorias = []
        for( const category of estructuraSoporte ){
            categorias = [...categorias, ...category.categorias.filter(c => c.selected === true)]
        }
        setActualizandoSoporte(true)
        return fetch(`${urlapi}/clientes/soporte`, {
        method: 'PUT',
        body: JSON.stringify({
            id: usuario._id,
            categorias
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
        },
        })
        .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
        })
        .then((res) => {
            if (!res) {
                toast.error('Sin datos');
                return setActualizandoSoporte(false);
            } else if (res.errorMessage) {
                toast.error(res.errorMessage);
                return setActualizandoSoporte(false);
            } else if (res.success === true) {
                toast.success('Actualizado exitosamente');
            }
            return setActualizandoSoporte(false);
        })
        .catch((error) => {
            toast.error('Error al consultar la información, intente nuevamente');
            return setActualizandoSoporte(false);
        })
    }

    const mostrarEstructuraSoporte = () => {
        return <div>
            <Typography variant="h4" sx={{ mb:2}}>Categorías de soporte</Typography>
            {
                estructuraSoporte.map((est,iest) => {
                    return <div key={est._id}>
                        <Typography variant="h5">{est.titulo ? est.titulo.toUpperCase() : ''} <b>{est.tipo ? est.tipo.toUpperCase() : ''}</b></Typography>
                        <Divider sx={{ mt:2, mb:2 }} />
                        <Grid container spacing={2} >
                        {
                            est.categorias.map((cat,icat) => {
                                return <Grid xs={3} item key={cat._id}>
                                    <Card sx={{ p:2, mb:2 }} variant="outlined" onClick={() => seleccionarCategoria(iest, icat)}>
                                    {cat.selected === true ? <CheckCircleIcon color="success" /> : <RadioButtonUncheckedIcon /> }
                                    <Typography>{cat.titulo}</Typography>
                                    </Card>
                                </Grid> 
                            })
                        }
                        </Grid>
                        
                    </div>
                })
            }
            <LoadingButton
                            variant="contained"
                            color="success"
                            loading={actualizandoSoporte}
                            onClick={() => guardarCambiosSoporte()}
                        >GUARDAR CAMBIOS</LoadingButton>
        </div>
    }

    const obtenerEstructuraSoporte = async (iduser) => {
        return fetch(`${data.urlapi}/atencioncliente/estructura?iduser=${iduser}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingSoporte(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingSoporte(false)
            } else if(Array.isArray(res) !== false){
                setEstructuraSoporte(res)
            }
            return setLoadingSoporte(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingSoporte(false)
        })
    }
    
    const obtenerUsuario = async () => {
        return fetch(`${data.urlapi}/usuarios?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingMaster(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingMaster(false)
            } else if(res._id){
                setUsuario(res)
                obtenerEstructuraSoporte(res._id)
                if(res.permisos){
                    if(Array.isArray(res.permisos) !== false){
                        setPermisosOtorgados(res.permisos.map(p => ({ slug: p.module, actions: p.actions })))
                    }
                }
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    const getEsquemaPermisos = async () => {
        setLoadingPermisos(true)
        return fetch(`${data.urlapi}/permisos/modelo`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingPermisos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingPermisos(false)
            } else if(Array.isArray(res) !== false){
                setPermisos(res)
            }
            return setLoadingPermisos(false)
        })
        .catch(error => {
            setLoadingPermisos(false)
            toast.error("Error al consultar la información, intente nuevamente")
        })
    }

    useEffect(() => {
        obtenerUsuario()
        getEsquemaPermisos()
    },[])

    const handleCheck = (e,permiso) => {
        const { name } = e.target
        const i = permisosOtorgados.findIndex(per => per.slug === permiso)
        if(i > -1){

            if(e.target.checked === true){
                if(!permisosOtorgados[i].actions.includes(name)) permisosOtorgados[i].actions.push(name)
            } else {
                const pos = permisosOtorgados[i].actions.findIndex(action => action === name)
                if(pos > -1) permisosOtorgados[i].actions.splice(pos,1)
            }

        } else {
            permisosOtorgados.push({
                slug: permiso,
                actions: [ name ]
            })
        }
        return setPermisosOtorgados(prev => [...[], ...permisosOtorgados])
    }
    
    const handleChange = (e) => {
        const { name, value } = e.target
        usuario[name] = value
        return setUsuario({...{}, ...usuario})
    }

    const handleChangePassRepeat = (e) => {
        const { name, value } = e.target
        credenciales[name] = value
        return setCredenciales(credenciales)
    }

    const reiniciarClaveUsuario = async () => {
        const { password, repeat_password } = credenciales
        if(!password) return toast.error(`Ingresa una nueva contraseña`)
        if(password.toString().length < 4) return toast.error(`Ingresa una nueva contraseña válida, al menos 4 carácteres`)
        if(!repeat_password) return toast.error(`Repite tu contraseña`)
        if(password !== repeat_password) return toast.error("Contraseñas no coinciden")
        
        setResetingPass(true)
        return fetch(`${data.urlapi}/usuarios/reset-password`,{
            method:'PUT',
            body: JSON.stringify({
                id: usuario._id,
                password: password,
                password_confirm: repeat_password
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setResetingPass(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setResetingPass(false)
            } else if(res._id){
                toast.success("Actualizado exitosamente")
            }
            return setResetingPass(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setResetingPass(false)
        })
    }

    const actualizarUsuario = async () => {
        
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        usuario.permisos = permisosOtorgados
        
        setLoading(true)
        return fetch(`${data.urlapi}/usuarios`,{
            method:'PUT',
            body: JSON.stringify(usuario),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                toast.success("Actualizado exitosamente")
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const render = () => {

        if(loadingMaster) return <CircularProgress />
        if(!usuario) return <SinData />
        return <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Href to="/users">Usuarios</Href>
                <Enlace underline="hover" color="text.primary" >Editar conductor</Enlace>
            </Breadcrumbs>
            <h1 style={{ margin: 0 }}>Editar usuario</h1>
            <Grid container spacing={2} sx={{ mb: 2}} >
                <Grid item md={12} xs={12}>
                    <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        
                        <Typography variant='h6'><SettingsIcon sx={{ mb:-0.7 }} /> Información de usuario</Typography>
                        <CamposObligatoriosLeyenda />
                    </Grid>
                <Grid item md={3} xs={12}>
                    <TextField
                        label="Nombres"
                        variant="filled"
                        name="nombres"
                        required={siEsRequeridoEnEsquema("nombres",requeridos)}
                        fullWidth
                        defaultValue={usuario.nombres}
                        onChange={handleChange}
                        />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField
                        label="Apellidos"
                        variant="filled"
                        name="apellidos"
                        required={siEsRequeridoEnEsquema("apellidos",requeridos)}
                        fullWidth
                        defaultValue={usuario.apellidos}
                        onChange={handleChange}
                        />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField
                        label="Email"
                        variant="filled"
                        name="email"
                        required={siEsRequeridoEnEsquema("email",requeridos)}
                        defaultValue={usuario.email}
                        fullWidth
                        disabled
                        />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField
                        label="Rut"
                        variant="filled"
                        name="rut"
                        required={siEsRequeridoEnEsquema("rut", requeridos)}
                        defaultValue={usuario.rut}
                        fullWidth
                        onChange={handleChange}
                        />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField
                        label="Teléfono"
                        variant="filled"
                        helperText="Ejemplo: 56912345678"
                        name="phone"
                        required={siEsRequeridoEnEsquema("phone",requeridos)}
                        defaultValue={usuario.phone}
                        fullWidth
                        onChange={handleChange}
                        />
                </Grid>
                <Grid item md={3} xs={12}>

                <FormControl fullWidth
                        className='mb-3'>
                <InputLabel id="demo-simple-select-label">Rol de usuario</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Rol de usuario"
                    name="role"
                    required={siEsRequeridoEnEsquema("role",requeridos)}
                    onChange={handleChange}
                    defaultValue={usuario.role}
                    >
                    <MenuItem value="admin">Administrador</MenuItem>
                    <MenuItem value="user">Usuario</MenuItem>
                </Select>
                </FormControl>

                </Grid>
                
                <Grid item md={3} xs={12}>

                <FormControl fullWidth
                        className='mb-3'>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Estado"
                    name="status"
                    required={siEsRequeridoEnEsquema("status",requeridos)}
                    onChange={handleChange}
                    defaultValue={usuario.status}
                    >
                    <MenuItem value="active">Activo</MenuItem>
                    <MenuItem value="trash">Desactivado</MenuItem>
                </Select>
                </FormControl>

                </Grid>
                <Grid item md={12} xs={12}>
                    {
                        loading === true ? <CircularProgress /> : <Button variant="contained" color="success" xs={{ m:0}} onClick={() => actualizarUsuario()} >GUARDAR CAMBIOS</Button>
                    }
                </Grid>
                    </Grid>
                </Grid>
                
            </Grid>      

            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="primary tabs example"
                >
                    <Tab icon={<CheckCircleOutlineIcon />} iconPosition="start" value={0} label="Permisos" {...a11yProps(0)} />
                    <Tab icon={<SecurityIcon />} iconPosition="start" value={4} label="Seguridad" {...a11yProps(0)} />
                    <Tab icon={<SupportAgentIcon />} iconPosition="start" value={1} label="Módulo de soporte" {...a11yProps(0)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Typography >Los siguientes permisos solo aplica definirlos cuando el usuario tiene un rol de usuario simple</Typography>
                {
                    permisos.map(permiso => {
                        return <Grid item key={permiso._id} md={3} spacing={2} sx={{ mt:2 }}>
                            <Card>
                                <CardContent>
                            <Typography ><LockIcon fontSize='small' sx={{ mb: -0.5 }} />Módulo</Typography>
                            <Typography variant="h6">{permiso.titulo}</Typography>
                            {
                                acciones.map((accion,i) => {
                                    let checkeado = false
                                    const pos = permisosOtorgados.findIndex(per => per.slug === permiso.slug)
                                    if(pos > -1){
                                        if(permisosOtorgados[pos].actions.includes(accion.slug)) checkeado = true
                                    }
                                    return <div key={accion.slug}>
                                        <FormControlLabel  control={<Switch defaultChecked={checkeado} name={accion.slug} onChange={(e) => handleCheck(e,permiso.slug)} />} label={`${accion.label} ${permiso.titulo ? permiso.titulo.toLowerCase() : ''}`} />
                                    </div>
                                })
                            }
                            </CardContent>
                            </Card>
                            </Grid>
                    })
                }
            </TabPanel> 
            <TabPanel value={value} index={1}>
                {mostrarEstructuraSoporte()}
            </TabPanel>    
            <TabPanel value={value} index={4}>
            <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                    <Typography variant="h6" sx={{ mb:2 }}>Cambia la contraseña de esta cuenta...</Typography>
                    <TextField
                        label="Contraseña"
                        variant="filled"
                        name="password"
                        type="password"
                        sx={{ mb:2 }}
                        fullWidth
                        onChange={handleChangePassRepeat}
                        />
                        <TextField
                        label="Repetir contraseña"
                        variant="filled"
                        name="repeat_password"
                        type="password"
                        fullWidth
                        sx={{ mb:2 }}
                        onChange={handleChangePassRepeat}
                        />
                        {
                            resetingPass === true ? <CircularProgress /> : <Button variant="contained" color="primary" xs={{ m:0}} onClick={() => reiniciarClaveUsuario()} >CAMBIAR CONTRASEÑA</Button>
                        }
                    </Grid>
                    </Grid>
            </TabPanel>      
        </div>
    }

    return <Structure component={render()} />
}

export default UsersDetails