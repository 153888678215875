import {
  Breadcrumbs,
  Button,
  Grid,
  Select as Selector,
  TextField,
  Typography,
  Card,
  Autocomplete,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import ModalEmpresas from '../Empresas/modal_seleccion';
import { extraerValor, siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import { rutas } from '../../lib/data/rutas';
import { key, module_name, tipos_ingreso, tipos_registro } from './data';
import { AiFillMinusCircle, AiOutlineMail, AiOutlinePlus } from 'react-icons/ai';
import { cerrarSesion } from '../../redux/actions/session';
import { urlapi } from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';

const CamposPersonalizadosCreate = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [vehiculo, setVehiculo] = useState({});
  const [selectOptionsColors, setSelectOptionsColors] = useState([]);
  const [ empresa, setEmpresa ] = useState(false);
  const [conductorSeleccionado, setConductorSeleccionado] = useState(false)
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const sesion = useSelector((state) => state.miusuario);
  const requeridos = [
    { value: 'title', label: 'Título' },
    { value: 'tipo', label: 'Tipo' }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    vehiculo[name] = value;
    return setVehiculo({ ...{}, ...vehiculo });
  };
  

const crearVehiculo = async () => {
    
  let faltantes = [];
  requeridos.map((campo) => {
    if (!vehiculo[campo.value]) faltantes.push(campo.label);
    return true;
  });
  if (faltantes.length > 0)
    return toast.error(`Faltan campos: ${faltantes.join(', ')}`);

  setLoading(true);
  if(empresa){
    vehiculo.propietario = empresa.rut
  }
  return fetch(`${urlapi}/${module_name}`, {
    method: 'POST',
    body: JSON.stringify(vehiculo),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer: ${sesion.tokenSession}`,
      'country': pais,
      'lang': idioma
    },
  })
    .then((res) => {
      if (res.status === 401) return dispatch(cerrarSesion());
      return res.json();
    })
    .then((res) => {
      if (!res) {
        toast.error('Sin datos');
        return setLoading(false);
      } else if (res.errorMessage) {
        toast.error(res.errorMessage);
        return setLoading(false);
      } else if (res._id) {
        toast.success('Creado exitosamente');
        setTimeout(() => {
          return (window.location = `${rutas[key].slug}-${res._id}`);
        }, 500);
      }
      return setLoading(false);
    })
    .catch((error) => {
      toast.error('Error al consultar la información, intente nuevamente');
      return setLoading(false);
    });
};



const handleChangeSelector = (e, name) => {
  vehiculo[name] = e.value
  return setVehiculo({ ...{}, ...vehiculo });
};

const handleChangeOpcion = (e,pos) => {
  return setVehiculo(prev => {
      let actual = {...prev}
      actual.opciones[pos].value = e.target.value
      return {...{}, ...actual}
  })
}

const eliminarOpcion = (i) => {
  return setVehiculo(prev => {
      const actual = {...prev}
      actual.opciones.splice(i,1)
      return {...{}, ...actual}
  })
}

const mostrarOpciones = () => {
  if(!vehiculo.opciones) return false
  return <div style={{ marginTop: 15 }}>
      {
          vehiculo.opciones.map((opcio,ip) => {
              return <Box key={`op-${ip}`} sx={{ mb: 2 }} >
                              <TextField style={{ width: "100%" }} value={opcio.value} onChange={(e) => handleChangeOpcion(e,ip)} />
                              <Button sx={{ mt:1}} size="small" variant='outlined' color="error" onClick={() => eliminarOpcion(ip)} startIcon={<AiFillMinusCircle  color={"red"} />} > REMOVER</Button>
                              
              </Box>
          })
      }
  </div>
}

const agregarValor = () => {
  return setVehiculo(prev => {
      let actual = {...prev}
      if(!actual.opciones) actual.opciones = []
      actual.opciones.unshift({
          value: ""
      })
      return {...{}, ...actual}
  })
}

const opcionesBox = () => {
  if(!vehiculo) return false
  if(vehiculo.tipo_ingreso !== "seleccion") return false
  return <div style={{ marginTop: 15 }}>
      <Button size="small" onClick={() => agregarValor()} variant="outlined" ><AiOutlinePlus style={{ verticalAlign: "middle" }} /> AGREGAR NUEVO VALOR</Button>
      {mostrarOpciones()}
  </div>
}
  const render = () => {


    let default_color = false

    if(vehiculo.color){
      const i_color = selectOptionsColors.findIndex(m => m.value === vehiculo.color)
      if(i_color > -1 ){
        default_color = selectOptionsColors[i_color]
      }
    }


    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to={rutas[key].slug}>{rutas[key].title}</Link>
          <Enlace underline='hover' color='text.primary'>
            Crear {rutas[key].title}
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Crear {rutas[key].title}</h1>
        <ModalEmpresas titulo="Seleccionar empresa" seleccionarEmpresa={(data) => {
          setEmpresa(data)
          vehiculo.id_usuario = "";
          vehiculo.id_categoria = "";
          vehiculo.id_sub_categoria = "";
          vehiculo.propietario = data.rut
          setConductorSeleccionado(false)
          return setVehiculo(prev => ({...{}, ...vehiculo}))
        }} />
        {
            empresa ? <div>
              <Card sx={{ p:2, mt: 2 }}>
              <Typography mb={0}>Empresa seleccionada</Typography>
              <Typography variant='h4' mb={0}>{empresa.razon_social}</Typography>
              <Button onClick={() => {
                setEmpresa(false)
                vehiculo.id_usuario = "";
                setConductorSeleccionado(false)
                return setVehiculo(prev => ({...{}, ...vehiculo}))
              }}>REMOVER</Button>
              </Card>
              </div> : false
          }
          <Grid container spacing={2}>
        <Grid item md={12} xs={12} mt={2}>
          <Typography variant='h6'><SettingsIcon sx={{ mb: -0.7 }} /> Información general</Typography>
          <CamposObligatoriosLeyenda />
        </Grid>
        
        <Grid item md={3} xs={12}>
          <TextField
            label='Título'
            variant='filled'
            name='title'
            required={siEsRequeridoEnEsquema("title",requeridos)}
            defaultValue={vehiculo.title}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
        <Autocomplete
                                                disablePortal
                                                noOptionsText="Sin opciones" 
                                                renderInput={(params) => <TextField label="Tipo de registro" {...params} />}
                                                options={tipos_registro}
                                                defaultValue={vehiculo.tipo}
                                                onChange={(e,d) => handleChangeSelector(d, 'tipo')} 
                                            />
        </Grid>
        <Grid item md={3} xs={12}>
        <Autocomplete
                                                disablePortal
                                                noOptionsText="Sin opciones" 
                                                renderInput={(params) => <TextField label="Tipo de ingreso" {...params} />}
                                                options={tipos_ingreso}
                                                defaultValue={vehiculo.tipo_ingreso}
                                                onChange={(e,d) => handleChangeSelector(d, 'tipo_ingreso')} 
                                            />
        </Grid>      
          <Grid item md={12} xs={12}>
            {opcionesBox()}
            <LoadingButton
              variant='contained'
              color='success'
              loading={loading}
              xs={{ m: 0 }}
              onClick={() => crearVehiculo()}
            >
              GUARDAR CAMBIOS
            </LoadingButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  
  return <Structure component={render()} />;
};

export default CamposPersonalizadosCreate