import { Typography } from "@mui/material"
import { calcularGananciaConductor } from "../../lib/helpers/conductor"
import { montoMoneda } from "../../lib/helpers/main"

const DetallesTarifaBox = (props) => {
    const trip = props.viaje ? props.viaje : false

    if(!trip.detalle_tarifa) return false
    if(typeof trip.detalle_tarifa !== "object") return false

    return <div>
            <Typography sx={{ mb:0 }} variant="h6">Detalles de la tarifa</Typography>
            {trip.detalle_tarifa.icon ? <img alt="ICONO TARIFA" style={{ width: "60%" }} src={trip.detalle_tarifa.icon} /> : false }
            <Typography sx={{ mb:0 }}>Vehículo <b>{trip.detalle_tarifa.vehiculo}</b></Typography>
            <Typography sx={{ mb:0 }}>Precio $<b>{montoMoneda( calcularGananciaConductor(trip.detalle_tarifa) )}</b></Typography>
            <Typography sx={{ mb:0 }}>Distancia <b>{trip.detalle_tarifa.distancia_km} KM.</b></Typography>
    </div>
}

export default DetallesTarifaBox