const validarDNIBrasil = (cpf) => {
    cpf = cpf.replace(/[^\d]/g, ''); // Eliminar caracteres no numéricos
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false;
    }
  
    const digito1 = calcularDigitoVerificador(cpf.substring(0, 9));
    const digito2 = calcularDigitoVerificador(cpf.substring(0, 9) + digito1.toString());
    return cpf.endsWith(digito2.toString());
  };
  
  const calcularDigitoVerificador = (num) => {
    const digitos = num.split('').map(Number);
    let suma = 0;
  
    for (let i = 0; i < 9; i++) {
      suma += digitos[i] * (10 - i);
    }
  
    let resto = suma % 11;
    if (resto === 0 || resto === 1) {
      return 0;
    }
  
    return 11 - resto;
  };


  const formatearCPF = (cpf = "")  => {
    cpf = cpf.toString()
    // Eliminar cualquier caracter que no sea dígito
    cpf = cpf.replace(/\D/g, '');
  
    // Aplicar el formato con puntos y guiones
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

const formatearTelefonoMovilBrasil = (telefono) => {
    // Convertir el número a una cadena
    telefono = telefono.toString();
  
    // Eliminar cualquier caracter que no sea dígito
    telefono = telefono.replace(/\D/g, '');
  
    // Formatear el número con el código de área (dos primeros dígitos) y espacios en el resto del número
    return `${telefono.substr(0, 2)} ${telefono.substr(2, 2)} ${telefono.substr(4, 5)} ${telefono.substr(9)}`;
}


module.exports = {
    validarDNIBrasil,
    formatearTelefonoMovilBrasil,
    formatearCPF
}