import { Box, Breadcrumbs, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Rating, Select, Slide, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import { Link as Enlace } from '@mui/material'
import Structure from '../Structure'
import { useDispatch, useSelector } from 'react-redux'
import { cerrarSesion } from '../../redux/actions/session'
import toast from 'react-hot-toast'
import data, { configs3, maps_key } from '../../lib/backend/data'
import SinData from '../General/sin_data'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import ListadoVehiculos from '../Vehicles/listado'
import socket from '../../lib/websockets';
import ListadoRegistrosFotograficos from './reporte_fotografico/listado'
import ListadoRequisitos from '../Requisitos/listado'
import Moment from 'react-moment'
import 'moment/locale/es';
import ListadoEventos from '../Eventos/listado'
import S3FileUpload from 'react-s3';
import ListadoViajes from '../Viajes/listado'
import ListadoDeCalificaciones from '../Calificaciones/listado'
import ListadoGanancias from '../Ganancias/listado'
import EmpresaVinculada from './empresa_vinculada'
import ListadoDeRequisitos from '../RequisitosConfig/listado'
import { formatearDNIPorPais, formatoAyudaDNIPorPais, formatoAyudaMovilPorPais, nombreDNIPorPais } from '../../lib/helpers/data/internationa'
import { siEsRequeridoEnEsquema, stringByStatusActive } from '../../lib/helpers/main'
import MapaVehiculosEstatico from '../Mapas/mapa_vehiculos_estatico'
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda'
import BotonEliminar from './boton_eliminar'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const divStyle = {
    background: `white`,
    padding: 5
  }
  

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));
  
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

const DriverDetails = () => {
    const [loading, setLoading] = useState(true)
    const [conductor, setConductor] = useState(false)
    const [conductorEditar, setConductorEditar] = useState(false)
    const [guardandoCambios, setGuardandoCambios] = useState(false)
    const [tazaC, setTazaC] = useState(0)
    const [showModalRFotograficos, setShowModalRFotograficos] = useState(false)
    const [showModalRating, setShowModalRating] = useState(false)
    const [showModalTazaC, setShowModalTazaC] = useState(false)
    const [editar, setEditar] = useState(false)
    const [selectedTab, setSelectedTab] = useState("1")
    const [coordenada, setCoordenada] = useState(false)
    const { id } = useParams()
    const sesion = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const centro_mapa = { lat: -33.458829, lng: -70.654644 }
    const zoom_mapa = 15
    const [loadingFoto, setLoadingFoto] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [loadintAsignacion, setLoadingAsignacion] = useState(false);
  
    const requeridos = [
        { value: 'nombres', label: 'Nombres' },
        { value: 'apellido_p', label: 'Apellido Paterno' },
        { value: 'apellido_m', label: 'Apellido Materno' },
        { value: 'email', label: 'Email' },
        { value: 'rut', label: nombreDNIPorPais(pais) },
        { value: 'phone', label: 'Movil' },
        { value: 'sexo', label: 'Genero' },
        { value: 'imagen_perfil', label: 'Imagen de perfil' },
      ];

    const guardarCambios = async () => {
        let faltantes = [];
        requeridos.map((campo) => {
            if (!conductorEditar[campo.value]) faltantes.push(campo.label);
            return true;
        });
        if (faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    
        setGuardandoCambios(true)
        return fetch(`${data.urlapi}/conductores`,{
            method:'PUT',
            body: JSON.stringify(conductorEditar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setGuardandoCambios(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setGuardandoCambios(false)
            } else if(res._id){
                setConductor(prev => ({...{}, ...conductorEditar}))
                setEditar(false)
                toast.success("Actualizado exitosamente")
            }
            return setGuardandoCambios(false)
        })
        .catch(error => {
            toast.error("Error al realizar esta operación, intente nuevamente")
            return setGuardandoCambios(false)
        })
    }

    const obtenerConductor = async () => {
        return fetch(`${data.urlapi}/conductores?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setConductor(res)
                setConductorEditar(res)
                setTazaC(res.taza_cancelacion)
                if(res.ultima_ubicacion){
                    if(res.ultima_ubicacion.location){
                        if(typeof res.ultima_ubicacion.location === "object"){
                            if(res.ultima_ubicacion.location.coordinates){
                                if(Array.isArray(res.ultima_ubicacion.location.coordinates) !== false ){
                                    const coord = {
                                        ...res.ultima_ubicacion,
                                        lat: res.ultima_ubicacion.location.coordinates[1],
                                        lng: res.ultima_ubicacion.location.coordinates[0],
                                        fecha: res.ultima_ubicacion.updatedAt
                                    }
                                    console.log({ coord })
                                    if(res.ultima_ubicacion.location.coordinates.length > 0) setCoordenada(coord)
                                }
                            }
                        }
                    }
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }
    
    const cambiarCalificacion = async (num,toastId) => {
        return setTimeout(() => {
            return fetch(`${data.urlapi}/conductores/rating?rating=${num}&id=${id}`,{
                method:'GET',
                headers: {
                    'Content-Type':'application/json',
                    'Authorization': `Bearer: ${sesion.tokenSession}`,
                    'country': pais,
                    'lang': idioma
                }
            })
            .then(res => {
                if(res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                toast.dismiss(toastId)
                if(!res){
                    toast.error('Sin datos')
                } else if(res.errorMessage){
                    toast.error(res.errorMessage)
                } else if(res._id){
                    let nuevo = conductor
                    nuevo.rating = res.rating
                    setConductor(prev => ({...{}, ...nuevo}))
                    toast.success('Actualizado exitosamente')
                }
                return setShowModalRating(false)
            })
            .catch(error => {
                toast.dismiss(toastId)
                toast.error("Error al realizar esta operación, intente nuevamente")
                return setShowModalRating(false)
            })
            
        }, 3000);
    }

    useEffect(() => {
        obtenerConductor()
        socket.on(`coordenadas_${id}`, data => {
            const registro = {
                lat: data.lat,
                lng: data.lng,
                fecha: new Date()
            }
            console.log({ data, registro })
            return setCoordenada(registro)
        })
    },[])

    const abrirTazaCModal = () => {
        return setShowModalTazaC(true)
    }

    const handleChangeTab = (e,val) => {
        return setSelectedTab(val.toString())
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

    const handleChangeTC = (e) => {
        const { value } = e.target
        return setTazaC(value)
    }

    const handleModalRF = () => {
        setShowModalRating(false)
        setShowModalTazaC(false)
        return setShowModalRFotograficos(false)
    }

    const ModalRegistrosFotograficos = () => {
        return <Dialog
        open={showModalRFotograficos}
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        onClose={()=>handleModalRF()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-description">
         Registros fotográficos
        </DialogTitle>
        <DialogContent>
            <ListadoRegistrosFotograficos condicion_default={{ id_conductor: id }} />
        </DialogContent>
        </Dialog>
    }

    const handleChangeRating = (e,val) => {
        const toastId = toast.loading('Autorizando cambio de calificación...');
        return cambiarCalificacion(val,toastId)
    }
    
    const ModalRating = () => {
        return <Dialog
        open={showModalRating}
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="xs"
        onClose={()=>handleModalRF()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-description">
         Rating
        </DialogTitle>
        <DialogContent>
        <Typography variant='p' sx={{ mb:0, color: '#cbcbcb' }}>Este es la calificación actual</Typography>
        <Typography variant='h3' sx={{ mb:0}}>{conductor.rating}</Typography>
            <Typography sx={{ mb:1 }}>Has click en la estrella para cambiar la calificación</Typography>
            <Rating name="read-only" defaultValue={conductor.rating} onChange={handleChangeRating} size="large" />
        </DialogContent>
        </Dialog>
    }
    
    const modalTazaCancelacion = () => {
        if(!showModalTazaC) return false
        return <Dialog
        open={showModalTazaC}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="xs"
        onClose={()=>handleModalRF()}
      >
        <DialogTitle id="alert-dialog-slide-description">
         Taza de cancelación
        </DialogTitle>
        <DialogContent>
        <Typography variant='p' sx={{ mb:0, color: '#cbcbcb' }}>Este es la taza de cancelación actual</Typography>
        <TextField
          label="Taza de cancelación"
          type="number"
          variant="standard"
          fullWidth
          sx={{ mb: 3 }}
          size="medium"
          defaultValue={conductor.taza_cancelacion}
          onChange={handleChangeTC}
        />
        <Typography sx={{ mb:1 }}>Puedes cambiar aquí la taza de cancelación solo si estás autorizado</Typography>
        <Button variant="contained" color="success">GUARDAR CAMBIOS</Button>
        </DialogContent>
        </Dialog>
    }

    const handleChangeImagen = (e) => {
        if(e.target.files.length < 1) return toast.error('Sin archivos encontrados')
        const archivo = e.target.files[0]
        setLoadingFoto(true)
        return S3FileUpload.uploadFile(archivo, configs3)
        .then(data => {
        if(!data.location) {
            toast.error('No pudimos cargar tu imagen')
            return setLoadingFoto(false)
        }
        conductorEditar.imagen_perfil = data.location
        setConductorEditar({ ...{}, ...conductorEditar })
        return setLoadingFoto(false)
    })
    .catch(err => {
        setLoadingFoto(false)
        return toast.error('No pudimos cargar tu imagen')
        })
    }

    const handleChangeConductor = (e) => {
        const { name, value } = e.target
        conductorEditar[name] = value
        return setConductorEditar({...{}, ...conductorEditar})
    }

    const formularioEditar = () => {
        return <Grid container spacing={2}>
            <Grid item xs={12}>
                <BotonEliminar id={conductor._id} />
                <CamposObligatoriosLeyenda />
            
            </Grid>
        <Grid item md={4} xs={12}>
            <TextField
            label="Nombres"
            variant="filled"
            name="nombres"
            required={siEsRequeridoEnEsquema("nombres",requeridos)}
            fullWidth
            sx={{ mb: 3 }}
            defaultValue={conductorEditar.nombres}
            onChange={handleChangeConductor}
            />
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
            label="Apellido P"
            variant="filled"
            name="apellido_p"
            required={siEsRequeridoEnEsquema("apellido_p",requeridos)}
            fullWidth
            sx={{ mb: 3 }}
            defaultValue={conductorEditar.apellido_p}
            onChange={handleChangeConductor}
            />
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
            label="Apellido M"
            variant="filled"
            name="apellido_m"
            required={siEsRequeridoEnEsquema("apellido_m",requeridos)}
            fullWidth
            sx={{ mb: 3 }}
            defaultValue={conductorEditar.apellido_m}
            onChange={handleChangeConductor}
            />
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
            label="Email"
            variant="filled"
            name="email"
            required={siEsRequeridoEnEsquema("email",requeridos)}
            fullWidth
            sx={{ mb: 3 }}
            defaultValue={conductorEditar.email}
            onChange={handleChangeConductor}
            />
        </Grid>
        <Grid item md={3} xs={12}>
              <FormControl fullWidth className='mb-3'>
                <InputLabel id='demo-simple-select-label'>Genero</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Genero'
                  name='sexo'
                  onChange={handleChangeConductor}
                >
                  <MenuItem value='H'>Masculino</MenuItem>
                  <MenuItem value='M'>Femenino</MenuItem>
                </Select>
              </FormControl>
            </Grid>
        <Grid item md={4} xs={12}>
        <TextField
            label="Móvil"
            variant="filled"
            name="phone"
            fullWidth
            required={siEsRequeridoEnEsquema("phone",requeridos)}
            helperText={formatoAyudaMovilPorPais(pais)}
            sx={{ mb: 3 }}
            defaultValue={conductorEditar.phone}
            onChange={handleChangeConductor}
            />
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
            label="DNI · Rut"
            variant="filled"
            name="rut"
            fullWidth
            required={siEsRequeridoEnEsquema("rut",requeridos)}
            helperText={formatoAyudaDNIPorPais(pais)}
            sx={{ mb: 3 }}
            defaultValue={conductorEditar.rut}
            onChange={handleChangeConductor}
            />
        </Grid>
        <Grid item md={4} xs={12}>
            <TextField
                        id="outlined-select-currency"
                        select
                        label="Estado"
                        sx={{ mb: 2 }}
                        fullWidth
                        name="status"
                        required={siEsRequeridoEnEsquema("status",requeridos)}
                        value={conductorEditar.status}
                        onChange={handleChangeConductor}
                        >
                        <MenuItem value="active">Activo</MenuItem>
                        <MenuItem value="trash">Inactivo</MenuItem>
                    </TextField>
        </Grid>
        <Grid item md={12} xs={12}>
            <Typography variant='h6'>Fotografía de perfil</Typography>
            {
                loadingFoto ? <CircularProgress /> : <Button
                variant="contained"
                component="label"
                >
                SUBIR FOTO PERFIL
                <input
                    type="file"
                    hidden
                    onChange={handleChangeImagen}
                />
                </Button>
            }
            {
                conductorEditar.imagen_perfil ? <div style={{ marginTop: 20 }}>
                    <img width={300} alt={conductorEditar.nombre} src={conductorEditar.imagen_perfil} />
                </div> : false
            }
            </Grid>
        <Grid item md={4} xs={12}>
            { guardandoCambios ? <CircularProgress /> : <Button variant="contained" color="success" size="large" onClick={() => guardarCambios()}>GUARDAR CAMBIOS</Button>  }
        </Grid>
    </Grid>
    }

    const asignarRequisito = async (_id) => {
        setLoadingAsignacion(true)
        return fetch(`${data.urlapi}/requisitos/asignar?id=${_id}&target=${id}`, {
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Agregado exitosamente")
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
            return setLoadingAsignacion(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingAsignacion(false)
        })
    }
    
      const seleccionarRequisito = (req) => {
        asignarRequisito(req._id)
      }

    const datosConductor = () => {
        if(editar) return formularioEditar()

        return <Grid container >
        <Grid item md={3} xs={12}>
            <label className='form-control-label'><b>Nombres</b></label>
            <p>{conductor.nombres}</p>
        </Grid>
        <Grid item md={3} xs={12}>
            <label className='form-control-label'><b>Apellido P.</b></label>
            <p>{conductor.apellido_p}</p>
        </Grid>
        <Grid item md={3} xs={12}>
            <label className='form-control-label'><b>Apellido M.</b></label>
            <p>{conductor.apellido_m}</p>
        </Grid>
        <Grid item md={3} xs={12}>
            <label className='form-control-label'><b>Email</b></label>
            <p>{conductor.email}</p>
        </Grid>
        <Grid item md={3} xs={12}>
            <label className='form-control-label'><b>Móvil</b></label>
            <p>{conductor.phone}</p>
        </Grid>
        <Grid item md={3} xs={12}>
            <label className='form-control-label'><b>DNI · Rut</b></label>
            <p>{formatearDNIPorPais(conductor.rut, pais)}</p>
        </Grid>
        <Grid item md={3} xs={12}>
            <label className='form-control-label'><b>Estado</b></label>
            <p>{stringByStatusActive(conductor.status)}</p>
        </Grid>
        <Grid item md={12} xs={12}>
            <EmpresaVinculada conductor={conductor._id} />
        </Grid>
    </Grid>
    }

    const render = () => {
        if(loading) return <CircularProgress />
        if(!conductor) return <SinData />
        return <div >
            <ModalRating />
            <Breadcrumbs aria-label="breadcrumb">
                <Link to="/drivers">Conductores</Link>
                <Enlace underline="hover" color="text.primary" >Editar conductor</Enlace>
            </Breadcrumbs>
            <ModalRegistrosFotograficos />
            {modalTazaCancelacion()}
            <Grid container >
                <Grid item md={7} xs={12}>
                <h3 style={{ marginBottom: 0 }}>Detalles del conductor</h3>
                <Box m={0}>
                <Rating name="read-only" value={conductor.rating} readOnly />
                <div>
                <Button color="warning" onClick={() => setShowModalRating(true) } sx={{ m: 0}} size="small" >EDITAR CALIFICACIÓN</Button>
                <Button  onClick={() => abrirTazaCModal()} sx={{ m: 0}} size="small" >EDITAR TAZA DE CANCELACIÓN {conductor.taza_cancelacion}</Button>
                </div>
                </Box>
                    <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList  onChange={handleChangeTab} aria-label="lab API tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                        >
                        <Tab label="Detalles" value="1" />
                        <Tab label="Vehículos" value="2" />
                        <Tab label="Viajes" value="3" />
                        <Tab label="Requisitos" value="4" />
                        <Tab label="Calificaciones" value="5" />
                        <Tab label="Ganancias" value="6" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ mt:1 }}>
                    <div style={{ marginBottom: 20 }}>
                    {
                        conductor.conectado === true ? 
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                        >
                        <Avatar alt="Conductor" src={conductor.imagen_perfil} />
                        </StyledBadge> :
                        <Avatar alt="Conductor" src={conductor.imagen_perfil} />
                    }
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        {
                            editar ? <Button sx={{ mr: 2 }} variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => setEditar(false)}>CANCELAR</Button> : <Button sx={{ mr: 2 }} variant="outlined" onClick={() => setEditar(true)}>Editar</Button>
                        }
                        <Button sx={{ mr: 2 }} variant="outlined" onClick={() => setShowModalRFotograficos(true)}>Registros fotográficos</Button>
                    </div>
                        {datosConductor()}
                        
                        <h4>Eventos administrativos</h4>
                        <ListadoEventos condicion_default={{ id_target: id }} show_filter={false} />
                    </TabPanel>
                    <TabPanel value="2" sx={{ mt: 1 }}>
                        <ListadoVehiculos condicion_default={{ id_usuario: id }} open_new_tab={true} />
                    </TabPanel>
                    <TabPanel value="3" sx={{ mt: 1 }}>
                        <ListadoViajes tipo="link" condicion_default={{ id_conductor: id }} />
                    </TabPanel>
                    <TabPanel value="4" sx={{ mt: 1 }}>
                    <Typography variant='h6' mb={2} style={{ marginTop: 0 }}>Requisitos cargados</Typography>
                        <ListadoRequisitos condicion_default={{ id_target: id }} />
                        <Divider light  />
                    <Typography variant='h6' mt={2}>Otros requisitos</Typography>
                    <Typography  mb={2}>Toda el requisito para agregarlo al conductor</Typography>
                    { loadintAsignacion ? <CircularProgress /> : <ListadoDeRequisitos tipo="funcion" typeView="card" id_target={conductor._id}  seleccionarConductor={(data) => seleccionarRequisito(data)} condicion_default={{ tipo: "conductor" }} /> }

                    </TabPanel>
                    <TabPanel value="5" sx={{ mt: 1 }}>
                        <ListadoDeCalificaciones tipo="modal" condicion_default={{ id_conductor: id }} />
                    </TabPanel>
                    <TabPanel value="6" sx={{ mt: 1 }}>
                        <ListadoGanancias id_conductor={id} />
                    </TabPanel>
                    </TabContext>
                </Grid>
                <Grid item md={5} xs={12}>
                    <MapaVehiculosEstatico marcadores={[coordenada]} />
                </Grid>
            </Grid>

        </div>
    }

    return <Structure component={render()} />
}

export default DriverDetails