// import { Button, Divider, List, Modal, Tag } from 'antd'
import { Badge, Box, Button, ButtonGroup, Chip, Dialog, DialogContentText, DialogTitle, Divider, List, ListItem, ListItemText } from '@mui/material'
import { useState } from 'react'
import * as XLSX from 'xlsx'

const InformacionEsquema = (props) => {
    const {
        titulo,
        esquema
    } = props
    const titulo_doc = typeof titulo === "string" ? titulo : "Modelo"
    const [ showModal, setShowModal ] = useState(false)

    const handleDownload = () => {
        // Crear el archivo de Excel
        const wb = XLSX.utils.book_new();
        const wsData = [ esquema.filter(e => !e.denieUpload).map(e => e.label) ]
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
    
        // Convertir el libro de trabajo a un archivo binario
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    
        // Crear un Blob con los datos binarios
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    
        // Generar un objeto URL del Blob
        const url = URL.createObjectURL(blob);
    
        // Crear un enlace de descarga invisible y hacer clic en él
        const a = document.createElement('a');
        a.href = url;
        a.download = `${titulo_doc}.xlsx`;
        a.click();
    
        // Liberar el objeto URL
        URL.revokeObjectURL(url);
      };
    
      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };

      const mostrarInformacion = () => {
        setShowModal(true)
      }

      const mostrarModal = () => {
        return <Dialog open={showModal} onClose={() => setShowModal(false)} footer={[]}>
            <Box style={{ padding: 10 }} >
            <DialogTitle className='mb-0'>Información del modelo excel</DialogTitle>
            <p>Estos son las columnas que tu documento excel debe tener</p>
            <List>
              {
                esquema.map((item,i) => {
                  return <ListItem key={`-${i}`}>
                    <ListItemText>
                      {item.label} {item.optionalUpload ? <Chip label="OPCIONAL" /> : <Chip color="warning" label="OBLIGATORIO" /> }
                      { item.description ? <div style={{ marginTop: 10 }}>{item.description}<Divider sx={{ mt:1 }} /></div> : false }
                      </ListItemText>
                  </ListItem>
                })
              }
            </List>
            </Box>
        </Dialog>
    }

      if(!esquema) return false

    return <div>
        {mostrarModal()}
        <ButtonGroup sx={{ mb:2, mt:1}}>

            <Button size="xs" variant="outlined" onClick={() => handleDownload()} >DESCARGAR MODELO</Button>
            <Button size="xs" variant="outlined" onClick={() => mostrarInformacion()}>INFORMACIÓN DEL MODELO</Button>
        </ButtonGroup>
    </div>
}

export default InformacionEsquema