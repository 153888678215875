import { Box, Button, Card, CardContent, Divider, Grid, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useRef, useState} from 'react'
import Structure from '../Structure'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { maps_key, polyline_options } from '../../lib/backend/data';
import socket from '../../lib/websockets';
import { useDispatch, useSelector } from 'react-redux';
import ContadorViajes from '../../subcomponents/viajes/contador';
import data from '../../lib/backend/data'
import { cerrarSesion } from '../../redux/actions/session';
import toast from 'react-hot-toast';
import { obtenerDireccionDestino, obtenerDireccionEntrega, procesarFotos } from '../../lib/helpers/viajes';
import { obtenerIdentificadorCliente, obtenerTelefonoCliente } from '../../lib/helpers/clientes';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { obtenerIdentificadorConductor } from '../../lib/helpers/conductor';
import ModalConductores from '../Drivers/modal_seleccion';
import Moment from 'react-moment';
import { fechaATexto } from '../../lib/helpers/helpers';
import { MapStyleDefault } from '../../lib/global/data';
import OperationMap from './mapa_operacion';
import ContadorConductores from '../../subcomponents/conductores/contador';
import ListadoLogViaje from '../../subcomponents/viajes/log_viaje';
import MostrarProductos from '../Viajes/mostrar_productos';
import DetallesTarifaBox from '../../subcomponents/viajes/detalles_tarifa_box';
import { getDirectionMovimiento, textoTipoViaje } from '../../lib/helpers/main';
import ChatViaje from '../Chat';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FotografiasViaje from '../../subcomponents/viajes/fotografias_viaje';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';

const centerMapCoordinates = (coords) => {
  if (coords.length === 1) {
    return coords[0];
  }

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  for (let coord of coords) {
    let latitude = coord.lat * Math.PI / 180;
    let longitude = coord.lng * Math.PI / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  let total = coords.length;

  x = x / total;
  y = y / total;
  z = z / total;

  let centralLongitude = Math.atan2(y, x);
  let centralSquareRoot = Math.sqrt(x * x + y * y);
  let centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    lat: centralLatitude * 180 / Math.PI,
    lng: centralLongitude * 180 / Math.PI
  };
}

const divStyle = {
  background: `white`,
  padding: 5
}

const Operation = () => {
    const [value, setValue] = useState(0)
    const [coordenada, setCoordenada] = useState(false)
    const [marcadores, setMarcadores] = useState([])
    const [route, setRoute] = useState(false)
    const [tipoVisualizacion, setTipoVisualizacion] = useState("mapa_conductores")
    const [pedidoSeleccionado, setPedidoSeleccionado] = useState(false)
    const [loadingPedido, setLoadingPedido] = useState(false)
    const [centro_mapa,setCentroMapa] = useState({ lat: -33.458829, lng: -70.654644 })
    const zoom_mapa = 13
    const sesion = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    function TabPanel(props) {
      const { children, value, index, ...other } = props;
      return (
        <div
          role='tabpanel'
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
      );
    }

    const socketFunctions = () => {
      socket.on(`coordenadas_mapa`, data => {
        console.log({data})
        let nuevos = marcadores
        const i = nuevos.findIndex(m => m.idusuario === data.idusuario)
        if(i > -1){
          nuevos[i] = data
        } else {
          nuevos.unshift(data)
        }
        return setMarcadores(prev => [...[], ...nuevos])
    })
    }
      const girarElemento = (elementId) => {
        const element = document.getElementById(elementId);
        let rotation = 0;
      
        const animate = () => {
          rotation += 2;
          element.style.transform = `rotate(${rotation}deg)`;
      
          if (rotation < 360) {
            requestAnimationFrame(animate);
          }
        };
      
        animate();
      };

    useEffect(() => {
      const direccion = getDirectionMovimiento({
        lat: -33.457407,
        lng: -70.652530
      }, {
        lat: -33.457329,
        lng: -70.651972
      })
  },[])

  const obtenerPedido = (id) => {
    if(!id) return
    setLoadingPedido(true)
    return fetch(`${data.urlapi}/viajes?id=${id}`,{
      method:'GET',
      headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer: ${sesion.tokenSession}`
      }
  })
  .then(res => {
      if(res.status === 401) return dispatch(cerrarSesion())
      return res.json()
  })
  .then(res => {
      console.log(res)
      if(!res){
          toast.error('Sin datos')
          return setLoadingPedido(false)
      } else if(res.errorMessage){
          toast.error(res.errorMessage)
          return setLoadingPedido(false)
      } else if(res._id){
        setPedidoSeleccionado(res)
        setTipoVisualizacion("pedido_detalle")
      }
      return setLoadingPedido(false)
  })
  .catch(error => {
      return setLoadingPedido(false)
  })
  }

  const onSelectedTrip = (id) => {
    setTipoVisualizacion('mapa_conductores')
    toast.promise(
      obtenerPedido(id),
       {
         loading: 'Consultando...',
         success: <b>Datos obtenidos</b>,
         error: <b>Error al consultar</b>,
       }
     )
  }

  const showByTipoVisualizacion = () => {
    if(tipoVisualizacion === "mapa_conductores") return <OperationMap />
  }

  const home = () => {
    setTipoVisualizacion('mapa_conductores')
    return setPedidoSeleccionado(false)
  }

  const showIconByStatus = () => {
    return <AccessTimeIcon fontSize='small' sx={{ mb: -0.7, color: data.style.secondary }} />
  }

  const actualizarPedido = async (driver) => {
    return fetch(`${data.urlapi}/viajes`,{
        method:'PUT',
        body: JSON.stringify({
            _id: pedidoSeleccionado._id,
            id_conductor: driver._id
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
            return false
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
            return false
        } else if(res._id){
            pedidoSeleccionado.conductor = driver
            setPedidoSeleccionado({...{}, ...pedidoSeleccionado})
        }
        return false
    })
    .catch(error => {
        return false
    })
}
  const reasignarConductor = async (driver) => {
    return fetch(`${data.urlapi}/viajes/reasignar`,{
        method:'PUT',
        body: JSON.stringify({
            _id: pedidoSeleccionado._id,
            id_conductor: driver._id
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
            return false
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
            return false
        } else if(res._id){
            pedidoSeleccionado.conductor = driver
            setPedidoSeleccionado({...{}, ...pedidoSeleccionado})
        }
        return false
    })
    .catch(error => {
        return false
    })
}

  const seleccionarConductor = (driver) => {
    toast.promise(
      reasignarConductor(driver),
       {
         loading: 'Actualizando...',
         success: <b>Realizado exitosamente</b>,
         error: <b>Error al consultar</b>,
       }
     )
  }

  const asignacionPedido = () => {
    return <div>
      <Card variant="outlined" sx={{ pl:1, pr:1, pt:1, mb:1 }}>
        <Typography sx={{ mb: 0 }} >Conductor designado</Typography>
        <Typography sx={{ mb: 1 }} fontWeight="600" >{obtenerIdentificadorConductor(pedidoSeleccionado.conductor)}</Typography>
        <ModalConductores seleccionarConductor={(driver) => seleccionarConductor(driver)} titulo={pedidoSeleccionado.id_conductor ? "Reasignar conductor" : "Asignar conductor"} />
      </Card>
    </div>
  }

  const modalidadPedido = () => {
    return pedidoSeleccionado.modo_programacion === 'programmed' ? <div>
        <Card sx={{ pt: 1, pb: 1, pl: 2, pr: 2, mb:2, backgroundColor: data.style.highlight}} variant="outlined" >
        <Typography sx={{ mb: 0 }} fontSize={13} color="white" >Este viaje fue recibido bajo la modalidad de programación</Typography>
        <Typography sx={{ mb: 0, color: 'white' }} fontWeight="600" ><AccessTimeIcon fontSize='small' sx={{ mb: -0.7, color: "white"}} /> {fechaATexto(pedidoSeleccionado.fecha_programacion)}</Typography>
        </Card>
      </div> : false
  }

  const handleChangeTab = (e, value) => {
    return setValue(value);
  };

  const showDetallePedido = () => {
    /*
    const coordenadas = pedidoSeleccionado.puntos.map(i => ({ lat: i.location.latitude, lng: i.location.longitude }))
    const fotos = procesarFotos(pedidoSeleccionado)
    return <div>
      <Grid container spacing={2} sx={{ mt:2 }}>
              <Grid item md={4} xs={12}>
                <Card>
                  <CardContent>
                  <Button variant="outlined" sx={{ mb:2 }} onClick={() => home()} >Atrás</Button>
                  <Typography sx={{ mb: 2 }} variant="h4" fontWeight="500" >Viaje {pedidoSeleccionado.id}</Typography>


                  <Card sx={{ pt: 1, pb: 1, pl: 2, pr: 2, mb:1, backgroundColor: data.style.primary }} variant="outlined" >
                    <Typography sx={{ mb: 0, color: 'white' }} fontWeight="500" >{pedidoSeleccionado.status_string}</Typography>
                  </Card>

                  {asignacionPedido()}
                  {modalidadPedido()}

                  <Typography sx={{ mb: 0, color: data.style.secondary }} >Nombre y apellido</Typography>
                  <Typography sx={{ mb: 2 }} fontWeight="600" >{obtenerIdentificadorCliente(pedidoSeleccionado.cliente)}</Typography>
                  
                  <Typography sx={{ mb: 0, color: data.style.secondary }} >Móvil</Typography>
                  <Typography sx={{ mb: 2 }} fontWeight="600" >{obtenerTelefonoCliente(pedidoSeleccionado.cliente)}</Typography>

                  <Typography sx={{ mb: 0, color: data.style.secondary }} >Origen</Typography>
                  <Typography sx={{ mb: 2 }} fontWeight="600" >{obtenerDireccionDestino(pedidoSeleccionado.puntos)}</Typography>

                  <Grid container >
                    <Grid item xs={6}>
                      <Typography sx={{ mb: 0, color: data.style.secondary }} >Tipo de viaje</Typography>
                      <Typography fontWeight="600">{ textoTipoViaje(pedidoSeleccionado.tipo_viaje)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ mb: 0, color: data.style.secondary }} >Ayudantes</Typography>
                      <Typography sx={{ mb: 2 }} fontWeight="600" >{pedidoSeleccionado.ayudantes}</Typography>
                    </Grid>
                  </Grid>

                  <Typography sx={{ mb: 0, color: data.style.secondary }} >Seguro de viaje</Typography>
                  <Typography sx={{ mb: 2 }} fontWeight="600" >{pedidoSeleccionado.seguro ? <div>
                    <Typography variant='h6'>{pedidoSeleccionado.seguro.titulo}</Typography>
                  </div> : "Sin seguro de viaje"}</Typography>
                  
                  <Card variant="outlined" sx={{ p:2, mb:2 }}>
                        <DetallesTarifaBox viaje={pedidoSeleccionado} />
                  </Card>

                  <Typography sx={{ mb: 1 }} variant="h6" >Detalles de la carga</Typography>
                  <MostrarProductos productos={pedidoSeleccionado.productos} />

                  <Grid container >
                    <Grid item xs={12}>
                      <FotografiasViaje fotos={fotos} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ mb: 0, color: data.style.secondary }} >Peso total {"(Kg.)"}</Typography>
                      <Typography sx={{ mb: 2 }} fontWeight="600" >{Intl.NumberFormat("es-ES").format(pedidoSeleccionado.peso)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ mb: 0, color: data.style.secondary }} >Volumen total {"(M3.)"}</Typography>
                      <Typography sx={{ mb: 2 }} fontWeight="600" >{Intl.NumberFormat("es-ES").format(pedidoSeleccionado.volumen)}</Typography>
                    </Grid>
                  </Grid>
                  <Typography sx={{ mb: 2 }} variant="h6" >{pedidoSeleccionado.puntos.filter(p => p.tipo !== 'origen').length} Puntos de entrega</Typography>
                  {
                    pedidoSeleccionado.puntos.filter(p => p.tipo !== 'origen').map((punto,i) => {
                      return <div key={punto._id}><Typography sx={{ mb: 0 }} >{i+1} {showIconByStatus(punto)} {punto.description}</Typography></div>
                    })
                  }
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={8} xs={12}>
                <Box sx={{ width: '100%' }}>
                    <Tabs
                      value={value}
                      onChange={handleChangeTab}
                      textColor='primary'
                      indicatorColor='primary'
                      aria-label='primary tabs example'
                    >
                      <Tab
                        // icon={<CheckCircleOutlineIcon />}
                        iconPosition='start'
                        value={0}
                        label='Mapa'
                      />
                      <Tab
                        // icon={<CheckCircleOutlineIcon />}
                        iconPosition='start'
                        value={1}
                        label='Eventos'
                      />
                      <Tab
                        // icon={<DeleteIcon />}
                        iconPosition='start'
                        value={2}
                        label='Chat interno'
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                  <MapaOperacion
                    height={400}
                    zoom={zoom_mapa + 1}
                    center={centerMapCoordinates(coordenadas)}
                    destination={obtenerDireccionEntrega(pedidoSeleccionado.puntos,1)}
                    origin={obtenerDireccionDestino(pedidoSeleccionado.puntos)}
                    puntos={pedidoSeleccionado.puntos}
                    markers={pedidoSeleccionado.puntos.map(p => ({ lat: p.location.latitude, lng: p.location.longitude }))}
                    travelMode="DRIVING"
                    estilo_mapa={MapStyleDefault}
                    polyline={[]}
                    isMarkerShown
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=${maps_key}`}
                    loadingElement={<div style={{ height: `100%` }} >
                        <div className="loadingbox">
                    <img alt="loading"  src="img/cargando.gif" />
                        <h3>Cargando mapa...</h3>
                    </div>
                    </div> }
                    containerElement={<div style={{ height: `600px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    className="mt-3"
                />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <ListadoLogViaje tipo="timeline" condicion_default={{ id_viaje: pedidoSeleccionado._id, important: true }} />
                    <ListadoLogViaje condicion_default={{ id_viaje: pedidoSeleccionado._id }} />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                      <ChatViaje id_viaje={pedidoSeleccionado._id} id_cliente={pedidoSeleccionado.id_cliente} />
                  </TabPanel>                
              </Grid>
      </Grid>
    </div>
    */
  }

  const contadores = () => {
      return <Grid container>
      <Grid item md={2} xs={3}>
          <ContadorViajes onSelectedTrip={(id) => onSelectedTrip(id)} condicion_default={{ status: "on_way_origin", modo_programacion: "express", }} titulo="En ruta a recoger" info="Conductores sin rutas en tránsito" />
      </Grid>
      <Grid item md={2} xs={3}>
          <ContadorViajes onSelectedTrip={(id) => onSelectedTrip(id)} condicion_default={{ status: "arrived_origin", modo_programacion: "express", }} titulo="En lugar de origen" info="Conductor se encuentra en el punto de partida" />
      </Grid>
      <Grid item md={2} xs={3}>
          <ContadorViajes onSelectedTrip={(id) => onSelectedTrip(id)} condicion_default={{ status: "transit", modo_programacion: "express", }} titulo="En ruta de entrega" info="Conductor salió del punto de partida" />
      </Grid>
      <Grid item md={2} xs={3}>
         <ContadorViajes onSelectedTrip={(id) => onSelectedTrip(id)} condicion_default={{ status: "unassigned", modo_programacion: "express", }} titulo="Disponibles" info="Viajes que aún no tienen conductor designado" />
      </Grid>
      <Grid item md={2} xs={3}>
          <ContadorViajes onSelectedTrip={(id) => onSelectedTrip(id)} condicion_default={{ modo_programacion: "programmed" }} titulo="Viajes programados" info="Viajes programados aún no finalizados" />
      </Grid>
      <Grid item md={2} xs={3}>
          <ContadorViajes showCounter onSelectedTrip={(id) => onSelectedTrip(id)} condicion_default={{ status: { $in: ["finished","cancelled","dismissed"] }, }} titulo="Viajes históricos" info="Viajes terminados" />
      </Grid>
      <Grid item md={2} xs={3}>
        <ContadorConductores onSelectedTrip={(id) => onSelectedTrip(id)} condicion_default={{ conectado: true }} titulo="Conductores activos" info="Conductores conectados actualmente" />
      </Grid>
  </Grid>
  }

  const render = () => {
        return <div>
            <div style={{ marginBottom: 20 }}>
              <h1 style={{ margin: 0 }}>Operación</h1>
              {contadores()}
              <OperationMap />
            </div>
        </div>
  }

  return <Structure component={render()} />

}

export default Operation