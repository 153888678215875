import { useDispatch, useSelector } from "react-redux"
import ReactFlagsSelect from "react-flags-select";
import { useState } from "react";
import { cambiarIdioma } from "../../redux/actions/idioma";

const SelectorIdioma = (props) => {
    const dispatch = useDispatch()
    const usuario = useSelector(state => state.miusuario)
    const idioma = useSelector(state => state.idioma)
    const pais = useSelector(state => state.pais)
    const [selected, setSelected] = useState(idioma);

    return <div>
    <ReactFlagsSelect
        className="selector_idioma"
        selected={selected}
        onSelect={(code) => {
            console.log(code)
            setSelected(code)
            dispatch(cambiarIdioma(code))
            window.location.reload()
        }}
        countries={["ES","BR"]}
        customLabels={{ ES: "Español", BR: "Portugues" }}
        placeholder="IDIOMA"

    />
    </div>
}

export default SelectorIdioma