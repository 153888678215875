import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import Structure from '../Structure';
  import { Link as Href, useParams } from 'react-router-dom';
  import { Link as Enlace } from '@mui/material';
  import { useDispatch, useSelector } from 'react-redux';
  import data from '../../lib/backend/data';
  import toast from 'react-hot-toast';
  import { cerrarSesion } from '../../redux/actions/session';
  import PropTypes from 'prop-types';
  import SinData from '../General/sin_data';
  import SecurityIcon from '@mui/icons-material/Security';
  import SettingsIcon from '@mui/icons-material/Settings';
  import ImageIcon from '@mui/icons-material/Image';
import GeocercaDrawer from './drawer';
  
  const GeocercaCrear = () => {
    const dispatch = useDispatch();
    const [loadingMaster, setLoadingMaster] = useState(false);
    const [loading, setLoading] = useState(false);
    const [publicacion, setPublicacion] = useState({});
    const [permisosOtorgados, setPermisosOtorgados] = useState([]);
    const [polygon, setPolygon] = useState([]);
    const sesion = useSelector((state) => state.miusuario);
    const { id } = useParams();
    function TabPanel(props) {
      const { children, value, index, ...other } = props;
  
      return (
        <div
          role='tabpanel'
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
      );
    }
  
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };
  
    const obtenerPublicacion = async () => {
      return fetch(`${data.urlapi}/geocercas?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then((res) => {
          if (!res) {
            toast.error('Sin datos');
            return setLoadingMaster(false);
          } else if (res.errorMessage) {
            toast.error(res.errorMessage);
            return setLoadingMaster(false);
          } else if (res._id) {
            setPublicacion(res);
            if(res.region){
                if(res.region.coordinates){
                    if(res.region.coordinates.length > 0){
                        const path = res.region.coordinates[0].map(p => ({ lat: p[1], lng: p[0] }))
                        console.log(path)
                        setPolygon(path)
                    }
                }
            }
          }
          return setLoadingMaster(false);
        })
        .catch((error) => {
          toast.error('Error al consultar la información, intente nuevamente');
          return setLoadingMaster(false);
        });
    };
  
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      publicacion[name] = value;
      console.log(publicacion);
      return setPublicacion({ ...{}, ...publicacion });
    };
  
    const actualizarPublicacion = async () => {
      const requeridos = [
        { value: 'ubicacion', label: 'Título' },
        { value: 'tipo', label: 'Tipo' },
      ];
      let faltantes = [];
      requeridos.map((campo) => {
        if (!publicacion[campo.value]) faltantes.push(campo.label);
        return true;
      });
      if (faltantes.length > 0)
        return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
      publicacion.permisos = permisosOtorgados;
  
      setLoading(true);
      return fetch(`${data.urlapi}/geocercas`, {
        method: 'POST',
        body: JSON.stringify(publicacion),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then((res) => {
          if (!res) {
            toast.error('Sin datos');
            return setLoading(false);
          } else if (res.errorMessage) {
            toast.error(res.errorMessage);
            return setLoading(false);
          } else if (res._id) {
            toast.success('Actualizado exitosamente')
            setTimeout(() => {
                window.location = `/geocerca-${res._id}`
            }, 500);
          }
          return setLoading(false);
        })
        .catch((error) => {
          toast.error('Error al consultar la información, intente nuevamente');
          return setLoading(false);
        });
    };
  
    const handleImage = (e) => {
      console.log(e);
    };

    const onPolygonComplete = (poly) => {
        setPolygon(prev => [...[], ...[]])
        const polyArray = poly.getPath().getArray();
        let paths = [];
        polyArray.forEach(function(path){
            paths.push([path.lng(), path.lat() ]);
        });

        setPolygon(prev => [...[], ...paths])
        const region = {
            type: "Polygon",
            coordinates: [
                paths
            ]
        }
        publicacion.region = region
        setPublicacion(prev => ({...{}, ...publicacion}))
    }
  
    const render = () => {
      if (loadingMaster) return <CircularProgress />;

      return (
        <div>
          <Breadcrumbs aria-label='breadcrumb'>
            <Href to='/geocercas'>Geocerca</Href>
            <Enlace underline='hover' color='text.primary'>
              Editar geocerca
            </Enlace>
          </Breadcrumbs>
          <h1 style={{ margin: 0 }}>Editar geocerca</h1>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item md={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Typography variant='h6'>
                    <SettingsIcon sx={{ mb: -0.7 }} /> Detalles
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    label='Descripción'
                    variant='filled'
                    name='ubicacion'
                    fullWidth
                    defaultValue={publicacion.ubicacion}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel id='demo-simple-select-label'>Tipo</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Tipo'
                    name='tipo'
                    onChange={handleChange}
                    defaultValue={publicacion.tipo}
                  >
                    <MenuItem value=''>Seleccione</MenuItem>
                    <MenuItem value='Disponible'>Disponibilidad</MenuItem>
                    <MenuItem value='No disponible'>No disponibilidad</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
                <Grid item md={12} xs={12}>
                <GeocercaDrawer
                            centerMap={{ lat: -33.458829, lng: -70.654644 }}
                            onPolygonComplete={(polygon) => onPolygonComplete(polygon)}
                            polygon={polygon}
                        />
                  {loading === true ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant='contained'
                      color='success'
                      xs={{ m: 0 }}
                      onClick={() => actualizarPublicacion()}
                    >
                      CREAR
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    };
  
    return <Structure component={render()} />;
  };
  
  export default GeocercaCrear;