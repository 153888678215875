import CargandoTabla from './cargando';
import {
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { fechaATexto } from '../../lib/helpers/helpers';
import { Link } from 'react-router-dom';

const ListadoDeSeguros = (props) => {
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.miusuario);
  const [soportes, setSoportes] = useState([]);
  const [loadingSoportes, setLoadingSoportes] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [campoBusqueda, setCampoBusqueda] = useState('');
  const [total, setTotal] = useState(0);
  const [condicion_busqueda, setCondicionBusqueda] = useState(
    props.condicion_default ? props.condicion_default : {}
  );
  const trash = props.trash ? props.trash : false;
  const tipo = props.tipo ? props.tipo : 'link';
  const titulo = props.title ? props.title : 'registros';

  const paginar = (page, ignorar) => {
    if (ignorar) return false;
    setPagina(page);
    obtenerSeguros(page);
  };

  const handleChangePagina = (e, page) => {
    setPagina(page);
    return obtenerSeguros(page, condicion_busqueda);
  };

  const paginacion = (ciclo, total) => {
    const cantidad = Math.ceil(total / ciclo);
    if (!cantidad) return false;
    return (
      <div className='mb-3'>
        <Pagination
          sx={{ mt: 2 }}
          color='primary'
          count={cantidad}
          page={pagina}
          onChange={handleChangePagina}
        />
      </div>
    );
  };

  const obtenerSeguros = async (page, query) => {
    setLoadingSoportes(true);
    if (query) if (trash === true) query.status = 'trash';
    const condicion = query ? query : condicion_busqueda;
    return fetch(`${data.urlapi}/seguros/list`, {
      method: 'POST',
      body: JSON.stringify({
        condicion,
        pagina: page,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingSoportes(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingSoportes(false);
        }
        if (Array.isArray(res.datos) !== false) {
          setSoportes(res.datos);
          setTotal(res.total);
        }
        return setLoadingSoportes(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSoportes(false);
      });
  };

  useEffect(() => {
    obtenerSeguros(1, false);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!campoBusqueda) return toast.error('Escribe alguna palabra clave');
    const condicion = {
      $text: { $search: campoBusqueda.toLowerCase() },
      activo: true,
    };
    setCondicionBusqueda(condicion);
    setPagina(1);
    return obtenerSeguros(1, condicion);
  };

  const seleccionarConductor = (driver) => {
    if (props.seleccionarConductor) props.seleccionarConductor(driver);
    return false;
  };

  const showByType = (tipo, driver) => {
    switch (tipo) {
      case 'funcion':
        return (
          <b onClick={() => seleccionarConductor(driver)}>{driver.titulo}</b>
        );
      case 'link':
        return (
          <b>
            <Link to={`/seguro-carga-${driver._id}`}>{driver.titulo}</Link>
          </b>
        );
      default:
        return;
    }
  };

  const handleChangeCampo = (e) => {
    const { value } = e.target;
    return setCampoBusqueda(value);
  };

  if (loadingSoportes) return <CargandoTabla />;

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Box
            component='form'
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1, mb: 1 }}
          >
            <Grid container spacing={2}>
                            <Grid item xs={4}>
            <TextField
              id='outlined-basic'
              label='Buscar por título, tipo o descripción'
              variant='outlined'
              size="small"
              defaultValue={campoBusqueda}
              onChange={handleChangeCampo}
              fullWidth
            />
                            </Grid>
                            <Grid item xs={3}>
                                <Button type="submit" size="large" variant="contained" >BUSCAR</Button>
                            </Grid>
                        </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          {paginacion(data.pagina, total)}
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <h3 sx={{ m: 0 }}>
            {total} {titulo}
          </h3>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label='sticky table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Título</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Registro</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {soportes.map((driver) => (
              <TableRow
                key={driver._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{showByType(tipo, driver)}</TableCell>
                <TableCell>{driver.tipo}</TableCell>
                <TableCell>{driver.descripcion}</TableCell>
                <TableCell>{fechaATexto(driver.createdAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ListadoDeSeguros;
