import React, { useEffect, useState } from "react"
import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Slide, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import GraficosCostos from "./graficos";

const ModalGraficos = (props) => {
    const {
        custom_component,
    } = props
    const titulo = props.titulo ? props.titulo : 'Registros'
    const informacion = props.info ? props.info : ''
    const condicion = props.condicion_default ? props.condicion_default : { }
    const [modalShow, setMotalShow] = useState(false)

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });
    
    useEffect(() => {
    }, [])

    const closeModal = () => {
        return setMotalShow(false)
    }
    
    const openModal = () => {
        return setMotalShow(true)
    }

    const modalDatos = () => {
        if(!modalShow) return false
        return <Dialog
        open={modalShow}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle><CloseIcon style={{ marginBottom: -4 }} onClick={() => closeModal()} /> {titulo}</DialogTitle>
        <DialogContent>        
        <Typography sx={{ mb:1 }}>{informacion}</Typography>
        <GraficosCostos condicion_default={condicion} /> 
        </DialogContent>
        </Dialog>
    }

    const mostrarBoton = () => {
        if(custom_component) return <div onClick={() => openModal()}>{custom_component}</div>
        return <div>
            <ButtonGroup sx={{ width: "100%" }}>
            <Button variant="outlined" color="inherit" sx={{ mb:0, width: "100%" }} onClick={() => openModal()} >MOSTRAR GRÁFICOS</Button>
            </ButtonGroup>
        </div>
    }

    return <Box >
        {mostrarBoton()}
        {modalDatos()}
    </Box>

}

export default ModalGraficos