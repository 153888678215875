import CargandoTabla from './cargando';
import {
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import data, { urlapi } from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { fechaATexto } from '../../lib/helpers/helpers';
import { Link } from 'react-router-dom';
import ExcelButtonDownload from '../../subcomponents/reports.js/button_download';
import ContadoresEmpresa from './contadores';
import FiltroZona from '../../subcomponents/zonas/filtro';

const ListadoDeEmpresas = (props) => {
  const {
    showCounters,
    hideExporter
  } = props
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.miusuario);
  const [clientes, setClientes] = useState([]);
  const [ filtroSeleccionado, setFiltroSeleccionado ] = useState('');
  const [loadingClientes, setLoadingClientes] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [campoBusqueda, setCampoBusqueda] = useState('');
  const [total, setTotal] = useState(0);
  const [condicion_busqueda, setCondicionBusqueda] = useState(
    props.condicion_default ? props.condicion_default : {}
  );
  const trash = props.trash ? props.trash : false;
  const tipo = props.tipo ? props.tipo : 'link';
  const titulo = props.title ? props.title : 'registros';
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)

  const paginar = (page, ignorar) => {
    if (ignorar) return false;
    setPagina(page);
    obtenerClientes(page);
  };

  const handleChangePagina = (e, page) => {
    setPagina(page);
    return obtenerClientes(page, condicion_busqueda);
  };

  const paginacion = (ciclo, total) => {
    const cantidad = Math.ceil(total / ciclo);
    if (!cantidad) return false;
    return (
      <div className='mb-3'>
        <Pagination
          sx={{ mt: 2 }}
          color='primary'
          count={cantidad}
          page={pagina}
          onChange={handleChangePagina}
        />
      </div>
    );
  };

  const obtenerClientes = async (page, query) => {
    setLoadingClientes(true);
    if (query) if (trash === true) query.status = 'trash';
    const condicion = query ? query : condicion_busqueda;
    return fetch(`${data.urlapi}/empresas/list`, {
      method: 'POST',
      body: JSON.stringify({
        condicion,
        pagina: page,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingClientes(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingClientes(false);
        }
        if (Array.isArray(res.datos) !== false) {
          setClientes(res.datos);
          setTotal(res.total);
        }
        return setLoadingClientes(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingClientes(false);
      });
  };

  useEffect(() => {
    obtenerClientes(1, false);
  }, []);

  const activarFiltro = (cond, llave) => {
    setFiltroSeleccionado(llave)
    obtenerClientes(1, cond)
    setCondicionBusqueda(cond)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFiltroSeleccionado(false)
    if (!campoBusqueda) return toast.error('Escribe alguna palabra clave');
    const condicion = {
      $text: { $search: campoBusqueda.toLowerCase() },
      activo: true,
    };
    setCondicionBusqueda(condicion);
    setPagina(1);
    return obtenerClientes(1, condicion);
  };

  const seleccionarCliente = (cliente) => {
    if (props.seleccionarEmpresa) props.seleccionarEmpresa(cliente);
    return false;
  };

  const showByType = (tipo, cliente) => {
    switch (tipo) {
      case 'funcion':
        return (
          <b onClick={() => seleccionarCliente(cliente)}>{cliente.razon_social}</b>
        );
      case 'link':
        return (
          <b>
            <Link to={`/empresas-${cliente._id}`}>{cliente.razon_social}</Link>
          </b>
        );
      default:
        return;
    }
  }

  const buscarPorGeocerca = async (id_geocerca) => {
    setFiltroSeleccionado(false)
    setLoadingClientes(true)
    setPagina(1)
    return fetch(`${urlapi}/empresas/contadores/geo`,{
        method:'POST',
        body: JSON.stringify({
            id_geocerca
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
      if (!res) {
        toast.error('Sin datos');
        return setLoadingClientes(false);
      } else if (res.errorMessage) {
        toast.error(res.errorMessage);
        return setLoadingClientes(false);
      }
        if (Array.isArray(res.datos) !== false) {
          setClientes(res.datos);
          setTotal(res.total);
        }
        if(res.condicion) setCondicionBusqueda(res.condicion)
        return setLoadingClientes(false)
    })
    .catch(error => {
        return setLoadingClientes(false)
    })
}

  const stringByStatus = (value) => {
    switch (value) {
      case "active":
        return "Activo"
      case "trash":
        return "En papelera"
      default:
        return "Pendiente"
    }
  }

  const handleChangeCampo = (e) => {
    const { value } = e.target;
    return setCampoBusqueda(value);
  };


  const mostrarRegistros = () => {
    if (loadingClientes) return <CargandoTabla />;

    return <TableContainer component={Paper} sx={{ mt: 2 }}>
    <Table sx={{ minWidth: 650 }} aria-label='sticky table' stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Razón social</TableCell>
          <TableCell>Rut</TableCell>
          <TableCell>Estado</TableCell>
          <TableCell>Fecha creación</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {clientes.map((driver) => (
          <TableRow
            key={driver._id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell>{showByType(tipo, driver)}</TableCell>
            <TableCell>{driver.rut}</TableCell>
            <TableCell>{stringByStatus(driver.status)}</TableCell>
            <TableCell>{fechaATexto(driver.createdAt)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  }


  return (
    <div>
      <Grid container>
        <Grid item xs={12}>

        { showCounters === true ? <ContadoresEmpresa selected={filtroSeleccionado} onClick={(data, llave) => activarFiltro(data, llave)} /> : false }

          <Box
            component='form'
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1, mb: 1 }}
          >
            <Grid container spacing={2}>
            <Grid item xs={4}>
            <TextField
              id='outlined-basic'
              label='Buscar por razón social o rut'
              variant='outlined'
              defaultValue={campoBusqueda}
              onChange={handleChangeCampo}
              fullWidth
            />
            </Grid>
            <Grid item xs={2}>
              <Button style={{ height: 57 }} type="submit" size="large" variant="contained" >BUSCAR</Button>
            </Grid>
            <Grid item xs={12}>
              <FiltroZona onSelectGeo={(data) => buscarPorGeocerca(data._id)} />
            </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      { hideExporter === true ? false : <ExcelButtonDownload condicion={condicion_busqueda} slug="business" /> }
      <Grid container>
        <Grid item xs={6}>
          {paginacion(data.pagina, total)}
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <h3 sx={{ m: 0 }}>
            {total} registros
          </h3>
        </Grid>
      </Grid>

      {mostrarRegistros()}
    </div>
  );
};

export default ListadoDeEmpresas