const obtenerDireccionDestino = (puntos) => {
    const default_resultado = ''
    if(!puntos) return default_resultado
    if(Array.isArray(puntos) !== true) return default_resultado
    if(puntos.length < 1) return default_resultado
    const i = puntos.findIndex(p => p.tipo === 'origen')
    if(i < 0) return default_resultado
    return puntos[i].description
}

const obtenerDireccionEntrega = (puntos,i) => {
    const default_resultado = ''
    if(!puntos) return default_resultado
    if(Array.isArray(puntos) !== true) return default_resultado
    if(puntos.length < 1) return default_resultado
    if(!puntos[i]) return default_resultado
    return puntos[i].description
}

const procesarFotos = (order) => {
    if(!order.fotos) return []
    if(Array.isArray(order.fotos) !== true) return []
    if(order.fotos.length < 1) return []
    return order.fotos
}

const stringStatusByStatus = (string) => {
    switch (string) {
        case "unnasigned":
            return "Estamos buscando tu vehículo..."
        case "on_way_origin":
            return "Vehículo en camino"
        case "arrived_origin":
            return "Llegada al punto de recogida"
        case "transit":
            return "Inicio de ruta"
        case "finished":
            return "Viaje finalizado"
        case "cancelled":
            return "Viaje cancelado"
        case "dismissed":
            return "Viaje cancelado"
        case "cambio_conductor":
            return "Conductor canceló viaje"
        default:
            return ""
    }
}

module.exports = {
    procesarFotos,
    obtenerDireccionDestino,
    obtenerDireccionEntrega,
    stringStatusByStatus
}