import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    TextField,
    Typography,
    Card,
    Modal,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Divider,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    InputAdornment,
    Dialog,
    Slide,
    DialogContent,
    CardContent,
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import Structure from '../Structure';
  import { Link, useParams } from 'react-router-dom';
  import { Link as Enlace } from '@mui/material';
  import { useDispatch, useSelector } from 'react-redux';
  import { cerrarSesion } from '../../redux/actions/session';
import { toast } from 'react-hot-toast';
import { urlapi } from '../../lib/backend/data';
import { obtenerPrefijoMovil, validarLongitudNumeroMovil } from '../../lib/helpers/moviles';
import { formatoAyudaDNIPorPais, formatoAyudaMovilPorPais, nombreDNIPorPais } from '../../lib/data/internacional';
import * as XLSX from 'xlsx'
import { validateEmail } from '../../lib/helpers/helpers';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ModalEmpresas from '../Empresas/modal_seleccion';

  const DriversImport = () => {
    const [ showUploadBox, setShowUploadBox ] = useState(true)
    const [ procesados, setProcesados ] = useState(0)
    const [ empresa, setEmpresa ] = useState(false)
    const [ modalProgress, setModalProgess ] = useState(false)
    const [ modalAsignacionColumna, setModalAsignacion ] = useState(false)
    const [ datos, setDatos ] = useState([])
    const [ llaveAsignar, setLlaveAsignar ] = useState(false)
    const [ validando, setValidando ] = useState(false)
    const [ datosExcelSinProcesar, setDatosExcelSinProcesar ] = useState([])
    const [ errores, setErrores ] = useState([])
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ columnasExcel, setColumnasExcel ] = useState([])
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)

    const esquema = [
        { value:'nombres', label: 'Nombres' },
        { value:'apellido_p', label: 'Primer Apellido' },
        { value:'apellido_m', label: 'Segundo Apellido' },
        { value:'rut', label: nombreDNIPorPais(pais), helptext: formatoAyudaDNIPorPais(pais) },
        { value:'email', label: 'Email' },
        { value:'phone', label: 'Móvil', helptext: formatoAyudaMovilPorPais(pais) }
    ]

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

    const obtenerNombrePorLlaveIdioma = (key) => {
        const i = esquema.findIndex(e => e.value === key)
        if(i > -1){
            return esquema[i].label
        }
        return key
    }

    const obtenerTextoAyudaPorLlaveIdioma = (key) => {
        const i = esquema.findIndex(e => e.value === key)
        if(i > -1){
            return esquema[i].helptext
        }
        return key
    }

    const procesarExcel = (target) => {

        let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            let hojas = []
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array', cellDates:true, dateNF:'dd.mm.yyyy' });
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })
            console.log(hojas)
            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            if(!hojas[0][0]) return toast.error('No se encontraron datos')
            
            let errores             = []
            let registros           = []
            let nombres_columnas    = []
            
            if(errores.length > 0) return toast.error(errores.join(', ')) // SI HAY ERRORES DETENER

            let pos = 0
            for( const hoja of hojas[0] ){

                // RECOPILAMOS LAS LLAVES DEL EXCEL
                Object.keys(hoja).map(key => {
                    const llave_limpia = key.toLocaleLowerCase()
                    if(!nombres_columnas.includes(llave_limpia)) nombres_columnas.push(llave_limpia)
                })

                pos++
                let objeto = {}
                for( const key of esquema ){
                    const llaves_obtenidas = Object.keys(hoja)
                    const i = llaves_obtenidas.findIndex(llave => llave.toLowerCase() === key.label.toLocaleLowerCase())
                    if(i < 0){
                        errores.push(`${key.label} no encontrado en la columna ${pos}`)
                        continue
                    } else {
                        objeto[key.value] = hoja[ llaves_obtenidas[i] ]
                    }
                }
                registros.push(objeto)
            }

            if(errores.length > 0){
                toast.error("Errores encontrados en tu archivo, Revisa los errores encontrados en tu archivo")
            }

            setColumnasExcel(nombres_columnas)
            const limpiar_llaves_para_filtro = hojas[0].map(info => {
                let objeto_limpio = {}
                Object.keys(info).map(ll => {
                    objeto_limpio[ll.toLowerCase()] = info[ll]
                })
                return objeto_limpio
            })
            setDatosExcelSinProcesar(limpiar_llaves_para_filtro)
            setShowUploadBox(false)
            return setDatos(registros)
        }
    }

    const handleDownload = () => {
        // Crear el archivo de Excel
        const wb = XLSX.utils.book_new();
        const wsData = [ esquema.map(e => e.label) ]
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
    
        // Convertir el libro de trabajo a un archivo binario
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    
        // Crear un Blob con los datos binarios
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    
        // Generar un objeto URL del Blob
        const url = URL.createObjectURL(blob);
    
        // Crear un enlace de descarga invisible y hacer clic en él
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Modelo Conductores.xlsx';
        a.click();
    
        // Liberar el objeto URL
        URL.revokeObjectURL(url);
      };
    
      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };

      const handleChangeFile = (e) => {
        procesarExcel(e.target)
      };
    
      const revisarLlavesFuncionPersonalizada = (registros, llave, funcion, params) => {
        let invalidos = []
        for( const registro of registros ){
            if(params){
                if(Array.isArray(params) !== false){
                    if(params.length > 0){
                        if(!funcion(registro[llave].toString(), ...params)) invalidos.push(registro[llave].toString())
                    }
                }
            } else {
                if(!funcion(registro[llave])) invalidos.push(registro[llave])
            }
        }
        return invalidos
      }

      const subirInformacion = async () => {
        const instancia_datos = JSON.parse( JSON.stringify(datos))

        const invalidos = instancia_datos.filter(dato => {
            for( const key of esquema ){
                const llaves_obtenidas = Object.keys(dato)
                const i = llaves_obtenidas.findIndex(llave => llave.toLowerCase() === key.value.toLocaleLowerCase())
                console.log({ key: key.value, i })
                if(i < 0) return true
            }
            return false
        })
        console.log({invalidos})
        
        if(invalidos.length > 0){
            return toast.error("Error, Tienes registros inválidos, corrije la información e intenta de nuevo")
        }
        
        
        const email_invalidos = revisarLlavesFuncionPersonalizada(instancia_datos, 'email', validateEmail)
        if(email_invalidos.length > 0){
            return toast.error(`${obtenerNombrePorLlaveIdioma("email")} inválidos, Tienes registros inválidos, corrije la información e intenta de nuevo. Registros encontrados: ${email_invalidos.join(', ')}`)
        }        
        
        const numeros_invalidos = revisarLlavesFuncionPersonalizada(instancia_datos, 'phone', validarLongitudNumeroMovil, [pais])
        if(numeros_invalidos.length > 0){
            return toast.error(`${obtenerNombrePorLlaveIdioma("phone")} inválidos, Tienes registros inválidos, corrije la información e intenta de nuevo. Registros encontrados: ${numeros_invalidos.join(', ')}`)
        }

        let validations = []
        for( const u of datos){
            validations = [...validations, ...[
                { key: "rut", value: u.rut.toString() },
                { key: "phone", value: `${obtenerPrefijoMovil(pais)}${u.phone.toString()}` },
                { key: "email", value: u.email.toLowerCase() }
            ]]
        }
        const validacion_previa = await validarCampos(validations)
        if(!validacion_previa) return setValidando(false)

        let imposibles = []
        setModalProgess(true)
        for( const user of datos ){
            const creado = await crearUsuairoIndividual(user)
            if(!creado) imposibles.push(1)
        }
        setModalProgess(false)
        setTimeout(() => {
            setProcesados(0)
        }, 500);
        if(errores.length > 0 || imposibles.length > 0){
            toast.error("Proceso finalizado con errores, Revisa los errores que ocurrieron")
            return setShowModalErrores(true)
        } else {
            toast.success("Proceso finalizado con errores, Revisa los errores que ocurrieron")
        }
        setShowUploadBox(true)
        setDatos([...[], ...[]])
        return setDatosExcelSinProcesar([...[], ...[]])
      }

      const validarCampos = async (arreglo)=>{
        setErrores([...[], ...[]])
        setValidando(true)
        return fetch(`${urlapi}/conductores/validate`, {
            method:'POST',
            body: JSON.stringify(arreglo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            setValidando(false)
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos del servidor')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(typeof res.success !== "undefined"){
                if(res.success === true){
                    return true
                } else {
                    if(res.errors){
                        if(Array.isArray(res.errors) !== false){
                            const problemas_encontrados = res.errors.map(error => {
                                const mensaje = `${obtenerNombrePorLlaveIdioma(error.key)} ya está en uso: ${error.value}`
                                return mensaje
                            })
                            setShowModalErrores(true)
                            console.log(problemas_encontrados)
                            setErrores(problemas_encontrados)
                        }
                    }
                }
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

      const crearUsuairoIndividual = async (usuario)=>{
        const progreso = procesados + 1
        const usuario_crear = JSON.parse( JSON.stringify(usuario))
        usuario_crear.status = "active"
        usuario_crear.phone = `${obtenerPrefijoMovil(pais)}${usuario_crear.phone}`
        usuario_crear.validado = true
        if(empresa) usuario_crear.propietario = empresa.rut
        return fetch(`${urlapi}/conductores`,{
            method:'POST',
            body: JSON.stringify(usuario_crear),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            setProcesados(progreso)
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                setErrores([...[], ...[res.errorMessage]])
                return false
            } else if(res._id){
                return res._id
            }
        })
        .catch(error => {
            return false
        })
    }
    
    const handleCloseAsignacion = () => setModalAsignacion(false)

    const mostrarErrores = () => {
        return <Dialog   style={{ padding: 30 }} open={showModalErrores} onClose={() => {
            setShowModalErrores(false)
            setErrores([...[], ...[]])
        }}>
            <DialogContent>
            <DangerousIcon style={{ fontSize: 40, color:"red" }} />
        <Typography variant='h4' fontSize={29} className="mt-0 mb-0"> Errores encontrados</Typography>
        <Typography variant='h4'fontSize={16} className="mt-0 mb-3">Encontramos los siguientes errores al validar la información que intentas cargar, corrígelos y vuelve a intentarlo</Typography>

            <List>
                {
                    errores.map((error,i) => {
                        return <ListItem key={`i-${i}`}>
                        <ListItemText primary={error} />
                      </ListItem>
                    })
                }
            </List>
            </DialogContent>
        </Dialog>
    }

    const handleClose = () => {
        return setModalProgess(false)
    }

    const mostrarModalProgreso = () => {
        return <Dialog  style={{ textAlign: "center", padding: 30 }} open={modalProgress} onClose={()=>handleClose()}>
            <DialogContent>
        <CircularProgress />
        <Typography  className="mt-0 mb-0"> Cargando tu información</Typography>
        <Typography  className="mt-0 mb-0">{procesados} procesados de {datos.length}</Typography>
        <Typography  className="mb-0 mt-0">No cierres la ventana por favor...</Typography>
        </DialogContent>
        </Dialog>
    }

    const asignarLlaveExcelADatos = (key) => {
        const instancia_datos = JSON.parse( JSON.stringify(datos))
        console.log(key,llaveAsignar,datosExcelSinProcesar,instancia_datos)
        const nuevo_arreglo = instancia_datos.map((dato,i) => {
            dato[llaveAsignar] = datosExcelSinProcesar[i][key]
            return dato
        })
        setDatos([...[], ...nuevo_arreglo])
        return setModalAsignacion(false)
    }
    
    const mostrarModalAsignacion = () => {
        return <Dialog  style={{ textAlign: "center", padding: 30 }} open={modalAsignacionColumna}  onClose={handleCloseAsignacion}>
            <DialogContent>
        <Typography level={2} className="mt-0 mb-2"> Asigna una columna de tu excel al campo {obtenerNombrePorLlaveIdioma(llaveAsignar)}</Typography>
        {
            columnasExcel.map(key => {
                return <Button style={{ margin: 4}} key={key} onClick={() => asignarLlaveExcelADatos(key)} >{key}</Button>
            })
        }
        </DialogContent>
        </Dialog>
    }

    const abrirAsignacion = (key) => {
        setLlaveAsignar(key)
        return setModalAsignacion(true)
    }

    const EncabezadoPesonalizado = ({ titulo, campo, helptext }) => {
        
        if(helptext) {
            return <Tooltip title={helptext} placement='top'>
           <div>
           <Typography className="mb-0 mt-0">{titulo} <HelpIcon style={{ fontSize: 14 }} /></Typography>
            <Button size="small" style={{ fontSize: 7 }} onClick={() => abrirAsignacion(campo)} variant="outlined" >CAMBIAR ASIGNACIÓN COLUMNA</Button>
           </div>
            </Tooltip>
        }

        return <div>
            <Typography className="mb-0 mt-0">{titulo}</Typography>
            <Button size="small" style={{ fontSize: 7 }} onClick={() => abrirAsignacion(campo)} variant="outlined" >CAMBIAR ASIGNACIÓN COLUMNA</Button>
        </div>
    }

    const handleChange = (e,pos) => {
        const { name, value } = e.target
        console.log({ name, value, pos })
        datos[pos][name] = value
        return setDatos([...[], ...datos])
      }

    const mostrarDatos = () => {
        if(datos.length < 1) return false

        return <div className="mt-3">
            <Box p={2}>
            <Typography variant='h5' className="mt-0 mb-2">Datos cargados</Typography>
            <LoadingButton size="small" variant="contained" loading={validando} onClick={() => subirInformacion()} >SUBIR INFORMACIÓN</LoadingButton>

            </Box>
            <Divider />
            <TableContainer>
            <Table>


            <TableHead>
                <TableRow>
                    <TableCell><EncabezadoPesonalizado campo="nombres"      titulo={obtenerNombrePorLlaveIdioma("nombres")}     helptext={obtenerTextoAyudaPorLlaveIdioma('nombres')} /></TableCell>
                    <TableCell><EncabezadoPesonalizado campo="apellido_p"   titulo={obtenerNombrePorLlaveIdioma("apellido_p")}  helptext={obtenerTextoAyudaPorLlaveIdioma('apellido_p')} /></TableCell>
                    <TableCell><EncabezadoPesonalizado campo="apellido_m"   titulo={obtenerNombrePorLlaveIdioma("apellido_m")}  helptext={obtenerTextoAyudaPorLlaveIdioma('apellido_m')} /></TableCell>
                    <TableCell><EncabezadoPesonalizado campo="rut"          titulo={nombreDNIPorPais(pais)}                     helptext={obtenerTextoAyudaPorLlaveIdioma('rut')} /></TableCell>
                    <TableCell><EncabezadoPesonalizado campo="email"        titulo={obtenerNombrePorLlaveIdioma("email")}       helptext={obtenerTextoAyudaPorLlaveIdioma('email')} /></TableCell>
                    <TableCell><EncabezadoPesonalizado campo="phone"        titulo={obtenerNombrePorLlaveIdioma("phone")}       helptext={obtenerTextoAyudaPorLlaveIdioma('phone')} /></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    datos.map((data,p) => {
                        return <TableRow key={`tab-${p}`}>
                            <TableCell>
                            <TextField
                                status={!data.nombres ? "error": ""} 
                                name="nombres" 
                                value={data.nombres} 
                                onChange={(e) => handleChange(e,p)} 
                                InputProps={{
                                    startAdornment: !data.nombres ? (
                                      <InputAdornment position="start">
                                       <ErrorIcon style={{ color: "red"}} />
                                      </InputAdornment>
                                    ) : <InputAdornment position="start">
                                        <CheckCircleIcon style={{ color: "green"}} />
                                    </InputAdornment>
                                }}
                            />
                            </TableCell>
                            <TableCell>
                            <TextField 
                                status={!data.apellido_p ? "error": ""} 
                                name="apellido_p" 
                                value={data.apellido_p} 
                                onChange={(e) => handleChange(e,p)} 
                                InputProps={{
                                    startAdornment: !data.apellido_p ? (
                                      <InputAdornment position="start">
                                       <ErrorIcon style={{ color: "red"}} />
                                      </InputAdornment>
                                    ) : <InputAdornment position="start">
                                        <CheckCircleIcon style={{ color: "green"}} />
                                    </InputAdornment>
                                }}
                            />
                            </TableCell>
                            <TableCell>
                            <TextField 
                                status={!data.apellido_m ? "error": ""} 
                                name="apellido_m" 
                                value={data.apellido_m} 
                                onChange={(e) => handleChange(e,p)} 
                                InputProps={{
                                    startAdornment: !data.apellido_m ? (
                                      <InputAdornment position="start">
                                       <ErrorIcon style={{ color: "red"}} />
                                      </InputAdornment>
                                    ) : <InputAdornment position="start">
                                        <CheckCircleIcon style={{ color: "green"}} />
                                    </InputAdornment>
                                }}
                            />
                            </TableCell>
                            <TableCell>
                            <TextField 
                                status={!data.rut ? "error": ""} 
                                name="rut" 
                                value={data.rut} 
                                onChange={(e) => handleChange(e,p)} 
                                InputProps={{
                                    startAdornment: !data.rut ? (
                                      <InputAdornment position="start">
                                       <ErrorIcon style={{ color: "red"}} />
                                      </InputAdornment>
                                    ) : <InputAdornment position="start">
                                        <CheckCircleIcon style={{ color: "green"}} />
                                    </InputAdornment>
                                }}
                            />
                            </TableCell>
                            <TableCell>
                            <TextField 
                                status={!data.email ? "error": ""} 
                                name="email" 
                                value={data.email} 
                                onChange={(e) => handleChange(e,p)} 
                                InputProps={{
                                    startAdornment: !data.email ? (
                                      <InputAdornment position="start">
                                       <ErrorIcon style={{ color: "red"}} />
                                      </InputAdornment>
                                    ) : <InputAdornment position="start">
                                        <CheckCircleIcon style={{ color: "green"}} />
                                    </InputAdornment>
                                }}
                            />
                            </TableCell>
                            <TableCell>
                            <TextField 
                                status={!data.phone ? "error": ""} 
                                name="phone" 
                                value={data.phone} 
                                onChange={(e) => handleChange(e,p)} 
                                InputProps={{
                                    startAdornment: !data.phone ? (
                                      <InputAdornment position="start">
                                       <ErrorIcon style={{ color: "red"}} />
                                      </InputAdornment>
                                    ) : <InputAdornment position="start">
                                        <CheckCircleIcon style={{ color: "green"}} />
                                    </InputAdornment>
                                }}
                            />
                            </TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
            </Table>
            </TableContainer>
        </div>
      }

    const render = () => {
      return (
        <div>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link to='/drivers'>Conductores</Link>
            <Enlace underline='hover' color='text.primary'>
              Importar Conductores
            </Enlace>
          </Breadcrumbs>
          <h1 style={{ margin: 0 }}>Importar conductores</h1>
            <Button style={{ marginRight: 10, marginBottom: 10 }}  variant='outlined' onClick={() => handleDownload()}><FilePresentIcon/> DESCARGAR MODELO</Button>
         
            <Card>
                <CardContent>

            <ModalEmpresas titulo="Seleccionar empresa" seleccionarEmpresa={(data) => setEmpresa(data)} />
          {
            empresa ? <div>
              <Card sx={{ p:2, mt: 2, mb:2 }}>
              <Typography mb={0}>Empresa seleccionada</Typography>
              <Typography variant='h4' mb={0}>{empresa.razon_social}</Typography>
              <Button onClick={() => setEmpresa(false)}>REMOVER</Button>
              </Card>
              </div> : false
          }

                {
                     showUploadBox ? <Box>
                        <Typography className="ant-upload-text">Has click o arrastra el archivo a esta sección</Typography>
                        <Typography mb={3} className="ant-upload-hint">Solo está permitida la subida de archivos de tipo excel</Typography>
                        <Button
                            variant="contained"
                            component="label"
                            >
                            CARGAR EXCEL
                            <input
                                type="file"
                                hidden
                                onChange={handleChangeFile}
                            />
                        </Button>
                    </Box> : false
                }
            {mostrarDatos()}
            </CardContent>
            </Card>
            {mostrarErrores()}
            {mostrarModalProgreso()}
            {mostrarModalAsignacion()}
              
        </div>
      );
    };
  
    return <Structure component={render()} />;
  };
  
  export default DriversImport