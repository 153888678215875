import CargandoTabla from './cargando';
import { addMoreDays } from '../../lib/helpers/dates';
import {
  Box,
  Grid,
  Pagination,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  Rating,
  CircularProgress,
} from '@mui/material';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { fechaATexto } from '../../lib/helpers/helpers';
import { Link } from 'react-router-dom';
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { montoMoneda } from '../../lib/helpers/main';
import SelectorConductores from '../../subcomponents/conductores/selector';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ListadoDeCalificaciones = (props) => {
  const initialDate = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };
  const [ selectionRange, setSelectionRange ] = useState(initialDate);
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.miusuario);
  const [ calificaciones, setCalificaciones ] = useState([]);
  const [ loadingCalificaciones, setLoadingCalificaciones ] = useState([]);
  const [ loadingCalificacion, setLoadingCalificacion ] = useState(false);
  const [ conductor, setConductor ] = useState(false);
  const [ pagina, setPagina ] = useState(1);
  const [ campoBusqueda, setCampoBusqueda ] = useState('');
  const [ total, setTotal ] = useState(0);
  const [ unaEstrellas, setUnaEstrellas ] = useState(0);
  const [ dosEstrellas, setDosEstrellas ] = useState(0);
  const [ tresEstrellas, setTresEstrellas ] = useState(0);
  const [ cuatroEstrellas, setCuatroEstrellas ] = useState(0);
  const [ cincoEstrellas, setCincoEstrellas ] = useState(0);
  const [ calificacionActual, setCalificacionActual ] = useState(false);
  const [ showModalCalificacion, setShowModalCalificacion ] = useState(false);
  const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {});
  const showFilter = props.showFilter ? props.showFilter : false
  const trash = props.trash ? props.trash : false;
  const tipo = props.tipo ? props.tipo : 'link';
  const titulo = props.title ? props.title : 'registros';

  const paginar = (page, ignorar) => {
    if (ignorar) return false;
    setPagina(page);
    obtenerCalificaciones(page);
  };

  const handleChangePagina = (e, page) => {
    setPagina(page);
    return obtenerCalificaciones(page, condicion_busqueda);
  };

  const paginacion = (ciclo, total) => {
    const cantidad = Math.ceil(total / ciclo);
    if (!cantidad) return false;
    return (
      <div className='mb-3'>
        <Pagination
          sx={{ mt: 2 }}
          color='primary'
          count={cantidad}
          page={pagina}
          onChange={handleChangePagina}
        />
      </div>
    );
  };

  const obtenerCalificaciones = async (page, query) => {
    setLoadingCalificaciones(true);

    if (query) if (trash === true) query.status = 'trash';
    const condicion = query ? query : condicion_busqueda;
    return fetch(`${data.urlapi}/calificaciones/list`, {
      method: 'POST',
      body: JSON.stringify({
        condicion,
        pagina: page,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingCalificaciones(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingCalificaciones(false);
        }
        if (Array.isArray(res.calificacion) !== false) {
          setCalificaciones(res.calificacion);
          setTotal(res.total);
          setUnaEstrellas(res.total_1_estrella)
          setDosEstrellas(res.total_2_estrella)
          setTresEstrellas(res.total_3_estrella)
          setCuatroEstrellas(res.total_4_estrella)
          setCincoEstrellas(res.total_5_estrella)
        }
        return setLoadingCalificaciones(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingCalificaciones(false);
      });
  };

  const resetStars = () => {
    setUnaEstrellas(0);
    setDosEstrellas(0);
    setTresEstrellas(0);
    setCuatroEstrellas(0);
    setCincoEstrellas(0);
  };

  const mapStars = async (calificaciones) => {
    resetStars()
    calificaciones.map((calificacion) => {
      return countStart(calificacion);
    });
  };

  const cambiarRangoFecha = (item) => {
    return setSelectionRange(item.selection);
  };

  useEffect(() => {
    obtenerCalificaciones(1, false);
  }, []);

  const handleDate = (e) => {
    const desde = selectionRange.startDate;
    const hasta = addMoreDays(selectionRange.endDate, 1);
    const condicion = {
      createdAt: { $gte: desde, $lte: new Date(hasta.getTime() - 1) },
      status: 'active',
    };

    obtenerCalificaciones(1, condicion);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!campoBusqueda) return toast.error('Escribe una palabra Clave');
    const condicion = {
      $text: { $search: campoBusqueda.toLowerCase() },
      activo: true,
    };
    setCondicionBusqueda(condicion);
    setPagina(1);
    return obtenerCalificaciones(1, condicion);
  };

  const seleccionarCliente = (cliente) => {
    if (props.seleccionarConductor) props.seleccionarConductor(cliente);
    return false;
  };

  const obtenerCalificacion = async (id) => {
    if(loadingCalificacion) return false
    setLoadingCalificacion(id)
    return fetch(`${data.urlapi}/calificaciones?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingCalificacion(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingCalificacion(false);
        } else if(res._id){
          setCalificacionActual(res)
          setShowModalCalificacion(true)
        }
        return setLoadingCalificacion(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingCalificacion(false);
      });
  }

  const showByType = (type, cliente) => {

    let identificador = 'Sin datos'
    if(typeof cliente === "object"){
      if(typeof cliente.data === "object"){
        if(typeof cliente.data.detallesConductor === "object"){
          identificador = cliente.data.detallesConductor.nombre
        }
      }
    }

    if(loadingCalificacion === cliente._id) return <CircularProgress />

    switch (type) {
      case 'funcion':
        return (
          <b onClick={() => seleccionarCliente(cliente)}>{cliente.titulo}</b>
        );
      case 'modal':
        return <b onClick={() => obtenerCalificacion(cliente._id)}>{identificador}</b>
      case 'link':
        return <b>
          <Link to={`/ratings-${cliente._id}`}>
            {identificador}
          </Link>
        </b>
      default:
        return false
    }
  };

  const countStart = async (rating) => {
    if (rating.destino === condicion_busqueda.destino) {
      switch (rating.numero_calificacion) {
        case 0:
          return;
        case 1:
          return setUnaEstrellas(unaEstrellas + 1);
        case 2:
          return setDosEstrellas(dosEstrellas + 1);
        case 3:
          return setTresEstrellas(tresEstrellas + 1);
        case 4:
          return setCuatroEstrellas(cuatroEstrellas + 1);
        case 5:
          return setCincoEstrellas(cincoEstrellas + 1);
        default:
          return;
      }
    }
  }

  const handleModalRF = () => {
    setShowModalCalificacion(false)
  }

  const mostrarDetallesViaje = (trip) => {
    if(!trip) return false
    if(!trip.data) return false
    if(typeof trip.data !== "object") return false

    return <div>
        <Typography sx={{ mb:0 }}>Detalles del viaje</Typography>
        <Typography sx={{ mb:0 }} variant="h4">Viaje {trip.data.detallesViaje.id}</Typography>
        <Typography sx={{ mb:0 }} >Por <Link to={`/client-${trip.data.detallesCliente._id}`}><b>{trip.data.detallesCliente.nombre}</b></Link> </Typography>
        <Typography sx={{ mb:0 }} >Asignado a <Link to={`/driver-${trip.data.detallesConductor._id}`}><b>{trip.data.detallesConductor.nombres}</b></Link> </Typography>
        <Typography sx={{ mb:0 }} >Solicitado el {fechaATexto(trip.data.detallesViaje.fecha_zona_horaria_local)}</Typography>
        <Typography sx={{ mb:0 }} ><b>{trip.data.detallesViaje.modo_programacion}</b></Typography>
        <Card>
          <CardContent>
            <Typography sx={{ mb:0 }} variant="h5">Detalles de la tarifa</Typography>
            {trip.data.detallesViaje.detalle_tarifa.icon ? <img style={{ width: "100%" }} src={trip.data.detallesViaje.detalle_tarifa.icon} /> : false }
            <Typography sx={{ mb:0 }}>Vehículo <b>{trip.data.detallesViaje.detalle_tarifa.vehiculo}</b></Typography>
            <Typography sx={{ mb:0 }}>Precio $<b>{montoMoneda(trip.data.detallesViaje.detalle_tarifa.precio_total)}</b></Typography>
            <Typography sx={{ mb:0 }}>Distancia <b>{trip.data.detallesViaje.detalle_tarifa.distancia_km} KM.</b></Typography>

          </CardContent>
        </Card>
    </div>
  }

  const ModalCalificacion = () => {
    if(!calificacionActual) return false
    return <Dialog
    open={showModalCalificacion}
    fullWidth={true}
    TransitionComponent={Transition}
    keepMounted
    maxWidth="sm"
    onClose={()=>handleModalRF()}
  >
    <DialogTitle>
     Detalle de calificación
    </DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={8}>
        <Typography>{calificacionActual.status === "done" ? "El usuario calificó" : "El usuario aún no ha completado esta calificación"}</Typography>
        <Rating value={calificacionActual.numero_calificacion} size="large" readOnly />
        <Typography>{fechaATexto(calificacionActual.fecha_creacion)}</Typography>
      {
        calificacionActual.detalles.map((detail,id) => {
          return <div key={`deta-${id}`}>
            <Typography variant='h5' sx={{ mb: 1, fontWeight: "bold" }}>{detail.titulo}</Typography>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              {
                detail.valores.map((valor,iv) => {
                  return <Grid item >
                    <Card variant="outlined" >
                      <CardContent>
                        <Typography sx={{ mb:0 }}>{valor.selected ? <CheckCircleIcon /> : false} {valor.valor}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                })
              }
            </Grid>
          </div>
        })
      }
        </Grid>
        <Grid item xs={4}>
          {mostrarDetallesViaje(calificacionActual)}
        </Grid>
      </Grid>
    </DialogContent>
    </Dialog>
}

  const handleChangeCampo = (e) => {
    const { value } = e.target;
    return setCampoBusqueda(value);
  };

  const onChangeValueConductor = (e) => {
    if(!e.value){
      setCondicionBusqueda({})
      return obtenerCalificaciones(1, {  })
    }
    setCondicionBusqueda({ id_conductor: e.value })
    obtenerCalificaciones(1, { id_conductor: e.value })
  }

  const mostrarFiltro = () => {
    if(!showFilter) return false 
    return <Grid container>
    <Grid item xs={12}>
      <Box
        component='form'
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 1, mb: 1 }}
      >
        <TextField
          id='outlined-basic'
          label='Buscar por Comentario'
          variant='outlined'
          onChange={handleChangeCampo}
          fullWidth
        />
      </Box>
    </Grid>
    <DateRangePicker
      locale={es}
      showSelectionPreview={true}
      editableDateInputs={true}
      onChange={(item) => cambiarRangoFecha(item)}
      moveRangeOnFirstSelection={false}
      ranges={[selectionRange]}
      direction='vertical'
      scroll={{ enabled: true }}
      months={1}
    />
    <Button
      variant='contained'
      color='success'
      xs={{ m: 0 }}
      onClick={() => handleDate()}
    >
      BUSQUEDA
    </Button>
  </Grid>
  }

  if (loadingCalificaciones) return <CargandoTabla />;
  return (
    <div>
      {mostrarFiltro()}
      <Grid container spacing={2} sx={{ width: "100%" }}>
        <Grid item md={12}>
          <Typography variant='h6' sx={{ mb:1}}>Filtrar por conductor</Typography>
        <SelectorConductores onChangeValue={(e) => onChangeValueConductor(e)} />
        </Grid>
        <Grid item ><Card >
          <CardContent>
          <Typography sx={{ fontSize: 14 }} gutterBottom>1 Estrella</Typography>
              <Rating value={1} size="small" readOnly />
            <Typography variant='h5' component='div'>
              {unaEstrellas}
            </Typography>
          </CardContent>
        </Card>
        </Grid>
        <Grid item ><Card>
          <CardContent>
          <Typography sx={{ fontSize: 14 }} gutterBottom>2 Estrellas</Typography>
            <Rating value={2} size="small" readOnly />
            <Typography variant='h5' component='div'>
              {dosEstrellas}
            </Typography>
          </CardContent>
        </Card>
        </Grid>
        <Grid item >
          <Card >
          <CardContent>
          <Typography sx={{ fontSize: 14 }} gutterBottom>3 Estrellas</Typography>
          <Rating value={3} size="small" readOnly />
            <Typography variant='h5' component='div'>
              {tresEstrellas}
            </Typography>
          </CardContent>
        </Card>
        </Grid>
        <Grid item ><Card >
          <CardContent>
          <Typography sx={{ fontSize: 14 }} gutterBottom>4 Estrellas</Typography>
          <Rating value={4} size="small" readOnly />
            <Typography variant='h5' component='div'>
              {cuatroEstrellas}
            </Typography>
          </CardContent>
        </Card>
        </Grid>
        <Grid item >
          <Card>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} gutterBottom>5 Estrellas</Typography>
            <Rating value={5} size="small" readOnly />
            <Typography variant='h5' component='div'>
              {cincoEstrellas}
            </Typography>
          </CardContent>
        </Card>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          {paginacion(data.pagina, total)}
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <h3 sx={{ m: 0 }}>
            {total} {titulo}
          </h3>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label='sticky table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Conductor</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Calificacion</TableCell>
              <TableCell>Fecha</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              calificaciones.map((datos) => {
                return <TableRow
                key={datos._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{showByType(tipo, datos)}</TableCell>
                <TableCell>{datos.data.detallesCliente.nombre}</TableCell>
                <TableCell><Rating value={datos.numero_calificacion} size="large" readOnly /></TableCell>
                <TableCell>{fechaATexto(datos.createdAt)}</TableCell>
              </TableRow>
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <ModalCalificacion />
    </div>
  );
};

export default ListadoDeCalificaciones;
