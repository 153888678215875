const obtenerIdentificadorCliente = (cliente) => {
    const default_texto = 'Sin información'
    let texto = ''
    if(!cliente) return default_texto
    if(typeof cliente !== 'object') return default_texto
    if(cliente.nombre) texto = cliente.nombre
    if(cliente.apellido) texto = `${texto} ${cliente.apellido}`
    return texto
}

const obtenerTelefonoCliente = (cliente) => {
    const default_texto = 'Sin información'
    let texto = ''
    if(!cliente) return default_texto
    if(typeof cliente !== 'object') return default_texto
    if(cliente.movil) texto = cliente.movil
    return texto
}

module.exports = {
    obtenerIdentificadorCliente,
    obtenerTelefonoCliente
}