import Structure from "../Structure"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/session";
import { url_images, urlapi } from "../../lib/backend/data";
import { DateTime } from "luxon";
import { formatoMoneda } from "../../lib/helpers/main";
import { BiStats } from "react-icons/bi";
import { Box, Card, CardContent, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Tag } from "@mui/icons-material";
import GraficoDona from "../../subcomponents/general/grafico_dona";
import toast from "react-hot-toast";
import MapaCalorPuntos from "../Mapas/mapa_calor_puntos";
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import ModalEmpresas from "../Empresas/modal_seleccion";
import ModalConductores from "../Drivers/modal_seleccion";

const ViajesReporte = (props) => {
    const initialDate = {
        from: DateTime.now().startOf("day").minus({ days: 8 }),
        to: DateTime.now().endOf("day"),
    }
    const dateFormat = 'YYYY-MM-DD'
    const [ loading, setLoading ] = useState(false)
    const [ empresa, setEmpresa ] = useState(false);
    const [ conductor, setConductor] = useState(false);
    const [ total, setTotal ] = useState(0)
    const [ estados, setEstados ] = useState([])
    const [ equipos, setEquipos ] = useState([])
    const [ typeVisualization, setVisualization ] = useState('empresa')
    const [ catEquipos, setCatEquipos ] = useState([])
    const [ subcatEquipos, setSubCatEquipos ] = useState([])
    const [ marcadores, setMarcadores ] = useState([])
    const [ conductores, setConductores ] = useState([])
    const [ vehiculos, setVehiculos ] = useState([])
    const [ zonas, setZonas ] = useState({})
    const [ dateRange, setDateRange ] = useState(initialDate)
    const [ configVisualizacion, setConfigVisualizacion ] = useState('')
    const [ rangoFecha, setRangoFecha ] = useState([
        DateTime.now().toUTC().startOf("day").minus({ days: 8 }).toJSDate(), DateTime.now().toUTC().endOf("day").toJSDate(),
    ])
    const idioma                    = useSelector(state => state.idioma)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const [coleccion_ordenes, setColeccionOrdenes] = useState({
        type: 'FeatureCollection',
        features: []
      })
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const module = 'viajes'

    useEffect(() => {
        // consultarDatos()
    }, [])

    const validationSearch = (cond, propietario, id_conductor) => {
        if(typeVisualization === "empresa"){
            return { ...cond, propietario }
        } else if(typeVisualization === "conductores"){
            return { ...cond, id_conductor }
        }
        return false
    }
    const consultarDatos = async (condicion_fechas, rut, id_conductor)=>{
        setConfigVisualizacion('')
        let enviar_condicion = condicion_fechas ? condicion_fechas : { createdAt: { $lte: dateRange.to.toUTC().toISO(), $gte: dateRange.from.toUTC().toISO() } }
        const propietario = rut ? rut : empresa?.rut
        const id_driver = id_conductor ? id_conductor : conductor?._id
        const query_validated = validationSearch(enviar_condicion, propietario, id_driver)
        if(!query_validated) return toast.error('Modifica tu búsqueda')
        setLoading(true)
        return fetch(`${urlapi}/${module}/report`,{
            method:'POST',
            body: JSON.stringify({
                condicion: query_validated
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.data){
                if(res.data.estados) setEstados(res.data.estados)
                setTotal(res.data.total)
                let markers = []

                if(res.data.zonas)                              setZonas(res.data.zonas)
                if(res.data.conductores)                        setConductores(res.data.conductores)
                if(res.data.vehiculos)                          setVehiculos(res.data.vehiculos)
                if(res.data.equipos)                            setEquipos(res.data.equipos)
                if(res.data.categorias_equipos)                 setCatEquipos(res.data.categorias_equipos)
                if(res.data.subcategorias_equipos)              setSubCatEquipos(res.data.subcategorias_equipos)

                const llaves_zonas = Object.keys(res.data.zonas)
                if(llaves_zonas.length > 0) setConfigVisualizacion(llaves_zonas[0])

                if(res.data.paradas){
                    if(Array.isArray(res.data.paradas) !== false){
                        const puntos = res.data.paradas.map(ord => {
                            if (!ord.start_point) return false
                            if (typeof ord.start_point !== "object") return false
                            if (!ord.start_point.type) return false
                            if (!ord.start_point.coordinates) return false
                            if (Array.isArray(ord.start_point.coordinates) !== true) return false
                            if (ord.start_point.coordinates.length < 2) return false
                            if (ord.start_point.coordinates.filter(c => !c).length > 0) return false

                            markers.push({ lat:ord.start_point.coordinates[1], lng: ord.start_point.coordinates[0] })
                            return {
                              type: "Feature",
                              properties: {},
                              geometry: {
                                type: ord.start_point.type,
                                coordinates: ord.start_point.coordinates
                              }
                            }
                          }).filter(p => p)

                          const newFeatures = {
                            type: 'FeatureCollection',
                            features: puntos
                          }
                          setColeccionOrdenes({ ...{}, ...newFeatures })
                
                    }
                }
                setMarcadores([...[], ...markers])
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    
    const handleChangeDate = (e) => {
        if(e){
            const from = DateTime.fromJSDate(e[1]).endOf("day")
            const to = DateTime.fromJSDate(e[0]).setZone("utc").startOf("day")
            let cond = { $lte: from.toISO(), $gte: to.toISO() }

            return setDateRange(prev => {
                let actual = {...prev}
                actual.from = from
                actual.to = to

                const condicion = { createdAt: cond }
                consultarDatos(condicion)
                return actual
            })
        }
    }

    const mostrarVehiculos = () => {
        const tabla = <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
            <TableHead>
            <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Cantidad</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                {
                    vehiculos.map((vehiculo,iv) => {
                        return <TableRow key={`veh-${iv}`}>
                            <TableCell>{vehiculo?.titulo.toString()}</TableCell>
                            <TableCell>{formatoMoneda(vehiculo.total)}</TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
        </TableContainer>
        const datos = vehiculos.length > 0 ? tabla : false

        return <div>
            
                    <Typography  variant="h5" fontWeight={"bold"}>Vehículos utilizados</Typography>
                    <Chip label="Se muestra la cantidad de vehículos asignados por conductor" />
                    <Grid container><Grid item md={4}><GraficoDona datos={vehiculos} /></Grid></Grid>
                    {datos}
        </div>
    }
    const mostrarConductores = () => {

        const tabla = <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
            <TableHead>
            <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Cantidad</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                {
                    conductores.map((vehiculo,iv) => {
                        return <TableRow key={`veh-${iv}`}>
                            <TableCell>{vehiculo?.titulo.toString()}</TableCell>
                            <TableCell>{formatoMoneda(vehiculo.total)}</TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
        </TableContainer>
        const datos = conductores.length > 0 ? tabla : false

        return <div>
                <Typography  variant="h5" fontWeight={"bold"}>Conductores gestionados</Typography>
                <Chip label="Se muestra la cantidad de viajes asignados por conductor" />
                <Grid container><Grid item md={4}><GraficoDona datos={conductores} /></Grid></Grid>
                {datos}
        </div>
    }
    
    const mostrarEquipos = () => {

        const tabla = <TableContainer>
        <Table showHeader={false}   size="small" dataSource={equipos} pagination={false} >
            <TableHead>
            <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Cantidad</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {
                equipos.map(equipo => {
                    return <TableRow key={`equipo-${equipo._id}`}>
                        <TableCell>{equipo?.titulo.toString()}</TableCell>
                        <TableCell>{formatoMoneda(equipo.total)}</TableCell>
                    </TableRow>
                })
            }
            </TableBody>
        </Table>
        </TableContainer>
        const datos = equipos.length > 0 ? tabla : false

        return <div>
                        <Typography  variant="h5" fontWeight={"bold"}>Equipos utilizados</Typography>
                        <Chip label="Se muestra la cantidad de viajes asignados por equipo" />
                        <Grid container><Grid item md={4}><GraficoDona datos={equipos} /></Grid></Grid>
                        {datos}
        </div>
    }
    
    const mostrarCatEquipos = () => {

        const tabla = <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
            <TableHead>
            <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Cantidad</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                {
                    catEquipos.map((vehiculo,iv) => {
                        return <TableRow key={`veh-${iv}`}>
                            <TableCell>{vehiculo?.titulo.toString()}</TableCell>
                            <TableCell>{formatoMoneda(vehiculo.total)}</TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
        </TableContainer>
        const datos = catEquipos.length > 0 ? tabla : false

        return <div>
                    <Typography  variant="h5" fontWeight={"bold"}>Categorías de Equipos utilizados</Typography>
                        <Chip label="Se muestra la cantidad de viajes asignados por categoría de equipos" />
                        <Grid container><Grid item md={4}><GraficoDona datos={catEquipos} /></Grid></Grid>
                        {datos}
        </div>
    }
    const mostrarSubCatEquipos = () => {
        const tabla = <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
            <TableHead>
            <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Cantidad</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                {
                    subcatEquipos.map((vehiculo,iv) => {
                        return <TableRow key={`veh-${iv}`}>
                            <TableCell>{vehiculo?.titulo.toString()}</TableCell>
                            <TableCell>{formatoMoneda(vehiculo.total)}</TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
        </TableContainer>
        const datos = subcatEquipos.length > 0 ? tabla : false

        return <div>
                        <Typography  variant="h5" fontWeight={"bold"}>Sub categorías de Equipos utilizados</Typography>
                        <Chip label="Se muestra la cantidad de viajes asignados por sub categoría de equipos" />
                        <Grid container><Grid item md={4}><GraficoDona datos={subcatEquipos} /></Grid></Grid>
                        {datos}
        </div>
    }

    const mostrarReportePorZona = () => {
        if(!zonas) return false
        if(typeof zonas !== "object") return false

        const opciones = Object.keys(zonas).map(key => {
            return {
                value: key,
                label: zonas[key]?.titulo
            }
        })

        let registros = configVisualizacion ? zonas[configVisualizacion].data : []

        return <div>
                <FormControl fullWidth className='mb-3'>
                <InputLabel>Tipo de búsqueda</InputLabel>
                <Select defaultValue={configVisualizacion} onChange={(e) => e?.target?.value ? setConfigVisualizacion(e?.target?.value) : null } >
                    {
                        opciones.map((op,io) => {
                            return <MenuItem value={op.value}>{op.label}</MenuItem>
                        })
                    }
                </Select>
                </FormControl>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
                        <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Cantidad</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                registros.map((vehiculo,iv) => {
                                    return <TableRow key={`veh-${iv}`}>
                                        <TableCell>{vehiculo.name}</TableCell>
                                        <TableCell>{formatoMoneda(vehiculo.total)}</TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    </TableContainer>
        </div>
    }

    const LoadingScreen = (props) => {
        return <Box sx={{ textAlign: "center" }}>
            <img src={`${url_images}/Smart Delivery Tracking.gif`} style={{ height: 200 }} />
            <Typography variant="h4" fontWeight={"Bold"}>Cargando reporte</Typography>
        </Box>
    }

    const mostrarDatos = () => {
        if(loading) return <LoadingScreen />

        const items = [
            mostrarConductores,
            mostrarVehiculos,
            mostrarEquipos,
            mostrarCatEquipos,
            mostrarSubCatEquipos
        ]

        const seccion_mapa = <Card size="small" sx={{ mb:2  }} >
        <CardContent>
        <Typography  variant="h5" fontWeight={"bold"}>Mapa de calor</Typography>
        <Chip sx={{ mb:2 }} label="Se muestran los puntos registrados en tus viajes, bien sean de recogida o entrega" />
        <Box sx={{ mb: 2 }}><MapaCalorPuntos height={300} markers={marcadores} coleccion={coleccion_ordenes} /></Box>
        {mostrarReportePorZona()}
        </CardContent>
        </Card>
        const mapa = marcadores.length > 0 ? seccion_mapa : false

        return <div>
            <Card size="small" sx={{ mb:2, mt:2  }} >
            <CardContent>
            <Chip label={`${total} viajes analizados en total`} />
            <Chip label="El sistema puede analizar por solicitud hasta 5.000 registros, tómalo en cuenta al seleccionar las fechas" />
            {/* <Typography className="mt-0 mb-0">Por estado</Typography> */}

                    <Grid container spacing={3} sx={{ mt: 1 }}>
                        {
                            estados.map((est,ie) => {
                                return <Grid item md={2} key={`est-${ie}`}>
                                    <Card >
                                    <CardContent>
                                        <Typography style={{ fontSize: 11 }} className="mt-0 mb-0">{est.titulo}</Typography>
                                        <Typography variant="h4" className="mt-0 mb-0">{est.total}</Typography>
                                    </CardContent>
                                    </Card>
                                </Grid>
                            })
                        }
                    </Grid>
            <Grid container><Grid item md={4}><GraficoDona datos={estados} /></Grid></Grid>
            </CardContent>
            </Card>
            {mapa}
            {
                items.map((item,i) => <Card key={`iem-${i}`} sx={{ mb: 2 }} >
                    <CardContent>{item()}</CardContent>
                </Card> )
            }
            
        </div>
    }

    const showByVisualizationType = () => {
        if(typeVisualization === 'empresa'){
            return <Box>
                <Typography className="mb-0 mt-0">Selecciona la empresa a visualizar</Typography>
                    <ModalEmpresas seleccionado={empresa}  showSeleccionado={true} titulo={ "Filtrar empresa" } seleccionarEmpresa={(data) => {
                        setEmpresa(data)
                        let cond = { $lte: dateRange.from, $gte: dateRange.to }
                        const condicion = { createdAt: cond }            
                        setTimeout(() => consultarDatos(condicion, data?.rut), 100)
                    }} />
            </Box>
        } else if(typeVisualization === 'conductores'){
            return <Box>
                <Typography className="mb-0 mt-0">Selecciona el conductor a visualizar</Typography>
                <ModalConductores seleccionado={conductor} titulo="SELECCIONAR" seleccionarConductor={data => {
                    setConductor(data)
                    let cond = { $lte: dateRange.from, $gte: dateRange.to }
                    const condicion = { createdAt: cond }            
                    setTimeout(() => consultarDatos(condicion, null, data?._id), 100)
                }} />
            </Box>
        }
    }

    const changeDestination = (val) => {
        setEmpresa(false)
        setConductor(false)
        setVisualization(val)
    }
    
    const render = () => {

        return <div style={{ backgroundColor: "#f3f3f3", padding: 20 }}>
            <Typography variant="h4" fontWeight={"bold"} sx={{ mb:1 }}><BiStats /> Reporte de Viajes</Typography>
            {/* <RangePicker
                defaultValue={[dayjs(initialDate.from.toFormat('yyyy-MM-dd'), dateFormat), dayjs(initialDate.to.toFormat('yyyy-MM-dd'), dateFormat)]}
                format={dateFormat}
                onChange={handleChangeDate}
                /> */}
                <Grid container spacing={2} xs={{ mb: 2 }}>
                    
                    <Grid item xs={2}>
                        <FormControl fullWidth className='mb-3'>
                        <InputLabel>Visualizar</InputLabel>
                        <Select value={typeVisualization} onChange={(e) => e?.target?.value ? changeDestination(e?.target?.value) : null } >
                            <MenuItem value="empresa">Empresa</MenuItem>
                            <MenuItem value="conductores">Conductores</MenuItem>
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        {showByVisualizationType()}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className="mb-0 mt-0">Selecciona el rango de fechas</Typography>
                        <DateRangePicker onChange={handleChangeDate} value={rangoFecha} />
                    </Grid>
                </Grid>
                
                {mostrarDatos()}
        </div>
    }
    
    return <Structure component={render()} />
}

export default ViajesReporte