import { useDispatch, useSelector } from "react-redux";
import { urlapi } from "../../../lib/backend/data";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { cerrarSesion } from "../../../redux/actions/session";
import Select from "react-select";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

const SelectorColor = (props) => {
    const {
        otherProps,
        value,
        defaultValue
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ opcionesComplete, setOpcionesComplete ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const pais                    = useSelector(state => state.pais)
    const idioma                  = useSelector(state => state.idioma)
    const session                 = useSelector(state => state.miusuario)
    const dispatch                = useDispatch()
    const other_props             = otherProps ? otherProps : {}

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/color/list/select`, {
          method: 'POST',
          body: JSON.stringify({}),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setOpcionesComplete(res)
              setOpciones(res.filter(m => m.titulo).map(op => ({ value: op._id, label: op.titulo.toUpperCase() })))
            }
            return setLoading(false);
          })
          .catch((error) => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }

      const onChangeValue = (e, data) => {
        if(!data){
          if(props.onChange) props.onChange(false)
          return false
        }
        const i = opcionesComplete.findIndex(m => m._id === data.value)
        
        if(props.onChange) props.onChange(opcionesComplete[i])
      }

      useEffect(() => {
        obtenerDatos()
      }, [])

      let valor = null
      if(value) {
        const i = opciones.findIndex(e => e.value === value)
        if(i > -1) valor = opciones[i]
      }

      return <div>
       <Autocomplete
        disablePortal
        value={valor}
        noOptionsText="Sin opciones"
        options={opciones}
        renderInput={(params) => <TextField {...other_props} {...params} InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }} />}
        onChange={onChangeValue}
        />
      </div>
}

export default SelectorColor