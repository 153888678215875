import {
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  InputLabel,
  Grid,
  Select as Selector,
  TextField,
  Typography,
  Card,
  CardActions,
  CardContent,
  Divider,
  Autocomplete,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import ModalEmpresas from '../Empresas/modal_seleccion';
import { extraerValor, siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import { rutas } from '../../lib/data/rutas';
import { key, module_name } from './data';
import { GoDeviceMobile } from 'react-icons/go'
import { GrAdd } from 'react-icons/gr'
import { codigos_area } from '../../lib/data/internacional';
import { AiFillMinusCircle, AiOutlineMail } from 'react-icons/ai';
import { validateEmail } from '../../lib/helpers/helpers';
import SelectorGeneral from '../../subcomponents/general/selector_general';
import { cerrarSesion } from '../../redux/actions/session';
import { maps_key, urlapi } from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { procesarGeoDatosGeocode } from '../../lib/helpers/maps';
import MapsAutoComplete from "react-google-autocomplete";

const DestinatarioCreate = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [vehiculo, setVehiculo] = useState({});
  const [loadingSelect, setLoadingSelect] = useState(true);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectOptionsColors, setSelectOptionsColors] = useState([]);
  const [ empresa, setEmpresa ] = useState(false);
  const [selectOptionsTipo, setSelectOptionsTipo] = useState([]);
  const [conductorSeleccionado, setConductorSeleccionado] = useState(false)
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const sesion = useSelector((state) => state.miusuario);
  const requeridos = [
    { value: 'razon_social', label: 'Razón social' },
    { value: 'rut', label: 'ID Fiscal' },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    vehiculo[name] = value;
    return setVehiculo({ ...{}, ...vehiculo });
  };
  
  const opcionesTipo = (res) => {
    selectOptionsTipo.length = 0;
    res.map((opcion) => {
      if (opcion.status === 'active') {
        return selectOptionsTipo.push({
          value: opcion._id,
          label: opcion.tipo,
        });
      }
    });
    setLoadingSelect(false);
    return selectOptions;
  };

  const handleChangeMovil = (e, pos, name) => {
    let value = e
    if(value){
      if(typeof e === "object") if(e.target) value = e.target.value
    }
    vehiculo.moviles_adicionales[pos][name] = value ? value : ""
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const removerMovil = (i) => {
    vehiculo.moviles_adicionales.splice(i,1)
    return setVehiculo(prev => ({...{}, ...vehiculo})) 
}

const anadirMovilAdicional = () => {
    if(!vehiculo.moviles_adicionales) vehiculo.moviles_adicionales = []
    vehiculo.moviles_adicionales.unshift({
        pais_codigo: '',
        movil: ''
    })
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const anadirEmailAdicional = () => {
    if(!vehiculo.emails_adicionales) vehiculo.emails_adicionales = []
    vehiculo.emails_adicionales.unshift({
        email: ''
    })
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const removerEmail = (i) => {
    vehiculo.emails_adicionales.splice(i,1)
    return setVehiculo(prev => ({...{}, ...vehiculo})) 
}

const handleChangeEmail = (e,pos) => {
    const { name, value } = e.target
    console.log({ name, value, pos})
    vehiculo.emails_adicionales[pos][name] = value
    vehiculo.emails_adicionales[pos].valid = validateEmail(value)
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const mostrarEmailsAdicionales = () => {
    if(!vehiculo.emails_adicionales) return false
    return <div>
        {
            vehiculo.emails_adicionales.map((field,i) => {
                return <div key={`email-${i}`} className="mt-3">
                    <Card  >
                            {/* <Input status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} /> */}
                            <CardContent>
                            <TextField
                              label='Email'
                              error={field.valid === false ? true : false }
                              placeholder="Escribe aquí el email"
                              variant='filled'
                              name='email'
                              required={siEsRequeridoEnEsquema("cargo",requeridos)}
                              fullWidth
                              value={field.email}
                              onChange={(e) => handleChangeEmail(e,i)}
                              helperText="Ingresa un email válido."

                            />
                            </CardContent>
                            <CardActions>
                            <Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>
                            </CardActions>
                    </Card>
                </div>
            })
        }
    </div>
}

const crearVehiculo = async () => {
    
  let faltantes = [];
  requeridos.map((campo) => {
    if (!vehiculo[campo.value]) faltantes.push(campo.label);
    return true;
  });
  if (faltantes.length > 0)
    return toast.error(`Faltan campos: ${faltantes.join(', ')}`);

  setLoading(true);
  if(empresa){
    vehiculo.propietario = empresa.rut
  }
  return fetch(`${urlapi}/${module_name}`, {
    method: 'POST',
    body: JSON.stringify(vehiculo),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer: ${sesion.tokenSession}`,
      'country': pais,
      'lang': idioma
    },
  })
    .then((res) => {
      if (res.status === 401) return dispatch(cerrarSesion());
      return res.json();
    })
    .then((res) => {
      if (!res) {
        toast.error('Sin datos');
        return setLoading(false);
      } else if (res.errorMessage) {
        toast.error(res.errorMessage);
        return setLoading(false);
      } else if (res._id) {
        toast.success('Creado exitosamente');
        setTimeout(() => {
          return (window.location = `${rutas[key].slug}-${res._id}`);
        }, 500);
      }
      return setLoading(false);
    })
    .catch((error) => {
      toast.error('Error al consultar la información, intente nuevamente');
      return setLoading(false);
    });
};



const handleChangeSelect = (e, name) => {
  const valor = extraerValor(e)
  console.log(valor)
  vehiculo[name] = valor
  return setVehiculo({ ...{}, ...vehiculo });
};

const mostrarMovilesAdicionales = () => {

    // const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    if(!vehiculo.moviles_adicionales) return false
    return <div>
        {
            vehiculo.moviles_adicionales.map((field,i) => {
                return <div key={`movil-${i}`} className="mt-3">
                <Card>
                    <CardContent>
                    <Grid container spacing={1}>
                        <Grid item md={4}>
                            <Autocomplete
                              style={{ width: "100%" }} 
                              placeholder="Código de area" 
                              name="pais_codigo" 
                              value={field.pais_codigo} 
                              disablePortal
                              noOptionsText="Sin opciones" 
                              options={codigos_area}
                              renderInput={(params) => <TextField label="Código de área" {...params} />}
                              onChange={(event,e) => handleChangeMovil(e,i,"pais_codigo")} 
                            />
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                              label='Número de teléfono'
                              placeholder="Escribe aquí el número telefónico"
                              variant='filled'
                              name='movil'
                              required={true}
                              fullWidth
                              value={field.movil}
                              onChange={(e) => handleChangeMovil(e,i,"movil")}
                            />
                        </Grid>
                    </Grid>
                    </CardContent>
                    <CardActions>
                    <Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>
                    </CardActions>
                </Card>
                </div>
            })
        }
    </div>
}

  const render = () => {


    let default_color = false

    if(vehiculo.color){
      const i_color = selectOptionsColors.findIndex(m => m.value === vehiculo.color)
      if(i_color > -1 ){
        default_color = selectOptionsColors[i_color]
      }
    }


    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to={rutas[key].slug}>{rutas[key].title}</Link>
          <Enlace underline='hover' color='text.primary'>
            Crear {rutas[key].title}
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Crear {rutas[key].title}</h1>
        <ModalEmpresas titulo="Seleccionar empresa" seleccionarEmpresa={(data) => {
          setEmpresa(data)
          vehiculo.id_usuario = "";
          vehiculo.id_categoria = "";
          vehiculo.id_sub_categoria = "";
          vehiculo.propietario = data.rut
          setConductorSeleccionado(false)
          return setVehiculo(prev => ({...{}, ...vehiculo}))
        }} />
        {
            empresa ? <div>
              <Card sx={{ p:2, mt: 2 }}>
              <Typography mb={0}>Empresa seleccionada</Typography>
              <Typography variant='h4' mb={0}>{empresa.razon_social}</Typography>
              <Button onClick={() => {
                setEmpresa(false)
                vehiculo.id_usuario = "";
                setConductorSeleccionado(false)
                return setVehiculo(prev => ({...{}, ...vehiculo}))
              }}>REMOVER</Button>
              </Card>
              </div> : false
          }
          <Grid container spacing={2}>
        <Grid item md={12} xs={12} mt={2}>
          <Typography variant='h6'><SettingsIcon sx={{ mb: -0.7 }} /> Información general</Typography>
          <CamposObligatoriosLeyenda />
        </Grid>
        
        <Grid item md={3} xs={12}>
          <TextField
            label='Razón social'
            variant='filled'
            name='razon_social'
            required={siEsRequeridoEnEsquema("razon_social",requeridos)}
            defaultValue={vehiculo.razon_social}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='ID Fiscal'
            variant='filled'
            name='rut'
            required={siEsRequeridoEnEsquema("rut",requeridos)}
            fullWidth
            defaultValue={vehiculo.rut}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
        <SelectorGeneral otherProps={{ label: "Categoría" }} value={vehiculo.id_categoria} strict={true} hideLabel={true} module="categorias" condicion_default={{ tipo: "cliente", propietario: { $in: ['admin', vehiculo.propietario ] } }} onChange={data => handleChangeSelect(data, 'id_categoria' )} />
        </Grid>
        <Grid item md={3} xs={12}>
        <SelectorGeneral otherProps={{ label: "Sub Categoría" }} value={vehiculo.id_sub_categoria} strict={true} hideLabel={true} module="sub-categorias" condicion_default={{ tipo: "cliente", id_categoria: vehiculo.id_categoria }} onChange={data => handleChangeSelect(data, 'id_sub_categoria' )} />
        </Grid>
        <Grid item md={9} xs={12}>
          <MapsAutoComplete
                    style={{ height: 57, width: "100%", fontSize: 15, padding: 12 }}
                    defaultValue={vehiculo.direccion}
                    placeholder='Ingresa la dirección'
                    options={{
                        types: ["geocode"],
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                          const geo_data = procesarGeoDatosGeocode(place)
                          return setVehiculo(prev => {
                            // Crea una copia del arreglo de paradas
                            const nuevoObjeto = {...prev}
                            nuevoObjeto.direccion = place.formatted_address
                            nuevoObjeto.geodata = geo_data
                            nuevoObjeto.location = {
                              type: "Point",
                              coordinates: [
                                  geo_data.lng, geo_data.lat
                              ]
                            }
                            return nuevoObjeto;
                        })
                    }}
                    />
        </Grid>
        <Grid item md={24} xs={24}>
                    <Divider />
                    <Grid container gutter={15}>
                        <Grid item md={12}>
                        <h3 ><GoDeviceMobile /> Teléfonos</h3>
                        <Button  variant="outlined" sx={{ mb:2 }} startIcon={<GrAdd />} onClick={() => anadirMovilAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarMovilesAdicionales()}
                        </Grid>
                        <Grid item md={12}>
                        <h3 ><AiOutlineMail /> Emails</h3>
                        <Button variant="outlined" sx={{ mb:2 }} startIcon={<GrAdd />} onClick={() => anadirEmailAdicional()} > AÑADIR NUEVO</Button>
                        {mostrarEmailsAdicionales()}
                        </Grid>
                    </Grid>
                </Grid>
          
          <Grid item md={12} xs={12}>
            { loading === true ? (
              <CircularProgress />
            ) : (
              <Button variant='contained' xs={{ m: 0 }} onClick={() => crearVehiculo()} >CREAR</Button>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };
  
  return <Structure component={render()} />;
};

export default DestinatarioCreate