import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Select as Selector,
  Tab,
  Alert,
  Card,
  Divider,
  IconButton,
  Menu,
  InputAdornment
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, Link, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import PropTypes from 'prop-types';
import SinData from '../General/sin_data';
import SettingsIcon from '@mui/icons-material/Settings';
import ListadoRequisitos from '../Requisitos/listado';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ModalConductores from '../Drivers/modal_seleccion';
import { LoadingButton } from '@mui/lab';
import ModalEmpresas from '../Empresas/modal_seleccion';
import ListadoDeRequisitos from '../RequisitosConfig/listado';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { obtenerUnidadesMedidaPorTipoPais } from '../../lib/helpers';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import BotonEliminar from './boton_eliminar';
import { rutas } from '../../lib/data/rutas';
import SelectorGeneral from '../../subcomponents/general/selector_general';
import SelectorColor from './selectores/color';

const EquipmentDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [loadingSelect, setLoadingSelect] = useState(true);
  const [loadingSelectTipoVeh, setLoadingSelectTipoVeh] = useState(true);
  const [marcas, setMarcas] = useState([]);
  const [selectOptionsColors, setSelectOptionsColors] = useState([]);
  const [empresa, setEmpresa] = useState(false);
  const [selectOptionsTipo, setSelectOptionsTipo] = useState([]);
  const [selectOptionsMedidas, setSelectOptionsMedidas] = useState([
    { value: 'metros', label: 'Metros' },
      { value: 'centimetros', label: 'CM' },
      { value: 'pies', label: 'FT' }
  ]);
  const [selectOptionsMedidasPeso, setSelectOptionsMedidasPeso] = useState([
    { value: 'kilogramos', label: 'KGS' },
      { value: 'libras', label: 'LIBRAS' }
  ]);
  const [loadingSelectModelo, setLoadingSelectModelo] = useState(true);
  const [selectOptionsModelo, setSelectOptionsModelo] = useState([]);
  const [selectOptionsTipoMotor, setSelectOptionsTipoMotor] = useState([]);
  const [loadingSelectMotor, setLoadingSelectMotor] = useState(true);
  const [loading, setLoading] = useState(false);
  const [vehiculo, setVehiculo] = useState([]);
  const [loadingDefinicion, setLoadingDefinicion] = useState(false);
  const [loadingConductores, setLoadingConductores] = useState(true);
  const [opcionesConductores, setOpcionesConductores] = useState([]);
  const [marca, setMarca] = useState('');
  const [permisosOtorgados, setPermisosOtorgados] = useState([]);
  const [selectedTab, setSelectedTab] = useState("1")
  const sesion = useSelector((state) => state.miusuario);
  const [loadintAsignacion, setLoadingAsignacion] = useState(false);
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
  const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
  const unidades_medida_volumen       = obtenerUnidadesMedidaPorTipoPais("volumen", pais).map(e => e)
  const requeridos = [
    { value: 'titulo', label: 'Título' },
  ];
  const module = 'equipos'

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { id } = useParams();

  const obtenerConductores = async () => {
    return fetch(`${data.urlapi}/${module}/list-select`, {
      method: 'POST',
      body: JSON.stringify({ condicion: {} }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (Array.isArray(res) !== false) {
          const opciones = res.map(con => ({ value: con._id, label: `${con.nombres} ${con.apellido_p ? con.apellido_p : ''}` }))
          setOpcionesConductores(opciones)
        }
        return setLoadingConductores(false)
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingConductores(false)
      });
  };

  const handleSelect = (e) => {
    vehiculo.marca = e.value
    vehiculo.modelo = ""
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  }

  const handleSelectModel = (e) => {
    vehiculo.modelo = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectColor = (e) => {
    vehiculo.color = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectConductor = (e) => {
    vehiculo.id_usuario = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectTipo = (e) => {
    vehiculo.id_tipo = e.value;
    vehiculo.tipo = e.label;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectMedida = (e) => {
    vehiculo.unidad_medida = e.label
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectMedidaPeso = (e) => {
    vehiculo.unidad_medida_peso = e.label;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectTipoMotor = (e) => {
    vehiculo.tipo_motor = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const obtenerVehiculo = async () => {
    return fetch(`${data.urlapi}/${module}?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setVehiculo(res);
          if(res.empresa) setEmpresa(res.empresa)
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      })
  };

  const obtenerMarcas = async () => {
    return fetch(`${data.urlapi}/vehiculos/marca/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      }
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if(Array.isArray(res) !== false){
          setMarcas(res.map(op => ({ value: op._id, label: op.titulo })))
        }
        return setLoadingSelect(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelect(false);
      })
  }

  const obtenerTipos = async () => {
    return fetch(`${data.urlapi}/vehiculos/tipo/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if( Array.isArray(res) !== false){
          setSelectOptionsTipo(res.map(op => ({ value: op._id, label: op.tipo })))
        }
        return setLoadingSelectTipoVeh(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelectTipoVeh(false);
      });
  };

  const obtenerTiposMotor = async (id) => {
    return fetch(`${data.urlapi}/tipomotor/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (res.length > 0) {
          opcionesTipoMotor(res);
        } else if (res.length < 1) {
          toast.error('No existen Colores de Vehiculos.');
        }
        return setLoadingSelectMotor(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelectMotor(false);
      });
  };

  const obtenerModelos = async () => {
    return fetch(`${data.urlapi}/vehiculos/modelo/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (Array.isArray(res) !== false) {
          setSelectOptionsModelo(res)
        }
        return setLoadingSelectModelo(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelectModelo(false);
      });
  };

  const obtenerColores = async (id) => {
    return fetch(`${data.urlapi}/color/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (Array.isArray(res) !== false) {
          setSelectOptionsColors(res.map(o => ({ value: o._id, label: o.titulo })))
        }
        return setLoadingSelect(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelect(false);
      });
  };

  const medidas = async () => {
    selectOptionsMedidas.length = 0;
    selectOptionsMedidasPeso.length = 0;
    selectOptionsMedidasPeso.push(
      { value: 'kilogramos', label: 'KGS' },
      { value: 'libras', label: 'LIBRAS' }
    );

    selectOptionsMedidas.push(
      { value: 'metros', label: 'Metros' },
      { value: 'centimetros', label: 'CM' },
      { value: 'pies', label: 'FT' }
    );
  };


  const opcionesModelo = async (res) => {
    selectOptionsModelo.length = 0;
    res.map((opcion) => {
      return selectOptionsModelo.push({
        value: opcion._id,
        label: opcion.titulo,
      });
    });
    vehiculo.modelo = '';
    setLoadingSelectModelo(false);
    return selectOptionsModelo;
  };

  const opcionesColores = (res) => {
    selectOptionsColors.length = 0;
    res.map((opcion) => {
      return selectOptionsColors.push({
        value: opcion._id,
        label: opcion.titulo,
      });
    });
    setLoadingSelect(false);
    return [];
  };

  const opcionesTipoMotor = (res) => {
    selectOptionsTipoMotor.length = 0;
    res.map((opcion) => {
      return selectOptionsTipoMotor.push({
        value: opcion._id,
        label: opcion.titulo,
      });
    });
    setLoadingSelectMotor(false);
    return [];
  };

  useEffect(() => {
    obtenerVehiculo()
    obtenerMarcas()
    obtenerTipos()
    obtenerModelos()
    obtenerColores()
    obtenerTiposMotor()
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    vehiculo[name] = value;
    return setVehiculo({ ...{}, ...vehiculo });
  };

  const extraerValor = (val) => {
    switch (typeof val) {
        case "string":
            return val
        case "object":
            if(val._id){
                return val._id
            } else if(val.value){
                return val.value
            } else if(val.target){
                const { name, value } = val.target
                console.log({name,value})
                return val.target.value
            } 
            return ''
        default:
            return ''
    }
  }

  const handleChangeSelect = (e, name) => {
    const valor = extraerValor(e)
    console.log(valor)
    vehiculo[name] = valor
    return setVehiculo({ ...{}, ...vehiculo });
  };

  const actualizarVehiculo = async () => {
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!vehiculo[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    vehiculo.permisos = permisosOtorgados;

    setLoading(true);
    return fetch(`${data.urlapi}/${module}`, {
      method: 'PUT',
      body: JSON.stringify(vehiculo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const handleChangeTab = (e,val) => {
    return setSelectedTab(val.toString())
  }

  const selectorModelo = (default_modelo) => {
    if(!vehiculo.marca) return false

    const opciones_filtradas = selectOptionsModelo.filter(m => m.id_marca === vehiculo.marca)

    let valorModelo = default_modelo
    if(!vehiculo.modelo){
      valorModelo = false
    }
    
    return <Grid item md={3} xs={12}>
    <InputLabel id="demo-simple-select-label" required={siEsRequeridoEnEsquema("modelo",requeridos)}>Modelo</InputLabel>
      <Select
        options={opciones_filtradas.map(m => ({ value: m._id, label: m.titulo }))}
        isLoading={loadingSelectModelo}
        loadingMessage={() => 'Cargando'}
        noOptionsMessage={() => 'Sin opciones'}
        placeholder='Selecciona un Modelo'
        value={valorModelo}
        onChange={handleSelectModel}
        height={20}
      />
    </Grid>
  }

  const desvincularConductor = () => {
      vehiculo.id_usuario = ''
      vehiculo.detalles_conductor = false
      vehiculo.selected = false
      setTimeout(() => {
        actualizarVehiculo()
      }, 500);
      scollToBootom()
      return setVehiculo({...{},  ...vehiculo})
  }

  const mostrarConductor = () => {
    if(!vehiculo) return false
    if(typeof vehiculo !== "object") return false
    if(!vehiculo.detalles_conductor) return false
    if(typeof vehiculo.detalles_conductor !== "object") return false
    if(!vehiculo.detalles_conductor._id) return false

    return <div>
      <Grid container >
              <Grid item md={10}>
                <Typography variant='h5' className='mb-0'>{vehiculo.detalles_conductor.nombres}</Typography>
              </Grid>
              <Grid item md={2}>
              <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '30ch',
          },
        }}
      >
        <MenuItem key={0} ><Link target="_blank" style={{ textDecoration: "none" }} to={`/driver-${vehiculo.detalles_conductor._id}`}>VER DETALLES</Link></MenuItem>
        <MenuItem key={0} onClick={() => desvincularConductor()}>DESVINCULAR CONDUCTOR</MenuItem>
      </Menu>
              </Grid>
            </Grid>

    </div>
  }

  const seleccionarConductor = (data) => {
    vehiculo.id_usuario = data._id
    vehiculo.detalles_conductor = data
    setTimeout(() => {
        actualizarVehiculo()
    }, 500);
    scollToBootom()
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  }

  const formularioEditar = () => {

    let default_modelo = false
    let default_tipo = false
    
    if(vehiculo.id_tipo){
      const i_tipo_vehiculo = selectOptionsTipo.findIndex(m => m.value === vehiculo.id_tipo)
      if(i_tipo_vehiculo > -1 ){
        default_tipo = { value: selectOptionsTipo[i_tipo_vehiculo].value, label: selectOptionsTipo[i_tipo_vehiculo].label }
      }
    }

    if(vehiculo.modelo){
      const i_model = selectOptionsModelo.findIndex(m => m._id === vehiculo.modelo)
      if(i_model > -1 ){
        default_modelo = { value: selectOptionsModelo[i_model]._id, label: selectOptionsModelo[i_model].titulo }
      }
    }

    let default_marca = false

    if(vehiculo.marca){
      const i_marca = marcas.findIndex(m => m.value === vehiculo.marca)
      if(i_marca > -1 ){
        default_marca = marcas[i_marca]
      }
    }
    
    let default_color = false

    if(vehiculo.color){
      const i_color = selectOptionsColors.findIndex(m => m.value === vehiculo.color)
      if(i_color > -1 ){
        default_color = selectOptionsColors[i_color]
      }
    }
    
    let default_tipo_motor = false

    if(vehiculo.tipo_motor){
      const i_tipo_motor = selectOptionsTipoMotor.findIndex(m => m.value === vehiculo.tipo_motor)
      if(i_tipo_motor > -1 ){
        default_tipo_motor = selectOptionsTipoMotor[i_tipo_motor]
      }
    }

    return <Grid container spacing={2} sx={{ mb: 2 }}>
    <Grid item md={12} xs={12}>
    <ModalEmpresas titulo="Seleccionar empresa" seleccionarEmpresa={(data) => {
      vehiculo.propietario = data.rut
      setEmpresa(data)
      toast.success("Guardar los cambios antes de salir de esta pantalla", { icon: false })
      return setVehiculo(vehiculo)
    }} />
    {
            empresa ? <div>
              <Card sx={{ p:2, mt: 2 }}>
              <Typography mb={0}>Empresa seleccionada</Typography>
              <Typography variant='h4' mb={0}>{empresa.razon_social}</Typography>
              <Button onClick={() => {
                setEmpresa(false)
                vehiculo.propietario = ''
                setVehiculo(vehiculo)
                toast.success("Guardar los cambios antes de salir de esta pantalla", { icon: false })
                }}>REMOVER</Button>
              </Card>
              </div> : false
          }
      <Grid container spacing={2}>
        <Grid item md={12} xs={12} mt={2}>
          <Typography variant='h6'><SettingsIcon sx={{ mb: -0.7 }} /> Información general</Typography>
          <CamposObligatoriosLeyenda />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Título'
            variant='filled'
            name='titulo'
            required={siEsRequeridoEnEsquema("titulo",requeridos)}
            defaultValue={vehiculo.titulo}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Patente'
            variant='filled'
            name='patente'
            required={siEsRequeridoEnEsquema("patente",requeridos)}
            fullWidth
            defaultValue={vehiculo.patente}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Año'
            variant='filled'
            name='ano'
            required={siEsRequeridoEnEsquema("ano",requeridos)}
            fullWidth
            defaultValue={vehiculo.ano}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Ejes'
            variant='filled'
            name='ejes'
            required={siEsRequeridoEnEsquema("ejes",requeridos)}
            fullWidth
            defaultValue={vehiculo.ejes}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Numero de chasis'
            variant='filled'
            name='chasis'
            required={siEsRequeridoEnEsquema("chasis",requeridos)}
            fullWidth
            defaultValue={vehiculo.chasis}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
        <SelectorColor otherProps={{ label: "Color" }} value={vehiculo.color}  strict={true} onChange={data => handleChangeSelect(data, 'color' )} />
        </Grid>
        <Grid item md={3} xs={12}>
        <SelectorGeneral otherProps={{ label: "Categoría" }} value={vehiculo.id_categoria} strict={true} hideLabel={true} module="categorias" condicion_default={{ tipo: "equipo" }} onChange={data => handleChangeSelect(data, 'id_categoria' )} />
        </Grid>
        <Grid item md={3} xs={12}>
        <SelectorGeneral otherProps={{ label: "Sub categoría" }} value={vehiculo.id_sub_categoria} strict={true} hideLabel={true} module="sub-categorias" condicion_default={{ tipo: "equipo" }} onChange={data => handleChangeSelect(data, 'id_sub_categoria' )} />
        </Grid>
        <Grid item md={3} xs={12}>

          <FormControl fullWidth className='mb-3'>
          <InputLabel id="demo-simple-select-label" required={siEsRequeridoEnEsquema("status",requeridos)}>Estado</InputLabel>
          <Selector
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Estado * "
              name="status"
              onChange={handleChange}
              defaultValue={vehiculo.status}
              >
              <MenuItem value="active">Activo</MenuItem>
              <MenuItem value="trash">Desactivado</MenuItem>
          </Selector>
          </FormControl>

          </Grid>

          <Grid item xs={12}>
          <Typography variant='h6'>
              <SettingsIcon sx={{ mb: -0.7 }} /> Capacidad de carga
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>

          <FormControl fullWidth className='mb-3'>
          <InputLabel id="demo-simple-select-label" required={siEsRequeridoEnEsquema("unidad_medida",requeridos)}>Unidad de medida longitud</InputLabel>
          <Selector
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={`Unidad de medida longitud *`}
              name="unidad_medida"
              
              onChange={handleChange}
              defaultValue={vehiculo.unidad_medida}
              >
              <MenuItem value="">Selecciona</MenuItem>
                {unidades_medida.map(e => <MenuItem value={e.code}>{e.unit}</MenuItem>)}
          </Selector>
          </FormControl>

          </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Ancho'
            variant='filled'
            name='ancho'
            required={siEsRequeridoEnEsquema("ancho",requeridos)}
            fullWidth
            onChange={handleChange}
            defaultValue={vehiculo.ancho}
            InputProps={{
              startAdornment: <InputAdornment position="start">{vehiculo.unidad_medida}</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Alto'
            variant='filled'
            name='alto'
            required={siEsRequeridoEnEsquema("alto",requeridos)}
            fullWidth
            defaultValue={vehiculo.alto}
            onChange={handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">{vehiculo.unidad_medida}</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <TextField
            label='Largo'
            variant='filled'
            name='largo'
            required={siEsRequeridoEnEsquema("largo",requeridos)}
            fullWidth
            defaultValue={vehiculo.largo}
            onChange={handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">{vehiculo.unidad_medida}</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6'>
              <SettingsIcon sx={{ mb: -0.7 }} /> Capacidad de carga
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>

          <FormControl fullWidth className='mb-3'>
          <InputLabel id="demo-simple-select-label" required={siEsRequeridoEnEsquema("unidad_medida_peso",requeridos)}>Unidad de medida peso</InputLabel>
          <Selector
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Unidad de medida peso * "
              name="unidad_medida_peso"
              onChange={handleChange}
              defaultValue={vehiculo.unidad_medida_peso}
              >
              <MenuItem value="">Selecciona</MenuItem>
                {unidades_medida_peso.map(e => <MenuItem value={e.code}>{e.unit}</MenuItem>)}
          </Selector>
          </FormControl>

          </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Peso'
            variant='filled'
            name='peso'
            required={siEsRequeridoEnEsquema("peso",requeridos)}
            fullWidth
            type="number"
            defaultValue={vehiculo.peso}
            onChange={handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">{vehiculo.unidad_medida_peso}</InputAdornment>,
            }}
          />
        </Grid>
        {/* <Grid item md={3} xs={12}>

          <FormControl fullWidth className='mb-3'>
          <InputLabel id="demo-simple-select-label">Unidad de medida volumen</InputLabel>
          <Selector
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Unidad de medida volumen"
              name="unidad_medida_volumen"
              required={siEsRequeridoEnEsquema("unidad_medida_volumen",requeridos)}
              onChange={handleChange}
              defaultValue={vehiculo.unidad_medida_volumen}
              >
              <MenuItem value="">Selecciona</MenuItem>
                {unidades_medida_volumen.map(e => <MenuItem value={e.code}>{e.unit}</MenuItem>)}
          </Selector>
          </FormControl>

          </Grid> 
        <Grid item md={3} xs={12}>
          <TextField
            label='Volumen'
            variant='filled'
            name='volumen'
            required={siEsRequeridoEnEsquema("volumen",requeridos)}
            fullWidth
            type="number"
            defaultValue={vehiculo.volumen}
            onChange={handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">{vehiculo.unidad_medida_volumen}</InputAdornment>,
            }}
          />
        </Grid>
        */}
        <Grid item md={12} xs={12}>
          {loading === true ? (
            <CircularProgress />
          ) : (
            <Button
              variant='contained'
              color='success'
              xs={{ m: 0 }}
              onClick={() => actualizarVehiculo()}
            >
              GUARDAR CAMBIOS
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  }

  const cambiarVehiculoConductor = async () => {
    setLoadingDefinicion(true)
    return fetch(`${data.urlapi}/vehiculos/definir-seleccionado`, {
        method: 'POST',
        body: JSON.stringify({
          id: vehiculo._id
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then((res) => {
          if (!res) {
            toast.error('Sin datos');
            return setLoadingDefinicion(false);
          } else if (res.errorMessage) {
            toast.error(res.errorMessage);
            return setLoadingDefinicion(false);
          } else if (res._id) {
            vehiculo.selected = true
            toast.success("Realizado exitosamente")
            setVehiculo(prev => ({...{}, ...vehiculo}));
          }
          return setLoadingDefinicion(false);
        })
        .catch((error) => {
          toast.error('Error al consultar la información, intente nuevamente');
          return setLoadingDefinicion(false);
        })
  }

  const asignarRequisito = async (_id) => {
    setLoadingAsignacion(true)
    return fetch(`${data.urlapi}/requisitos/asignar?id=${_id}&target=${id}`, {
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(res._id){
            toast.success("Agregado exitosamente")
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }
        return setLoadingAsignacion(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setLoadingAsignacion(false)
    })
}

  const seleccionarRequisito = (req) => {
    asignarRequisito(req._id)
  }


  const scollToBootom = () => {
    const container = document.getElementById('main'); // Reemplaza 'container' con el ID de tu contenedor
    if(!container) return false
    container.scrollTo({
      top: container.scrollHeight,
      behavior: 'smooth',
      block: 'end'
    });
  }
  const autoSeleccionado = () => {
    if(!vehiculo.id_usuario) return false
    if(vehiculo.selected !== true) return <LoadingButton
        loading={loadingDefinicion}
        variant="outlined"
        sx={{ mb:2 }}
        onClick={() => cambiarVehiculoConductor()}
    >DEFINIR COMO VEHÍCULO ACTUAL DE ESTE CONDUCTOR</LoadingButton>

    return <div>
      <Alert severity="info" sx={{ mb:2}}>Este vehículo es el vehículo actual de este conductor</Alert>
    </div>
  }

  const render = () => {
    if (loadingMaster) return <CircularProgress />
    if (loadingSelectTipoVeh) return <CircularProgress />
    if (loadingSelect) return <CircularProgress />
    if (loadingSelectMotor) return <CircularProgress />
    if (loadingSelectModelo) return <CircularProgress />
    if (!vehiculo) return <SinData />

    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Href to={rutas.equipments.slug}>{rutas.equipments.title}</Href>
          <Enlace underline='hover' color='text.primary'>Editar Equipo</Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Editar Equipo</h1>
          <BotonEliminar id={id} />
        <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList  onChange={handleChangeTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        >
                        <Tab label="Ficha" value="1" />
                        <Tab label="Requisitos" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                      {autoSeleccionado()}
                      {formularioEditar()}
                    </TabPanel>
                    <TabPanel value="2">
                    <h3>Requisitos cargados</h3>

                      <ListadoRequisitos condicion_default={{ id_target: id }} />
                      <Divider light  />
                    <h3>Otros requisitos</h3>
                    { loadintAsignacion ? <CircularProgress /> : <ListadoDeRequisitos tipo="funcion" typeView="card" seleccionarConductor={(data) => seleccionarRequisito(data)} condicion_default={{ tipo: "equipo" }} /> }

                    </TabPanel>
                    </TabContext>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default EquipmentDetails