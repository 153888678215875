import { Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { stringStatusByStatus } from "../../lib/helpers/viajes"

const FotografiasViaje = (props) => {
    const { 
        fotos
     } = props
     let fotografias = []
     if(Array.isArray(fotos) !== false) fotografias = fotos

     const fotos_cliente = fotografias.filter(f => [null,false,''].includes(f.status) || typeof f.status === "undefined")
     const fotos_conductor = fotografias.filter(f => typeof f.status !== "undefined")

     const mostrarFotos = (images, title) => {
        if(images.length < 1) return false
        return <Box sx={{ mb:3 }}>
            <Typography sx={{ mb: 2 }} variant="h6" >{title}</Typography>
            <Grid container spacing={2} >
            {
                images.map((imagen,im) => {
                    return <Grid item xs={4} key={`foto-${im}`}>
                    <a href={imagen.url} target="_blank"><img src={imagen.url} style={{ width: "100%" }} /></a>
                    {imagen.status ? <Typography sx={{ mb:0 }}>{stringStatusByStatus(imagen.status)}</Typography> : false }
                    {imagen.observaciones ? <Typography sx={{ mb:0, fontSize:14 }}>{imagen.observaciones}</Typography> : false }
                    </Grid>
                })
            }
            </Grid>
        </Box>
     }

     return <div>
        {mostrarFotos(fotos_cliente, 'Fotografías')}
        {mostrarFotos(fotos_conductor, 'Fotografías del conductor')}
     </div>
}

export default FotografiasViaje