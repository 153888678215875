import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MenuConfig from '../Configuracion/menu';
import ListadoDeSeguros from './listado';
import { rutas } from '../../lib/data/rutas';

const MarcaVehiculo = () => {
  const [value, setValue] = useState(0);
  const sesion = useSelector((state) => state.miusuario);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (e, value) => {
    return setValue(value);
  };

  const render = () => {
    return (
      <div>
        <h1 style={{ margin: 0 }}>Configuración</h1>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <MenuConfig />
          </Grid>
          <Grid item md={9}>
            <h1 style={{ margin: 0 }}>Marcas Vehículo</h1>
            <Link to={rutas.vehicles_brands_new.slug}>
              <Button variant='outlined'>CREAR</Button>
            </Link>
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor='primary'
                indicatorColor='primary'
                aria-label='primary tabs example'
              >
                <Tab
                  icon={<CheckCircleOutlineIcon />}
                  iconPosition='start'
                  value={0}
                  label='Activos'
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<DeleteIcon />}
                  iconPosition='start'
                  value={1}
                  label='Archivados'
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <ListadoDeSeguros
                title='activos'
                condicion_default={{ status: { $nin: 'trash' } }}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ListadoDeSeguros
                title='en papelera'
                trash={true}
                condicion_default={{ status: 'trash' }}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default MarcaVehiculo;
