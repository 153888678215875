import CargandoTabla from "./cargando"
import { Box, Card, CardContent, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useEffect, useState } from "react"
import data from '../../lib/backend/data'
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { fechaATexto } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { obtenerIdentificadorCliente } from "../../lib/helpers/clientes"
import { obtenerDireccionDestino } from "../../lib/helpers/viajes"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const ListadoViajes = (props) => {
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [ conteos, setConteos] = useState([])
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const trash = props.trash ? props.trash : false
    const titulo = props.title ? props.title : 'registros'
    const tipo = props.tipo ? props.tipo : "funcion"
    const showCounter = props.showCounter === true ? true : false
    const open_new_tab = props.open_new_tab ? props.open_new_tab : false;

    
    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerConductores(page, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination  sx={{ mt:2 }} color="primary" count={cantidad} page={pagina} onChange={handleChangePagina} />
        </div>
    }

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/viajes/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
                setConteos(res.conteos)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!campoBusqueda) return toast.error('Selecciona una patente')
        const condicion = { $text: { $search: campoBusqueda.toLowerCase() }, activo: true }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerConductores(1, condicion)
    }

    const seleccionarPedido = (id) => {
        if(props.onSelectedTrip) props.onSelectedTrip(id)
        return false
    }

    const showByType = (type, trip) => {
        switch (type) {
            case "funcion":
                return <b onClick={() => seleccionarPedido(trip._id)}>{trip.id} <OpenInNewIcon style={{ marginBottom: -4 }} fontSize="small" /></b>
            case "link":
                return <b><Link target={open_new_tab === true ? "_blank" : "_self"} to={`/viaje-${trip._id}`}>{trip.id} <OpenInNewIcon style={{ marginBottom: -4 }} fontSize="small" /></Link></b>
            default:
                return 
        }
    }

    const mostrarConteos = () => {
        if(!showCounter) return false
        return <Grid container spacing={2} sx={{ width: "100%" }}>
            {
                conteos.map((conteo,ic) => {
                    return <Grid item xs={2} key={`conteo-${ic}`}>
                    <Card>
                        <CardContent>
                        <Typography sx={{ fontSize: 14 }} gutterBottom>{conteo.label_short}</Typography>
                        <Typography variant='h5' component='div'>{conteo.cantidad}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                })
            }
            
        </Grid>
    }

    if(loadingConductores) return <CargandoTabla />

    if(conductores.length < 1) return <Typography variant="h6">No hay registros</Typography>

    return <div>

        {mostrarConteos()}

        <Grid container >
                <Grid item xs={6}>{paginacion(data.pagina, total)}</Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}><h3 sx={{ m:0 }} >{total} {titulo}</h3></Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt:2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
            <TableHead>
            <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Recogida</TableCell>
                <TableCell>Puntos entrega</TableCell>
                <TableCell>Registro</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {
                conductores.map((viaje) => {

                    const cliente = obtenerIdentificadorCliente(viaje.cliente)
                    const origen = obtenerDireccionDestino(viaje.puntos)

                    return <TableRow
                        key={viaje._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell>{showByType(tipo, viaje)}</TableCell>
                    <TableCell>{cliente}</TableCell>
                    <TableCell>{origen}</TableCell>
                    <TableCell>{viaje.puntos.filter(v => v.tipo !== "origen").length}</TableCell>
                    <TableCell>{fechaATexto(viaje.fecha_zona_horaria_local)}</TableCell>
                    </TableRow>
                })
            }
            </TableBody>
        </Table>
        </TableContainer>
    </div>
}

export default ListadoViajes