const obtenerIdentificadorConductor = (driver) => {
    const default_texto = 'Sin información'
    let texto = ''
    if(!driver) return default_texto
    if(typeof driver !== 'object') return default_texto
    if(driver.nombres) texto = driver.nombres
    if(driver.apellido_p) texto = `${texto} ${driver.apellido_p}`
    if(driver.apellido_m) texto = `${texto} ${driver.apellido_m}`
    return texto
}

const calcularGananciaConductor = (cobro) => {
    if(!cobro.profit) return cobro.precio_total
    if( isNaN( parseInt(cobro.profit) ) ) return cobro.total
    const calculado = cobro.precio_total - (cobro.precio_total * cobro.profit / 100)
    return parseInt(calculado)
}

const obtenerProfit = (cobro) => {
    if(!cobro.profit) return 0
    if( !isNaN( parseInt(cobro.profit) ) ) return cobro.profit
    return 0
}

module.exports = {
    calcularGananciaConductor,
    obtenerProfit,
    obtenerIdentificadorConductor
}