import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Select as Selector,
  Tab,
  Alert,
  Card,
  Divider,
  IconButton,
  Menu,
  InputAdornment,
  Autocomplete,
  CardContent,
  CardActions
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, Link, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data, { maps_key } from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import SinData from '../General/sin_data';
import MapsAutoComplete from "react-google-autocomplete";
import { LoadingButton } from '@mui/lab';
import ModalEmpresas from '../Empresas/modal_seleccion';
import { obtenerUnidadesMedidaPorTipoPais } from '../../lib/helpers';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import BotonEliminar from './boton_eliminar';
import { rutas } from '../../lib/data/rutas';
import SelectorGeneral from '../../subcomponents/general/selector_general';
import { category_key, key, module_name } from './data';
import { Title } from '@mui/icons-material';
import { GoDeviceMobile } from 'react-icons/go'
import { GrAdd } from 'react-icons/gr'
import { codigos_area } from '../../lib/data/internacional';
import { AiFillMinusCircle, AiOutlineMail } from 'react-icons/ai';
import { validateEmail } from '../../lib/helpers/helpers';
import ListadoBodegas from '../Bodegas/listado';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from '../../lib/helpers/usequery';
import { procesarGeoDatosGeocode } from '../../lib/helpers/maps';
import ListadoContactos from '../Contactos/listado';

const DestinatarioDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [loadingSelect, setLoadingSelect] = useState(true);
  const [loadingSelectTipoVeh, setLoadingSelectTipoVeh] = useState(true);
  const [marcas, setMarcas] = useState([]);
  const [selectOptionsColors, setSelectOptionsColors] = useState([]);
  const [empresa, setEmpresa] = useState(false);
  const [selectOptionsTipo, setSelectOptionsTipo] = useState([]);
  const [selectOptionsMedidas, setSelectOptionsMedidas] = useState([
    { value: 'metros', label: 'Metros' },
      { value: 'centimetros', label: 'CM' },
      { value: 'pies', label: 'FT' }
  ]);
  const [selectOptionsMedidasPeso, setSelectOptionsMedidasPeso] = useState([
    { value: 'kilogramos', label: 'KGS' },
      { value: 'libras', label: 'LIBRAS' }
  ]);
  const [loadingSelectModelo, setLoadingSelectModelo] = useState(true);
  const [selectOptionsModelo, setSelectOptionsModelo] = useState([]);
  const [selectOptionsTipoMotor, setSelectOptionsTipoMotor] = useState([]);
  const [loadingSelectMotor, setLoadingSelectMotor] = useState(true);
  const [loading, setLoading] = useState(false);
  const [vehiculo, setVehiculo] = useState([]);
  const [loadingDefinicion, setLoadingDefinicion] = useState(false);
  const [loadingConductores, setLoadingConductores] = useState(true);
  const [opcionesConductores, setOpcionesConductores] = useState([]);
  const [marca, setMarca] = useState('');
  const [permisosOtorgados, setPermisosOtorgados] = useState([]);
  const sesion = useSelector((state) => state.miusuario);
  const [loadintAsignacion, setLoadingAsignacion] = useState(false);
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
  const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
  const unidades_medida_volumen       = obtenerUnidadesMedidaPorTipoPais("volumen", pais).map(e => e)
  const requeridos = [
    { value: 'razon_social', label: 'Razón social' },
  ];
  const query = useQuery()
  const default_tab = query.get("tab")
  const history = useHistory()
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState( default_tab ? default_tab : "1")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { id } = useParams();

  const obtenerConductores = async () => {
    return fetch(`${data.urlapi}/${module_name}/list-select`, {
      method: 'POST',
      body: JSON.stringify({ condicion: {} }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (Array.isArray(res) !== false) {
          const opciones = res.map(con => ({ value: con._id, label: `${con.razon_social} ${con.apellido_p ? con.apellido_p : ''}` }))
          setOpcionesConductores(opciones)
        }
        return setLoadingConductores(false)
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingConductores(false)
      });
  };

  const handleSelect = (e) => {
    vehiculo.marca = e.value
    vehiculo.modelo = ""
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  }

  const handleSelectModel = (e) => {
    vehiculo.modelo = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectColor = (e) => {
    vehiculo.color = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectConductor = (e) => {
    vehiculo.id_usuario = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectTipo = (e) => {
    vehiculo.id_tipo = e.value;
    vehiculo.tipo = e.label;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectMedida = (e) => {
    vehiculo.unidad_medida = e.label
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectMedidaPeso = (e) => {
    vehiculo.unidad_medida_peso = e.label;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectTipoMotor = (e) => {
    vehiculo.tipo_motor = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const obtenerVehiculo = async () => {
    return fetch(`${data.urlapi}/${module_name}?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setVehiculo(res);
          if(res.empresa) setEmpresa(res.empresa)
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      })
  };

  const obtenerMarcas = async () => {
    return fetch(`${data.urlapi}/vehiculos/marca/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      }
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if(Array.isArray(res) !== false){
          setMarcas(res.map(op => ({ value: op._id, label: op.titulo })))
        }
        return setLoadingSelect(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelect(false);
      })
  }

  const obtenerTipos = async () => {
    return fetch(`${data.urlapi}/vehiculos/tipo/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if( Array.isArray(res) !== false){
          setSelectOptionsTipo(res.map(op => ({ value: op._id, label: op.tipo })))
        }
        return setLoadingSelectTipoVeh(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelectTipoVeh(false);
      });
  };

  const obtenerTiposMotor = async (id) => {
    return fetch(`${data.urlapi}/tipomotor/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (res.length > 0) {
          opcionesTipoMotor(res);
        } else if (res.length < 1) {
          toast.error('No existen Colores de Vehiculos.');
        }
        return setLoadingSelectMotor(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelectMotor(false);
      });
  };

  const obtenerModelos = async () => {
    return fetch(`${data.urlapi}/vehiculos/modelo/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (Array.isArray(res) !== false) {
          setSelectOptionsModelo(res)
        }
        return setLoadingSelectModelo(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelectModelo(false);
      });
  };

  const obtenerColores = async (id) => {
    return fetch(`${data.urlapi}/color/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (Array.isArray(res) !== false) {
          setSelectOptionsColors(res.map(o => ({ value: o._id, label: o.titulo })))
        }
        return setLoadingSelect(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelect(false);
      });
  };

  const medidas = async () => {
    selectOptionsMedidas.length = 0;
    selectOptionsMedidasPeso.length = 0;
    selectOptionsMedidasPeso.push(
      { value: 'kilogramos', label: 'KGS' },
      { value: 'libras', label: 'LIBRAS' }
    );

    selectOptionsMedidas.push(
      { value: 'metros', label: 'Metros' },
      { value: 'centimetros', label: 'CM' },
      { value: 'pies', label: 'FT' }
    );
  };


  const opcionesModelo = async (res) => {
    selectOptionsModelo.length = 0;
    res.map((opcion) => {
      return selectOptionsModelo.push({
        value: opcion._id,
        label: opcion.titulo,
      });
    });
    vehiculo.modelo = '';
    setLoadingSelectModelo(false);
    return selectOptionsModelo;
  };

  const opcionesColores = (res) => {
    selectOptionsColors.length = 0;
    res.map((opcion) => {
      return selectOptionsColors.push({
        value: opcion._id,
        label: opcion.titulo,
      });
    });
    setLoadingSelect(false);
    return [];
  };

  const opcionesTipoMotor = (res) => {
    selectOptionsTipoMotor.length = 0;
    res.map((opcion) => {
      return selectOptionsTipoMotor.push({
        value: opcion._id,
        label: opcion.titulo,
      });
    });
    setLoadingSelectMotor(false);
    return [];
  };

  useEffect(() => {
    obtenerVehiculo()
    obtenerMarcas()
    obtenerTipos()
    obtenerModelos()
    obtenerColores()
    obtenerTiposMotor()
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    vehiculo[name] = value;
    return setVehiculo({ ...{}, ...vehiculo });
  };

  const extraerValor = (val) => {
    switch (typeof val) {
        case "string":
            return val
        case "object":
            if(val._id){
                return val._id
            } else if(val.value){
                return val.value
            } else if(val.target){
                const { name, value } = val.target
                console.log({name,value})
                return val.target.value
            } 
            return ''
        default:
            return ''
    }
  }

  const handleChangeSelect = (e, name) => {
    const valor = extraerValor(e)
    console.log(valor)
    vehiculo[name] = valor
    if(name==="id_categoria") vehiculo.id_sub_categoria = ''
    return setVehiculo({ ...{}, ...vehiculo });
  };

  const actualizarVehiculo = async () => {
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!vehiculo[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    vehiculo.permisos = permisosOtorgados;

    const emails = vehiculo.emails_adicionales ? vehiculo.emails_adicionales : []
    const emails_invalidos = emails.filter(e => {
      return !validateEmail(e.email)
    }).map(e => e.email ? e.email : "Email vacío")

    if(emails_invalidos.length > 0) return toast.error(`Emails inválidos, corrige y vuelve a intentar: ${emails_invalidos.join(', ')}`)

    setLoading(true);
    return fetch(`${data.urlapi}/${module_name}`, {
      method: 'PUT',
      body: JSON.stringify(vehiculo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const handleChangeTab = (e,val) => {
    const current = val.toString()
    const queryParams = new URLSearchParams();
    queryParams.set('tab', current)
    history.push({
      pathname: location.pathname,
      search: queryParams.toString()
    });

    return setSelectedTab(current)
  }

  const handleChangeMovil = (e, pos, name) => {
    let value = e
    if(value){
      if(typeof e === "object") if(e.target) value = e.target.value
    }
    vehiculo.moviles_adicionales[pos][name] = value ? value : ""
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const removerMovil = (i) => {
    vehiculo.moviles_adicionales.splice(i,1)
    return setVehiculo(prev => ({...{}, ...vehiculo})) 
}

const anadirMovilAdicional = () => {
    if(!vehiculo.moviles_adicionales) vehiculo.moviles_adicionales = []
    vehiculo.moviles_adicionales.unshift({
        pais_codigo: '',
        movil: ''
    })
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const anadirEmailAdicional = () => {
    if(!vehiculo.emails_adicionales) vehiculo.emails_adicionales = []
    vehiculo.emails_adicionales.unshift({
        email: ''
    })
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const removerEmail = (i) => {
    vehiculo.emails_adicionales.splice(i,1)
    return setVehiculo(prev => ({...{}, ...vehiculo})) 
}

const handleChangeEmail = (e,pos) => {
    const { name, value } = e.target
    console.log({ name, value, pos})
    vehiculo.emails_adicionales[pos][name] = value
    vehiculo.emails_adicionales[pos].valid = validateEmail(value)
    return setVehiculo(prev => ({...{}, ...vehiculo}))
}

const mostrarEmailsAdicionales = () => {
    if(!vehiculo.emails_adicionales) return false
    return <div>
        {
            vehiculo.emails_adicionales.map((field,i) => {
                return <div key={`email-${i}`} className="mt-3">
                    <Card  >
                            {/* <Input status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} /> */}
                            <CardContent>
                            <TextField
                              label='Email'
                              error={field.valid === false ? true : false }
                              placeholder="Escribe aquí el email"
                              variant='filled'
                              name='email'
                              required={siEsRequeridoEnEsquema("cargo",requeridos)}
                              fullWidth
                              value={field.email}
                              onChange={(e) => handleChangeEmail(e,i)}
                              helperText="Ingresa un email válido."

                            />
                            </CardContent>
                            <CardActions>
                            <Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>
                            </CardActions>
                    </Card>
                </div>
            })
        }
    </div>
}

const mostrarMovilesAdicionales = () => {

    // const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    if(!vehiculo.moviles_adicionales) return false
    return <div>
        {
            vehiculo.moviles_adicionales.map((field,i) => {
                return <div key={`movil-${i}`} className="mt-3">
                <Card>
                    <CardContent>
                    <Grid container spacing={1}>
                        <Grid item md={4}>
                            <Autocomplete
                              style={{ width: "100%" }} 
                              placeholder="Código de area" 
                              name="pais_codigo" 
                              value={field.pais_codigo} 
                              disablePortal
                              noOptionsText="Sin opciones" 
                              options={codigos_area}
                              renderInput={(params) => <TextField label="Código de área" {...params} />}
                              onChange={(event,e) => handleChangeMovil(e,i,"pais_codigo")} 
                            />
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                              label='Número de teléfono'
                              placeholder="Escribe aquí el número telefónico"
                              variant='filled'
                              name='movil'
                              required={true}
                              fullWidth
                              value={field.movil}
                              onChange={(e) => handleChangeMovil(e,i,"movil")}
                            />
                        </Grid>
                    </Grid>
                    </CardContent>
                    <CardActions>
                    <Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>
                    </CardActions>
                </Card>
                </div>
            })
        }
    </div>
}

  const formularioEditar = () => {

    return <Grid container spacing={2} sx={{ mb: 2 }}>
    <Grid item md={12} xs={12}>
          <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                  <TabList  onChange={handleChangeTab}
                                  variant="scrollable"
                                  scrollButtons="auto"
                                  >
                                  <Tab label="Ficha" value="1" />
                                  <Tab label="Bodegas" value="2" />
                                  <Tab label="Contactos" value="3" />
                                  </TabList>
                                  <TabPanel value="1">
                                  <ModalEmpresas titulo="Seleccionar empresa" seleccionarEmpresa={(data) => {
      vehiculo.propietario = data.rut
      setEmpresa(data)
      toast.success("Guardar los cambios antes de salir de esta pantalla", { icon: false })
      return setVehiculo(vehiculo)
    }} />
    {
            empresa ? <div>
              <Card sx={{ p:2, mt: 2 }}>
              <Typography mb={0}>Empresa asignada</Typography>
              <Typography variant='h6' mb={0}>{empresa.razon_social}</Typography>
              <Button onClick={() => {
                setEmpresa(false)
                vehiculo.propietario = ''
                setVehiculo(vehiculo)
                toast.success("Guardar los cambios antes de salir de esta pantalla", { icon: false })
                }}>REMOVER</Button>
              </Card>
              </div> : false
          }
                                  <Grid container spacing={2}>
        <Grid item md={12} xs={12} >
          <CamposObligatoriosLeyenda />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Razón social'
            variant='filled'
            name='razon_social'
            required={siEsRequeridoEnEsquema("razon_social",requeridos)}
            defaultValue={vehiculo.razon_social}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='ID Fiscal'
            variant='filled'
            name='rut'
            required={siEsRequeridoEnEsquema("rut",requeridos)}
            fullWidth
            defaultValue={vehiculo.rut}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
        <SelectorGeneral otherProps={{ label: "Categoría" }} value={vehiculo.id_categoria} strict={true} hideLabel={true} module="categorias" condicion_default={{ tipo: "cliente" }} onChange={data => handleChangeSelect(data, 'id_categoria' )} />
        </Grid>
        <Grid item md={3} xs={12}>
        <SelectorGeneral otherProps={{ label: "Sub Categoría" }} value={vehiculo.id_sub_categoria} strict={true} hideLabel={true} module="sub-categorias" condicion_default={{ tipo: "cliente", id_categoria: vehiculo.id_categoria }} onChange={data => handleChangeSelect(data, 'id_sub_categoria' )} />
        </Grid>
        <Grid item md={9} xs={12}>
          <MapsAutoComplete
                    style={{ height: 57, width: "100%", fontSize: 15, padding: 12 }}
                    defaultValue={vehiculo.direccion}
                    placeholder='Ingresa la dirección'
                    options={{
                        types: ["geocode"],
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                          const geo_data = procesarGeoDatosGeocode(place)
                          return setVehiculo(prev => {
                            // Crea una copia del arreglo de paradas
                            const nuevoObjeto = {...prev}
                            nuevoObjeto.direccion = place.formatted_address
                            nuevoObjeto.geodata = geo_data
                            nuevoObjeto.location = {
                              type: "Point",
                              coordinates: [
                                  geo_data.lng, geo_data.lat
                              ]
                            }
                            return nuevoObjeto;
                        })
                    }}
                    />
        </Grid>
        <Grid item md={3} xs={12}>

          <FormControl fullWidth className='mb-3'>
          <InputLabel id="demo-simple-select-label" required={siEsRequeridoEnEsquema("status",requeridos)}>Estado</InputLabel>
          <Selector
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Estado * "
              name="status"
              onChange={handleChange}
              defaultValue={vehiculo.status}
              >
              <MenuItem value="active">Activo</MenuItem>
              <MenuItem value="trash">Desactivado</MenuItem>
          </Selector>
          </FormControl>

          </Grid>
          <Grid item md={24} xs={24}>
                    <Divider />
                    <Grid container gutter={15}>
                        <Grid item md={12}>
                        <h3 ><GoDeviceMobile /> Teléfonos</h3>
                        <Button  variant="outlined" sx={{ mb:2 }} startIcon={<GrAdd />} onClick={() => anadirMovilAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarMovilesAdicionales()}
                        </Grid>
                        <Grid item md={12}>
                        <h3 ><AiOutlineMail /> Emails</h3>
                        <Button variant="outlined" sx={{ mb:2 }} startIcon={<GrAdd />} onClick={() => anadirEmailAdicional()} > AÑADIR NUEVO</Button>
                        {mostrarEmailsAdicionales()}
                        </Grid>
                    </Grid>
                </Grid>
        
         
        <Grid item md={12} xs={12}>
          {loading === true ? (
            <CircularProgress />
          ) : (
            <Button
              variant='contained'
              color='success'
              xs={{ m: 0 }}
              onClick={() => actualizarVehiculo()}
            >
              GUARDAR CAMBIOS
            </Button>
          )}
        </Grid>
      </Grid>
                                  </TabPanel>
                                  <TabPanel value="2">
                                  <ListadoBodegas condicion_default={{ id_cliente: id }} />
                                  </TabPanel>
                                  <TabPanel value="3">
                                  <ListadoContactos condicion_default={{ id_cliente: id }} />
                                  </TabPanel>
          </Box>
          </TabContext>
                
    </Grid>
  </Grid>
  }

  const cambiarVehiculoConductor = async () => {
    setLoadingDefinicion(true)
    return fetch(`${data.urlapi}/vehiculos/definir-seleccionado`, {
        method: 'POST',
        body: JSON.stringify({
          id: vehiculo._id
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then((res) => {
          if (!res) {
            toast.error('Sin datos');
            return setLoadingDefinicion(false);
          } else if (res.errorMessage) {
            toast.error(res.errorMessage);
            return setLoadingDefinicion(false);
          } else if (res._id) {
            vehiculo.selected = true
            toast.success("Realizado exitosamente")
            setVehiculo(prev => ({...{}, ...vehiculo}));
          }
          return setLoadingDefinicion(false);
        })
        .catch((error) => {
          toast.error('Error al consultar la información, intente nuevamente');
          return setLoadingDefinicion(false);
        })
  }

  const asignarRequisito = async (_id) => {
    setLoadingAsignacion(true)
    return fetch(`${data.urlapi}/requisitos/asignar?id=${_id}&target=${id}`, {
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(res._id){
            toast.success("Agregado exitosamente")
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }
        return setLoadingAsignacion(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setLoadingAsignacion(false)
    })
}

  const seleccionarRequisito = (req) => {
    asignarRequisito(req._id)
  }


  const scollToBootom = () => {
    const container = document.getElementById('main'); // Reemplaza 'container' con el ID de tu contenedor
    if(!container) return false
    container.scrollTo({
      top: container.scrollHeight,
      behavior: 'smooth',
      block: 'end'
    });
  }
  const autoSeleccionado = () => {
    if(!vehiculo.id_usuario) return false
    if(vehiculo.selected !== true) return <LoadingButton
        loading={loadingDefinicion}
        variant="outlined"
        sx={{ mb:2 }}
        onClick={() => cambiarVehiculoConductor()}
    >DEFINIR COMO VEHÍCULO ACTUAL DE ESTE CONDUCTOR</LoadingButton>

    return <div>
      <Alert severity="info" sx={{ mb:2}}>Este vehículo es el vehículo actual de este conductor</Alert>
    </div>
  }

  const render = () => {
    if (loadingMaster) return <CircularProgress />
    if (loadingSelectTipoVeh) return <CircularProgress />
    if (loadingSelect) return <CircularProgress />
    if (loadingSelectMotor) return <CircularProgress />
    if (loadingSelectModelo) return <CircularProgress />
    if (!vehiculo) return <SinData />

    return (
      <div>
          <Breadcrumbs aria-label='breadcrumb'>
          <Href to={rutas[key].slug}>{rutas[key].title}</Href>
          <Enlace underline='hover' color='text.primary'>Editar { vehiculo ? vehiculo.razon_social : rutas[key].title}</Enlace>
          </Breadcrumbs>
          <h1 style={{ margin: 0 }}>Editar { vehiculo ? vehiculo.razon_social : rutas[key].title}</h1>
          <BotonEliminar id={id} />
          {autoSeleccionado()}
          {formularioEditar()}
      </div>
    );
  };
  
  return <Structure component={render()} />;
};

export default DestinatarioDetails