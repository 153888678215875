import { Box, Button, Divider, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState} from 'react'
import Structure from '../Structure'
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MenuConfig from './menu';
import FormularioReconocimientoFacial from './formulario_reconocimiento_facial';
import FormularioAlgoritmoRadioBusqueda from './formulario_algoritmo_radio';
import FormularioAlgoritmoCiclos from './formulario_algoritmo_ciclos';
import FormularioRestriccionGeocerca from './formulario_restriccion_geocerca';
import FormularioTiempoSolicitudes from './formulario_tiempo_solicitudes';
import FormularioAlgoritmoTiempoBusqueda from './formulario_algoritmo_tiempo_busqueda';

const Configuracion = () => {
    const [value, setValue] = useState(0)
    const sesion = useSelector(state => state.miusuario)

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ pt: 3 }}>{children}</Box>
            )}
          </div>
        )
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
      
    const handleChange = (e, value) => {
        return setValue(value)
    }

    const render = () => {
        return <div>
            <h1 style={{ margin: 0 }}>Configuración</h1>
            <Grid container spacing={3}>
                <Grid item md={3} >
                <MenuConfig />
                </Grid>
                <Grid item md={9} >
                  <FormularioReconocimientoFacial />
                  <Divider sx={{ mt:3, mb:3 }} />
                  <FormularioAlgoritmoRadioBusqueda />
                  <FormularioAlgoritmoCiclos showTitle={false} />
                  <FormularioAlgoritmoTiempoBusqueda />
                  <Divider sx={{ mt:3, mb:3 }} />
                  <FormularioRestriccionGeocerca />
                  <Divider sx={{ mt:3, mb:3 }} />
                  <FormularioTiempoSolicitudes />
                </Grid>
            </Grid> 
        </div>
    }

    return <Structure component={render()} />
}

export default Configuracion