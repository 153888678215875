import CargandoTabla from './cargando';
import {
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { fechaATexto } from '../../lib/helpers/helpers';
import { Link } from 'react-router-dom';

const ListadoGeocercas = (props) => {
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.miusuario);
  const [vehiculos, setVehiculos] = useState([]);
  const [loadingVehiculos, setLoadingVehiculos] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [campoBusqueda, setCampoBusqueda] = useState('');
  const [total, setTotal] = useState(0);
  const [condicion_busqueda, setCondicionBusqueda] = useState(
    props.condicion_default ? props.condicion_default : {}
  );
  const trash = props.trash ? props.trash : false;
  const titulo = props.title ? props.title : 'registros';
  const show_filter = props.show_filter ? props.show_filter : false;

  const paginacion = (ciclo, total) => {
    const cantidad = Math.ceil(total / ciclo);
    if (!cantidad) return false;
    return (
      <div className='mb-3'>
        <Pagination
          sx={{ mt: 2 }}
          color='primary'
          count={cantidad}
          page={pagina}
          onChange={handleChangePagina}
        />
      </div>
    );
  };

  const obtenerVehiculos = async (page, query) => {
    setLoadingVehiculos(true);
    if (query) if (trash === true) query.status = 'trash';
    const condicion = query ? query : condicion_busqueda;
    return fetch(`${data.urlapi}/geocercas/list`, {
      method: 'POST',
      body: JSON.stringify({
        condicion,
        pagina: page,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingVehiculos(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingVehiculos(false);
        }
        if (Array.isArray(res.datos) !== false) {
          setVehiculos(res.datos);
          setTotal(res.total);
        }
        return setLoadingVehiculos(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingVehiculos(false);
      });
  };

  const handleChangePagina = (e, page) => {
    setPagina(page);
    return obtenerVehiculos(page, condicion_busqueda);
  };

  useEffect(() => {
    obtenerVehiculos(1, false);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!campoBusqueda) return toast.error('Selecciona una patente');
    const condicion = {
      $text: { $search: campoBusqueda.toLowerCase() },
      activo: true,
    };
    setCondicionBusqueda(condicion);
    setPagina(1);
    return obtenerVehiculos(1, condicion);
  };

  const handleChangeCampo = (e) => {
    const { value } = e.target;
    return setCampoBusqueda(value);
  };

  const filtro = () => {
    //if(!show_filter) return false
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Box
              component='form'
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, mb: 1 }}
            >
              <Grid container spacing={2}>
                            <Grid item xs={4}>
              <TextField
                id='outlined-basic'
                label='Buscar por titulo'
                variant='outlined'
                size="small"
                defaultValue={campoBusqueda}
                onChange={handleChangeCampo}
                fullWidth
              />
                            </Grid>
                            <Grid item xs={3}>
                                <Button type="submit" size="large" variant="contained" >BUSCAR</Button>
                            </Grid>
            </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  };

  const mostrarRegistros = () => {
    if (vehiculos.length < 1) return <h3>No hay registros</h3>;

    return (
      <div>
        {filtro()}
        <Grid container>
          <Grid item xs={6}>
            {paginacion(data.pagina, total)}
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <h3 sx={{ m: 0 }}>
              {total} {titulo}
            </h3>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label='sticky table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Patente</TableCell>
                <TableCell>Modelo</TableCell>
                <TableCell>Marca</TableCell>
                <TableCell>Año</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Registro</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehiculos.map((driver) => (
                <TableRow
                  key={driver._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <b>
                      <Link to={`/geocerca-${driver._id}`}>
                        {driver.ubicacion ? driver.ubicacion.toUpperCase() : 'VER DETALLES'}
                      </Link>
                    </b>{' '}
                  </TableCell>
                  <TableCell>{driver.modelo}</TableCell>
                  <TableCell>{driver.marca}</TableCell>
                  <TableCell>{driver.ano}</TableCell>
                  <TableCell>{driver.tipo}</TableCell>
                  <TableCell>{fechaATexto(driver.createdAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  if (loadingVehiculos) return <CargandoTabla />;

  return <div>{mostrarRegistros()}</div>;
};

export default ListadoGeocercas;
