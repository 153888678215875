import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { urlapi } from '../../lib/backend/data';
import { cerrarSesion } from '../../redux/actions/session';

const SelectorClientes = (props) => {
    const [ options, setOptions ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const sesion = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    const listadoInicial = async (condicion, page) => {
        return fetch(`${urlapi}/clientes/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            }
            if(Array.isArray(res.datos) !== false){
                let opciones = [{ value: "", label: "Todos" }]
                const nuevas = res.datos.map(empresa => ({ value: empresa._id, label: `${empresa.nombre} ${empresa.apellido ? empresa.apellido : ''}` }))
                opciones = [...opciones, ...nuevas]
                setOptions(opciones)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    } 

    useEffect(() => {
        listadoInicial({},1)
    }, [])

    const buscar = async (value) => {
        return fetch(`${urlapi}/clientes/search`,{
            method:'POST',
            body: JSON.stringify({
                value
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return []
            } else if(res.errorMessage){
                return []
            }
            let opciones = [{ value: "", label: "Todos" }]
            const nuevas = res.map(empresa => ({ value: empresa._id, label: `${empresa.nombre} ${empresa.apellido ? empresa.apellido : ''}` }))
            opciones = [...opciones, ...nuevas]
            return opciones
        })
        .catch(error => {
            toast.error(error.message)
            return false
        })
    }

    const promiseOptions = async (inputValue) => {
        return new Promise( async (resolve) => {
            setTimeout( async () => {
            const busqueda = await buscar(inputValue)
              resolve(busqueda);
            }, 500)
        })
    }

    const handleChange = (e) => {
        if(props.onChangeValue) props.onChangeValue(e)
        return false
    }
    return <div>
        <AsyncSelect 
                    cacheOptions  
                    placeholder="Buscar cliente..."
                    defaultOptions={options}
                    isLoading={loading}
                    noOptionsMessage={()=>"Sin opciones"}
                    loadingMessage={()=>"Cargando información..."}
                    loadOptions={promiseOptions} 
                    onChange={handleChange}
                    />
    </div>

}

export default SelectorClientes