import { forwardRef, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import Slide from '@mui/material/Slide';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const ConfirmationAlert = (props) => {
    const {
        variant,
        color,
        loading
    } = props
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
    }

    const confirmar = () => {
        setOpen(false)
        if(props.onConfirm) props.onConfirm()
    }
    
    return <div>
        <LoadingButton
            loading={typeof loading !== "undefined" ? loading : false}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant={variant ? variant : "outlined"} 
            color={color ? color : "error"}
            onClick={() => handleClickOpen()}
            >ELIMINAR</LoadingButton>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"¿Estás seguro?"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Estás a punto de eliminar este recurso, esta acción no se puede deshacer
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>CANCELAR</Button>
            <Button onClick={confirmar} autoFocus>ACEPTAR</Button>
            </DialogActions>
        </Dialog>
    </div>
}

export default ConfirmationAlert