import { LoadingButton } from "@mui/lab"
import { useState } from "react"
import { urlapi } from "../../lib/backend/data"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { toast } from "react-hot-toast"
import ListAltIcon from '@mui/icons-material/ListAlt';


const ExcelButtonDownload = (props) => {
    const {
        condicion,
        slug,
        size
    } = props
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const sizeButton = size ? size : "large"
    const dispatch = useDispatch()

    const descargarReporte = async () => {
        setLoadingExcel(true)
        return fetch(`${urlapi}/reports/${slug}`,{
            method:'POST',
            body: JSON.stringify({
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${slug ? slug :"registros"}-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }


    return <div>
        <LoadingButton fullWidth size={sizeButton} loading={loadingExcel} onClick={() => descargarReporte()} variant="contained" color="success" startIcon={<ListAltIcon />} >DESCARGAR REPORTE</LoadingButton>
    </div>
}

export default ExcelButtonDownload