import Map, { Layer, Marker, Source } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import { BsFillStopCircleFill } from 'react-icons/bs';

const MapaCalorPuntos = (props) => {
    const {
        height,
        markers,
        pinType,
        resize,
        coleccion
    } = props
    const [ marcadores, setMarcadores ] = useState(markers)
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const coleccion_ordenes = coleccion
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()

    const ajustarCentro = (puntos) => {
        if(zoomInit) return false
        if(puntos.length < 1) return false
        setZoomInit(true)
        const points = puntos.map(marker => turf.point([marker.longitude, marker.latitude]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 50 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current?.fitBounds(bounds, options);
    }

      useEffect(() => {
          if(mapRef) if(mapRef.current) mapRef.current.resize()
        setTimeout(() => {
            const datos = markers ? markers : marcadores
            ajustarCentro(datos.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
        }, 500);
      }, [ resize, coleccion ])

      const refrescarListado = async ()=>{
        console.log("se dispara")
        return fetch(`${urlapi}/conductores/vehicles-actives`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return res.json() //dispatch(cerrarSesio())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(Array.isArray(res) !== false){
                ajustarCentro(res.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
                setMarcadores(prev => [...[], ...res])
            }
            return false
        })
        .catch(error => {
            return false
        })
    }


    return <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: height ? height : 600}}
    pitch={25}
    scrollZoom={false}
    dragPan={false}
    mapStyle="mapbox://styles/mapbox/dark-v9"
    // mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
  >
    <Source
            key={"ajsfkn2"}
            id={"geocerca._id2"}
            type="geojson"
            data={coleccion_ordenes}
          >
            <Layer
              id="heatmap-layer"
              type="heatmap"
              paint={{
                'heatmap-weight': 1,
                'heatmap-intensity': 0.5,
                'heatmap-radius': 20,
                'heatmap-color': [
                  'interpolate',
                  ['linear'],
                  ['heatmap-density'],
                  0,
                  'rgba(0, 0, 255, 0)',
                  0.2,
                  'royalblue',
                  0.4,
                  'cyan',
                  0.6,
                  'lime',
                  0.8,
                  'yellow',
                  1,
                  'red',
                ],
                'heatmap-opacity': 0.8,
              }}
            />

          </Source>
  </Map>
}

export default MapaCalorPuntos