import { Card, CardContent, CircularProgress, Grid, Typography, Tab, Tabs, Box } from "@mui/material"
import { obtenerDireccionDestino, obtenerDireccionEntrega, procesarFotos } from "../../lib/helpers/viajes"
import { urlapi, style } from '../../lib/backend/data';
import { obtenerIdentificadorCliente, obtenerTelefonoCliente } from "../../lib/helpers/clientes";
import ListadoLogViaje from "../../subcomponents/viajes/log_viaje";
import { fechaATexto } from "../../lib/helpers/helpers";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import Moment from 'react-moment';
import { calcularGananciaConductor, obtenerIdentificadorConductor, obtenerProfit } from "../../lib/helpers/conductor";
import ModalConductores from "../Drivers/modal_seleccion";
import { MapStyleDefault } from "../../lib/global/data";
import Structure from "../Structure";
import { useParams } from "react-router-dom";
import MostrarProductos from "./mostrar_productos";
import DetallesTarifaBox from '../../subcomponents/viajes/detalles_tarifa_box'
import { textoTipoViaje } from "../../lib/helpers/main";
import SinData from "../General/sin_data";
import ChatViaje from "../Chat";
import FotografiasViaje from "../../subcomponents/viajes/fotografias_viaje";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const centerMapCoordinates = (coords) => {
    if (coords.length === 1) {
      return coords[0];
    }
  
    let x = 0.0;
    let y = 0.0;
    let z = 0.0;
  
    for (let coord of coords) {
      let latitude = coord.lat * Math.PI / 180;
      let longitude = coord.lng * Math.PI / 180;
  
      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    }
  
    let total = coords.length;
  
    x = x / total;
    y = y / total;
    z = z / total;
  
    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);
  
    return {
      lat: centralLatitude * 180 / Math.PI,
      lng: centralLongitude * 180 / Math.PI
    };
  }
  
const divStyle = {
    background: `white`,
    padding: 5
  }

const MapaOperacion = (props) => {
/*
  const mapRef = useRef(null);
    const [rutas, setRutas] = useState([])
  
    let marcadores = []
    let polyline_path = []
    let showInfoWindow = props.showInfoWindow ? true : false
  
    const handleLoad = (map) => {
      mapRef.current = map;
    }
  
    const handleCenterChanged = () => {
      if (!mapRef.current) return;
      const newPos = mapRef.current.getCenter().toJSON();
    }
  
    if(props.markers){
        if(Array.isArray(props.markers) !== false){
            marcadores = props.markers
        }
    }
    
    if(props.polyline){
        if(Array.isArray(props.polyline) !== false){
            polyline_path = props.polyline
        }
    }
  
    const directionsCallback = (response) => {
      if (response !== null) {
        if (response.status === 'OK') {
          rutas.push(response)
          setRutas(prev => [...[], ...rutas])
        } else {
          console.log('response: ', response)
        }
      }
    }

    const MostrarMultiPuntos = (props) => {
      const puntos = props.destinos
      if(rutas.length >= puntos.length) return false
      return puntos.length > 0 ? puntos.map((punto,ip) => {
        if(ip === 0) return false
        return <DirectionsService
        key={maps_key}
        options={{
          origin: puntos[ip-1].description,
          destination: punto.description,
          travelMode: "DRIVING"
        }}
        callback={directionsCallback}
      />
      }) : false
    }
  
    return (
    <LoadScript
        googleMapsApiKey={maps_key}
        >
            <GoogleMap
                mapContainerStyle={{
                  width: '100%',
                  height: props.height ? props.height : '60vh',
                  borderRadius: 10
                }}
                onLoad={handleLoad}
                center={{
                  lat: -33.449187,
                  lng: -70.669575
                }}
                zoom={props.zoom}
                options={{
                  styles: props.estilo_mapa ? props.estilo_mapa : false ,
                }}
              >
                {
                  rutas.length > 0 ? rutas.map(route => {
                    return <DirectionsRenderer
                    options={{
                      directions: route,
                      suppressMarkers: true
                    }}
                  />
                  }) : false
                }
                <MostrarMultiPuntos destinos={props.puntos} />
                {
                  marcadores.map((mark,imark) => {
                    return <Marker 
                    key={mark.idusuario}
                    label={(imark+1).toString()}
                    position={{
                    lat: mark.lat,
                    lng: mark.lng
                    }}
                    />
                  })
                }
        </GoogleMap>
        </LoadScript>
    )
    */
  }

const DetallesViaje = (props) => {
    const [pedidoSeleccionado, setPedidoSeleccionado] = useState(false)
    const [loadingPedido, setLoadingPedido] = useState(true)
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [value, setValue] = useState(0);
    const zoom_mapa = 13
    const { id } = useParams();

    function TabPanel(props) {
      const { children, value, index, ...other } = props;
      return (
        <div
          role='tabpanel'
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
      );
    }

    const handleChangeTab = (e, value) => {
      return setValue(value);
    };
    
    const obtenerPedido = (identificador) => {
        if(!identificador) return
        setLoadingPedido(true)
        return fetch(`${urlapi}/viajes?id=${identificador}`,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${sesion.tokenSession}`
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.json()
      })
      .then(res => {
          console.log(res)
          if(!res){
              toast.error('Sin datos')
              return setLoadingPedido(false)
          } else if(res.errorMessage){
              toast.error(res.errorMessage)
              return setLoadingPedido(false)
          } else if(res._id){
            setPedidoSeleccionado(res)
          }
          return setLoadingPedido(false)
      })
      .catch(error => {
          return setLoadingPedido(false)
      })
    }

    useEffect(() => {
        obtenerPedido(id)
    }, [])

    const modalidadPedido = () => {
        return pedidoSeleccionado.modo_programacion === 'programmed' ? <div>
            <Card sx={{ pt: 1, pb: 1, pl: 2, pr: 2, mb:2, backgroundColor: style.highlight}} variant="outlined" >
            <Typography sx={{ mb: 0 }} fontSize={13} color="white" >Este viaje fue recibido bajo la modalidad de programación</Typography>
            <Typography sx={{ mb: 0, color: 'white' }} fontWeight="600" ><AccessTimeIcon fontSize='small' sx={{ mb: -0.7, color: "white"}} /> {fechaATexto(pedidoSeleccionado.fecha_programacion)}</Typography>
            </Card>
          </div> : false
    }

    const showIconByStatus = (mostrar) => {
        if(mostrar !== true) return <AccessTimeIcon fontSize='small' sx={{ mb: -0.7, color: style.secondary }} />
        return <CheckCircleIcon fontSize='small' sx={{ mb: -0.7, color: "green" }} />
    }

    const reasignarConductor = async (driver) => {
        return fetch(`${urlapi}/viajes/reasignar`,{
            method:'PUT',
            body: JSON.stringify({
                _id: pedidoSeleccionado._id,
                id_conductor: driver._id
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(res._id){
                pedidoSeleccionado.conductor = driver
                setPedidoSeleccionado({...{}, ...pedidoSeleccionado})
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

    const seleccionarConductor = (driver) => {
        toast.promise(
          reasignarConductor(driver),
           {
             loading: 'Actualizando...',
             success: <b>Realizado exitosamente</b>,
             error: <b>Error al consultar</b>,
           }
         )
      }

    const asignacionPedido = () => {
        return <div>
          <Card variant="outlined" sx={{ pl:1, pr:1, pt:1, mb:1 }}>
            <Typography sx={{ mb: 0 }} >Conductor designado</Typography>
            <Typography sx={{ mb: 1 }} fontWeight="600" >{obtenerIdentificadorConductor(pedidoSeleccionado.conductor)}</Typography>
            <ModalConductores seleccionarConductor={(driver) => seleccionarConductor(driver)} titulo={pedidoSeleccionado.id_conductor ? "Reasignar conductor" : "Asignar conductor"} />
          </Card>
        </div>
    }

    
    const visualizarPedido = () => {

        if(loadingPedido) return <CircularProgress />
        if(!pedidoSeleccionado) return <SinData />
        const coordenadas = pedidoSeleccionado.puntos.map(i => ({ lat: i.location.latitude, lng: i.location.longitude }))
        const fotos = procesarFotos(pedidoSeleccionado)
    
        return <div>
          <Grid container spacing={2} sx={{ mt:2 }}>
                  <Grid item md={4} xs={12}>
                    <Card>
                      <CardContent>
    
                      <Typography sx={{ mb: 2 }} variant="h4" fontWeight="500" >Viaje {pedidoSeleccionado.id}</Typography>
    
    
                      <Card sx={{ pt: 1, pb: 1, pl: 2, pr: 2, mb:1, backgroundColor: style.primary }} variant="outlined" >
                        <Typography sx={{ mb: 0, color: 'white' }} fontWeight="500" >{pedidoSeleccionado.status_string}</Typography>
                      </Card>
    
                      {asignacionPedido()}
                      {modalidadPedido()}
    
                      <Typography sx={{ mb: 0, color: style.secondary }} >Nombre y apellido</Typography>
                      <Typography sx={{ mb: 2 }} fontWeight="600" >{obtenerIdentificadorCliente(pedidoSeleccionado.cliente)}</Typography>
                      
                      <Typography sx={{ mb: 0, color: style.secondary }} >Móvil</Typography>
                      <Typography sx={{ mb: 2 }} fontWeight="600" >{obtenerTelefonoCliente(pedidoSeleccionado.cliente)}</Typography>
    
                      <Typography sx={{ mb: 0, color: style.secondary }} >Origen</Typography>
                      <Typography sx={{ mb: 2 }} fontWeight="600" >{obtenerDireccionDestino(pedidoSeleccionado.puntos)}</Typography>
    
                      <Grid container >
                        <Grid item xs={6}>
                          <Typography sx={{ mb: 0, color: style.secondary }} >Tipo de viaje</Typography>
                          <Typography fontWeight="600">{ textoTipoViaje(pedidoSeleccionado.tipo_viaje)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{ mb: 0, color: style.secondary }} >Ayudantes</Typography>
                          <Typography sx={{ mb: 2 }} fontWeight="600" >{pedidoSeleccionado.ayudantes}</Typography>
                        </Grid>
                      </Grid>
    
                      <Typography sx={{ mb: 0, color: style.secondary }} >Seguro de viaje</Typography>
                      <Typography sx={{ mb: 2 }} fontWeight="600" >{pedidoSeleccionado.seguro ? <div>
                        <Typography variant="h5">{pedidoSeleccionado.seguro.titulo}</Typography>
                      </div> : "Sin seguro de viaje"}</Typography>
                      
                      <Card variant="outlined" sx={{ p:2, mb:2 }}>
                        <DetallesTarifaBox viaje={pedidoSeleccionado} />
                      </Card>
                      <Typography sx={{ mb: 1 }} variant="h6" >Detalles de la carga</Typography>
                      <MostrarProductos productos={pedidoSeleccionado.productos} />

                      <Grid container >
                        <Grid item xs={12}>
                          <FotografiasViaje fotos={fotos} />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{ mb: 0, color: style.secondary }} >Peso total {"(Kg.)"}</Typography>
                          <Typography sx={{ mb: 2 }} fontWeight="600" >{Intl.NumberFormat("es-ES").format(pedidoSeleccionado.peso)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{ mb: 0, color: style.secondary }} >Volumen total {"(M3.)"}</Typography>
                          <Typography sx={{ mb: 2 }} fontWeight="600" >{Intl.NumberFormat("es-ES").format(pedidoSeleccionado.volumen)}</Typography>
                        </Grid>
                      </Grid>
                      <Typography sx={{ mb: 2 }} variant="h6" >{pedidoSeleccionado.puntos.filter(p => p.tipo !== 'origen').length} Puntos de entrega</Typography>
                      {
                        pedidoSeleccionado.puntos.filter(p => p.tipo !== 'origen').map((punto,i) => {
                          return <div key={punto._id}><Typography sx={{ mb: 0 }} >{i+1} {showIconByStatus(punto.finished)} {punto.description}</Typography></div>
                        })
                      }
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    { /** <MapaOperacion
                        height={500}
                        zoom={zoom_mapa + 1}
                        center={centerMapCoordinates(coordenadas)}
                        destination={obtenerDireccionEntrega(pedidoSeleccionado.puntos,1)}
                        origin={obtenerDireccionDestino(pedidoSeleccionado.puntos)}
                        puntos={pedidoSeleccionado.puntos}
                        travelMode="DRIVING"
                        estilo_mapa={MapStyleDefault}
                        markers={pedidoSeleccionado.puntos.map(p => ({ lat: p.location.latitude, lng: p.location.longitude }))}
                        polyline={[]}
                        isMarkerShown
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=${maps_key}`}
                        loadingElement={<div style={{ height: `100%` }} >
                            <div className="loadingbox">
                        <img alt="loading"  src="img/cargando.gif" />
                            <h3>Cargando mapa...</h3>
                        </div>
                        </div> }
                        containerElement={<div style={{ height: `600px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        className="mt-3"
                    /> */}
                    <Box sx={{ width: '100%' }}>
                    <Tabs
                      value={value}
                      onChange={handleChangeTab}
                      textColor='primary'
                      indicatorColor='primary'
                      aria-label='primary tabs example'
                    >
                      <Tab
                        // icon={<CheckCircleOutlineIcon />}
                        iconPosition='start'
                        value={0}
                        label='Eventos'
                      />
                      <Tab
                        // icon={<DeleteIcon />}
                        iconPosition='start'
                        value={1}
                        label='Chat interno'
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <ListadoLogViaje show_title={false} condicion_default={{ id_viaje: pedidoSeleccionado._id }} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                      <ChatViaje id_viaje={id} id_cliente={pedidoSeleccionado.id_cliente} />
                  </TabPanel>
                  </Grid>
          </Grid>
        </div>
    }

    const render = () => {
        return <div>
            {visualizarPedido()}
        </div>
    }
    return <Structure component={render()} />
    
}

export default DetallesViaje