const guardarUsuario = objeto => {
    return {
        type: 'guardarUsuario',
        payload: objeto
    }
}

const cerrarSesion = objeto => {
    return {
        type: 'cerrarSesion',
        payload: objeto
    }
}

module.exports = {
    guardarUsuario,
    cerrarSesion
}