import { Avatar, Grid, Skeleton } from "@mui/material"

const datos = [
    {},
    {},
    {}
]

const CargandoTabla = () => {
    return <div>
        
        { datos.map((driver,i) => (
            <Grid container sx={{ mb: 3 }} spacing={3} >
                   <Grid item xs={2}>
                        <Skeleton variant="circular" >
                            <Avatar sx={{ height: 80, width: 80 }} />
                        </Skeleton>
                    </Grid>
                   <Grid item xs={3} ><Skeleton height={80} variant="rectangular" /></Grid>
                   <Grid item xs={3} ><Skeleton height={80} variant="rectangular" /></Grid>
                   <Grid item xs={4} ><Skeleton height={80} variant="rectangular" /></Grid>
            </Grid>
        ))}
        
    </div>
}

export default CargandoTabla