import CargandoTabla from "./cargando"
import { Box, Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, LinearProgress, MenuItem, Pagination, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from "react"
import data from '../../lib/backend/data'
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { fechaATexto } from "../../lib/helpers/helpers"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import socket from '../../lib/websockets';
import ErrorIcon from '@mui/icons-material/Error';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ListadoChatSoporte = (props) => {
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ vehiculos, setVehiculos] = useState([])
    const [ showModal, setShowModal] = useState(false)
    const [ currentRequisito, setCurrentRequisito] = useState(false)
    const [ loadingVehiculos, setLoadingVehiculos] = useState([])
    const [ loadingCambios, setLoadingCambios] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ idLoading, setIdLoading ] = useState(false)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const trash = props.trash ? props.trash : false
    const show_filter = props.show_filter ? props.show_filter : false
    const titulo = props.title ? props.title : 'registros'
    const categorias = props.categorias ? props.categorias : []
    
    const listadoChats = useRef(false)
    listadoChats.current = vehiculos

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerDatos(page)
    }
    
    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerDatos(page,false)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination sx={{ mt:2 }} color="primary" count={cantidad} page={pagina} onChange={handleChangePagina} />
        </div>
    }

    const obtenerDatos = async (page, query)=>{
        setLoadingVehiculos(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/atencioncliente/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingVehiculos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingVehiculos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setVehiculos(res.datos)
                setTotal(res.total)
            }
            return setLoadingVehiculos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingVehiculos(false)
        })
    }

    const procesarMensajes = (datos) => {
        const lista = listadoChats.current
        if(!datos.id_chat) return false
        const i = lista.findIndex(c => c._id === datos.id_chat)
        if(i < 0) return false
        let mover = lista[i]
        if(datos.from !== "soporte"){
            mover.showNotification = true
        }
        lista.splice(i,1)
        lista.unshift(mover)
        listadoChats.current = lista
        return setVehiculos(prev => [...[], ...lista])
    }

    useEffect(() => {
        obtenerDatos(1, false)
        socket.on("chat_soporte_global", procesarMensajes)
        return () => socket.off("chat_soporte_global", procesarMensajes)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!campoBusqueda) return toast.error('Selecciona una patente')
        const condicion = { $text: { $search: campoBusqueda.toLowerCase() }, activo: true }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerDatos(1, condicion)
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target
        return setCampoBusqueda(value)
    }

    const filtro = () => {
        if(!show_filter) return false
        return <div>
            <Grid container >
                <Grid item xs={12}>
                    <Box component="form" noValidate onSubmit={handleSubmit}  sx={{ mt: 1, mb: 1 }}>
                        <TextField id="outlined-basic" label="Buscar por nombre, apellido, email o móvil" variant="outlined" onChange={handleChangeCampo} fullWidth />
                    </Box>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={6}>{paginacion(data.pagina, total)}</Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}><h3 sx={{ m:0 }} >{total} {titulo}</h3></Grid>
            </Grid>
        </div>
    }

    const obtenerRegistro = (id) => {
        const i = vehiculos.findIndex(l => l._id === id)
        if(i > -1) vehiculos[i].showNotification = false
        listadoChats.current = vehiculos
        setVehiculos(prev => [...[], ...vehiculos])
        if(props.onSelectedChat) return props.onSelectedChat(id)
    }

    const handleModalRF = () => {
        return setShowModal(false)
    }

    const guardarCambios = () => {
        setLoadingCambios(true)
        return fetch(`${data.urlapi}/requisitos`,{
            method:'PUT',
            body: JSON.stringify(currentRequisito),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingCambios(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCambios(false)
            } else if(res._id){
                toast.success('Actualizado exitosamente')
                const instance = vehiculos
                const i = instance.findIndex(v => v._id === res._id)
                if(i > -1){
                    instance[i] = res
                    setVehiculos(prev => [...[], ...instance])
                }
                setShowModal(false)
            }
            return setLoadingCambios(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCambios(false)
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        currentRequisito[name] = value
        return setCurrentRequisito(prev => ({...{}, ...currentRequisito}))
    }

    const ModalRegistrosFotograficos = () => {
        let titulo = 'No disponible'
        if(!showModal) return false

        if(typeof currentRequisito.permiso_modelo === 'object'){
            titulo = currentRequisito.permiso_modelo.titulo
        }

        return <Dialog
            open={showModal}
            fullWidth={true}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            onClose={()=>handleModalRF()}
            aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle id="alert-dialog-slide-description">{titulo}</DialogTitle>
        <DialogContent>
            <Grid container spacing={3}>
                <Grid item md={8}>
                    {
                        currentRequisito.valor ? <img src={currentRequisito.valor} style={{ width: '100%' }} /> : <div>
                            <ErrorOutlineIcon fontSize="large" />
                            <Typography variant="h6" xs={{ mb:0 }}>Sin datos cargados</Typography>
                        </div>
                    }
                </Grid>
                <Grid item md={4}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Detalles</Typography>
                    <TextField 
                        sx={{ mb: 2 }} 
                        id="outlined-basic" 
                        label="Observaciones" 
                        name="comentario" 
                        onChange={handleChange} 
                        value={currentRequisito.comentario} 
                        variant="outlined" fullWidth 
                    />
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Estado"
                        sx={{ mb: 2 }}
                        fullWidth
                        name="status"
                        value={currentRequisito.status}
                        onChange={handleChange}
                        >
                        <MenuItem value="pendiente">Pendiente</MenuItem>
                        <MenuItem value="rechazado">Rechazado</MenuItem>
                        <MenuItem value="aprobado">Aprobado</MenuItem>
                    </TextField>

                    { loadingCambios === true ? <CircularProgress /> : 
                    <Button variant="contained" color="success" fullWidth onClick={()=>guardarCambios()} >GUARDAR</Button> }

                </Grid>
            </Grid>
        </DialogContent>
        </Dialog>
    }

    const mostrarRegistros = () => {
        if(vehiculos.length < 1) return <h3>No hay registros</h3>

        const ids_categorias = categorias.map(c => c._id)
        const filtrados = vehiculos.filter(c => ids_categorias.includes(c.id_topic))
        
        return <div>
            <Card>
                { filtrados.map((data) => {
                    return <div key={data._id} sx={{ mb: 1 }} onClick={() => obtenerRegistro(data._id)}>
                        <CardContent>
                            { data.showNotification === true ? <ErrorIcon color="error" /> : false }
                            <Grid container >
                                <Grid item sx={6}><Typography style={{ fontSize: 13 }}>{data.tipo_creador.toUpperCase()}: <b>{data.identificador.toUpperCase()}</b></Typography></Grid>
                            </Grid>
                            <Typography mb={0} component="div" variant="p" sx={{ fontSize: 10 }} >CREADO: {data.fecha_zona_horaria_local ? fechaATexto(data.fecha_zona_horaria_local) : ''}</Typography>
                            <Typography mb={0} component="div" variant="p"sx={{ fontSize: 16, fontWeight: 'bold' }} >{data.topic}</Typography>
                            { data.id_viaje ? <Typography mb={0} variant="p" sx={{ fontSize: 10 }} ><DirectionsCarIcon /> ESTE CHAT ES SOBRE UN VIAJE</Typography> : false }
                        </CardContent>
                        <Divider />
                    </div>
                })}
            </Card>
        </div>
    }

    if(loadingVehiculos) return <CargandoTabla />

    return <div>
        {mostrarRegistros()}
    </div>
}

export default ListadoChatSoporte