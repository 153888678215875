import React, { useState} from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useHistory, useLocation } from 'react-router-dom'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { userService } from '../../services/user.service'
import { CircularProgress } from '@mui/material';
import { toast } from 'react-hot-toast'
import { useDispatch } from 'react-redux';
import { guardarUsuario } from '../../redux/actions/session';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://delpa.com/">
        Delpa Group
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const Login = () => {
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  let location = useLocation();


  const handleChangeEmail = (e) => {
    const { value } = e.target
    return setEmail(value)
  }
  const handleChangePassword = (e) => {
    const { value } = e.target
    return setPassword(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!(email && password )) {
        return alert("Sin datos")
    }

    setLoading(true)
    userService.login(email, password )
        .then(
            user => {
                if(user.tokenSession){
                    const { from } = location.state || { from: { pathname: "/" } };
                    dispatch(guardarUsuario(user))
                    history.push(from);
                } else if( user.errorMessage ){
                    toast.error(user.errorMessage)
                    setLoading(false)
                }
            },
            error => {
              return setLoading(false)
            }
        )
}
    return <ThemeProvider theme={theme}>
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url("/images/admin.jpg")',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Inicia sesión
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit}  sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChangeEmail}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChangePassword}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recordarme"
            />
            {
                loading ? <div>
                    <CircularProgress />
                </div> : <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >INICIAR SESIÓN</Button>
            }
            <Grid container>
              <Grid item xs>
                <Link to="/olvide-contrasena" variant="body2">
                  ¿Olvidaste tu contraseña?
                </Link>
              </Grid>
              { /** <Grid item>
                <Link to="/registrarse" variant="body2">
                  Aún no tienes una cuenta? regístrate
                </Link>
          </Grid> */ }
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  </ThemeProvider>

}

export default Login