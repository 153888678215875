import { useState } from "react"
import data from '../../lib/backend/data'
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { MapStyleDefault } from "../../lib/global/data";
import { Button, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";

const Mapa = (props) => {

    const polygon = props.polygon ? props.polygon : false

    const onLoad = drawingManager => {
        console.log(drawingManager)
    }

    const onPolygonComplete = polygon => {
        if(props.onPolygonComplete) return props.onPolygonComplete(polygon)
    }

    return false
    /*
    return <LoadScript
    googleMapsApiKey={maps_key}
    libraries={["drawing"]}
    >
      <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '60vh',
        borderRadius: 10
      }}
  defaultZoom={8}
  zoom={props.zoom}
  center={props.center}
  options={{
    styles: MapStyleDefault,
  }}
  >
  <DrawingManager
        drawingMode="polygon"
        onLoad={onLoad}
        defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: "TOP_CENTER",
              drawingModes: [
                "POLYGON",
              ],
            },
            circleOptions: {
              fillColor: `#ffff00`,
              fillOpacity: 1,
              strokeWeight: 5,
              clickable: false,
              editable: true,
              zIndex: 1,
            },
        }}
        onPolygonComplete={onPolygonComplete}
    />
    {
        polygon ? <Polygon 
        paths={polygon}
        /> : false
    }
  
  </GoogleMap>
    </LoadScript>
    */
  }

const GeocercaDrawer = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const token = props.token ? props.token : false
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const centro_mapa = props.centerMap
    const zoom_mapa = 14
    const [ marcadores, setMarcadores ] = useState([])
    const polygon = props.polygon ? props.polygon : false

    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/ordenes/campos-personalizados`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/ordenes/campos-personalizados`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        nuevoProveedor[name] = value
        return setNuevoProveedor(nuevoProveedor)
    }


    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/ordenes/campos-personalizados?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const onPolygonComplete = (data) => {
        if(props.onPolygonComplete) return props.onPolygonComplete(data)
    }

    const View = () => {

        return <div>
        <Mapa
                    zoom={zoom_mapa}
                    onPolygonComplete={(polygon) => onPolygonComplete(polygon)}
                    polygon={polygon}
                    center={centro_mapa}
                    polyline={[]}
                    markers={marcadores}
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=AIzaSyA2UVu71Lhw-lWjuTloT-VGIylp_63lgRY"
                    loadingElement={<div style={{ height: 500 }} >
                        <div className="loadingbox">
                    <img alt="loading"  src="img/cargando.gif" />
                        <h3>Cargando mapa...</h3>
                    </div>
                    </div> }
                    containerElement={<div style={{ height: `600px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    className="mt-3"
                    />
            {
                loading === true ? <CircularProgress /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </div>
    }

    return <div>
        {View()}
    </div>
}

export default GeocercaDrawer