import CargandoTabla from "./cargando"
import { Box, Button, Card, CardContent, CircularProgress, Divider, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useEffect, useState } from "react"
import data from '../../lib/backend/data'
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { fechaATexto, fechaATextoSimple } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { obtenerIdentificadorCliente } from "../../lib/helpers/clientes"
import { obtenerDireccionDestino } from "../../lib/helpers/viajes"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { addMoreDays, formatDateHoy, getLastAndFirstDayWeek } from "../../lib/helpers/dates"
import { montoMoneda } from "../../lib/helpers/main"

const ListadoGanancias = (props) => {
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState([])
    const [ conteos, setConteos] = useState([])
    const [ pagina, setPagina ] = useState(1)
    const [ loadingMore, setLoadingMore ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ totalPagado, setTotalPagado ] = useState(0)
    const [ skip, setSkip ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const trash = props.trash ? props.trash : false
    const titulo = props.title ? props.title : 'registros'
    const id_conductor = props.id_conductor ? props.id_conductor : false
    
    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerConductores(page, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination  sx={{ mt:2 }} color="primary" count={cantidad} page={pagina} onChange={handleChangePagina} />
        </div>
    }

    const obtenerConductores = async (fecha, id_conductor, cargar_mas)=>{
        if(cargar_mas){
            setLoadingMore(true)
        } else {
            setLoadingConductores(true)
        }
        return fetch(`${data.urlapi}/ganancias/list`,{
            method:'POST',
            body: JSON.stringify({
                skip: 10, 
                fecha, 
                id_conductor
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(Array.isArray(res.segmentos) !== false){
                setTotal(res.total_ganancia)
                setTotalPagado(res.total_pagado)
                if(cargar_mas === true){
                    setConductores(prev => [...conductores, ...res.segmentos])
                } else {
                    setConductores(res.segmentos)
                }
            }
            if(cargar_mas){
                return setLoadingMore(false)
            } else {
                return setLoadingConductores(false)
            }
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            if(cargar_mas){
                return setLoadingMore(false)
            } else {
                return setLoadingConductores(false)
            }
        })
    }

    useEffect(() => {
        const date = formatDateHoy(new Date())
        obtenerConductores(date, id_conductor)
    }, [])

    const seleccionarPedido = (id) => {
        if(props.onSelectedTrip) props.onSelectedTrip(id)
        return false
    }

    const dibujarBarra = (height) => {
        return <div style={{ width: "100%", height, backgroundColor: "green" }}></div>
      }

    if(loadingConductores) return <CargandoTabla />

    if(conductores.length < 1) return <Typography variant="h6">No hay registros</Typography>

    return <div>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Card sx={{ mb:3}}>
                        <CardContent>
                        <Typography sx={{ fontSize: 14 }} gutterBottom>TOTAL GENERADO</Typography>
                        <Typography variant='h5' component='div'>${montoMoneda(total)}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card sx={{ mb:3}}>
                        <CardContent>
                        <Typography sx={{ fontSize: 14 }} gutterBottom>TOTAL PAGADO</Typography>
                        <Typography variant='h5' component='div'>${montoMoneda(totalPagado)}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
                    
            {
                conductores.map((req,pos) => {

                    const config_fecha = { day:"numeric", month: "short" }
                
                    let mayor = 0
    
                    for(const ga of req.ganancias){
                      mayor = Math.max(mayor, ga.total)
                    }
                    const maxHeight = 32
                    let lun = parseInt(maxHeight * (req.ganancias[0].total / mayor * 100) / 100)
                    if(isNaN(lun)) lun = 0
                    let mar = parseInt(maxHeight * (req.ganancias[1].total / mayor * 100) / 100)
                    if(isNaN(mar)) mar = 0
                    let mier = parseInt(maxHeight * (req.ganancias[2].total / mayor * 100) / 100)
                    if(isNaN(mier)) mier = 0
                    let jue = parseInt(maxHeight * (req.ganancias[3].total / mayor * 100) / 100)
                    if(isNaN(jue)) jue = 0
                    let vier = parseInt(maxHeight * (req.ganancias[4].total / mayor * 100) / 100)
                    if(isNaN(vier)) vier = 0
                    let sab = parseInt(maxHeight * (req.ganancias[5].total / mayor * 100) / 100)
                    if(isNaN(sab)) sab = 0
                    let dom = parseInt(maxHeight * (req.ganancias[6].total / mayor * 100) / 100)
                    if(isNaN(dom)) dom = 0

                    
                    return <Card sx={{ mb:2 }}>
                            <CardContent>
                  <div >
                    <Grid container spacing={2}>
                        <Grid item xs={6} sx={{ fontSize:12 }}>{ fechaATextoSimple(req.segmento_fecha_firstday, config_fecha, false).toUpperCase()}</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right", fontSize:12 }} >{fechaATextoSimple(req.segmento_fecha_lastday, config_fecha, false).toUpperCase()}</Grid>
                    </Grid>
                    <Divider sx={{ mt:1, mb:1 }} />
                    <Typography variant="h5" sx={{ fontWeight: "bold", mb:0 }}>TOTAL ${montoMoneda(req.total)}</Typography>
                    <Divider sx={{ mt:1, mb:1 }} />
                  </div>
                    <Grid container spacing={2}>
                        <Grid item >
                          <Typography style={{ fontWeight: "bold", marginBottom: 10 }}>D</Typography>
                          <div style={{ justifyContent: "flex-end", flex: 1 }}>
                          {dibujarBarra(dom)}
                          </div>
                        </Grid>
                        <Grid item >
                          <Typography style={{ fontWeight: "bold", marginBottom: 10 }}>L</Typography>
                          <div style={{ justifyContent: "flex-end", flex: 1 }}>
                          {dibujarBarra(lun)}
                          </div>
                          </Grid>
                        <Grid item >
                          <Typography style={{ fontWeight: "bold", marginBottom: 10 }}>M</Typography>
                          <div style={{ justifyContent: "flex-end", flex: 1 }}>
                          {dibujarBarra(mar)}
                          </div>
                        </Grid>
                        <Grid item >
                          <Typography style={{ fontWeight: "bold", marginBottom: 10 }}>M</Typography>
                          <div style={{ justifyContent: "flex-end", flex: 1 }}>
                          {dibujarBarra(mier)}
                          </div>
                        </Grid>
                        <Grid item >
                          <Typography style={{ fontWeight: "bold", marginBottom: 10 }}>J</Typography>
                          <div style={{ justifyContent: "flex-end", flex: 1 }}>
                          {dibujarBarra(jue)}
                          </div>
                        </Grid>
                        <Grid item >
                          <Typography style={{ fontWeight: "bold", marginBottom: 10 }}>V</Typography>
                          <div style={{ justifyContent: "flex-end", flex: 1 }}>
                          {dibujarBarra(vier)}
                          </div>
                        </Grid>
                        <Grid item >
                          <Typography style={{ fontWeight: "bold", marginBottom: 10 }}>S</Typography>
                          <div style={{ justifyContent: "flex-end", flex: 1 }}>
                          {dibujarBarra(sab)}
                          </div>
                        </Grid>
                    </Grid>
                    </CardContent>
                    </Card>
                })
            }
            { loadingMore ? <CircularProgress /> : <Button onClick={() => obtenerConductores(addMoreDays(conductores[conductores.length-1].segmento_fecha_lastday, 2), id_conductor, true) }>CARGAR MAS</Button> }
    </div>
}

export default ListadoGanancias