import React, { useEffect, useState } from "react"
import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Slide, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import ListadoDestinatarios from "./listado";

const ModalDestinatarios = (props) => {
    const {
        custom_component,
        showSeleccionado,
        condicion_default,
        seleccionado
    } = props
    const titulo = props.titulo ? props.titulo : 'Registros'
    const informacion = props.info ? props.info : ''
    const condicion = condicion_default ? condicion_default : { }
    const [ empresa, setEmpresa ] = useState(false)
    const [modalShow, setMotalShow] = useState(false)

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });
    
    const closeModal = () => {
        return setMotalShow(false)
    }
    
    const openModal = () => {
        return setMotalShow(true)
    }

    const seleccionarEmpresa = (driver) => {
        if(props.seleccionarEmpresa) props.seleccionarEmpresa(driver)
        // setEmpresa(driver)
        return setMotalShow(false)
    }

    const mostrarSeleccionado = () => {
        if(!showSeleccionado) return false
        if(showSeleccionado !== true) return false
        if(!seleccionado) return false
        return <Button variant="outlined" color="inherit" sx={{ width: "100%" }} size="small" onClick={() => seleccionarEmpresa(false)}>REMOVER</Button>
    }

    const modalDatos = () => {
        if(!modalShow) return false
        return <Dialog
        open={modalShow}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="xl"
      >
        <DialogTitle><CloseIcon style={{ marginBottom: -4 }} onClick={() => closeModal()} /> {titulo} </DialogTitle>
        <DialogContent>        
        <Typography sx={{ mb:1 }}>{informacion}</Typography>
            <ListadoDestinatarios hideExporter={true} tipo="funcion" seleccionarVehiculo={(data) => seleccionarEmpresa(data)} condicion_default={condicion}  />
        </DialogContent>
        </Dialog>
    }

    const mostrarBoton = () => {
        if(custom_component) return <div onClick={() => openModal()}>{custom_component}</div>
        return <div>
            <ButtonGroup sx={{ width: "100%" }}>
            <Button variant="outlined" color="inherit" sx={{ mb:0, width: "100%" }} onClick={() => openModal()} >{seleccionado ? seleccionado.razon_social : titulo}</Button>
            {mostrarSeleccionado()}
            </ButtonGroup>
        </div>
    }

    return <Box >
        {mostrarBoton()}
        {modalDatos()}
    </Box>

}

export default ModalDestinatarios