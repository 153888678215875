import Map, { Marker } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Tooltip } from '@mui/material';
import Moment from 'react-moment';

const MapaVehiculosEstatico = (props) => {
    const {
        height,
        marcadores
    } = props
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const initial_viewstate = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(initial_viewstate);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()

    const centrarMapa = (centro, max_zoom) => {
      if(isNaN(parseFloat(centro.lat)) || isNaN(parseFloat(centro.lng))) return false
      const nuevo_zoom = initial_viewstate.zoom + (max_zoom ? max_zoom : 3)
      console.log({centro, nuevo_zoom })
      mapRef.current?.flyTo({center: [centro.lng, centro.lat], zoom: nuevo_zoom, duration: 2000});
    }

    const ajustarCentro = (markers) => {
      if(markers.length === 1){
        return centrarMapa(markers[0], 3)
      }
        //if(zoomInit) return false
        if(markers.length < 1) return false
        //setZoomInit(true)

        const markers_validos = markers.filter(ma => {
          if(isNaN(parseFloat(ma.lat)) || isNaN(parseFloat(ma.lng))) return false
          return true 
        })

        const points = markers_validos.map(marker => turf.point([marker.lng, marker.lat]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2,
        zoom: initial_viewstate.zoom + 3
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current?.fitBounds(bounds, options);
    }

    const handleLoad = () => {
      ajustarCentro(marcadores)
    }
    

    return <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: height ? height : 400}}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
    onLoad={handleLoad}
  >
    {
        marcadores.map(marker => {

          if(isNaN(parseFloat(marker.lat)) || isNaN(parseFloat(marker.lng))) return false

            return <Marker
                    key={marker._id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                  <Tooltip title={<div>
                    <Moment locale="ES" fromNow>{marker.fecha}</Moment>
                  </div>} placement="top">

                <DirectionsCarIcon />
                      </Tooltip>
            </Marker>
        })
    }
  </Map>
}

export default MapaVehiculosEstatico