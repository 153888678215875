import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    TextField,
    Typography,
    Card,
    Modal,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Divider,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    InputAdornment,
    Dialog,
    Slide,
    DialogContent,
    CardContent,
    Autocomplete,
    ButtonGroup,
    Grid,
  } from '@mui/material';
  import React, { useState } from 'react';
  import Structure from '../Structure';
  import { Link } from 'react-router-dom';
  import { Link as Enlace } from '@mui/material';
  import { useDispatch, useSelector } from 'react-redux';
  import { cerrarSesion } from '../../redux/actions/session';
import { toast } from 'react-hot-toast';
import { style, urlapi } from '../../lib/backend/data';
import * as XLSX from 'xlsx'
import DangerousIcon from '@mui/icons-material/Dangerous';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SelectorMarcas from './selectores/marca';
import SelectorTipo from './selectores/tipo_vehiculo';
import SelectorModelo from './selectores/modelo';
import SelectorColor from './selectores/color';
import ModalEmpresas from '../Empresas/modal_seleccion';
import { rutas } from '../../lib/data/rutas';
import InformacionEsquema from '../../subcomponents/general/esquema_excel';
import SelectorGeneral from '../../subcomponents/general/selector_general';
import { obtenerUnidadesMedidaPorTipoPais } from '../../lib/helpers';
import { MdPublishedWithChanges } from 'react-icons/md'
import { AiOutlineLink } from 'react-icons/ai'
import { extraerValor } from '../../lib/helpers/main';
import { category_key, key, module_name } from './data';
import ModalDestinatarios from '../Destinatarios/modal_seleccion';

  const BodegasImport = () => {
    const pais                                  = useSelector(state => state.pais)
    const session                               = useSelector(state => state.miusuario)
    const [ showUploadBox, setShowUploadBox ]   = useState(true)
    const [ procesados, setProcesados ]         = useState(0)
    const [ modalProgress, setModalProgess ]    = useState(false)
    const [ modalAsignacionColumna, setModalAsignacion ] = useState(false)
    const [ datos, setDatos ] = useState([])
    const [ llaveAsignar, setLlaveAsignar ]     = useState(false)
    const [ validando, setValidando ]           = useState(false)
    const [ datosExcelSinProcesar, setDatosExcelSinProcesar ] = useState([])
    const [ errores, setErrores ]               = useState([])
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ empresa, setEmpresa ]               = useState(false)
    const [ destinatario, setDestinatario ]               = useState(false)
    const [ modalAsignacionValorMasivo, setModalAsignacionValorMasivo ]               = useState(false)
    const [ columnasExcel, setColumnasExcel ]   = useState([])
    const unidades_medida                       = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso                  = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const dispatch                              = useDispatch()
    const idioma                                = useSelector(state => state.idioma)

    const esquema = [
        { value: 'titulo', label: 'Titulo', hide_replace_cell:true, },
        { value: 'direccion', label: 'Direccion', optionalUpload: true, hide_replace_cell:true, }
    ]

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

    const obtenerNombrePorLlaveIdioma = (key) => {
        const i = esquema.findIndex(e => e.value === key)
        if(i > -1){
            return esquema[i].label
        }
        return key
    }

    const obtenerTextoAyudaPorLlaveIdioma = (key) => {
        const i = esquema.findIndex(e => e.value === key)
        if(i > -1){
            return esquema[i].helptext
        }
        return key
    }

    const procesarExcel = (target) => {

        let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            let hojas = []
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array', cellDates:true, dateNF:'dd.mm.yyyy' });
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })
            console.log(hojas)
            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            if(!hojas[0][0]) return toast.error('No se encontraron datos')
            
            let errores             = []
            let registros           = []
            let nombres_columnas    = []
            
            if(errores.length > 0) return toast.error(errores.join(', ')) // SI HAY ERRORES DETENER

            let pos = 0
            for( const hoja of hojas[0] ){

                // RECOPILAMOS LAS LLAVES DEL EXCEL
                Object.keys(hoja).map(key => {
                    const llave_limpia = key.toLocaleLowerCase()
                    if(!nombres_columnas.includes(llave_limpia)) nombres_columnas.push(llave_limpia)
                })

                pos++
                let objeto = {}
                for( const key of esquema ){
                    if(key.denieUpload === true) continue
                    const llaves_obtenidas = Object.keys(hoja)
                    const i = llaves_obtenidas.findIndex(llave => llave.toLowerCase() === key.label.toLocaleLowerCase())
                    if(i < 0){
                        errores.push(`${key.label} no encontrado en la columna ${pos}`)
                        continue
                    } else {
                        objeto[key.value] = hoja[ llaves_obtenidas[i] ]
                    }
                }
                registros.push(objeto)
            }

            if(errores.length > 0){
                toast.error("Errores encontrados en tu archivo, Revisa los errores encontrados en tu archivo")
            }

            setColumnasExcel(nombres_columnas)
            const limpiar_llaves_para_filtro = hojas[0].map(info => {
                let objeto_limpio = {}
                Object.keys(info).map(ll => {
                    objeto_limpio[ll.toLowerCase()] = info[ll]
                })
                return objeto_limpio
            })
            setDatosExcelSinProcesar(limpiar_llaves_para_filtro)
            setShowUploadBox(false)
            return setDatos(registros)
        }
    }
    
      const handleChangeFile = (e) => {
        procesarExcel(e.target)
      };
    
      const handleChangeSpecial = (name, objeto, pos) => {
          if(objeto){
              const valor = extraerValor(objeto)
                console.log({ name, objeto, valor, pos })
            datos[pos][name] = valor
            if(name === "id_tipo") datos[pos].tipo = objeto.tipo
        } else {
            datos[pos][name] = ''
        }
        console.log(datos)
        return setDatos([...[], ...datos])
      }
      
      const handleChangeSpecialMasivo = (name, objeto) => {
        const instancia         = JSON.parse( JSON.stringify(datos))
        const valor             = extraerValor(objeto)
        const iterado           = instancia.map((item => {
            item[name] = valor
            return item
        }))
        handleCloseAsignacion()
        console.log(iterado)
        return setDatos([...[], ...iterado])
      }

      const subirInformacion = async () => {
        setErrores([...[], ...[]])
        const instancia_datos = JSON.parse( JSON.stringify(datos))

        const invalidos = instancia_datos.filter(dato => {
            for( const key of esquema ){
                if(key.optionalUpload === true) continue
                const llaves_obtenidas = Object.keys(dato)
                const i = llaves_obtenidas.findIndex(llave => llave.toLowerCase() === key.value.toLocaleLowerCase())
                if(i < 0) return true
            }
            return false
        })

        
        if(invalidos.length > 0){
            return toast.error("Error, Tienes registros inválidos, corrije la información e intenta de nuevo")
        }
        
        let validations = []
        for( const u of datos){
            validations = [...validations, ...[
                // { key: "patente", value: u.patente.toString().toUpperCase() }
            ]]
        }
        const validacion_previa = await validarCampos(validations)
        if(!validacion_previa) return setValidando(false)

        setModalProgess(true)
        let imposibles = []
        for( const user of datos ){
            const exito = await crearRegistroIndividual(user)
            if(!exito) imposibles.push(1)
        }
        setModalProgess(false)
        setTimeout(() => {
            setProcesados(0)
        }, 500);
        console.log(errores)
        if(errores.length > 0 || imposibles.length > 0){
            toast.error("Proceso finalizado con errores, Revisa los errores que ocurrieron")
            return setShowModalErrores(true)
        } else {
            toast.success("Proceso finalizado, Datos cargados")
        }
        setShowUploadBox(true)
        setDatos([...[], ...[]])
        return setDatosExcelSinProcesar([...[], ...[]])
      }

      const validarCampos = async (arreglo)=>{
        setErrores([...[], ...[]])
        setValidando(true)
        return fetch(`${urlapi}/${module_name}/validate`, {
            method:'POST',
            body: JSON.stringify(arreglo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            setValidando(false)
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos del servidor')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(typeof res.success !== "undefined"){
                if(res.success === true){
                    return true
                } else {
                    if(res.errors){
                        if(Array.isArray(res.errors) !== false){
                            const problemas_encontrados = res.errors.map(error => {
                                const mensaje = `${obtenerNombrePorLlaveIdioma(error.key)} ya está en uso: ${error.value}`
                                return mensaje
                            })
                            setShowModalErrores(true)
                            console.log(problemas_encontrados)
                            setErrores(problemas_encontrados)
                        }
                    }
                }
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

    const crearRegistroIndividual = async (usuario)=>{
        const progreso = procesados + 1
        const usuario_crear = JSON.parse( JSON.stringify(usuario))
        usuario_crear.status = "active"
        usuario_crear.validado = true
        if(empresa) usuario_crear.propietario = empresa.rut
        if(destinatario) usuario_crear.id_cliente = destinatario._id
        return fetch(`${urlapi}/${module_name}`,{
            method:'POST',
            body: JSON.stringify(usuario_crear),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            setProcesados(progreso)
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                setErrores([...[], ...[res.errorMessage]])
                return false
            } else if(res._id){
                return res._id
            }
        })
        .catch(error => {
            return false
        })
    }
    
    const handleCloseAsignacion = () => {
        setModalAsignacion(false)
        setModalAsignacionValorMasivo(false)
    }

    const mostrarErrores = () => {
        return <Dialog   style={{ padding: 30 }} open={showModalErrores} onClose={() => {
            setShowModalErrores(false)
            setErrores([...[], ...[]])
        }}>
            <DialogContent>
            <DangerousIcon style={{ fontSize: 40, color:"red" }} />
        <Typography variant='h4' fontSize={29} className="mt-0 mb-0"> Errores encontrados</Typography>
        <Typography variant='h4'fontSize={16} className="mt-0 mb-3">Encontramos los siguientes errores al validar la información que intentas cargar, corrígelos y vuelve a intentarlo</Typography>

            <List>
                {
                    errores.map(error => {
                        return <ListItem>
                        <ListItemText primary={error} />
                      </ListItem>
                    })
                }
            </List>
            </DialogContent>
        </Dialog>
    }

    const handleClose = () => {
        return setModalProgess(false)
    }

    const mostrarModalProgreso = () => {
        return <Dialog  style={{ textAlign: "center", padding: 30 }} keepMounted open={modalProgress} onClose={handleClose}>
            <DialogContent>
        <CircularProgress />
        <Typography  className="mt-0 mb-0"> Cargando tu información</Typography>
        <Typography  className="mt-0 mb-0">{procesados} procesados de {datos.length}</Typography>
        <Typography  className="mb-0 mt-0">No cierres la ventana por favor...</Typography>
        </DialogContent>
        </Dialog>
    }

    const asignarLlaveExcelADatos = (key) => {
        const instancia_datos = JSON.parse( JSON.stringify(datos))
        console.log(key,llaveAsignar,datosExcelSinProcesar,instancia_datos)
        const nuevo_arreglo = instancia_datos.map((dato,i) => {
            dato[llaveAsignar] = datosExcelSinProcesar[i][key]
            return dato
        })
        setDatos([...[], ...nuevo_arreglo])
        return setModalAsignacion(false)
    }

    const componentePorLlave = () => {
        switch (llaveAsignar) {
            case "id_categoria":
                return <div>
                    <SelectorGeneral strict={true} hideLabel={true} module="categorias" condicion_default={{ tipo: category_key }} onChange={data => handleChangeSpecialMasivo(llaveAsignar, data )} />
                </div>
            case "id_sub_categoria":
                return <div>
                    <SelectorGeneral strict={true} hideLabel={true} module="sub-categorias" condicion_default={{ tipo: category_key }} onChange={data => handleChangeSpecialMasivo(llaveAsignar, data )} />
                </div>
            case "color":
                return <div>
                    <SelectorColor strict={true} onChange={data => handleChangeSpecialMasivo(llaveAsignar, data )} />
                </div>
            case "unidad_medida":
                return <div>
                    <Autocomplete 
                                                disablePortal
                                                noOptionsText="Sin opciones" 
                                                renderInput={(params) => <TextField {...params} />}
                                                options={unidades_medida.map(e => ({ value: e.code, label: e.unit }))}
                                                onChange={(e,val) => handleChangeSpecialMasivo(llaveAsignar, val)} 
                                            />
                </div>
            case "unidad_medida_peso":
                return <div>
                    <Autocomplete 
                                                disablePortal
                                                noOptionsText="Sin opciones" 
                                                renderInput={(params) => <TextField {...params} />}
                                                options={unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))}
                                                onChange={(e,val) => handleChangeSpecialMasivo(llaveAsignar, val)} 
                                            />
                </div>
            default:
                break;
        }
    }
    
    const mostrarModalAsignacion = () => {
        return <Dialog  style={{  padding: 30 }} open={modalAsignacionColumna}  onClose={handleCloseAsignacion}>
            <DialogContent>
        <Typography level={2} className="mt-0 mb-2"> Asigna una columna de tu excel al campo {obtenerNombrePorLlaveIdioma(llaveAsignar)}</Typography>
        {
            columnasExcel.map(key => {
                return <Button style={{ margin: 4}} key={key} onClick={() => asignarLlaveExcelADatos(key)} >{key}</Button>
            })
        }
        </DialogContent>
        </Dialog>
        
    }
    
    const mostrarModalAsignacionValorMasivo = () => {
        return <Dialog style={{  padding: 30 }} open={modalAsignacionValorMasivo}  onClose={handleCloseAsignacion}>
            <DialogContent style={{ paddingTop: 30, paddingBottom: 30, minHeight: 400 }}>
        <Typography level={2} className="mt-0 mb-2"> Define un valor para <b style={{ color: style.primary }} >{obtenerNombrePorLlaveIdioma(llaveAsignar)}</b> masivamente para todos los registros</Typography>
        <Typography level={2} sx={{ mt:2 }} className="mt-0 mb-2"> <b style={{ color: style.primary }} >{obtenerNombrePorLlaveIdioma(llaveAsignar)}</b></Typography>
        {componentePorLlave()}
        </DialogContent>
        </Dialog>
    }

    const abrirAsignacion = (key, stop) => {
        const mensaje = 'Este campo no se puede reemplazar porque no es importable desde excel'
        if(stop === true) return toast.error(mensaje)
        setLlaveAsignar(key)
        return setModalAsignacion(true)
    }
    const abrirAsignacionValorMasivo = (key, stop) => {
        const mensaje = 'Este campo no tiene permitida la escritura masiva'
        if(stop === true) return toast.error(mensaje)
        setLlaveAsignar(key)
        return setModalAsignacionValorMasivo(true)
    }

    const EncabezadoPesonalizado = ({ titulo, campo, helptext, hideReplaceButton, allowLink }) => {

        let default_help_text           = 'Asignar columna'
        let default_help_text_select    = 'Selección masiva'
        let hideAccion = false
        let allow_link_masive = true
        if(hideReplaceButton === true) hideAccion = true
        if(allowLink === true) allow_link_masive = false

        if(helptext) {
            return <Box>
           <Tooltip title={helptext} placement='top'><Typography className="mb-0 mt-0">{titulo} <HelpIcon style={{ fontSize: 14 }} /></Typography></Tooltip>
           <Tooltip title={default_help_text} placement='top'><Button size="medium" disabled={hideAccion} onClick={() => abrirAsignacion(campo, hideAccion)} variant="outlined" ><MdPublishedWithChanges /> </Button></Tooltip>
           <Tooltip title={default_help_text_select} placement='top'><Button size="medium" onClick={() => abrirAsignacionValorMasivo(campo, false)} variant="outlined" ><AiOutlineLink /> </Button></Tooltip>
            </Box>
        }

        return <Box sx={{ mb: 0 }}>
            <Typography className="mb-0 mt-0">{titulo}</Typography>
            <ButtonGroup>
            <Tooltip title={default_help_text} placement='top'><Button size="medium" disabled={hideAccion} onClick={() => abrirAsignacion(campo, hideAccion)} variant="outlined" ><MdPublishedWithChanges /></Button></Tooltip>
            <Tooltip title={default_help_text_select} placement='top'><Button disabled={allow_link_masive} size="medium" onClick={() => abrirAsignacionValorMasivo(campo, allow_link_masive)} variant="outlined" ><AiOutlineLink /> </Button></Tooltip>
            </ButtonGroup>
        </Box>
    }

    const handleChange = (e,pos) => {
        const { name, value } = e.target
        console.log({ name, value, pos })
        datos[pos][name] = value
        return setDatos([...[], ...datos])
      }

      const mostrarDatos = () => {
        if(datos.length < 1) return false

        return <div className="mt-3">
            <Box p={2}>
            <Typography variant='h5' className="mt-0 mb-2">Datos cargados</Typography>
            <LoadingButton size="small" variant="contained" loading={validando} onClick={() => subirInformacion()} >SUBIR INFORMACIÓN</LoadingButton>

            </Box>            <Divider />
            <TableContainer>
            <Table size="small" style={{ width: "100%", overflowY: "scroll" }}>
            <TableHead>
                <TableRow style={{ minWidth: 300 }}>
                    {
                        esquema.map((esq,posicion) => {
                            return <TableCell style={{ minWidth: 200 }} key={`thead-${posicion}`}>
                            <EncabezadoPesonalizado
                                hideReplaceButton={esq.hide_replace_cell} 
                                campo={esq.value} titulo={obtenerNombrePorLlaveIdioma(esq.value)} 
                                helptext={obtenerTextoAyudaPorLlaveIdioma(esq.value)} 
                                allowLink={esq.allowLink}
                            /></TableCell>
                        })
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    datos.map((data,p) => {
                        return <TableRow style={{ minWidth: 300 }}>
                            {
                                esquema.map((esq,posicion) => {
                                    let valor = null
                                    if(esq.value === "marca"){
                                        return <TableCell key={`tcell-${posicion}`}>
                                                <SelectorMarcas error={!data[esq.value] ? true : false} strict={true} onChange={data => handleChangeSpecial(esq.value, data, p)} />
                                        </TableCell>
                                    } else if(esq.value === "id_tipo"){
                                            return <TableCell key={`tcell-${posicion}`}>
                                                <SelectorTipo error={!data[esq.value] ? true : false} strict={true} onChange={data => handleChangeSpecial(esq.value, data, p)} />
                                                </TableCell>
                                    } else if(esq.value === "modelo"){
                                            return <TableCell key={`tcell-${posicion}`}>
                                                <SelectorModelo error={!data[esq.value] ? true : false} filterBy={data.marca} filterByKey="id_marca" onChange={data => handleChangeSpecial(esq.value, data, p)} />
                                                </TableCell>
                                    } else if(esq.value === "color"){
                                            return <TableCell key={`tcell-${posicion}`}>
                                                <SelectorColor value={data[esq.value]} error={!data[esq.value] ? true : false} strict={true} onChange={data => handleChangeSpecial(esq.value, data, p)} />
                                                </TableCell>
                                    } else if(esq.value === "id_categoria"){
                                            return <TableCell key={`tcell-${posicion}`}>
                                                <SelectorGeneral value={data[esq.value]} error={!data[esq.value] ? true : false} strict={true} hideLabel={true} module="categorias" condicion_default={{ tipo: category_key }} onChange={data => handleChangeSpecial(esq.value, data, p)} />
                                                </TableCell>
                                    } else if(esq.value === "id_sub_categoria"){
                                        return <TableCell key={`tcell-${posicion}`}>
                                            {/* <SelectorTipoMotor error={!data[esq.value] ? true : false} strict={true} onChange={data => handleChangeSpecial(esq.value, data, p)} /> */}
                                            <SelectorGeneral value={data[esq.value]} error={!data[esq.value] ? true : false} strict={true} hideLabel={true} module="sub-categorias" condicion_default={{ tipo: category_key }} onChange={data => handleChangeSpecial(esq.value, data, p)} />
                                            </TableCell>
                                    } else if(esq.value === "unidad_medida"){
                                        const opciones_um = unidades_medida.map(e => ({ value: e.code, label: e.unit }))
                                        if(data[esq.value]) {
                                            const i = opciones_um.findIndex(e => e.value === data[esq.value])
                                            if(i > -1) valor = opciones_um[i]
                                        }
                                        
                                        return <TableCell key={`tcell-${posicion}`}>
                                            <Autocomplete 
                                                disablePortal
                                                noOptionsText="Sin opciones" 
                                                value={valor}
                                                renderInput={(params) => <TextField {...params} />}
                                                options={opciones_um}
                                                onChange={(e,val) => handleChangeSpecial(esq.value, val, p)} 
                                            />
                                            </TableCell>
                                    } else if(esq.value === "unidad_medida_peso"){
                                        const opciones_um = unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))
                                        if(data[esq.value]) {
                                            const i = opciones_um.findIndex(e => e.value === data[esq.value])
                                            if(i > -1) valor = opciones_um[i]
                                        }
                                        return <TableCell key={`tcell-${posicion}`}>
                                            <Autocomplete 
                                                disablePortal
                                                noOptionsText="Sin opciones" 
                                                value={valor}
                                                renderInput={(params) => <TextField {...params} />}
                                                options={opciones_um}
                                                onChange={(e,val) => handleChangeSpecial(esq.value, val, p)} 
                                            />
                                            </TableCell>
                                    }
            
                                        return <TableCell key={`tcell-${posicion}`}>
                                            <TextField 
                                                name={esq.value}
                                                value={data[esq.value]} 
                                                onChange={(e) => handleChange(e,p)} 
                                                InputProps={{
                                                    startAdornment: !data[esq.value] ? (
                                                      <InputAdornment position="start">
                                                       <ErrorIcon style={{ color: "red"}} />
                                                      </InputAdornment>
                                                    ) : <InputAdornment position="start">
                                                        <CheckCircleIcon style={{ color: "green"}} />
                                                    </InputAdornment>
                                                }}
                                            />
                                        </TableCell>
                                })
                            }
                        </TableRow>
                    })
                }

            </TableBody>
                
            </Table>
            </TableContainer>
        </div>
      }

    const render = () => {
      return (
        <div>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link to={rutas[key].slug}>{rutas[key].title}</Link>
            <Enlace underline='hover' color='text.primary'>
              Importar {rutas[key].title}
            </Enlace>
          </Breadcrumbs>
          <h1 style={{ margin: 0 }}>Importar {rutas[key].title}</h1>
            <InformacionEsquema esquema={esquema} />
            <Card>
                <CardContent>
                <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
            <ModalEmpresas titulo="Seleccionar empresa" seleccionarEmpresa={(data) => {
                setEmpresa(data)
                return setDestinatario(false)
              }} />
              {
                      empresa ? <div>
                        <Card sx={{ p:2, mt: 2 }}>
                        <Typography mb={0}>Empresa asignada</Typography>
                        <Typography variant='h6' mb={0}>{empresa.razon_social}</Typography>
                        <Button onClick={() => {
                          setEmpresa(false)
                          return setDestinatario(false)
                          }}>REMOVER</Button>
                        </Card>
                        </div> : false
              }
            </Grid>
            <Grid item md={6} xs={12}>
            <ModalDestinatarios titulo="Seleccionar destinatario" condicion_default={{ propietario: empresa ? empresa.rut : null }} seleccionarEmpresa={(data) => {
                return setDestinatario(data)
              }} />
              {
                      destinatario ? <div>
                        <Card sx={{ p:2, mt: 2 }}>
                        <Typography mb={0}>Destinatario asignado</Typography>
                        <Typography variant='h6' mb={0}>{destinatario.razon_social}</Typography>
                        <Button onClick={() => {
                            return setDestinatario(false)
                          }}>REMOVER</Button>
                        </Card>
                        </div> : false
              }
            </Grid>
          </Grid>
                
                {
                     showUploadBox ? <Box>
                        <Typography className="ant-upload-text">Has click o arrastra el archivo a esta sección</Typography>
                        <Typography mb={3} className="ant-upload-hint">Solo está permitida la subida de archivos de tipo excel</Typography>
                        <Button
                            variant="contained"
                            component="label"
                            >
                            CARGAR EXCEL
                            <input
                                type="file"
                                hidden
                                onChange={handleChangeFile}
                            />
                        </Button>
                    </Box> : false
                }
            {mostrarDatos()}
            </CardContent>
            </Card>
            {mostrarErrores()}
            {mostrarModalProgreso()}
            {mostrarModalAsignacion()}
            {mostrarModalAsignacionValorMasivo()}
              
        </div>
      );
    };
  
    return <Structure component={render()} />;
  };
  
  export default BodegasImport