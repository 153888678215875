import { Accordion, AccordionDetails, Paper, Box, Button, Card, CardContent, FormControl, Grid, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography, CircularProgress, Pagination } from '@mui/material'
import React, { useEffect, useState} from 'react'
import Structure from '../Structure'
import data from '../../lib/backend/data'
import { useDispatch, useSelector } from 'react-redux'
import { cerrarSesion } from '../../redux/actions/session'
import toast from 'react-hot-toast'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { montoMoneda } from '../../lib/helpers/main'
import SelectorConductores from '../../subcomponents/conductores/selector'
import SelectorClientes from '../../subcomponents/clientes/selector'
import CargandoTabla from './cargando'
import { fechaATexto } from '../../lib/helpers/helpers'
import AssessmentIcon from '@mui/icons-material/Assessment';
import { LoadingButton } from '@mui/lab'
import * as XLSX from 'xlsx'

const Ganancias = (props) => {
    const [ value, setValue ] = useState(0)
    const dispatch = useDispatch()
    const [ loading, setLoading ] = useState(true)
    const [ loadingSubidaReporte, setLoadingSubidaReporte ] = useState(false)
    const [ loadingReporte, setLoadingReporte ] = useState(false)
    const [ openFilterDate, setOpenFilterDate ] = useState(false)
    const [ ganancias, setGanancias ] = useState([])
    const [ pagina, setPagina ] = useState(1)
    const [ conductorSeleccionado, setConductorSeleccionado ] = useState('')
    const [ clienteSeleccionado, setClienteSeleccionado ] = useState('')
    const [ total_pagado, setTotalPagado ] = useState(0)
    const [ totalPagina, setTotalPagina ] = useState(0)
    const [ total_pendiente, setTotalPendiente ] = useState(0)
    const [ total_profit, setTotalProfit ] = useState(0)
    const [ total, setTotal ] = useState(0)
    const [ estadoSeleccionado, setEstadoSeleccionado ] = useState('')
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const [ rangoFecha, setRangoFecha ] = useState([
        Date(), Date()
    ])
    const sesion = useSelector(state => state.miusuario)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const trash = props.trash ? props.trash : false
    const titulo = props.title ? props.title : 'registros'
    
    const abrirFiltroFecha = () => {
        return setOpenFilterDate( openFilterDate === true ? false : true)
    }

    const handleInputChangeExcel = (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        setErrores(prev => [])
        setRegistros(prev => [])
        if (name === 'file') {
            setLoadingExcel(true)
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            
            let errores = []
            let records = []
            
            if(errores.length > 0) return toast.error(errores.join(', ')) // SI HAY ERRORES DETENER
                        
            let columnas_detectadas = []
            hojas[0].map((row,irow) => {
                
                if(!row.ID) return false
                records.push(row.ID)
            })

            if(errores.length > 0){
                setErrores(errores)
                setLoadingExcel(false)
                return toast.error(`Hay errores en el archivo, corrijelos e intenta de nuevo`)
            }

            setLoadingExcel(false)
            return setRegistros(records)
          }
        }
    }

    const obtenerGanancias = async (page, query)=>{
        setLoading(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/ganancias/list-custom`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            }
            if(Array.isArray(res.datos) !== false){
                setGanancias(res.datos)
                if( typeof res.total_pagado !== "undefined" ) setTotalPagado(res.total_pagado)
                if( typeof res.total_pendiente !== "undefined" ) setTotalPendiente(res.total_pendiente)
                if( typeof res.total_profit !== "undefined" ) setTotalProfit(res.total_profit)
                if( typeof res.total !== "undefined" ) setTotal(res.total)
                setTotalPagina(res.total_registros)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const obtenerReporte = async (query)=>{
        setLoadingReporte(true)
        return fetch(`${data.urlapi}/ganancias/reporte`,{
            method:'POST',
            body: JSON.stringify({
                condicion: query
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob)
            var a = document.createElement('a')
            a.href = url
            const fecha = new Date().toDateString()
            a.download = `reporte-${fecha}.xlsx`
            document.body.appendChild(a)
            a.click()
            a.remove()
            return setLoadingReporte(false)
        })
        .catch(error => {
            console.log(error)
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingReporte(false)
        })
    }

    useEffect(() => {
        obtenerGanancias(1, false)
    }, [])

    const onChangeValue = (e) => {
        setConductorSeleccionado(e.value)
    }

    const onChangeValueCliente = (e) => {
        setClienteSeleccionado(e.value)
    }
    
    const onChangeFecha = (e) => {
        return setRangoFecha(e)
    }

    const mostrarFecha = () => {
        if(!openFilterDate) return false
        return <Grid item md={3}>
            <DateRangePicker onChange={onChangeFecha} value={rangoFecha} />
        </Grid>
    }

    const procesarEstado = (value) => {
        switch (value) {
            case "pendiente":
                return false
            default:
                return true
        }
    }

    const handleChangeEstadoPago = (e) => {
        const { value } = e.target
        return setEstadoSeleccionado(value)
    }

    const actualizarResultados = () => {
        if(loading) return false
        let condicion = {}
        if(conductorSeleccionado) condicion.id_conductor = conductorSeleccionado
        if(clienteSeleccionado) condicion.id_cliente = clienteSeleccionado
        if(estadoSeleccionado) condicion.pagado_proveedor = procesarEstado(estadoSeleccionado)
        if(openFilterDate === true){
            condicion.createdAt = { $gte: rangoFecha[0], $lte: rangoFecha[1] }
        }
        obtenerGanancias(1, condicion)
    }

    const subirReporte = () => {
        if(loadingSubidaReporte) return false
        console.log(registros)
        confirmarSubirReporte()
    }

    const confirmarSubirReporte = async () => {
        setLoadingSubidaReporte(true)
        return fetch(`${data.urlapi}/ganancias/upload`,{
            method:'POST',
            body: JSON.stringify(registros),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingSubidaReporte(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingSubidaReporte(false)
            } else if(res.success === true){
                toast.success("Cargado exitosamente")
                setRegistros(prev => [])
            }
            return setLoadingSubidaReporte(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingSubidaReporte(false)
        })
    }

    const descargarReporte = () => {
        if(loadingReporte) return false
        let condicion = {}
        if(conductorSeleccionado) condicion.id_conductor = conductorSeleccionado
        if(clienteSeleccionado) condicion.id_cliente = clienteSeleccionado
        if(estadoSeleccionado) condicion.pagado_proveedor = procesarEstado(estadoSeleccionado)
        if(openFilterDate === true){
            condicion.createdAt = { $gte: rangoFecha[0], $lte: rangoFecha[1] }
        }
        obtenerReporte(condicion)
    }

    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerGanancias(page,false)
    }

    const paginacion = (ciclo, totalPage) => {
        const cantidad = Math.ceil(totalPage / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination sx={{ mt:2 }} color="primary" count={cantidad} page={pagina} onChange={handleChangePagina} />
        </div>
    }

    const mostrarRegistros = () => {
        if(loading) return <div style={{ marginTop:20 }}><CargandoTabla /></div>
        return <div>

            <Grid container >
                <Grid item xs={6}>{paginacion(data.pagina, totalPagina)}</Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}><h3 sx={{ m:0 }} >{totalPagina} {titulo}</h3></Grid>
            </Grid>

            <TableContainer component={Paper} sx={{ mt:2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="table" >
            <TableHead>
            <TableRow>
                <TableCell>Tipo de viaje</TableCell>
                <TableCell>ID Viaje</TableCell>
                <TableCell>Conductor</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Pago proveedor</TableCell>
                <TableCell>Fecha</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            { ganancias.map((driver) => {
                let tipo_viaje = 'Sin información'
                let id_viaje = ''
                let conductor = ''
                let cliente = ''
                if(driver.detalle_viaje){
                    tipo_viaje = driver.detalle_viaje.modo_programacion
                }
                if(driver.detalle_viaje){
                    id_viaje = driver.detalle_viaje.id
                }
                if(driver.detalle_conductor){
                    conductor = `${driver.detalle_conductor.nombres} ${driver.detalle_conductor.apellido_p ? driver.detalle_conductor.apellido_p : ''}`
                }
                if(driver.detalle_cliente){
                    cliente = `${driver.detalle_cliente.nombre} ${driver.detalle_cliente.apellidos ? driver.detalle_cliente.apellidos : ''}`
                }
                return <TableRow
                key={driver.email}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell>{tipo_viaje}</TableCell>
                <TableCell>{id_viaje}</TableCell>
                <TableCell>{conductor}</TableCell>
                <TableCell>{cliente}</TableCell>
                <TableCell>{montoMoneda(driver.total)}</TableCell>
                <TableCell>{driver.pagado_proveedor === true ? "Pagado" : "Pendiente"}</TableCell>
                <TableCell>{fechaATexto(driver.createdAt)}</TableCell>
                </TableRow>
            })}
            </TableBody>
        </Table>
        </TableContainer>
        </div>
    }

    const mostrarLista = () => {
      return <div>
        <Grid container spacing={2} sx={{mb:2}}>
                <Grid item md={3}>
                    <Card sx={{ p:2 }}>
                        <Typography >Total</Typography>
                        <Typography variant='h4'>{ loading ? <CircularProgress /> : montoMoneda(total)}</Typography>
                    </Card>
                </Grid>
                <Grid item md={3}>
                    <Card sx={{ p:2 }}>
                        <Typography >Total pagado</Typography>
                        <Typography variant='h4'>{ loading ? <CircularProgress /> :  montoMoneda(total_pagado)}</Typography>
                    </Card>
                </Grid>
                <Grid item md={3}>
                    <Card sx={{ p:2 }}>
                        <Typography >Total pendiente</Typography>
                        <Typography variant='h4'>{ loading ? <CircularProgress /> : montoMoneda(total_pendiente)}</Typography>
                    </Card>
                </Grid>
                <Grid item md={3}>
                    <Card sx={{ p:2 }}>
                        <Typography >Total profit</Typography>
                        <Typography variant='h4'>{ loading ? <CircularProgress /> : montoMoneda(total_profit)}</Typography>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={4}>
                    <Typography variant="p">Conductor</Typography>
                    <SelectorConductores onChangeValue={(e) => onChangeValue(e)} />
                </Grid>
                <Grid item md={4}>
                    <Typography variant="p">Cliente</Typography>
                    <SelectorClientes onChangeValue={(e) => onChangeValueCliente(e)} />
                </Grid>
                <Grid item md={4}>
                    <FormControl fullWidth className='mb-3'>
                    <InputLabel id='demo-simple-select-label'>Estado del pago</InputLabel>
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            label='Estado del pago'
                            name='pagado_proveedor'
                            defaultValue={""}
                            onChange={handleChangeEstadoPago}
                        >
                            <MenuItem value="">Todos</MenuItem>
                            <MenuItem value='pendiente'>Pendiente</MenuItem>
                            <MenuItem value='pagado'>Pagado</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={3}>
                    <Button sx={{ width: "100%" }} variant={openFilterDate ? "outlined" : "contained"} onClick={() => abrirFiltroFecha()} >{openFilterDate ? "OCULTAR FECHAS" : "SELECCIONAR FECHAS"}</Button>
                </Grid>
                {mostrarFecha()}
                <Grid item md={3}>
                    <Button sx={{ width: "100%" }} variant="contained" color="success" onClick={() => actualizarResultados()} >ACTUALIZAR RESULTADOS</Button>
                </Grid>
                <Grid item md={3}>
                <LoadingButton
                    sx={{ width: "100%" }}
                    loading={loadingReporte}
                    loadingPosition="start"
                    variant="outlined"
                    color="success"
                    endIcon={<AssessmentIcon/>}
                    onClick={() => descargarReporte()}
                    >
                     EXPORTAR
                </LoadingButton>
                </Grid>
            </Grid>


            <Card sx={{ p:2, mt:2 }}>
                <Typography variant='h5' sx={{ mb:2 }}>Subir documentos pagados</Typography>
            <Grid container spacing={2}>
            <Grid item md={12}>
                <input
                        type="file" 
                        name="file" 
                        id="file" 
                        className="form-control mb-3"
                        onChange={handleInputChangeExcel} 
                        placeholder="Archivo de excel" 
                    />
                </Grid>
            <Grid item md={12}>
                <LoadingButton
                    sx={{ width: "100%" }}
                    loading={loadingSubidaReporte}
                    loadingPosition="start"
                    variant="outlined"
                    color="success"
                    endIcon={<AssessmentIcon/>}
                    onClick={() => subirReporte()}
                    >
                     SUBIR DOCUMENTO
                </LoadingButton>
                </Grid>
            </Grid>
            </Card>

            {mostrarRegistros()} 
            
        </div>
    }

    const render = () => {
        return <div>
            <h1 style={{ margin: 0 }}>Ganancias</h1>
            {mostrarLista()}
        </div>
    }

    return <Structure component={render()} />
}

export default Ganancias