import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  InputLabel,
  Grid,
  Select as Selector,
  TextField,
  Typography,
  Card,
  InputAdornment,
} from '@mui/material';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import SettingsIcon from '@mui/icons-material/Settings';
import ModalEmpresas from '../Empresas/modal_seleccion';
import ModalConductores from '../Drivers/modal_seleccion';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import { obtenerUnidadesMedidaPorTipoPais } from '../../lib/helpers';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import { rutas } from '../../lib/data/rutas';

const EquipmentCreate = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [vehiculo, setVehiculo] = useState({});
  const [loadingSelect, setLoadingSelect] = useState(true);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectOptionsColors, setSelectOptionsColors] = useState([]);
  const [ empresa, setEmpresa ] = useState(false);
  const [selectOptionsTipo, setSelectOptionsTipo] = useState([]);
  const [selectOptionsMedidas, setSelectOptionsMedidas] = useState([]);
  const [selectOptionsMedidasPeso, setSelectOptionsMedidasPeso] = useState([]);
  const [loadingSelectModelo, setLoadingSelectModelo] = useState(true);
  const [selectOptionsModelo, setSelectOptionsModelo] = useState([]);
  const [selectOptionsTipoMotor, setSelectOptionsTipoMotor] = useState([]);
  const [loadingSelectMotor, setLoadingSelectMotor] = useState(true);
  const [loadingConductores, setLoadingConductores] = useState(true);
  const [conductorSeleccionado, setConductorSeleccionado] = useState(false);
  const [opcionesConductores, setOpcionesConductores] = useState([]);
  const [ marcas, setMarcas ] = useState([])
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const sesion = useSelector((state) => state.miusuario);
  const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
  const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
  const unidades_medida_volumen       = obtenerUnidadesMedidaPorTipoPais("volumen", pais).map(e => e)
  const requeridos = [
    { value: 'titulo', label: 'Título' }
  ];
  const module = 'equipos'

  const handleChange = (e) => {
    const { name, value } = e.target;
    vehiculo[name] = value;
    return setVehiculo({ ...{}, ...vehiculo });
  };

  const handleSelect = (e) => {
    vehiculo.marca = e.value
    vehiculo.modelo = ""
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  }
  
  const handleSelectModel = (e) => {
    vehiculo.modelo = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectColor = (e) => {
    vehiculo.color = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectConductor = (e) => {
    vehiculo.id_usuario = e.value;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectTipo = (e) => {
    vehiculo.id_tipo = e.value;
    vehiculo.tipo = e.label;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectMedida = (e) => {
    vehiculo.unidad_medida = e.label
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectMedidaPeso = (e) => {
    vehiculo.unidad_medida_peso = e.label;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const handleSelectTipoMotor = (e) => {
    vehiculo.tipo_motor = e.label;
    return setVehiculo(prev => ({...{}, ...vehiculo}))
  };

  const obtenerMarcas = async () => {
    return fetch(`${data.urlapi}/vehiculos/marca/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if(Array.isArray(res) !== false){
          setMarcas(res.map(op => ({ value: op._id, label: op.titulo })))
        }
        return setLoadingSelect(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelect(false);
      })
  }

  const obtenerTipos = async () => {
    return fetch(`${data.urlapi}/vehiculos/tipo/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (res.length > 0) {
          opcionesTipo(res);
        } else if (res.length < 1) {
          setLoadingSelect(false);
          toast.error('No existen Tipos de Vehículos.');
        }
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
      });
  };

  const obtenerTiposMotor = async (id) => {
    return fetch(`${data.urlapi}/tipomotor/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (res.length > 0) {
          opcionesTipoMotor(res);
        } else if (res.length < 1) {
          setLoadingSelectMotor(false);
          toast.error('No existen Colores de Vehiculos.');
        }
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
      });
  };
  
  const obtenerConductores = async () => {
    return fetch(`${data.urlapi}/conductores/list-select`, {
      method: 'POST',
      body: JSON.stringify({ condicion: {} }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (Array.isArray(res) !== false) {
          const opciones = res.map(con => ({ value: con._id, label: `${con.nombres} ${con.apellido_p ? con.apellido_p : ''}` }))
          setOpcionesConductores(opciones)
        }
        return setLoadingConductores(false)
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingConductores(false)
      });
  };

  const obtenerColores = async (id) => {
    return fetch(`${data.urlapi}/color/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (res.length > 0) {
          opcionesColores(res);
        } else if (res.length < 1) {
          setLoadingSelect(false);
          toast.error('No existen Colores de Vehiculos.');
        }
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
      });
  };

  const medidas = async () => {
    selectOptionsMedidas.length = 0;
    selectOptionsMedidasPeso.length = 0;
    selectOptionsMedidasPeso.push(
      { value: 'kilogramos', label: 'KGS' },
      { value: 'libras', label: 'LIBRAS' }
    );

    selectOptionsMedidas.push(
      { value: 'centimetros', label: 'CM' },
      { value: 'pulgadas', label: 'IN' },
      { value: 'pies', label: 'FT' }
    );
  };

  const opciones = (res) => {
    selectOptions.length = 0;
    res.map((opcion) => {
      return selectOptions.push({ value: opcion._id, label: opcion.titulo });
    });
    setLoadingSelect(false);
    medidas();
    return selectOptions;
  };

  const opcionesTipo = (res) => {
    selectOptionsTipo.length = 0;
    res.map((opcion) => {
      if (opcion.status === 'active') {
        return selectOptionsTipo.push({
          value: opcion._id,
          label: opcion.tipo,
        });
      }
    });
    setLoadingSelect(false);
    return selectOptions;
  };

  const opcionesModelo = async (res) => {
    selectOptionsModelo.length = 0;
    res.map((opcion) => {
      return selectOptionsModelo.push({
        value: opcion._id,
        label: opcion.titulo,
      });
    });
    setLoadingSelectModelo(false);
    return selectOptionsModelo;
  };

  const opcionesColores = (res) => {
    selectOptionsColors.length = 0;
    res.map((opcion) => {
      return selectOptionsColors.push({
        value: opcion._id,
        label: opcion.titulo,
      });
    });
    setLoadingSelect(false);
    return selectOptions;
  };

  const opcionesTipoMotor = (res) => {
    selectOptionsTipoMotor.length = 0;
    res.map((opcion) => {
      return selectOptionsTipoMotor.push({
        value: opcion._id,
        label: opcion.titulo,
      });
    });
    setLoadingSelectMotor(false);
    return selectOptions;
  };

  const obtenerModelos = async () => {
    return fetch(`${data.urlapi}/vehiculos/modelo/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (Array.isArray(res) !== false) {
          setSelectOptionsModelo(res)
        }
        return setLoadingSelectModelo(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingSelectModelo(false);
      });
  };

  const crearVehiculo = async () => {
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!vehiculo[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);

    setLoading(true);
    if(empresa){
      vehiculo.propietario = empresa.rut
    }
    return fetch(`${data.urlapi}/${module}`, {
      method: 'POST',
      body: JSON.stringify(vehiculo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Creado exitosamente');
          setTimeout(() => {
            return (window.location = `${rutas.equipments.slug}-${res._id}`);
          }, 500);
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  useEffect(() => {
    obtenerMarcas();
    obtenerModelos()
    obtenerColores();
    obtenerTipos();
    obtenerTiposMotor();
    obtenerConductores();
  }, []);

  const selectorModelo = (default_modelo) => {
    if(!vehiculo.marca) return false

    const opciones_filtradas = selectOptionsModelo.filter(m => m.id_marca === vehiculo.marca)

    let valorModelo = default_modelo
    if(!vehiculo.modelo){
      valorModelo = false
    }

    return <Grid item md={3} xs={12}>
    <Typography>Modelo</Typography>
      <Select
        options={opciones_filtradas.map(m => ({ value: m._id, label: m.titulo }))}
        isLoading={loadingSelectModelo}
        loadingMessage={() => 'Cargando'}
        noOptionsMessage={() => 'Sin opciones'}
        placeholder='Selecciona un Modelo'
        value={valorModelo}
        onChange={handleSelectModel}
        height={20}
      />
    </Grid>
  }

  const render = () => {


    let default_color = false

    if(vehiculo.color){
      const i_color = selectOptionsColors.findIndex(m => m.value === vehiculo.color)
      if(i_color > -1 ){
        default_color = selectOptionsColors[i_color]
      }
    }


    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to={rutas.equipments.slug}>{rutas.equipments.title}</Link>
          <Enlace underline='hover' color='text.primary'>
            Crear {rutas.equipments.title}
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Crear {rutas.equipments.title}</h1>
        <ModalEmpresas titulo="Seleccionar empresa" seleccionarEmpresa={(data) => {
          setEmpresa(data)
          vehiculo.id_usuario = "";
          setConductorSeleccionado(false)
          return setVehiculo(prev => ({...{}, ...vehiculo}))
        }} />
        {
            empresa ? <div>
              <Card sx={{ p:2, mt: 2 }}>
              <Typography mb={0}>Empresa seleccionada</Typography>
              <Typography variant='h4' mb={0}>{empresa.razon_social}</Typography>
              <Button onClick={() => {
                setEmpresa(false)
                vehiculo.id_usuario = "";
                setConductorSeleccionado(false)
                return setVehiculo(prev => ({...{}, ...vehiculo}))
              }}>REMOVER</Button>
              </Card>
              </div> : false
          }
          <Grid container spacing={2}>
        <Grid item md={12} xs={12} mt={2}>
          <Typography variant='h6'><SettingsIcon sx={{ mb: -0.7 }} /> Información general</Typography>
          <CamposObligatoriosLeyenda />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Título'
            variant='filled'
            name='titulo'
            required={siEsRequeridoEnEsquema("titulo",requeridos)}
            defaultValue={vehiculo.titulo}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Patente'
            variant='filled'
            name='patente'
            required={siEsRequeridoEnEsquema("patente",requeridos)}
            fullWidth
            defaultValue={vehiculo.patente}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Año'
            variant='filled'
            name='ano'
            required={siEsRequeridoEnEsquema("ano",requeridos)}
            fullWidth
            defaultValue={vehiculo.ano}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Ejes'
            variant='filled'
            name='ejes'
            required={siEsRequeridoEnEsquema("ejes",requeridos)}
            fullWidth
            defaultValue={vehiculo.ejes}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Numero de chasis'
            variant='filled'
            name='chasis'
            required={siEsRequeridoEnEsquema("chasis",requeridos)}
            fullWidth
            defaultValue={vehiculo.chasis}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
        <InputLabel id="demo-simple-select-label" required={siEsRequeridoEnEsquema("color",requeridos)}>Color</InputLabel>
          <Select
            options={selectOptionsColors}
            isLoading={loadingSelect}
            loadingMessage={() => 'Cargando'}
            noOptionsMessage={() => 'Sin opciones'}
            placeholder='Selecciona un Color'
            onChange={handleSelectColor}
            defaultValue={default_color}
            height={20}
          />
        </Grid>
        <Grid item md={3} xs={12}>

          <FormControl fullWidth className='mb-3'>
          <InputLabel id="demo-simple-select-label" required={siEsRequeridoEnEsquema("status",requeridos)}>Estado</InputLabel>
          <Selector
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Estado * "
              name="status"
              onChange={handleChange}
              defaultValue={vehiculo.status}
              >
              <MenuItem value="active">Activo</MenuItem>
              <MenuItem value="trash">Desactivado</MenuItem>
          </Selector>
          </FormControl>

          </Grid>

          <Grid item xs={12}>
          <Typography variant='h6'>
              <SettingsIcon sx={{ mb: -0.7 }} /> Capacidad de carga
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>

          <FormControl fullWidth className='mb-3'>
          <InputLabel id="demo-simple-select-label" required={siEsRequeridoEnEsquema("unidad_medida",requeridos)}>Unidad de medida longitud</InputLabel>
          <Selector
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={`Unidad de medida longitud *`}
              name="unidad_medida"
              
              onChange={handleChange}
              defaultValue={vehiculo.unidad_medida}
              >
              <MenuItem value="">Selecciona</MenuItem>
                {unidades_medida.map(e => <MenuItem value={e.code}>{e.unit}</MenuItem>)}
          </Selector>
          </FormControl>

          </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Ancho'
            variant='filled'
            name='ancho'
            required={siEsRequeridoEnEsquema("ancho",requeridos)}
            fullWidth
            onChange={handleChange}
            defaultValue={vehiculo.ancho}
            InputProps={{
              startAdornment: <InputAdornment position="start">{vehiculo.unidad_medida}</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Alto'
            variant='filled'
            name='alto'
            required={siEsRequeridoEnEsquema("alto",requeridos)}
            fullWidth
            defaultValue={vehiculo.alto}
            onChange={handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">{vehiculo.unidad_medida}</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <TextField
            label='Largo'
            variant='filled'
            name='largo'
            required={siEsRequeridoEnEsquema("largo",requeridos)}
            fullWidth
            defaultValue={vehiculo.largo}
            onChange={handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">{vehiculo.unidad_medida}</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6'>
              <SettingsIcon sx={{ mb: -0.7 }} /> Capacidad de carga
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>

          <FormControl fullWidth className='mb-3'>
          <InputLabel id="demo-simple-select-label" required={siEsRequeridoEnEsquema("unidad_medida_peso",requeridos)}>Unidad de medida peso</InputLabel>
          <Selector
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Unidad de medida peso * "
              name="unidad_medida_peso"
              onChange={handleChange}
              defaultValue={vehiculo.unidad_medida_peso}
              >
              <MenuItem value="">Selecciona</MenuItem>
                {unidades_medida_peso.map(e => <MenuItem value={e.code}>{e.unit}</MenuItem>)}
          </Selector>
          </FormControl>

          </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Peso'
            variant='filled'
            name='peso'
            required={siEsRequeridoEnEsquema("peso",requeridos)}
            fullWidth
            type="number"
            defaultValue={vehiculo.peso}
            onChange={handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">{vehiculo.unidad_medida_peso}</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>

          <FormControl fullWidth className='mb-3'>
          <InputLabel id="demo-simple-select-label">Unidad de medida volumen</InputLabel>
          <Selector
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Unidad de medida volumen"
              name="unidad_medida_volumen"
              required={siEsRequeridoEnEsquema("unidad_medida_volumen",requeridos)}
              onChange={handleChange}
              defaultValue={vehiculo.unidad_medida_volumen}
              >
              <MenuItem value="">Selecciona</MenuItem>
                {unidades_medida_volumen.map(e => <MenuItem value={e.code}>{e.unit}</MenuItem>)}
          </Selector>
          </FormControl>

          </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Volumen'
            variant='filled'
            name='volumen'
            required={siEsRequeridoEnEsquema("volumen",requeridos)}
            fullWidth
            type="number"
            defaultValue={vehiculo.volumen}
            onChange={handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">{vehiculo.unidad_medida_volumen}</InputAdornment>,
            }}
          />
        </Grid>
        
          
          <Grid item md={12} xs={12}>
            {loading === true ? (
              <CircularProgress />
            ) : (
              <Button
                variant='contained'
                xs={{ m: 0 }}
                onClick={() => crearVehiculo()}
              >
                CREAR
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default EquipmentCreate;
