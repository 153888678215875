import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import PropTypes from 'prop-types';
import SinData from '../General/sin_data';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import ConfirmationAlert from '../../subcomponents/main/confirmation';

const ValoresDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [loading, setLoading] = useState(false);
  const [valores, setValores] = useState(false);
  const [loadingEliminado, setLoadingEliminado] = useState(false);
  const [permisosOtorgados, setPermisosOtorgados] = useState([]);
  const sesion = useSelector((state) => state.miusuario);
  const { id } = useParams();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const obtenerValores = async () => {
    return fetch(`${data.urlapi}/calificaciones/valores?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setValores(res);
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      });
  };

  useEffect(() => {
    obtenerValores();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    valores[name] = value;
    console.log(valores);
    return setValores({ ...{}, ...valores });
  };

  const ActualizarValores = async () => {
    const requeridos = [{ value: 'valor', label: 'Valor' }];
    let faltantes = [];
    requeridos.map((campo) => {
      if (!valores[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    valores.permisos = permisosOtorgados;

    setLoading(true);
    return fetch(`${data.urlapi}/calificaciones/valores`, {
      method: 'PUT',
      body: JSON.stringify(valores),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  }

  const eliminarRecurso = async () => {
    setLoadingEliminado(true);
    return fetch(`${data.urlapi}/calificaciones/valores?id=${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingEliminado(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingEliminado(false);
        } else if (res._id) {
          toast.success('Eliminado exitosamente');
          setTimeout(() => {
              window.location.href = '/calificaciones/valores'
          }, 500);
        }
        return setLoadingEliminado(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingEliminado(false);
      });
  };

  const render = () => {
    if (loadingMaster) return <CircularProgress />;
    if (!valores) return <SinData />;
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Href to='/calificaciones/valores'>Calificaciones Valores</Href>
          <Enlace underline='hover' color='text.primary'>
            Editar Calificaciones-Valores
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Editar valor de calificación</h1>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography variant='h6'>
                  <SettingsIcon sx={{ mb: -0.7 }} /> Detalles
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} style={{ textAlign: "right" }}>
                <ConfirmationAlert 
                    loading={loadingEliminado}
                    onConfirm={() => eliminarRecurso()}
                  />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Valor'
                  variant='filled'
                  name='valor'
                  fullWidth
                  defaultValue={valores.valor}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel id='demo-simple-select-label'>Estado</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Estado'
                    name='status'
                    onChange={handleChange}
                    defaultValue={valores.status}
                  >
                    <MenuItem value='active'>Activo</MenuItem>
                    <MenuItem value='trash'>Desactivado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  color="success"
                  onClick={() => ActualizarValores()}
                >
                  GUARDAR CAMBIOS
                </LoadingButton>
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default ValoresDetails;
