import { Box, Button, Card, Divider, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState} from 'react'
import Structure from '../Structure'
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ListadoChatSoporte from './listado';
import DetalleChat from './detalle';
import { urlapi } from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';

const AtencionCliente = () => {
    const dispatch = useDispatch()
    const [ value, setValue ] = useState(0)
    const sesion = useSelector(state => state.miusuario)
    const [ currentChat, setCurrentChat ] = useState(false)
    const [ loadingSoporte, setLoadingSoporte ] = useState(true)
    const [ estructuraSoporte, setEstructuraSoporte ] = useState([])

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ pt: 3 }}>{children}</Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        }
    }
      
    const handleChange = (e, value) => {
        return setValue(value)
    }

    const onSelectedChat = (id) => {
        window.scrollTo({top: 0, behavior: 'smooth'})
        setCurrentChat(id)
    }

    const obtenerEstructuraSoporte = async () => {
      return fetch(`${urlapi}/atencioncliente/mi-estructura`,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${sesion.tokenSession}`
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.json()
      })
      .then(res => {
          if(!res){
              toast.error('Sin datos')
              return setLoadingSoporte(false)
          } else if(res.errorMessage){
              toast.error(res.errorMessage)
              return setLoadingSoporte(false)
          } else if(Array.isArray(res) !== false){
              setEstructuraSoporte(res)
          }
          return setLoadingSoporte(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setLoadingSoporte(false)
      })
  }

  const mostrarCategorias = () => {
    if(estructuraSoporte.length < 1) return false
    return <div>
      <Typography variant='h6'>Categorías asignadas</Typography>
      <Divider sx={{ mb:2, mt:2 }}/>
      <Grid container spacing={2}>
      {
        estructuraSoporte.map(cat => {
          return <Grid item key={cat._id}>
            <Card sx={{pt:1,pb:1,pr:2,pl:2,mb:2}}><Typography variant='p' sx={{ mb:0 }}>{cat.titulo}</Typography></Card>
          </Grid>
        })
      }
      </Grid>
      <Divider sx={{ mb:2, mt:1 }}/>
    </div>
  }

  useEffect(() => {
    obtenerEstructuraSoporte()
  },[])

    const render = () => {
        return <div>
            <h1 style={{ marginTop: 0, marginBottom: 15 }}>Atención al cliente</h1>
            {mostrarCategorias()}
            <Grid container spacing={2}>
                <Grid item md={3} >
                  <Card variant="outlined">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="primary tabs example"
                >
                    <Tab  value={0} label="Abiertos" {...a11yProps(0)} />
                    <Tab  value={1} label="Cerrados" {...a11yProps(1)} />
                </Tabs>
                  </Card>
                  <TabPanel value={value} index={0} style={{ padding:0 }} >
                    <ListadoChatSoporte categorias={estructuraSoporte} condicion_default={{ status: "open" }} onSelectedChat={(id) => onSelectedChat(id)} />
                  </TabPanel>
                  <TabPanel value={value} index={1} style={{ padding:0 }} >
                    <ListadoChatSoporte categorias={estructuraSoporte} condicion_default={{ status: "closed" }} onSelectedChat={(id) => onSelectedChat(id)} />
                  </TabPanel>
                </Grid>
                <Grid item md={9} >
                    <DetalleChat currentChat={currentChat} />
                </Grid>
            </Grid> 
        </div>
    }

    return <Structure component={render()} />
}

export default AtencionCliente