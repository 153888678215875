import { Button, Card, CardContent, CircularProgress, Divider, Grid, Typography } from "@mui/material"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { style, urlapi } from "../../lib/backend/data"
import { fechaATexto } from "../../lib/helpers/helpers"
import { cerrarSesion } from "../../redux/actions/session"
import { useCallback, useEffect, useRef, useState } from "react"
import socket from '../../lib/websockets';
import DetallesTarifaBox from "../../subcomponents/viajes/detalles_tarifa_box"
import ListadoLogViaje from "../../subcomponents/viajes/log_viaje"
import { obtenerIdentificadorCliente, obtenerTelefonoCliente } from "../../lib/helpers/clientes"
import { obtenerDireccionDestino } from "../../lib/helpers/viajes"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from "react-router-dom"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const ChatViaje = (props) => {
    const dispatch = useDispatch()
    const [ loading, setLoading ] = useState(false)
    const [ mensajes, setMensajes ] = useState([])
    const [ hilo, setHilo ] = useState(false)
    const [ msg, setMsg ] = useState("")
    const sesion = useSelector(state => state.miusuario)
    const seleccionado = { maxWidth: "60%", background: "#07539e", padding: "10px 15px", lineHeight: 1, borderRadius: 6, color: "white", fontWeight: "bold", marginBottom: 5, display: "inline-block" }
    const no_seleccionado = { maxWidth: "60%", background: "black", padding: "10px 15px", lineHeight: 1, borderRadius: 6, color: "white", fontWeight: "bold", marginBottom: 5, display: "inline-block" }
    const id_cliente = props.id_cliente ? props.id_cliente : ''
    const stateRef = useRef();
    stateRef.current = mensajes

    const enviarMensajes = async (payload) => {
        const { text } = payload
        const payload_sent = {
            _id: new Date().toString(),
            id_chat: props.currentChat,
            title: "Soporte",
            to: hilo.id_creador,
            text,
            sent: true,
            pending: true,
            from: "soporte",
            createdAt: new Date().toDateString()
        }
        return fetch(`${urlapi}/soporte/chats/send`, {
            method: 'POST',
            body: JSON.stringify({
                id_chat: props.currentChat,
                mensaje: payload_sent
            }),
            headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${sesion.tokenSession}`
            }
          })
          .then(res => res.json())
          .then(pros => {

            if(!pros){
                return toast.error("Sin respuesta del servidor")
            } else if(pros.errorMessage){
                return toast.error(pros.errorMessage)
            } else if(pros._id){
                
            }
          })
          .catch(error => {
            return toast.error(error.message)
          })
    }
    

    const obtenerDetalles = async () => {
        if(!props.id_viaje) return false
        setLoading(true)
        return fetch(`${urlapi}/viajes/chat?id=${props.id_viaje}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setMensajes(res)
                stateRef.current = res
            }
            setTimeout(() => {
                var objDiv = document.getElementById("divchat");
                if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;
            }, 10);
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const procesarMensajes = (data) => {
        toast.success("nuevo mensaje")
        const msgs = stateRef.current
        msgs.push(data)
        stateRef.current = msgs
        setMensajes(prev => [...[], ...msgs])
        var objDiv = document.getElementById("divchat");
        objDiv.scrollTop = objDiv.scrollHeight;
    }
    
    useEffect(() => {
        obtenerDetalles()
        const identificador = `chat_${props.id_viaje}`
        socket.on(identificador, procesarMensajes)
        return () => socket.off(identificador, procesarMensajes)
    }, [])

    if(loading) return <div>
        <CircularProgress />
        <Typography variant="h6">Cargando mensajes, espere un momento</Typography>
        </div>

    if(!props.id_viaje) return <div>
        <Typography>No hay datos para mostrar, selecciona una conversación</Typography>
    </div>

    const handleChangeInput = (e) => {
        const { value } = e.target
        return setMsg(value)
    }

    const enviarMensaje = () => {
        if(!msg) return false
        mensajes.push({
            text: msg,
            createdAt: new Date(),
            user: {
                _id: "soporte",
                name: "Soporte"
            }
        })
        enviarMensajes({ text: msg })
        stateRef.current = mensajes
        setMsg("")
        return setMensajes(prev => [...[], ...mensajes])
    }

    const showIconByStatus = () => {
        return <AccessTimeIcon fontSize='small' sx={{ mb: -0.7, color: style.secondary }} />
    }

    const linkByCreatorType = (tipo) => {
        switch (tipo) {
            case "conductor":
                return "driver"
            default:
                return "client"
        }
    }

    const SinDatosEspeciales = () => {
        return <div style={{ padding: 20, textAlign: "center" }}>
            <div><img style={{ width: "30%" }} src="/images/computador.png" /></div>
            <Typography mb={0} variant="p" sx={{ m:0, fontSize: 15 }} >Sin datos para mostrar</Typography>
        </div>
    }

    const showIfTripType = () => {
        if(!hilo) return <SinDatosEspeciales />
        if(!hilo.detalles_viaje) return <SinDatosEspeciales />
        if(typeof hilo.detalles_viaje !== "object") return <SinDatosEspeciales />
        return <div>
            <Typography mb={0} variant="p" sx={{ m:0, fontSize: 10 }} >{fechaATexto(hilo.detalles_viaje.fecha_zona_horaria_local)}</Typography>
            <Typography mb={0} variant="h6" sx={{ m:0 }} >VIAJE {hilo.detalles_viaje.id} <b><Link target="_blank" to={`/viaje-${hilo.detalles_viaje._id}`}><OpenInNewIcon style={{ marginBottom: -4 }} fontSize="small" /></Link></b></Typography>
            <Card sx={{ pt: 1, pb: 1, pl: 2, pr: 2, mb:1, backgroundColor: style.primary }} variant="outlined" >
            <Typography sx={{ mb: 0, color: 'white' }} fontWeight="500" >{hilo.detalles_viaje.status_string}</Typography>
            </Card>
            <Typography sx={{ mb: 0, color: style.secondary, fontSize:13 }} >Cliente</Typography>
            <Typography sx={{ mb: 1, fontSize:13 }} fontWeight="600" >{obtenerIdentificadorCliente(hilo.detalles_viaje.cliente)}</Typography>
                      
            <Typography sx={{ mb: 0, color: style.secondary, fontSize:13 }} >Móvil</Typography>
            <Typography sx={{ mb: 1, fontSize:13 }} fontWeight="600" >{obtenerTelefonoCliente(hilo.detalles_viaje.cliente)}</Typography>
    
            <Typography sx={{ mb: 0, color: style.secondary, fontSize:13 }} >Origen</Typography>
            <Typography sx={{ mb: 1, fontSize:13 }} fontWeight="600" >{obtenerDireccionDestino(hilo.detalles_viaje.puntos)}</Typography>

            <Typography sx={{ mb: 0, color: style.secondary, fontSize:13 }} >Destinos</Typography>
            { hilo.detalles_viaje.puntos.filter(p => p.tipo !== 'origen').map((punto,i) => {
                return <div key={punto._id}><Typography sx={{ mb: 1, fontSize:13 }} fontWeight="600" >{i+1} {showIconByStatus(punto)} {punto.description}</Typography></div>
            }) }

            <Divider sx={{ mb:1, mt:1 }}/>
            <DetallesTarifaBox viaje={hilo.detalles_viaje} />
            <Divider sx={{ mb:1, mt:1 }}/>
            <Typography mb={0} component="div" variant="h6"sx={{ fontSize: 16, fontWeight: 'bold' }} >Log del viaje</Typography>
            <ListadoLogViaje tipoLoading="progress" condicion_default={{ id_viaje: hilo.detalles_viaje.id }} />
        </div>
    }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        enviarMensaje()
    }

    let identificador = ''

    if(hilo.persona){
        if(typeof hilo.persona === "object"){
            if(hilo.persona.identificador){
                identificador = hilo.persona.identificador
            }
        }
    }

    return <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card  sx={{ mb: 1 }} >
                    <CardContent>
                        <Grid container >
                            <Grid item xs={6}>
                                <Typography mb={0} component="div" variant="p" sx={{ fontSize: 10 }} >{hilo.fecha_zona_horaria_local ? fechaATexto(hilo.fecha_zona_horaria_local) : ''}</Typography>
                                <Typography mb={0} component="div" variant="p"sx={{ fontSize: 16, fontWeight: 'bold' }} >{hilo.topic}</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: "right" }}>
                                <Typography mb={0} component="div" variant="p" sx={{ fontSize: 10 }} >{hilo.tipo_creador}</Typography>
                                { identificador ? <Typography mb={0} component="div" variant="p"sx={{ fontSize: 16, fontWeight: 'bold' }} ><Link target="_blank" to={`/${linkByCreatorType(hilo.tipo_creador)}-${hilo.persona._id}`}>{identificador} <OpenInNewIcon style={{ marginBottom: -4 }} fontSize="small" /></Link> </Typography> : false }
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                <div style={{ paddingRight: 20, paddingLeft: 20, paddingBottom: 20 }}>
                <div id="divchat" style={{ maxHeight: 500, overflow: "scroll" }}>
                {
                    mensajes.map(message => {
                        const estilo = message.user._id === id_cliente ? seleccionado : no_seleccionado
                        const align = message.user._id === id_cliente ? "right" : "left"
                        let identificador = ''
                        if(message.user){
                            if(typeof message.user === "object"){
                                if(message.user.name) identificador = message.user.name
                            }
                        }
                        return <div key={message._id} style={{ textAlign: align }}>
                            
                            <p style={estilo}>
                                { message.user._id === id_cliente ? <Typography variant="p"><StarBorderIcon sx={{ mb: "-6px" }} /> CLIENTE</Typography> : false }
                                <Typography variant="h6" sx={{ mb:0 }}>{identificador}</Typography>
                                {message.text}<br/>
                                <b style={{ fontSize: 9 }}>{fechaATexto(message.createdAt)}</b>
                            </p>
                        </div>
                    })
                }
                </div>
                </div>
                </Card>
            </Grid>
        </Grid>
    </div>
}

export default ChatViaje