import React, { useState, useEffect, useRef } from "react";
import { maps_key } from "../../../lib/backend/data";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef, handleChange) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {  }
  );
  autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, handleChange)
  );
}

async function handlePlaceSelect(updateQuery, handleChange) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  handleChange(addressObject)
}

function SearchLocationInput(props) {
  const {
    default_value
  } = props
  const [query, setQuery] = useState(default_value ? default_value : "");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    /* eslint-disable */
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${maps_key}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef, props.onChange)
    );
    /* eslint-disable */
  }, []);

  return (
    <div className="search-location-input">
      <input
        ref={autoCompleteRef}
        style={{ height: 57, width: "100%", padding: 12 }}
        className="form-control"
        onChange={event => setQuery(event.target.value)}
        placeholder="Ingresa tu ubicación"
        value={query}
      />
    </div>
  );
}

export default SearchLocationInput;