import CargandoTabla from './cargando';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import data, { urlapi } from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { fechaATexto } from '../../lib/helpers/helpers';
import { Link } from 'react-router-dom';
import ExcelButtonDownload from '../../subcomponents/reports.js/button_download';
import { LoadingButton } from '@mui/lab';
import ModalEmpresas from '../Empresas/modal_seleccion';
import { rutas } from '../../lib/data/rutas';
import SelectorGeneral from '../../subcomponents/general/selector_general';
import { extraerValor } from '../../lib/helpers/main';
import { category_key, excel_key, key, module_name } from './data';
import ModalDestinatarios from '../Destinatarios/modal_seleccion';
import SelectorZonaV2 from '../../subcomponents/maps/selector_geocerca_v2';

const ListadoBodegas = (props) => {
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.miusuario);
  const [vehiculos, setVehiculos] = useState([]);
  const [loadingVehiculos, setLoadingVehiculos] = useState(true);
  const [pagina, setPagina] = useState(1);
  const [loadingAcciones, setLoadingAcciones] = useState(false);
  const [empresa, setEmpresa] = useState(false);
  const [ geocercaBusqueda, setGeocercaBusqueda ] = useState(false)
  const [destinatario, setDestinatario] = useState(false);
  const [campoBusqueda, setCampoBusqueda] = useState('');
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const [total, setTotal] = useState(0);
  const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
  const [condicion_busqueda, setCondicionBusqueda] = useState(
    props.condicion_default ? props.condicion_default : {}
  );
  const trash = props.trash ? props.trash : false;
  const titulo = props.title ? props.title : 'registros';
  const tipo = props.tipo ? props.tipo : 'link';
  const show_filter = typeof props.show_filter !== "undefined" ? props.show_filter : false;
  const open_new_tab = props.open_new_tab ? props.open_new_tab : false;

  const paginacion = (ciclo, total) => {
    const cantidad = Math.ceil(total / ciclo);
    if (!cantidad) return false;
    return (
      <div className='mb-3'>
        <Pagination
          sx={{ mt: 2 }}
          color='primary'
          count={cantidad}
          page={pagina}
          onChange={handleChangePagina}
        />
      </div>
    );
  };

  const obtenerVehiculos = async (page, query) => {
    setLoadingVehiculos(true);
    if (query) if (trash === true) query.status = 'trash';
    const condicion = query ? query : condicion_busqueda;
    return fetch(`${data.urlapi}/${module_name}/list`, {
      method: 'POST',
      body: JSON.stringify({
        condicion,
        pagina: page,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (Array.isArray(res.datos) !== false) {
          setVehiculos(res.datos);
          setTotal(res.total);
        }
        return setLoadingVehiculos(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingVehiculos(false);
      })
  };

  const handleChangePagina = (e, page) => {
    setPagina(page);
    return obtenerVehiculos(page, condicion_busqueda);
  };

  useEffect(() => {
    obtenerVehiculos(1, false);
  }, []);

  const buscarPorGeocerca = async () => {
    setLoadingVehiculos(true)
    return fetch(`${urlapi}/${module_name}/geo-busqueda`,{
        method:'POST',
        body: JSON.stringify({
            id_geocerca: geocercaBusqueda._id
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then((res) => {
      if (!res) {
        toast.error('Sin datos');
      } else if (res.errorMessage) {
        toast.error(res.errorMessage);
      } else if (Array.isArray(res.datos) !== false) {
        setVehiculos(res.datos);
        setTotal(res.total);
      }
      return setLoadingVehiculos(false);
    })
    .catch((error) => {
      toast.error('Error al consultar la información, intente nuevamente');
      return setLoadingVehiculos(false);
    })
}

  const agregarFiltro = (key, value) => {
    console.log( value )
    if(key === "id_categoria") delete filtroBusqueda['id_sub_categoria']
    if(!value) {
      delete filtroBusqueda[key]
    } else if(Array.isArray(value) !== false){
      filtroBusqueda[key] = { $in: value.map(v => v._id) }
    } else {
      const valor = extraerValor(value)
      if(!valor) {
        delete filtroBusqueda[key]
      } else {
        filtroBusqueda[key] = valor
      }
    }
    setFiltroBusqueda({...{}, ...filtroBusqueda})
  }

  const handleChangeSeleccion = (e) => {
    const { name } = e.target
    const valor = e.target.checked
    const i = vehiculos.findIndex(ve => ve._id === name )
    vehiculos[i].seleccionado = valor
    setVehiculos([...[], ...vehiculos])
  }

  const deseleccionarTodos = (e) => {
    setVehiculos([...[], ...vehiculos.map(veh => { 
      veh.seleccionado = e.target.checked
      return veh 
    })])
  }

  const handleSubmit = (e) => {
    if(props.onCleanFilter) props.onCleanFilter()
    if(e) e.preventDefault();
    let cond = {}
    Object.keys(filtroBusqueda).map(llave => {
        if(filtroBusqueda[llave]['$in']){
            if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
            }
        } else {
          cond[llave] = filtroBusqueda[llave]
        }
    })
    if(campoBusqueda) cond["$text"] = { $search: campoBusqueda.toLowerCase() }
    setCondicionBusqueda(cond);
    setPagina(1);
    return obtenerVehiculos(1, cond);
  };

  const handleChangeCampo = (e) => {
    const { value } = e.target;
    return setCampoBusqueda(value);
  };

  const asignacionEmpresa = async (campo) => {
    setLoadingAcciones(true);
    const ids = vehiculos.filter(veh => veh.seleccionado === true).map(veh => veh._id)
    return fetch(`${data.urlapi}/${module_name}/asignacion/empresa`, {
      method: 'POST',
      body: JSON.stringify({
        ids,
        id_cliente: campo,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        console.log(res)
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if(res.modifiedCount){
          toast.success("Realizado exitosamente")
          setTimeout(() => {
              window.location.reload()
          }, 1000);
        }
        return setLoadingAcciones(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingAcciones(false);
      });
  };


  const acciones = () => {
    if(loadingAcciones) return <CircularProgress  />
    const seleccionados = vehiculos.filter(veh => veh.seleccionado === true).length
    const enable = seleccionados > 0 ? false : true
    return <Box sx={{ mt:2}}>
      <Card variant="outlined">
      <Grid container spacing={2}>
        <Grid item md={2}>
        <LoadingButton disabled={enable} style={{ fontSize: 11 }} fullWidth color='inherit' onClick={() => asignacionEmpresa('')}>DESVINCULAR DESTINATARIO</LoadingButton>
        </Grid>
        <Grid item md={2}>
        <ModalDestinatarios seleccionarEmpresa={(compania) => asignacionEmpresa(compania._id)} custom_component={<LoadingButton style={{ fontSize: 11 }} disabled={enable} fullWidth color='inherit'>ASIGNAR DESTINATARIO</LoadingButton>} titulo="REASIGNAR DESTINATARIO" />
        </Grid>
      </Grid>
      </Card>
    </Box>
  }

  const borrarFiltros = () => {
    const objeto_limpio = {...{}, ...{}}
    setEmpresa(false)
    setDestinatario(false)
    setFiltroBusqueda(objeto_limpio)    
    setCondicionBusqueda(objeto_limpio);
    setPagina(1);
    return obtenerVehiculos(1, objeto_limpio);
  }

  const filtro = () => {
    if(show_filter !== true) return false
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              component='form'
              noValidate
              onSubmit={handleSubmit}
              sx={{ mb: 1 }}
            >
                <TextField
                id='outlined-basic'
                // size=""
                label='Buscar por título o patente'
                variant='outlined'
                onChange={handleChangeCampo}
                fullWidth
              />
              </Box>
          </Grid>
          <Grid item xs={4}>
          <ModalEmpresas seleccionado={empresa} showSeleccionado={true} titulo="Filtrar empresa" seleccionarEmpresa={(data) => {
                agregarFiltro('id_cliente', '')
                agregarFiltro('propietario', data ? data.rut : '')
                setEmpresa(data)
              }} />
          </Grid>
          <Grid item xs={4}>
          <ModalDestinatarios seleccionado={destinatario} condicion_default={{ propietario: filtroBusqueda.propietario }} showSeleccionado={true} titulo="Filtrar destinatario" seleccionarEmpresa={(data) => {
                agregarFiltro('id_cliente', data ? data._id : '')
                setDestinatario(data)
              }} />
          </Grid>
          <Grid item xs={2}><LoadingButton style={{ width: "100%" }} type="submit" variant="contained" onClick={handleSubmit} >BUSCAR</LoadingButton></Grid>
          <Grid item xs={2}><LoadingButton style={{ width: "100%" }} type="submit" variant="contained" onClick={() => borrarFiltros()} >BORRAR FILTROS</LoadingButton></Grid>
          <Grid item xs={3}><ExcelButtonDownload size="medium" condicion={condicion_busqueda} slug={excel_key} /></Grid>
        </Grid>
        <Grid container sx={{ mt:1 }} spacing={2} >
        <Grid item md={4}>
            <Typography>Búsqueda por zona</Typography>
            <SelectorZonaV2 defaultValue={geocercaBusqueda ? geocercaBusqueda.titulo.toUpperCase() : ''} onChange={data => setGeocercaBusqueda(data)} />
          </Grid>
          <Grid item md={4}>
              {
                geocercaBusqueda ? <div>
                  <Typography>Click aquí para buscar</Typography>
                  <Button style={{ width: "100%"}} variant="outlined" size="large" onClick={() => buscarPorGeocerca()} >BUSCAR EN {geocercaBusqueda.titulo ? geocercaBusqueda.titulo.toUpperCase() : "No determinado"}</Button>
                </div> : false
              }   
          </Grid>
        </Grid>
      </div>
    );
  };

  const seleccionarVehiculo = (driver) => {
    if (props.seleccionarVehiculo) props.seleccionarVehiculo(driver);
    return false;
  };

  const showByType = (tipo, driver) => {
    switch (tipo) {
      case 'funcion':
        return (
          <b onClick={() => seleccionarVehiculo(driver)}>{driver.titulo}</b>
        );
      case 'link':
        return (
          <b>
            <Link target={open_new_tab === true ? "_blank" : "_self"} to={`${rutas[key].slug}-${driver._id}`}>
                        {driver.titulo ? driver.titulo.toUpperCase() : 'VER DETALLES'}
            </Link>
          </b>
        );
      default:
        return;
    }
  };
  
  const mostrarTabla = () => {
    if(loadingVehiculos) return <CargandoTabla />
    if (vehiculos.length < 1) return <h3>NO HAY REGISTROS</h3>;
    return <div>
      <Grid container>
          <Grid item xs={6}>
            {paginacion(data.pagina, total)}
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <h3 sx={{ m: 0 }}>
              {total} REGISTROS
            </h3>
          </Grid>
        </Grid>
      
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label='sticky table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell><Checkbox size='small' onChange={deseleccionarTodos} /></TableCell>
                <TableCell>Título</TableCell>
                <TableCell>Empresa</TableCell>
                <TableCell>Destinatario</TableCell>
                <TableCell>Dirección</TableCell>
                <TableCell>Registro</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehiculos.map((driver) => (
                <TableRow
                key={driver._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell><Checkbox size='small' id='vehiculo_check' checked={driver.seleccionado ? true : false} name={driver._id} onChange={handleChangeSeleccion} /></TableCell>
                  <TableCell>{showByType(tipo, driver)}</TableCell>
                  <TableCell>{driver.empresa}</TableCell>
                  <TableCell>{driver.destinatario}</TableCell>
                  <TableCell>{driver.direccion}</TableCell>
                  <TableCell>{fechaATexto(driver.createdAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </div>
  }
  
  return (
    <div>
      {filtro()}
      {acciones()}
      {mostrarTabla()}
    </div>
  );

};

export default ListadoBodegas