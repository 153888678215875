import { Avatar, Grid, Skeleton } from "@mui/material"

const datos = [
    {},
    {},
    {}
]

const CargandoTabla = () => {
    return <div>
        <Skeleton variant="rectangular" animation="wave" height={70} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" animation="wave" height={70} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" animation="wave" height={70} sx={{ mb: 2 }} />
    </div>
}

export default CargandoTabla