import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import PropTypes from 'prop-types';
import SinData from '../General/sin_data';
import SettingsIcon from '@mui/icons-material/Settings';
import RequisitosCreate from './crear';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';

const RequisitoDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(true);
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const [requisito, setRequisito] = useState(false);
  const [selectOptionsTipo, setSelectOptionsTipo] = useState([]);
  const [permisosOtorgados, setPermisosOtorgados] = useState([]);
  const sesion = useSelector((state) => state.miusuario);
  const { id } = useParams();
  const requeridos = [
      { value: 'titulo', label: 'Titulo' },
      { value: 'tipo', label: 'Tipo' },
    ];
    
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleSelectTipo = (e) => {
    requisito.sub_tipo = e.target.value;
  };

  const obtenerRequisito = async () => {
    return fetch(`${data.urlapi}/requisitos/tipo?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setRequisito(res);
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      });
  };

  const obtenerTipos = async () => {
    return fetch(`${data.urlapi}/vehiculos/tipo/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (res.length > 0) {
          opcionesTipo(res);
        } else if (res.length < 1) {
          setLoadingSelect(false);
          toast.error('No existen Tipos de Vehículos.');
        }
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
      });
  };

  useEffect(() => {
    obtenerRequisito();
    obtenerTipos();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    requisito[name] = value;
    return setRequisito({ ...{}, ...requisito });
  };

  const actualizarRequisito = async () => {
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!requisito[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    requisito.permisos = permisosOtorgados;

    if (requisito.tipo !== 'Vehiculo') {
      requisito.vehiculo_name = '';
      requisito.tipo_vehiculo = '';
    }
    setLoading(true);
    return fetch(`${data.urlapi}/requisitos/tipo`, {
      method: 'PUT',
      body: JSON.stringify(requisito),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const opcionesTipo = (res) => {
    selectOptionsTipo.length = 0;
    res.map((opcion) => {
      if (opcion.status === 'active') {
        return selectOptionsTipo.push({
          value: opcion._id,
          label: opcion.tipo,
        });
      }
    });
    setLoadingSelect(false);
    return selectOptionsTipo;
  };

  const render = () => {
    if (loadingMaster) return <CircularProgress />;
    if (!requisito) return <SinData />;
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Href to='/requisitos'>Requisitos</Href>
          <Enlace underline='hover' color='text.primary'>
            Editar Requisitos
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Editar Requisitos</h1>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography variant='h6'>
                  <SettingsIcon sx={{ mb: -0.7 }} /> Detalles</Typography>
                  <CamposObligatoriosLeyenda />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Título'
                  variant='filled'
                  name='titulo'
                  required={siEsRequeridoEnEsquema("titulo",requeridos)}
                  fullWidth
                  defaultValue={requisito.titulo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
            <TextField
              label='Descripción'
              variant='filled'
              name='descripcion'
              required={siEsRequeridoEnEsquema("descripcion",requeridos)}
              fullWidth
              onChange={handleChange}
              defaultValue={requisito.descripcion}
            />
          </Grid>

              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel required={siEsRequeridoEnEsquema("tipo",requeridos)}>Tipo</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Tipo *'
                    name='tipo'
                    onChange={handleChange}
                    defaultValue={requisito.tipo}
                  >
                    <MenuItem value='cliente'>Cliente</MenuItem>
                    <MenuItem value='empresa'>Empresa</MenuItem>
                    <MenuItem value='conductor'>Conductor</MenuItem>
                    <MenuItem value='vehiculo'>Vehículo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {requisito.tipo === 'vehiculo' ? (
                <Grid item md={3} xs={12}>
                  <FormControl fullWidth className='mb-3'>
                    <InputLabel required={siEsRequeridoEnEsquema("sub_tipo",requeridos)}>Tipo de Vehiculo</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      label='Tipo de vehiculo *'
                      name='sub_tipo'
                      onChange={handleSelectTipo}
                      defaultValue={requisito.sub_tipo}
                    >
                       <MenuItem value={"todos"}>Todos</MenuItem>
                      {selectOptionsTipo.map((opcion) => {
                        return (
                          <MenuItem key={opcion._id} value={opcion.value}>
                            {opcion.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                ''
              )}

              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel required={siEsRequeridoEnEsquema("status",requeridos)}>Estado</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Estado *'
                    name='status'
                    onChange={handleChange}
                    defaultValue={requisito.status}
                  >
                    <MenuItem value='active'>Activo</MenuItem>
                    <MenuItem value='trash'>Desactivado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                {loading === true ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant='contained'
                    color='success'
                    xs={{ m: 0 }}
                    onClick={() => actualizarRequisito()}
                  >
                    GUARDAR CAMBIOS
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default RequisitoDetails;
