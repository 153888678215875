import CargandoTabla from './cargando';
import {
  CircularProgress,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { fechaATexto } from '../../lib/helpers/helpers';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineOppositeContent } from '@mui/lab';

const ListadoLogViaje = (props) => {
  const { show_title } = props
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.miusuario);
  const [vehiculos, setVehiculos] = useState([]);
  const [loadingVehiculos, setLoadingVehiculos] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [total, setTotal] = useState(0);
  const [condicion_busqueda, setCondicionBusqueda] = useState(
    props.condicion_default ? props.condicion_default : {}
  );
  const trash = props.trash ? props.trash : false;
  const titulo = props.title ? props.title : 'registros';
  const tipo = props.tipo ? props.tipo : "list"
  const tipoLoading = props.tipoLoading ? props.tipoLoading : "table"

  const paginacion = (ciclo, total) => {
    const cantidad = Math.ceil(total / ciclo);
    if (!cantidad) return false;
    return (
      <div className='mb-3'>
        <Pagination
          sx={{ mt: 2 }}
          color='primary'
          count={cantidad}
          page={pagina}
          onChange={handleChangePagina}
        />
      </div>
    );
  };

  const obtenerVehiculos = async (page, query) => {
    setLoadingVehiculos(true);
    if (query) if (trash === true) query.status = 'trash';
    const condicion = query ? query : condicion_busqueda;
    return fetch(`${data.urlapi}/viajes/log/list`, {
      method: 'POST',
      body: JSON.stringify({
        condicion,
        pagina: page,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingVehiculos(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingVehiculos(false);
        }
        if (Array.isArray(res.datos) !== false) {
          setVehiculos(res.datos);
          setTotal(res.total);
        }
        return setLoadingVehiculos(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingVehiculos(false);
      });
  };

  const handleChangePagina = (e, page) => {
    setPagina(page);
    return obtenerVehiculos(page, false);
  };

  useEffect(() => {
    obtenerVehiculos(1, false);
  }, []);

  const listadoTabla = () => {
    return (
      <div style={{ marginBottom: 20 }}>
        {
          show_title === false ? false : <Typography variant='h6'>Histórico de eventos</Typography>
        }
        <Grid container>
          <Grid item xs={6}>
            {paginacion(data.pagina, total)}
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <h3 sx={{ m: 0 }}>
              {total} {titulo}
            </h3>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label='sticky table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Descripción</TableCell>
                <TableCell>Registro</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vehiculos.map((driver) => (
                <TableRow
                  key={driver._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{driver.descripcion}</TableCell>
                  <TableCell>{fechaATexto(driver.createdAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

  const listadoLineaTiempo = () => {
    return <Timeline>
    {
      vehiculos.map(veh => {
        return <TimelineItem key={veh._id}>
        <TimelineOppositeContent color="textSecondary">{fechaATexto(veh.fecha_zona_horaria_local)}</TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>{veh.descripcion}</TimelineContent>
        </TimelineItem>
      })
    }
  </Timeline>
  }

  const mostrarRegistros = () => {
    if (vehiculos.length < 1) return <Typography variant="h6" sx={{ p:0, fontSize:17  }}>No hay registros</Typography>;

    switch (tipo) {
      case "timeline":
        return listadoLineaTiempo()
      default:
        return listadoTabla()
    }
  };

  if (loadingVehiculos){
    switch (tipoLoading) {
      case "table":
        return <CargandoTabla />;
      default:
        return <div style={{ padding: 10 }}><CircularProgress /></div>
    }
  }
  return <div>{mostrarRegistros()}</div>;
};

export default ListadoLogViaje;