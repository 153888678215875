import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Structure from '../Structure';
import { Link, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';

const SeguroCreate = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [seguro, setSeguro] = useState({});
  const sesion = useSelector((state) => state.miusuario);
  const requeridos = [
    { value: 'titulo', label: 'Titulo' },
    { value: 'tipo', label: 'Tipo' },
    { value: 'descripcion', label: 'Descripcion' },
    { value: 'valor', label: 'Valor' },
    { value: 'unidad_medida', label: 'UnidadMedida' },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    seguro[name] = value;
    return setSeguro({ ...{}, ...seguro });
  };

  const crearSoporte = async () => {
    console.log(sesion);
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!seguro[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);

    setLoading(true);
    return fetch(`${data.urlapi}/seguros`, {
      method: 'POST',
      body: JSON.stringify(seguro),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Creado exitosamente');
          setTimeout(() => {
            return (window.location = `/seguro-carga-${res._id}`);
          }, 500);
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const render = () => {
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to='/seguro-carga'>Seguros de Carga</Link>
          <Enlace underline='hover' color='text.primary'>
            Crear Seguro
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Crear Seguro</h1>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item md={12} xs={12}>
            <Typography variant='h6'>Datos</Typography>
            <CamposObligatoriosLeyenda />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label='Titulo'
              variant='filled'
              name='titulo'
              required={siEsRequeridoEnEsquema("titulo",requeridos)}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth className='mb-3'>
              <InputLabel required={siEsRequeridoEnEsquema("tipo",requeridos)}>Tipo</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Tipo'
                name='tipo'
                
                onChange={handleChange}
              >
                <MenuItem value='carga'>Carga</MenuItem>
                <MenuItem value='cliente'>Cliente</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label='Descripción'
              variant='filled'
              name='descripcion'
              required={siEsRequeridoEnEsquema("descripcion",requeridos)}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label='Valor'
              variant='filled'
              name='valor'
              required={siEsRequeridoEnEsquema("valor",requeridos)}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth className='mb-3'>
              <InputLabel required={siEsRequeridoEnEsquema("unidad_medida",requeridos)}>
                Unidad de medida
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='UnidadMedida'
                name='unidad_medida'
                
                onChange={handleChange}
              >
                <MenuItem value='uf'>UF</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={12} xs={12}>
            {loading === true ? (
              <CircularProgress />
            ) : (
              <Button
                variant='contained'
                xs={{ m: 0 }}
                onClick={() => crearSoporte()}
              >
                CREAR
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default SeguroCreate;
