import { Grid } from '@mui/material'

const SinData = () => {
    return <div className='p-5'>
            <Grid container className="justify-content-md-center">
                <Grid item md={7} className="text-center">
                <h4>No se puede mostrar esta página</h4>
                <p>No se obtuvieron suficientes datos para mostrar el recurso que intentas visualizar</p>
                </Grid>
            </Grid>
        </div>
}

export default SinData