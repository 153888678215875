import { Skeleton } from "@mui/material"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

const conductores = [
    {},
    {},
    {},
    {}
]

const CargandoTabla = () => {
    return <div>
<TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
                <TableHead>
                <TableRow>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                { conductores.map((driver,i) => (
                    <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
    </div>
}

export default CargandoTabla