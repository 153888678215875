import { LoadingButton } from "@mui/lab"
import { CircularProgress, Grid, MenuItem, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/session"

const FormularioTiempoSolicitudes = (props) => {
    const [ configuracion, setConfiguracion ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const dispatch = useDispatch()
    const sesion = useSelector((state) => state.miusuario)
    const showTitle = props.showTitle ? props.showTitle : false

    const handleChange = (e) => {
        const { name, value } = e.target
        configuracion[name] = value
        return setConfiguracion(configuracion)
    }

    const obtenerConfiguracion = async (tipo, subtipo) => {
        setLoading(true);
        return fetch(`${urlapi}/configuracion/tipo?tipo=${tipo}&subtipo=${subtipo}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
        },
        })
        .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
        })
        .then((res) => {
            console.log(res)
            if (!res) {
                toast.error('Sin datos');
                return setLoading(false);
            } else if (res.errorMessage) {
                toast.error(res.errorMessage);
                return setLoading(false);
            } else if (res._id) {
                setConfiguracion(res)
            }
            return setLoading(false);
        })
        .catch((error) => {
            toast.error('Error al consultar la información, intente nuevamente');
            return setLoading(false);
        })
    }
    
    const actualizarConfiguracion = async () => {
        setLoadingGuardado(true);
        return fetch(`${urlapi}/configuracion`, {
        method: 'PUT',
        body: JSON.stringify(configuracion),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
        },
        })
        .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
        })
        .then((res) => {
            console.log(res)
            if (!res) {
                toast.error('Sin datos');
                return setLoadingGuardado(false);
            } else if (res.errorMessage) {
                toast.error(res.errorMessage);
                return setLoadingGuardado(false);
            } else if (res._id) {
                toast.success("Guardado exitosamente")
            }
            return setLoadingGuardado(false);
        })
        .catch((error) => {
            toast.error('Error al consultar la información, intente nuevamente');
            return setLoadingGuardado(false);
        })
    }

    useEffect(() => {
        obtenerConfiguracion("restricciones_conductor","tiempo_solicitud")
    }, [])

    if(loading) return <div><CircularProgress /></div>

    return <div style={{ marginTop: 20 }}>
        { showTitle === true ? <Typography variant='h5' sx={{ mb:1 }} >Tiempo duración de solicitudes de viaje a conductores</Typography> : false }
        <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
                <TextField
                  label='Duración de solicitudes'
                  variant='filled'
                  name='numero'
                  fullWidth
                  type="number"
                  defaultValue={configuracion.numero}
                  onChange={handleChange}
                />
              </Grid>
            <Grid item md={12} xs={12}>
            <LoadingButton
                    sx={{ width: "100%" }}
                    loading={loadingGuardado}
                    loadingPosition="start"
                    variant="outlined"
                    color="success"
                    onClick={() => actualizarConfiguracion()}
                    >
                     GUARDAR CAMBIOS
                </LoadingButton>
              </Grid>
        </Grid>
    </div>
}

export default FormularioTiempoSolicitudes