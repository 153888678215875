import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ListadoDeCalificaciones from './listado';

const Ratings = () => {
  const [value, setValue] = useState(0);
  const sesion = useSelector((state) => state.miusuario);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (e, value) => {
    return setValue(value);
  };

  const tabsConductores = () => {
    return (
      <div>
        <ListadoDeCalificaciones
            tipo="modal"
            condicion_default={{ destino: 'conductor' }}
          />
      </div>
    );
  };

  const tabsClientes = () => {
    return (
      <div>
        <ListadoDeCalificaciones
            tipo="modal"
            condicion_default={{ destino: 'cliente' }}
          />
      </div>
    );
  };

  const render = () => {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <h1 style={{ margin: 0 }}>Calificacion</h1>

            <Box sx={{ width: '100%' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor='primary'
                indicatorColor='primary'
                aria-label='primary tabs example'
              >
                <Tab value={0} label='Conductores' {...a11yProps(0)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              {tabsConductores()}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {tabsClientes()}
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default Ratings;
