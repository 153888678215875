import {
  Divider,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import { rutas } from '../../lib/data/rutas';

const MenuConfig = () => {
  return (
    <nav aria-label='main mailbox folders'>
      <Link
        to='/configuration'
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='General' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Link
        to='/users'
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Usuarios' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Link
        to='/configuration-vehicles-type'
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Tipos de vehículo' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />

      <Divider />
      <Link
        to={rutas.vehicles_brands.slug}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Marcas de vehículo' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Divider />
      <Link
        to='/model/vehicles'
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Modelos de vehículo' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Divider />
      <Link
        to='/color-vehicles'
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Colores de vehículo' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Divider />
      <Link to='/motor-type' style={{ textDecoration: 'none', color: 'black' }}>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Tipos de motor' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Divider />
      <Link to='/subsoporte' style={{ textDecoration: 'none', color: 'black' }}>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Categorías de soporte' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Divider />
      <Link
        to='/seguro-carga'
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Seguro de carga' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Divider />
      <Link to='/requisitos' style={{ textDecoration: 'none', color: 'black' }}>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Requisitos' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Divider />
      <Link
        to='/calificaciones/opciones'
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Opciones de calificaciones' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Divider />
      <Link
        to='/calificaciones/valores'
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Valores de calificaciones' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Divider />
      <Link to='/banco' style={{ textDecoration: 'none', color: 'black' }}>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Bancos' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Link
        to='/terms-conditions'
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Términos y condiciones' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Link
        to='/publicacion'
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Publicaciones' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
      <Divider />
      <Link
        to='/geocercas'
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary='Geocercas' />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider />
    </nav>
  );
};

export default MenuConfig;
